import { IObject } from 'helpers/types';
import { AnyAction } from 'redux';
import { ON_SHARE_CSV_IMPORT_SUCCEEDED } from 'redux/constants/campaign.action.constants';
import { ON_RELAUNCH_CAMPAIGN_SUCCEDED } from 'redux/constants/myCampaigns.action.constants';

import * as constants from '../constants/share.action.constants';

/*TODO FIXME psokolowicz change shareCampaignToCopy variable name*/
interface IShareReducerState {
  step: number;
  chosen: string;
  importChosen: string;
  shareData: IObject[];
  mailchimp: {
    lists: IObject[];
    listFields: IObject[];
    segments: IObject[];
    segmentMembers: IObject[];
  };
  success: boolean;
  shareCampaignToCopy: string;
  loading: boolean;
}

const defaultState: IShareReducerState = {
  step: 0,
  chosen: '',
  importChosen: '',
  shareData: [],
  mailchimp: {
    lists: [],
    listFields: [],
    segments: [],
    segmentMembers: [],
  },
  success: false,
  shareCampaignToCopy: '',
  loading: true,
};

const addShareData = (state: IShareReducerState, action: AnyAction) => {
  return {
    ...state,
    shareData: action.data,
  };
};

const removeShareData = (state: IShareReducerState) => {
  return {
    ...state,
    shareData: [],
  };
};

const changeStep = (state: IShareReducerState, action: AnyAction) => {
  return {
    ...state,
    step: action.step,
  };
};

const changeChosen = (state: IShareReducerState, action: AnyAction) => {
  return {
    ...state,
    chosen: action.chosen,
  };
};

const changeImportSelected = (state: IShareReducerState, action: AnyAction) => {
  return {
    ...state,
    importChosen: action.importChosen,
  };
};

const addMailchimpLists = (state: IShareReducerState, action: AnyAction) => {
  return {
    ...state,
    mailchimp: {
      ...state.mailchimp,
      lists: action.data.lists.lists,
      listFields: action.data.mergeFields.merge_fields,
      segments: action.data.segments,
    },
    loading: false,
  };
};

const addListMergeFields = (state: IShareReducerState, action: AnyAction) => {
  return {
    ...state,
    mailchimp: {
      ...state.mailchimp,
      listFields: action.data.merge_fields,
    },
  };
};

const addSegmentMembers = (state: IShareReducerState, action: AnyAction) => {
  return {
    ...state,
    mailchimp: {
      ...state.mailchimp,
      segmentMembers: action.data.members,
    },
  };
};

const addMailchimpFieldsSegments = (state: IShareReducerState, action: AnyAction) => {
  return {
    ...state,
    mailchimp: {
      ...state.mailchimp,
      listFields: action.data.mergeFields,
      segmentMembers: action.data.members,
    },
    loading: false,
  };
};

const setShareSuccess = (state: IShareReducerState) => {
  return {
    ...state,
    success: true,
  };
};

const setShareStatusAsDefault = (state: IShareReducerState) => {
  return {
    ...state,
    success: false,
  };
};

const setShareCampaignToCopyAsDefault = (state: IShareReducerState) => {
  return {
    ...state,
    shareCampaignToCopy: '',
  };
};

const setShareCampaignToCopy = (state: IShareReducerState, action: AnyAction) => {
  return {
    ...state,
    shareCampaignToCopy: action.id,
  };
};

const removeLoading = (state: IShareReducerState) => {
  return {
    ...state,
    loading: false,
  };
};

const setLoading = (state: IShareReducerState) => {
  return {
    ...state,
    loading: true,
  };
};

const shareReducer = (
  state: IShareReducerState = defaultState,
  action: AnyAction
): IShareReducerState => {
  switch (action.type) {
    case constants.ON_CHANGE_STEP_REQUESTED:
      return changeStep(state, action);
    case constants.ON_CHANGE_CHOSEN_REQUESTED:
      return changeChosen(state, action);
    case constants.ON_IMPORT_SELECTED_REQUESTED:
      return changeImportSelected(state, action);
    case constants.ON_INITIALIZE_CAMPAIGN_SHARE_SUCCEDEED:
      return addShareData(state, action);
    case constants.ON_MAILCHIMP_IMPORT_SUCCEEDED:
      return addMailchimpLists(state, action);
    case constants.ON_INITIALIZE_MATCH_VIEW_MERGE_FIELDS_SUCCEDED:
      return addListMergeFields(state, action);
    case constants.ON_INITIALIZE_MATCH_VIEW_SEGMENT_MEMBERS_SUCCEDED:
      return addSegmentMembers(state, action);
    case constants.ON_INITIALIZE_SHARE_MATCH_SUCCEDED:
      return addMailchimpFieldsSegments(state, action);
    case constants.ON_SUBMIT_ONE_TO_ONE_SEND_MAIL_SUCCEDEED:
    case constants.ON_SELECT_MAILCHIMP_SEND_MAIL_SUCCEDED:
    case constants.ON_SELECT_MAILCHIMP_LAUNCH_SUCCEDED:
      return setShareSuccess(state);
    case constants.ON_SUBMIT_SHARE_MATCH_SUCCEDEED:
    case ON_RELAUNCH_CAMPAIGN_SUCCEDED:
      return setShareStatusAsDefault(state);
    case constants.ON_REQUEST_LINK_SUCCEDED:
      return setShareCampaignToCopy(state, action);
    case constants.ON_REQUEST_LINK_FAILED:
      return setShareCampaignToCopyAsDefault(state);
    case constants.ON_RETRIEVE_SEGMENT_MEMBERS_SUCCEDED:
      return addSegmentMembers(state, action);
    case constants.ON_INITIALIZE_SHARE_LAUNCH_REQUESTED:
      return removeShareData(state);
    case ON_SHARE_CSV_IMPORT_SUCCEEDED:
      return removeLoading(state);
    case constants.ON_INITIALIZE_SHARE_MATCH_REQUESTED:
      return setLoading(state);
    default:
      return state;
  }
};

export default shareReducer;
