import styled from 'styled-components';

import { IFadeprops, IIsError } from './types';

export const TransitionModal = styled.div<IFadeprops>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.3);
  z-index: 150;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  opacity: ${(props) => {
    if (props.fadeType === 'in') {
      return '1';
    } else {
      return '0';
    }
  }};
  transition: ${(props) => {
    if (props.fadeType === 'in') {
      return `opacity ease-in 0.25s;`;
    } else if (props.fadeType === 'out') {
      return `opacity ease-in 0.25s;`;
    } else {
      return '';
    }
  }};
`;

export const ModalContainer = styled.div`
  width: 420px;
  height: 270px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0px 2px 6px #00000029;
  background: ${(props) => props.theme.colors.white};
`;

export const TextContainer = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 20px;
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  margin-bottom: 20px;
  text-align: center;
`;

export const Message = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.raven};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  text-align: center;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  place-content: center;
  justify-content: center;
`;

export const Button = styled.button<IIsError>`
  width: 140px;
  height: 40px;
  text-align: center;
  background: ${(props) => (props.isError ? props.theme.colors.cello : props.theme.colors.white)};
  color: ${(props) => (props.isError ? props.theme.colors.white : props.theme.colors.fiord)};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.light};
  cursor: pointer;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  transition all .2s ease-in;
  border: 1px solid #EBEDEF;
  border-radius: 6px;
  margin-right: 5px;
  margin-left: 5px;
  &:hover{
    background: ${(props) =>
      props.isError ? props.theme.colors.bigStone : props.theme.colors.porcelain};
    color: ${(props) => (props.isError ? props.theme.colors.white : props.theme.colors.bigStone)};
  }
`;

export const MessageCampaign = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.raven};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  text-align: center;
  margin-bottom: 10px;
`;

export const CloseModalWrapper = styled.div`
  width: 420px;
  height: 35px;
  justify-content: flex-end;
  display: flex;
  position: absolute;
`;
