import React, { FC, useEffect, useRef, useState } from 'react';

import MessageModal from 'components/MessageModal/MessageModal';
import Wrapper from 'components/common/Wrapper/Wrapper';
import { MESSAGE } from 'constants/messages/messages.constants';
import { addHeadersToCSV } from 'helpers/csvToJson.helper';
import i18n from 'i18next';
import moment from 'moment';
import Papa from 'papaparse';

import './i18n';
import {
  Bolder,
  ButtonImport,
  CSV,
  ConfirmButton,
  Container,
  ImportText,
  TextContainer,
  WrapperImport,
} from './styles';
import { IProps } from './types';

const ImportCSV: FC<IProps> = (props) => {
  const [fileName, setFileName] = useState('');
  const [error, setError] = useState<string>('');
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleChangeFile = (e) => {
    setError('');
    props.setFile([]);
    const reader = new FileReader();
    const file = e.target ? e.target.files[0] : e[0];
    let split = [];
    if (file) {
      split = file.name.toString().split('.');
    }
    if (!split.length) {
      return;
    }
    if (split[split.length - 1] !== 'csv') {
      setError(i18n.t('MESSAGE:FILE_TYPE_ERROR'));
      return;
    }
    if (file) {
      setFileName(file.name);
    }
    reader.onloadend = function (e) {
      if (e.target) {
        const data = e.target.result;
        if (data) {
          const base64 = (data as string).split('base64,')[1];
          const csv = atob(base64);
          const parsedCSV = Papa.parse(csv, {
            delimitersToGuess: [',', ';', '|'],
          });
          if (parsedCSV && parsedCSV.data) {
            const jsonCSV = addHeadersToCSV(parsedCSV.data);
            props.setFile(jsonCSV);
          } else {
            setError(i18n.t('MESSAGE:PARSE_FILE_ERROR'));
          }
        }
      }
    };
    reader.readAsDataURL(file);
  };

  const onClickImport = () => {
    if (fileName && props.fileData.length > 1) {
      props.onSelectImport(fileName);
    } else if (fileName && props.shareData) {
      props.onRedirectToSelect();
    }
  };

  const toggleError = () => {
    setError('');
  };

  useEffect(() => {
    setError((prevState) =>
      prevState === '' && props.fileData.length === 1
        ? i18n.t('MESSAGE:EMPTY_FILE_ERROR')
        : prevState
    );
  }, [props.fileData]);

  useEffect(() => {
    if (props.shareData) {
      setFileName(props.shareData?.fileName ? (props.shareData?.fileName as string) : '');
    }
  }, [props.shareData]);

  useEffect(() => {
    if (error !== '') {
      setFileName('');
    }
  }, [error]);

  return (
    <WrapperImport>
      <Wrapper>
        <Container>
          {fileName && (props.fileData.length > 1 || props.shareData) ? (
            <>
              {props.fileData.length > 1 && <ImportText>{i18n.t('SHARECSV:SELECTED')}</ImportText>}
              <TextContainer>
                <ImportText>
                  {i18n.t('SHARECSV:SELECTEDFILE')}
                  <Bolder colorApricot={false}>{fileName}</Bolder>
                  {i18n.t('SHARECSV:PIPE')}
                  <Bolder colorApricot={true} onClick={handleClick}>
                    {i18n.t('SHARECSV:REPLACE')}
                  </Bolder>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleChangeFile}
                    style={{ display: 'none', cursor: 'default' }}
                    accept=".csv"
                  />
                </ImportText>
              </TextContainer>
              <ConfirmButton onClick={onClickImport}>{i18n.t('SHARECSV:CONFIRM')}</ConfirmButton>
            </>
          ) : (
            <>
              <ImportText>{i18n.t('SHARECSV:IMPORTCSV')}</ImportText>
              <ImportText>
                {i18n.t('SHARECSV:IMPORTCSVSECOND')}
                <CSV data={props.exportData} filename={`BMS_${moment().format('YYYY_MM_DD')}.csv`}>
                  <Bolder colorApricot={true}>{i18n.t('SHARECSV:FILE')}</Bolder>
                </CSV>
              </ImportText>
              <ButtonImport hasFile={props.fileData.length > 1} onClick={handleClick}>
                {i18n.t('SHARECSV:CHOOSE')}
              </ButtonImport>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChangeFile}
                style={{ display: 'none', cursor: 'default' }}
                accept=".csv"
              />
            </>
          )}
        </Container>
      </Wrapper>
      {error !== '' && (
        <MessageModal
          title={MESSAGE.error.title}
          icon={MESSAGE.error.icon}
          closeIcon={MESSAGE.error.close}
          bgColor={MESSAGE.error.bgColor}
          onClose={toggleError}
          message={error}
        />
      )}
    </WrapperImport>
  );
};
export default ImportCSV;
