import React, { FC, useState } from 'react';

import MessageModal from 'components/MessageModal/MessageModal';
import VideoEditModal from 'components/VideoEditModal/VideoEditModal';
import ModalLoading from 'components/common/ModalLoading/ModalLoading';
import { MESSAGE } from 'constants/messages/messages.constants';
import i18n from 'i18next';

import './i18n';
import {
  EditIcon,
  IconWrapper,
  Line,
  NoImage,
  NoImageWrapper,
  Subtitle,
  Title,
  VideoComponent,
  WrapperVideo,
} from './styles';
import { IProps } from './types';

const VideoTab: FC<IProps> = (props) => {
  const [isVisible, setIsVisible] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);

  const toggleError = () => {
    setIsError((prevState) => !prevState);
  };

  const onClickVideo = (id: string) => {
    props.onSelectEditVideo();
    setIsVisible(id);
  };
  return (
    <>
      <Title>{i18n.t('VIDEO:TITLE')}</Title>
      <Line />
      {props.loading && <ModalLoading text={i18n.t('VIDEO:LOADING')} />}
      {!!props.video && <Subtitle>{i18n.t('VIDEO:CHANGE_VIDEO')}</Subtitle>}
      {props.video ? (
        <>
          <WrapperVideo
            onClick={() => onClickVideo(props.video && props.video._id ? props.video._id : '')}
          >
            <VideoComponent src={props.video?.path} muted playsInline loop />
            <IconWrapper>
              <EditIcon />
            </IconWrapper>
          </WrapperVideo>
          {isVisible === props.video._id && (
            <VideoEditModal
              video={props.video}
              onClose={() => setIsVisible('')}
              onSubmitVideo={props.onSubmitVideo}
              setError={toggleError}
              originalVideo={props.originalVideo}
              uploadOriginalVideo={props.uploadOriginalVideo}
              loading={props.loading}
              videoCropSucceed={props.videoCropSucceed}
            />
          )}
        </>
      ) : (
        <NoImageWrapper>
          <NoImage />
          <Subtitle noImage>{i18n.t('VIDEO:NO_VIDEO')}</Subtitle>
        </NoImageWrapper>
      )}
      {isError && (
        <MessageModal
          title={MESSAGE.error.title}
          icon={MESSAGE.error.icon}
          closeIcon={MESSAGE.error.close}
          bgColor={MESSAGE.error.bgColor}
          onClose={toggleError}
          message={i18n.t('MESSAGE:FILE_SIZE_WARNING')}
        />
      )}
    </>
  );
};

export default VideoTab;
