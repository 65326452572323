import React, { FC, useEffect, useState } from 'react';

import {
  CloseModal,
  Error,
  Icon,
  ModalContainer,
  Rectangle,
  RightContainer,
  TextContainer,
  Title,
  TransitionModal,
} from './styles';
import { IProps } from './types';

const MessageModal: FC<IProps> = (props) => {
  const [fadeType, setFadeType] = useState('');

  useEffect(() => {
    setTimeout(() => setFadeType('in'), 0);
  }, []);

  const handleClose = () => {
    setTimeout(() => setFadeType('out'), 0);
  };

  useEffect(() => {
    if (fadeType === 'in') {
      setTimeout(() => setFadeType('out'), 5000);
    }
  }, [fadeType]);

  if (fadeType === 'out') {
    props.onClose();
  }

  return (
    <TransitionModal fadeType={fadeType} topPosition={props.topPosition}>
      <ModalContainer bgColor={props.bgColor}>
        <Rectangle>
          <Icon src={props.icon} alt="Message Icon" />
        </Rectangle>
        <RightContainer>
          <TextContainer>
            <Title>{props.title}</Title>
            <Error>{props.message}</Error>
          </TextContainer>
          <CloseModal src={props.closeIcon} onClick={handleClose} alt="Close Icon" />
        </RightContainer>
      </ModalContainer>
    </TransitionModal>
  );
};

export default MessageModal;
