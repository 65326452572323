export const ON_CHANGE_STEP_REQUESTED = 'ON_CHANGE_STEP_REQUESTED';
export const ON_CHANGE_CHOSEN_REQUESTED = 'ON_CHANGE_CHOSEN_REQUESTED';
export const ON_IMPORT_SELECTED_REQUESTED = 'ON_IMPORT_SELECTED_REQUESTED';

export const ON_INITIALIZE_CAMPAIGN_SHARE_SUCCEDEED = 'ON_INITIALIZE_CAMPAIGN_SHARE_SUCCEDEED';

export const ON_INITIALIZE_CAMPAIGN_SHARE_FAILED = 'ON_INITIALIZE_CAMPAIGN_SHARE_FAILED';

export const ON_SUBMIT_SHARE_MATCH_REQUESTED = 'ON_SUBMIT_SHARE_MATCH_REQUESTED';

export const ON_SUBMIT_SHARE_MATCH_SUCCEDEED = 'ON_SUBMIT_SHARE_MATCH_SUCCEDEED';

export const ON_SUBMIT_SHARE_MATCH_FAILED = 'ON_SUBMIT_SHARE_MATCH_FAILED';

export const ON_SUBMIT_ONE_TO_ONE_SEND_MAIL_REQUESTED = 'ON_SUBMIT_ONE_TO_ONE_SEND_MAIL_REQUESTED';

export const ON_SUBMIT_ONE_TO_ONE_SEND_MAIL_SUCCEDEED = 'ON_SUBMIT_ONE_TO_ONE_SEND_MAIL_SUCCEDEED';

export const ON_SUBMIT_ONE_TO_ONE_SEND_MAIL_FAILED = 'ON_SUBMIT_ONE_TO_ONE_SEND_MAIL_FAILED';

export const ON_SELECT_CSV_REQUESTED = 'ON_SELECT_CSV_REQUESTED';

export const ON_SELECT_CSV_SUCCEEDED = 'ON_SELECT_CSV_SUCCEEDED';

export const ON_SELECT_CSV_FAILED = 'ON_SELECT_CSV_FAILED';

export const ON_SELECT_MAILCHIMP_REQUESTED = 'ON_SELECT_MAILCHIMP_REQUESTED';

export const ON_SELECT_MAILCHIMP_SUCCEDED = 'ON_SELECT_MAILCHIMP_SUCCEDED';

export const ON_SELECT_MAILCHIMP_FAILED = 'ON_SELECT_MAILCHIMP_FAILED';

export const ON_INITIALIZE_SELECT_VIEW_REQUESTED = 'ON_INITIALIZE_SELECT_VIEW_REQUESTED';
export const ON_INITIALIZE_SELECT_VIEW_SUCCEEDED = 'ON_INITIALIZE_SELECT_VIEW_SUCCEEDED';
export const ON_INITIALIZE_SELECT_VIEW_FAILED = 'ON_INITIALIZE_SELECT_VIEW_FAILED';

export const ON_MAILCHIMP_IMPORT_REQUESTED = 'ON_MAILCHIMP_IMPORT_REQUESTED';
export const ON_MAILCHIMP_IMPORT_SUCCEEDED = 'ON_MAILCHIMP_IMPORT_SUCCEEDED';
export const ON_MAILCHIMP_IMPORT_FAILED = 'ON_MAILCHIMP_IMPORT_FAILED';

export const ON_INITIALIZE_MATCH_VIEW_REQUESTED = 'ON_INITIALIZE_MATCH_VIEW_REQUESTED';

export const ON_INITIALIZE_MATCH_VIEW_SUCCEDED = 'ON_INITIALIZE_MATCH_VIEW_SUCCEDED';

export const ON_INITIALIZE_MATCH_VIEW_FAILED = 'ON_INITIALIZE_MATCH_VIEW_FAILED';

export const ON_INITIALIZE_MATCH_VIEW_MERGE_FIELDS_REQUESTED =
  'ON_INITIALIZE_MATCH_VIEW_MERGE_FIELDS_REQUESTED';

export const ON_INITIALIZE_MATCH_VIEW_MERGE_FIELDS_SUCCEDED =
  'ON_INITIALIZE_MATCH_VIEW_MERGE_FIELDS_SUCCEDED';

export const ON_INITIALIZE_MATCH_VIEW_MERGE_FIELDS_FAILED =
  'ON_INITIALIZE_MATCH_VIEW_MERGE_FIELDS_FAILED';

export const ON_INITIALIZE_MATCH_VIEW_SEGMENT_MEMBERS_REQUESTED =
  'ON_INITIALIZE_MATCH_VIEW_SEGMENT_MEMBERS_REQUESTED';

export const ON_INITIALIZE_MATCH_VIEW_SEGMENT_MEMBERS_SUCCEDED =
  'ON_INITIALIZE_MATCH_VIEW_SEGMENT_MEMBERS_SUCCEDED';

export const ON_INITIALIZE_MATCH_VIEW_SEGMENT_MEMBERS_FAILED =
  'ON_INITIALIZE_MATCH_VIEW_SEGMENT_MEMBERS_FAILED';

export const ON_SELECT_MAILCHIMP_LAUNCH_REQUESTED = 'ON_SELECT_MAILCHIMP_LAUNCH_REQUESTED';

export const ON_SELECT_MAILCHIMP_LAUNCH_SUCCEDED = 'ON_SELECT_MAILCHIMP_LAUNCH_SUCCEDED';

export const ON_SELECT_MAILCHIMP_LAUNCH_FAILED = 'ON_SELECT_MAILCHIMP_LAUNCH_FAILED';

export const ON_SELECT_MAILCHIMP_SEND_MAIL_REQUESTED = 'ON_SELECT_MAILCHIMP_SEND_MAIL_REQUESTED';

export const ON_SELECT_MAILCHIMP_SEND_MAIL_SUCCEDED = 'ON_SELECT_MAILCHIMP_SEND_MAIL_SUCCEDED';

export const ON_SELECT_MAILCHIMP_SEND_MAIL_FAILED = 'ON_SELECT_MAILCHIMP_SEND_MAIL_FAILED';

export const ON_CLICK_MOVE_STEP_BUTTON = 'ON_CLICK_MOVE_STEP_BUTTON';

export const ON_CLICK_BACK_SHARE_SELECT = 'ON_CLICK_BACK_SHARE_SELECT';

export const ON_CLICK_BACK_SHARE_SELECT_SUCCEED = 'ON_CLICK_BACK_SHARE_SELECT_SUCCEED';

export const ON_CLICK_BACK_SHARE_SELECT_FAILED = 'ON_CLICK_BACK_SHARE_SELECT_FAILED';

export const ON_CLICK_BACK_TO_EDIT_CAMPAIGN = 'ON_CLICK_BACK_TO_EDIT_CAMPAIGN';

export const ON_INITIALIZE_SHARE_MATCH_REQUESTED = 'ON_INITIALIZE_SHARE_MATCH_REQUESTED';

export const ON_INITIALIZE_SHARE_MATCH_SUCCEDED = 'ON_INITIALIZE_SHARE_MATCH_SUCCEDED';

export const ON_INITIALIZE_SHARE_MATCH_FAILED = 'ON_INITIALIZE_SHARE_MATCH_FAILED';

export const ON_INITIALIZE_SHARE_LAUNCH_REQUESTED = 'ON_INITIALIZE_SHARE_LAUNCH_REQUESTED';

export const ON_INITIALIZE_SHARE_LAUNCH_SUCCEDED = 'ON_INITIALIZE_SHARE_LAUNCH_SUCCEDED';

export const ON_INITIALIZE_SHARE_LAUNCH_FAILED = 'ON_INITIALIZE_SHARE_LAUNCH_FAILED';

export const ON_SELECT_BACK_SHARE_MATCH = 'ON_SELECT_BACK_SHARE_MATCH';

export const ON_SELECT_BACK_SHARE_IMPORT = 'ON_SELECT_BACK_SHARE_IMPORT';

export const ON_SELECT_BACK_SHARE_CSV = 'ON_SELECT_BACK_SHARE_CSV';

export const ON_SELECT_BACK_SHARE_LAUNCH = 'ON_SELECT_BACK_SHARE_LAUNCH';

export const ON_SELECT_BACK_SHARE_LAUNCH_SUCCEED = 'ON_SELECT_BACK_SHARE_LAUNCH_SUCCEED';

export const ON_SELECT_BACK_SHARE_LAUNCH_FAILED = 'ON_SELECT_BACK_SHARE_LAUNCH_FAILED';

export const ON_REQUEST_LINK = 'ON_REQUEST_LINK';

export const ON_REQUEST_LINK_SUCCEDED = 'ON_REQUEST_LINK_SUCCEDED';

export const ON_REQUEST_LINK_FAILED = 'ON_REQUEST_LINK_FAILED';

export const ON_RETRIEVE_SEGMENT_MEMBERS = 'ON_RETRIEVE_SEGMENT_MEMBERS';

export const ON_RETRIEVE_SEGMENT_MEMBERS_SUCCEDED = 'ON_RETRIEVE_SEGMENT_MEMBERS_SUCCEDED';

export const ON_RETRIEVE_SEGMENT_MEMBERS_FAILED = 'ON_RETRIEVE_SEGMENT_MEMBERS_FAILED';

export const ON_SELECT_EDIT_TEMPLATE = 'ON_SELECT_EDIT_TEMPLATE';

export const ON_PARSE_ROW_CREATE_REQUEST = 'ON_PARSE_ROW_CREATE_REQUEST';
