import React, { FC, useEffect, useState } from 'react';

import { PUBLIC_URL } from 'configs';
import Lottie, { AnimationItem } from 'lottie-web';
import { v4 as uuidv4 } from 'uuid';

import { AnimationContainer, AnimationLayer, LoadingContainer } from './styles';
import { IProps } from './types';

const LoadingPoints: FC<IProps> = (props) => {
  const [id] = useState(uuidv4());
  const [anim, setAnim] = useState<AnimationItem>();

  useEffect(() => {
    if (anim) {
      Lottie.destroy(`loading_points_${id}`);
    }

    return () => {
      Lottie.destroy(`loading_points_${id}`);
    };
  }, []);

  useEffect(() => {
    const animation: AnimationItem = Lottie.loadAnimation({
      container: document.getElementById(`loading_points_${id}`) as HTMLElement,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: `${PUBLIC_URL}/lottie/loading/loading_points.json`,
      name: `loading_${id}`,
    });
    setAnim(animation);
  }, []);

  useEffect(() => {
    if (anim) {
      anim.play();
    }
  }, [anim]);

  return (
    <LoadingContainer isFullscreen={props.isFullscreen}>
      <AnimationContainer isFullscreen={props.isFullscreen}>
        <AnimationLayer id={`loading_points_${id}`} />
      </AnimationContainer>
    </LoadingContainer>
  );
};
export default LoadingPoints;
