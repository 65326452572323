import { get, put } from './api';

export const getTemplates = async (): Promise<string | undefined> => {
  const response = await get('templates/');
  return response.data;
};

export const getCategories = async (): Promise<string | undefined> => {
  const response = await get('categories/');
  return response.data;
};

export const getTemplate = async (id: string): Promise<string | undefined> => {
  const response = await get(`templates/video/${id}/`);
  return response.data;
};

export const getTemplateNewBMS = async (id: string): Promise<string | undefined> => {
  const response = await get(`templates/new-bms/${id}`);
  return response.data;
};

export const sendEmail = async (
  data: string[],
  templateName: string
): Promise<string | undefined> => {
  const info = {
    data,
    templateName,
  };
  const response = await put(`templates/send-email-existing`, info);
  return response.data;
};

export const sendPersonalizedCampaignEmail = async (
  audience: number,
  emailProvider: string,
  containVideo: string,
  objective: string
): Promise<string | undefined> => {
  const data = {
    audience,
    emailProvider,
    containVideo,
    objective,
  };
  const response = await put(`templates/send-email-custom`, data);
  return response.data;
};
