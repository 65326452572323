import React, { FC, Fragment, useEffect, useState } from 'react';

import WrapperEditTemplate from 'components/WrapperEditTemplate/WrapperEditTemplate';
import WrapperPlayer from 'components/WrapperPlayer/WrapperPlayer';
import WrapperSidebarTab from 'components/WrapperSidebarTab/WrapperSidebarTab';
import { TextVariableType } from 'enums/textVariable.enum';
import { useResize } from 'helpers/checkMobile.helper';
import Player from 'views/Campaign/Player/Player';
import ScenesSliderEdit from 'views/Campaign/ScenesSlider/ScenesSlider';
import Sidebar from 'views/Campaign/Sidebar/Sidebar';
import TextEditModal from 'views/Campaign/TextEditModal/TextEditModal';
import TopbarEdit from 'views/Campaign/Topbar/TopbarEdit';
import { IScene } from 'views/Video/types';

import { IProps } from './types';

const CampaignEdit: FC<IProps> = (props) => {
  const [selectedTextVariable, setSelectedTextVariable] = useState<string>('');
  const [segments, setSegments] = useState<number[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [isMobile, handleWindowSizeChange]: any[] = useResize();
  const [currentScene, setCurrentScene] = useState(props.scenes[0]._id);

  const onClickScene = (scene: IScene) => {
    setSegments([scene.frameStart, scene.frameEnd]);
    setCurrentScene(scene._id);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      props.backHome();
    }
  }, []);

  // const [isOpenTips, setIsOpen] = useState<boolean>(false);

  // useEffect(() => {
  //   setIsOpen(true);
  // }, [isOpenTips]);

  const handleTextChange = (textId: string) => {
    setSelectedTextVariable(textId);
  };

  const handleModalClose = () => {
    setSelectedTextVariable('');
  };

  const handleModalSubmit = (text: string, textId: string, link: string) => {
    props.onSubmitText(text, textId, link);
    handleModalClose();
  };

  let selectedTextVariableValue = '';

  let maxLength = 0;
  let variableType = 0;
  let ctaLink = '';

  if (selectedTextVariable) {
    const found = props.textVariables.find((variable) => variable._id === selectedTextVariable);
    selectedTextVariableValue = found?.value || '';
    maxLength = found?.maxLength || 0;
    variableType = found?.type || 0;
    ctaLink = found?.ctaLink || '';
  }

  const scene = props.scenes.find((scene) => scene._id === currentScene);

  return (
    <div>
      <Fragment>
        {/* <ModalQuickTips isOpen={isOpenTips} /> */}
        <TopbarEdit
          name={props.campaign.name}
          onSubmit={props.onSubmit}
          textVariables={props.textVariables}
          colorVariables={props.colorVariables}
          duration={props.campaign.template.duration}
          imageVariables={props.imageVariables}
          onNextSubmit={props.onNextSubmit}
          layers={props.layers}
          backgroundColor={props.backgroundColor}
          backgroundVideo={props.video && props.video.path ? props.video.path : ''}
          scenes={props.scenes}
          onRemoveVideoData={props.onRemoveVideoData}
          id={props.campaign._id}
        />
        <WrapperEditTemplate>
          <WrapperSidebarTab>
            <Sidebar
              thumbnail={props.campaign.template.thumbnail?.path}
              colorVariables={props.campaign.colorVariables}
              onSubmitColor={props.onSubmitColor}
              imageVariables={props.imageVariables}
              onSubmitImage={props.onSubmitImage}
              video={props.video}
              onSubmitVideo={props.onSubmitVideo}
              loading={props.loading}
              originalVideo={props.originalVideo}
              uploadOriginalVideo={props.uploadOriginalVideo}
              videoCropSucceed={props.videoCropSucceed}
              onSelectEditVideo={props.onSelectEditVideo}
            />
          </WrapperSidebarTab>
          <WrapperPlayer>
            <Player
              layers={props.layers}
              scenes={props.scenes}
              textVariables={props.textVariables}
              imageVariables={props.imageVariables}
              colorVariables={props.campaign.colorVariables}
              segments={segments}
              currentFrameDistance={scene && scene.frameEnd - scene.frameStart}
              handleTextChange={handleTextChange}
              backgroundColor={props.backgroundColor}
              backgroundVideo={props.video && props.video.path ? props.video.path : ''}
              onRemoveVideoData={props.onRemoveVideoData}
            />
            <ScenesSliderEdit
              layers={props.layers}
              scenes={props.scenes}
              textVariables={props.textVariables}
              imageVariables={props.imageVariables}
              onClickScene={onClickScene}
              currentScene={currentScene}
              colorVariables={props.colorVariables}
              backgroundColor={props.backgroundColor}
              backgroundVideo={props.video && props.video.path ? props.video.path : ''}
              onRemoveVideoData={props.onRemoveVideoData}
            />
          </WrapperPlayer>
        </WrapperEditTemplate>
        {selectedTextVariable &&
          (variableType === TextVariableType.Still || variableType === TextVariableType.CTA) && (
            <TextEditModal
              id={selectedTextVariable}
              maxLength={maxLength}
              value={selectedTextVariableValue}
              onClose={handleModalClose}
              onSubmit={handleModalSubmit}
              variableType={variableType}
              ctaLink={ctaLink}
            />
          )}
      </Fragment>
    </div>
  );
};

export default CampaignEdit;
