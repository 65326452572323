import React, { FC, Fragment, useState } from 'react';

import Button from 'components/Button/Button';
import MessageModal from 'components/MessageModal/MessageModal';
import NoCampaigns from 'components/NoCampaigns/NoCampaigns';
import Wrapper from 'components/common/Wrapper/Wrapper';
import { MESSAGE } from 'constants/messages/messages.constants';
import StoreLayout from 'containers/StoreLayout/StoreLayout';
import { sortCampaign, sortCampaignByDate, sortCampaignsByCreatedDate } from 'helpers/sorter';
import i18n from 'i18next';
import CampaignDataListItem from 'views/CampaignDataListItem/CampaignDataListItem';
import CampaignDataThumbnail from 'views/CampaignDataThumbnail/CampaignDataThumbnail';
import LoadingPoints from 'views/LoadingPoints/LoadingPoints';

import './i18n';
import {
  ButtonContainer,
  CampaignContainerColumn,
  CampaignContainerRow,
  Container,
  FilterGrid,
  FilterList,
  Filters,
  HeaderContainer,
  IconArrow,
  IconArrowUp,
  LeftContainer,
  LoadingPointsWrapper,
  MyCampaignsContainer,
  RightContainer,
  SortContainer,
  SortText,
  TextContainer,
  Title,
} from './styles';
import { IProps } from './types';

const MyCampaigns: FC<IProps> = (props) => {
  const [direction, setDirection] = useState<boolean>(false);
  const [sortDirection, setSortDirection] = useState('');
  const [pagination, setPagination] = useState(2);

  const changeDirection = () => {
    if (direction) {
      setDirection(false);
    } else {
      setDirection(true);
    }
  };

  const sortByAlpha = () => {
    props.campaigns.sort((a, b) => sortCampaignByDate(a, b));
    if (sortDirection !== 'directionNameUp') {
      props.campaigns.sort((a, b) => sortCampaign(a, b));
      setSortDirection('directionNameUp');
    } else {
      props.campaigns.sort((b, a) => sortCampaign(a, b));
      setSortDirection('directionNameDown');
    }
  };

  const sortByLastModified = () => {
    if (sortDirection !== 'directionLastModifiedUp') {
      props.campaigns.sort((b, a) => sortCampaignByDate(a, b));
      setSortDirection('directionLastModifiedUp');
    } else {
      props.campaigns.sort((a, b) => sortCampaignByDate(a, b));
      setSortDirection('directionLastModifiedDown');
    }
  };

  const sortCampaignsByCreated = () => {
    if (sortDirection !== 'directionCreatedAtUp') {
      props.campaigns.sort((b, a) => sortCampaignsByCreatedDate(a, b));
      setSortDirection('directionCreatedAtUp');
    } else {
      props.campaigns.sort((a, b) => sortCampaignsByCreatedDate(a, b));
      setSortDirection('directionCreatedAtDown');
    }
  };

  const toggleMessage = () => {
    props.changeIsSuccess();
  };

  const loadMoreCampaigns = () => {
    setPagination((prevState) => (prevState += 1));
    props.loadMoreCampaigns(pagination);
  };

  return (
    <StoreLayout isTemplate="myCampaigns">
      <Wrapper>
        <MyCampaignsContainer>
          <HeaderContainer>
            <Title>{i18n.t('MY_CAMPAIGNS:CAMPAIGNS')}</Title>
            <Filters>
              {direction ? (
                <FilterList onClick={changeDirection}></FilterList>
              ) : (
                <FilterGrid onClick={changeDirection}></FilterGrid>
              )}
            </Filters>
          </HeaderContainer>
          {props.campaigns.length ? (
            <Container>
              {direction ? (
                <Fragment>
                  <CampaignContainerRow>
                    {props.campaigns.map((campaign) => {
                      return (
                        <CampaignDataThumbnail
                          key={campaign._id}
                          campaign={campaign}
                          id={campaign._id}
                          title={campaign.name}
                          selectCampaign={props.selectCampaign}
                          deleteCampaign={props.deleteCampaign}
                          updatedAt={campaign.updatedAt}
                          relaunchCampaign={props.relaunchCampaign}
                          selectMetricsCampaign={props.selectMetricsCampaign}
                          isLoadingDeleteCampaign={props.isLoadingDeleteCampaign}
                          onRemoveVideoData={props.onRemoveVideoData}
                        />
                      );
                    })}
                  </CampaignContainerRow>
                  {!props.allCampaignsFetched && (
                    <ButtonContainer>
                      {!props.isLoadingCampaigns ? (
                        <Button onClick={loadMoreCampaigns}>
                          {i18n.t('MY_CAMPAIGNS:LOAD_MORE')}
                        </Button>
                      ) : (
                        <LoadingPointsWrapper>
                          <LoadingPoints />
                        </LoadingPointsWrapper>
                      )}
                    </ButtonContainer>
                  )}
                </Fragment>
              ) : (
                <>
                  <SortContainer>
                    <LeftContainer>
                      <TextContainer onClick={sortByAlpha}>
                        <SortText>{i18n.t('MY_CAMPAIGNS:NAME')}</SortText>
                        {sortDirection === 'directionNameUp' ? <IconArrowUp /> : <IconArrow />}
                      </TextContainer>
                    </LeftContainer>
                    <RightContainer>
                      <TextContainer onClick={sortByLastModified}>
                        <SortText>{i18n.t('MY_CAMPAIGNS:LAST_MODIFICATION')}</SortText>
                        {sortDirection === 'directionLastModifiedUp' ? (
                          <IconArrowUp />
                        ) : (
                          <IconArrow />
                        )}
                      </TextContainer>
                      <TextContainer onClick={sortCampaignsByCreated}>
                        <SortText>{i18n.t('MY_CAMPAIGNS:CREATED_AT')}</SortText>
                        {sortDirection === 'directionCreatedAtUp' ? <IconArrowUp /> : <IconArrow />}
                      </TextContainer>
                    </RightContainer>
                  </SortContainer>
                  <CampaignContainerColumn>
                    {props.campaigns.map((campaign) => {
                      return (
                        <CampaignDataListItem
                          key={campaign._id}
                          campaign={campaign}
                          id={campaign._id}
                          title={campaign.name}
                          createdAt={campaign.createdAt}
                          colorVariables={props.colorVariables}
                          imageVariables={props.imageVariables}
                          updatedAt={campaign.updatedAt}
                          selectCampaign={props.selectCampaign}
                          deleteCampaign={props.deleteCampaign}
                          relaunchCampaign={props.relaunchCampaign}
                          selectMetricsCampaign={props.selectMetricsCampaign}
                          isLoadingDeleteCampaign={props.isLoadingDeleteCampaign}
                          onRemoveVideoData={props.onRemoveVideoData}
                        />
                      );
                    })}
                    {!props.allCampaignsFetched && (
                      <ButtonContainer>
                        {!props.isLoadingCampaigns ? (
                          <Button onClick={loadMoreCampaigns}>
                            {i18n.t('MY_CAMPAIGNS:LOAD_MORE')}
                          </Button>
                        ) : (
                          <LoadingPointsWrapper>
                            <LoadingPoints />
                          </LoadingPointsWrapper>
                        )}
                      </ButtonContainer>
                    )}
                  </CampaignContainerColumn>
                </>
              )}
            </Container>
          ) : props.isLoadingCampaigns ? (
            <LoadingPointsWrapper>
              <LoadingPoints />
            </LoadingPointsWrapper>
          ) : (
            <NoCampaigns />
          )}
        </MyCampaignsContainer>
        {/* <HelpTooltip></HelpTooltip> */}
      </Wrapper>
      {props.isSuccessDeleteCampaign && (
        <MessageModal
          title={MESSAGE.success.title}
          icon={MESSAGE.success.icon}
          closeIcon={MESSAGE.success.close}
          bgColor={MESSAGE.success.bgColor}
          onClose={toggleMessage}
          message={i18n.t('MESSAGE:DELETE_CAMPAIGN_SUCCESS')}
        />
      )}
      {props.deleteCampaignError && (
        <MessageModal
          title={MESSAGE.error.title}
          icon={MESSAGE.error.icon}
          closeIcon={MESSAGE.error.close}
          bgColor={MESSAGE.error.bgColor}
          onClose={toggleMessage}
          message={i18n.t('MESSAGE:DELETE_CAMPAIGN_FAILED')}
        />
      )}
    </StoreLayout>
  );
};
export default MyCampaigns;
