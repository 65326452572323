import styled from 'styled-components';

export const Title = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  font-weight: 400;
  text-align: left;
  margin-bottom: 10px;
`;

export const TemplateImage = styled.img`
  width: 230px;
  height: 130px;
  border-radius: 5%;
  opacity: 0.7;
`;
