import { ShareType } from 'enums/shareType.enum';
import { removeCookie } from 'helpers/cookies';
import history from 'helpers/history';
import { AnyAction } from 'redux';
import {
  ON_CONFIRM_CSV_FILE,
  ON_SELECT_EDIT_CREATED_CAMPAIGN,
  ON_SELECT_IMPORT_CSV_SUCCEDED,
  ON_SELECT_MAILCHIMP_SEGMENT_SUCCEDED,
  ON_SELECT_TEMPLATE_SUCCEEDED,
} from 'redux/constants/campaign.action.constants';
import { ON_REDIRECT_TO_METRICS_DASHBOARD } from 'redux/constants/metrics.actions.constants';
import {
  ON_RELAUNCH_CAMPAIGN_SUCCEDED,
  ON_SELECT_CAMPAIGN_REQUESTED,
} from 'redux/constants/myCampaigns.action.constants';
import { ON_SELECT_BACK_HOME } from 'redux/constants/resize.action.constants';
import {
  ON_CLICK_BACK_SHARE_SELECT_SUCCEED,
  ON_CLICK_MOVE_STEP_BUTTON,
  ON_SELECT_BACK_SHARE_CSV,
  ON_SELECT_BACK_SHARE_IMPORT,
  ON_SELECT_BACK_SHARE_LAUNCH_SUCCEED,
  ON_SELECT_BACK_SHARE_MATCH,
  ON_SELECT_CSV_SUCCEEDED,
  ON_SELECT_EDIT_TEMPLATE,
  ON_SELECT_MAILCHIMP_SUCCEDED,
  ON_SUBMIT_SHARE_MATCH_SUCCEDEED,
} from 'redux/constants/share.action.constants';

import {
  ON_RESET_PASSWORD_SUCCEDED,
  ON_USER_LOGIN_AND_CREATE_CAMPAIGN_SUCCEEDED,
  ON_USER_LOGOUT,
  USER_ON_LOGIN_SUCCEEDED,
} from '../constants/user.actions.constants';

const redirectMiddleware =
  () =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  (next: any) =>
  (action: AnyAction): void => {
    next(action);
    switch (action.type) {
      case USER_ON_LOGIN_SUCCEEDED:
        history.push('/');
        break;
      case ON_SELECT_TEMPLATE_SUCCEEDED:
      case ON_SELECT_CAMPAIGN_REQUESTED:
      case ON_USER_LOGIN_AND_CREATE_CAMPAIGN_SUCCEEDED:
      case ON_SELECT_BACK_SHARE_IMPORT:
      case ON_SELECT_EDIT_CREATED_CAMPAIGN:
      case ON_SELECT_EDIT_TEMPLATE:
        history.push(`/campaign/${action.id}/edit`);
        break;
      case ON_USER_LOGOUT:
        removeCookie('accessToken');
        window.location.href = '/';
        break;
      case ON_SELECT_CSV_SUCCEEDED:
        history.push(`/campaign/${action.data}/share/import/csv`);
        break;
      case ON_CLICK_BACK_SHARE_SELECT_SUCCEED:
        if (action.shareType && action.shareType === ShareType.CSV) {
          history.push(`/campaign/${action.campaignId}/share/import/csv`);
        } else {
          history.push(`/campaign/${action.campaignId}/share/`);
        }
        break;
      case ON_SELECT_MAILCHIMP_SUCCEDED:
        window.location.href = `${action.data}`;
        break;
      case ON_SELECT_IMPORT_CSV_SUCCEDED:
      case ON_CONFIRM_CSV_FILE:
        history.push(`/campaign/${action.data._id}/share/select/`);
        break;
      case ON_SELECT_MAILCHIMP_SEGMENT_SUCCEDED:
        history.push(`/campaign/${action.data._id}/share/match/`);
        break;
      case ON_SUBMIT_SHARE_MATCH_SUCCEDEED:
        history.push(`/campaign/${action.id}/share/launch/`);
        break;
      case ON_RESET_PASSWORD_SUCCEDED:
        history.push('/');
        break;
      case ON_CLICK_MOVE_STEP_BUTTON:
      case ON_RELAUNCH_CAMPAIGN_SUCCEDED:
        history.push(action.url);
        break;
      case ON_SELECT_BACK_SHARE_CSV:
        history.push(`/campaign/${action.campaignId}/share/`);
        break;
      case ON_SELECT_BACK_SHARE_MATCH:
        history.push(`/campaign/${action.campaignId}/share/select/`);
        break;
      case ON_SELECT_BACK_SHARE_LAUNCH_SUCCEED:
        history.push(`/campaign/${action.campaignId}/share/match/`);
        break;
      case ON_REDIRECT_TO_METRICS_DASHBOARD:
        history.push(`/reports/${action.id}`);
        break;
      case ON_SELECT_BACK_HOME:
        history.push('/');
        break;
      default:
        break;
    }
  };

export default redirectMiddleware;
