import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import * as userActions from 'redux/actions/user.actions';
import ConfirmAccount from 'views/ConfirmAccount/ConfirmAccount';

type ConfirmAccountParams = {
  token: string;
};

type ConfirmAccountProps = RouteComponentProps<ConfirmAccountParams>;

const ForgotPassordContainer: FC<ConfirmAccountProps> = (props) => {
  const dispatch = useDispatch();
  const token = props.match.params.token;

  useEffect(() => {
    dispatch(userActions.initializeConfirmAccount(token));
  });

  return <ConfirmAccount />;
};

export default ForgotPassordContainer;
