import React, { FunctionComponent, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { PUBLIC_URL } from 'configs/index';
import i18n from 'i18next';

import {
  Container,
  DropContainer,
  DropText,
  DroppableContainer,
  DroppableStyle,
  Image,
  Item,
  ItemsWrapper,
  Name,
  Remove,
  RemoveContainer,
  SelectedItem,
  SelectedItemWrapper,
  Text,
  TextAndRemoveContainer,
} from './styles';
import { IDDResult, IDDTextVariable, IField, IProps } from './types';

const DragAndDrop: FunctionComponent<IProps> = (props) => {
  const [items, setItems] = useState<IField[]>([]);
  const [slots, setSlots] = useState<IDDTextVariable[]>(props.textVariables);

  props.matchedFields(slots);

  const remove = (key: string) => {
    const newSlot = slots.filter((slot) => slot.key === key);

    const newItem = {
      key: newSlot[0].selected,
      value: newSlot[0].selectedValue,
    };

    setItems((oldItems) => [...oldItems, newItem]);

    const newSlots = slots.map((slot) => {
      if (slot.key !== key) {
        return slot;
      }
      return {
        ...slot,
        selected: '',
        selectedValue: '',
        disabled: false,
      };
    });
    setSlots(newSlots);
  };

  const onDragEnd = (result: IDDResult) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      return;
    } else {
      const newValue = items[source.index];
      const newSlots = slots.map((slot) => {
        if (slot.key !== destination.droppableId) {
          return slot;
        }
        return {
          ...slot,
          selected: newValue.key,
          selectedValue: newValue.value,
          disabled: true,
        };
      });
      setSlots(newSlots);
      const newItems = items.filter((item) => item.value !== newValue.value);
      setItems(newItems);
    }
  };

  const valuesMatch = (slotValue: string, itemValue: string) => {
    return (
      slotValue.toLowerCase() === itemValue.toLowerCase() ||
      slotValue.replace('[', '').replace(']', '').toLowerCase() === itemValue.toLowerCase() ||
      slotValue.replace('#', '').toLowerCase() === itemValue.toLowerCase() ||
      slotValue.replace('\r', '').toLowerCase() === itemValue.toLowerCase() ||
      slotValue.replace('[', '').replace(']', '').replace('\r', '').toLowerCase() ===
        itemValue.toLowerCase()
    );
  };

  useEffect(() => {
    if (props.items) {
      const matchedItems = props.items.filter((item) =>
        slots.some((slot) => valuesMatch(slot.value, item.value))
      );
      let newItems = props.items;
      if (matchedItems.length) {
        matchedItems.forEach((matchedItem) => {
          newItems = newItems.filter((item) => item.key !== matchedItem.key);
          setSlots((prevState) => {
            return prevState.map((slot) => {
              if (valuesMatch(slot.value, matchedItem.value)) {
                return {
                  ...slot,
                  selected: slot.value,
                  selectedValue: matchedItem.value,
                  disabled: true,
                };
              }
              return slot;
            });
          });
        });
      }
      setItems(newItems);
    }
  }, [props.items]);

  return (
    <Container>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable isDropDisabled={true} droppableId="items">
          {(provided) => (
            <ItemsWrapper ref={provided.innerRef}>
              {items.map((item, i) => (
                <Draggable key={item.key} draggableId={item.key} index={i}>
                  {(provided) => (
                    <Item
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Text>{item.value}</Text>
                    </Item>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ItemsWrapper>
          )}
        </Droppable>
        <Image src={`${PUBLIC_URL}/images/drag-drop.svg`} />
        <DropContainer>
          {slots.map((slot, i) => (
            <DroppableContainer key={slot.key}>
              <Name>{slot.value}</Name>
              <DroppableStyle>
                <Droppable isDropDisabled={slot.disabled} droppableId={slot.key}>
                  {(provided) => (
                    <SelectedItemWrapper ref={provided.innerRef} selected={slot.selected}>
                      <Draggable
                        isDragDisabled={true}
                        key={slot.key}
                        draggableId={slot.key}
                        index={i}
                      >
                        {(provided) => (
                          <SelectedItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            selected={slot.selected}
                            hover={`${process.env.PUBLIC_URL}/images/x-h.svg`}
                          >
                            {slot.selected ? (
                              <TextAndRemoveContainer>
                                <Text>{slot.selectedValue}</Text>
                                <RemoveContainer>
                                  {slot.selected && (
                                    <Remove
                                      onClick={() => remove(slot.key)}
                                      image={`${process.env.PUBLIC_URL}/images/x.svg`}
                                    ></Remove>
                                  )}
                                </RemoveContainer>
                              </TextAndRemoveContainer>
                            ) : (
                              <DropText>{i18n.t('SHAREMATCH:DROP')}</DropText>
                            )}
                          </SelectedItem>
                        )}
                      </Draggable>
                      {provided.placeholder}
                    </SelectedItemWrapper>
                  )}
                </Droppable>
              </DroppableStyle>
            </DroppableContainer>
          ))}
        </DropContainer>
      </DragDropContext>
    </Container>
  );
};
export default DragAndDrop;
