import React, { FC, useEffect, useState } from 'react';

import Wrapper from 'components/common/Wrapper/Wrapper';
import { IPreviewData } from 'containers/PreviewPlayerContainer/types';
import { ColorVariableType } from 'enums/colorVariable.enum';
import { getJsonLayer } from 'helpers/get_json_layer.helper';
import i18n from 'i18next';
import { ILayer } from 'interfaces/layer.interface';
import Video from 'views/Video/Video';
import { IColorVariable } from 'views/Video/types';

import Editor from './Editor';
import './i18n';
import {
  ButtonWrapper,
  CKEditorContainer,
  Campaign,
  Container,
  FormContainer,
  Next,
} from './styles';
import { IProps } from './types';

const NewBMSTemplateScenes: FC<IProps> = (props) => {
  const [layersData, setLayersData] = useState<ILayer[]>([]);
  const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');
  const [data, setData] = useState<string[]>([]);

  useEffect(() => {
    const previewData: IPreviewData = {
      colorVariables: props.template.colorVariables,
      imageVariables: props.template.imageVariables,
      duration: props.template.duration,
      textVariables: props.template.textVariables,
    };
    getJsonLayer(previewData, setLayersData, props.template.layers, props.template.textVariables);
  }, [props.template, props.template.layers]);

  useEffect(() => {
    const color = props.template.colorVariables.find(
      (color: IColorVariable) => color.type === ColorVariableType.Background
    );
    if (color) {
      setBackgroundColor(color.value);
    }
  }, [props.template.colorVariables]);

  const onChange = (dataReceive: string, index: number) => {
    const newData = [...data];
    newData[index] = dataReceive;
    setData(newData);
  };

  const onSubmit = () => {
    props.onSubmit(data);
  };

  return (
    <Wrapper>
      <FormContainer>
        {props.template.scenes.map((scene, index: number) => {
          return (
            <Container>
              <Campaign>
                <Video
                  layers={layersData}
                  textVariables={props.template.textVariables}
                  isPlaying={false}
                  frame={scene.frameEnd}
                  backgroundColor={backgroundColor}
                  isEdit={false}
                  replay={false}
                  backgroundVideo={''}
                  autoplayBackgroundVideo={false}
                  removeVideoData={props.onRemoveVideoData}
                  colorVariables={props.template.colorVariables}
                />
              </Campaign>
              <CKEditorContainer>
                <Editor name="name" value={data[index]} onChange={onChange} index={index} />
              </CKEditorContainer>
            </Container>
          );
        })}
        <ButtonWrapper>
          <Next onClick={props.stepBack}>{i18n.t('NEW_BMS_TEMPLATE_SCENES:BACK')}</Next>
          <Next onClick={onSubmit}>{i18n.t('NEW_BMS_TEMPLATE_SCENES:SEND')}</Next>
        </ButtonWrapper>
      </FormContainer>
    </Wrapper>
  );
};
export default NewBMSTemplateScenes;
