import styled from 'styled-components';

export const ButtonComponent = styled.button`
  width: 125px;
  height: 40px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  border: 1px solid ${(props) => props.theme.colors.porcelain};
  border-radius: 5px;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.cello};
  cursor: pointer;
  :hover {
    background: ${(props) => props.theme.colors.bigStone};
  }
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${(props) => props.theme.transition.all};
`;
