import React, { FC, useState } from 'react';
import { Trans } from 'react-i18next';

import Wrapper from 'components/common/Wrapper/Wrapper';
import { ShareType } from 'enums/shareType.enum';
import i18n from 'i18next';

import './i18n';
import {
  Arrow,
  ArrowDown,
  CVSFields,
  CVSLists,
  CVSText,
  Container,
  Divs,
  Field,
  FieldContainer,
  Fields,
  Line,
  ListDiv,
  ListItem,
  MCFieldContainer,
  MatchFieldsContainer,
  RequiredDiv,
  Segment,
  SegmentDropdown,
  SegmentText,
  ShareTitle,
  SubTitle,
  Text,
  VerticalLine,
  WrapperImport,
} from './styles';
import { IHeader, IProps } from './types';

const ShareSelect: FC<IProps> = (props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState({});

  const handleListClick = () => {
    setVisible((prevState) => !prevState);
  };

  const handleSegmentClick = (segmentId: number) => {
    setSelected(() => ({ [segmentId]: true }));
    const segmentFound = props.mailchimpSegments?.find((segment) => segment.id === segmentId);
    const sharedData = {
      segmentId: segmentFound?.id.toString(),
      listId: segmentFound?.list_id,
    };
    props.setSegment(sharedData);
  };

  return (
    <WrapperImport shareType={props.shareType}>
      <Wrapper>
        <Container>
          <RequiredDiv>
            <ShareTitle>{props.title}</ShareTitle>
            <SubTitle>
              {props.subtitle ? (
                <Trans components={[<b />]}>{props.subtitle}</Trans>
              ) : (
                props.subtitle
              )}
            </SubTitle>
          </RequiredDiv>
          {(!!props.csvData.length ||
            (!!props.mailChimpLists.length && !!props.mailchimpSegments.length)) && (
            <MatchFieldsContainer>
              {props.shareType === ShareType.MAILCHIMP && (
                <CVSLists>
                  <CVSText>{i18n.t('SHARESELECT:LISTS')}</CVSText>
                  <Line />
                  <ListDiv>
                    {props.mailChimpLists.map((listData, index: number) => {
                      return (
                        <div key={index}>
                          <ListItem
                            selectedSegment={Object.values(selected).includes(true)}
                            selectedItem={true}
                            onClick={handleListClick}
                            key={listData.id}
                          >
                            {visible && selected && (
                              <ArrowDown
                                image={`${process.env.PUBLIC_URL}/images/arrow-dropdown-w.svg`}
                              />
                            )}
                            {!visible && !selected && (
                              <Arrow image={`${process.env.PUBLIC_URL}/images/arrow-right-b.svg`} />
                            )}
                            {visible && !selected && (
                              <ArrowDown
                                image={`${process.env.PUBLIC_URL}/images/arrow-dropdown-b.svg`}
                              />
                            )}
                            {!visible && selected && (
                              <Arrow image={`${process.env.PUBLIC_URL}/images/arrow-right-w.svg`} />
                            )}
                            <Text
                              selectedSegment={Object.values(selected).includes(true)}
                              selectedItem={true}
                            >
                              {listData.name}
                            </Text>
                          </ListItem>
                          {visible && (
                            <SegmentDropdown>
                              {props.mailchimpSegments.map((segment, index: number) => {
                                return (
                                  <Segment
                                    onClick={() => handleSegmentClick(segment.id)}
                                    selectedItem={selected[segment.id]}
                                    key={index}
                                  >
                                    <SegmentText selectedItem={selected[segment.id]}>
                                      {segment.name} ({segment.member_count})
                                    </SegmentText>
                                  </Segment>
                                );
                              })}
                            </SegmentDropdown>
                          )}
                        </div>
                      );
                    })}
                  </ListDiv>
                </CVSLists>
              )}
              {props.shareType === ShareType.MAILCHIMP && <VerticalLine></VerticalLine>}
              <CVSFields shareType={props.shareType}>
                {props.shareType === ShareType.CSV ? (
                  <CVSText>{i18n.t('SHARESELECT:CSVFIELDS')}</CVSText>
                ) : (
                  <CVSText>{i18n.t('SHARESELECT:SELECTED')}</CVSText>
                )}
                <Line />
                <Divs>
                  <Fields>
                    {props.shareType === ShareType.CSV
                      ? props.csvData.map((field: IHeader) => {
                          return (
                            <FieldContainer key={field.text}>
                              <Field active={field.active}>{field.text}</Field>
                            </FieldContainer>
                          );
                        })
                      : props.mailchimpListFields.map((field: IHeader) => {
                          return (
                            <MCFieldContainer key={field.text}>
                              <Field active={field.active}>{field.text}</Field>
                            </MCFieldContainer>
                          );
                        })}
                  </Fields>
                </Divs>
              </CVSFields>
            </MatchFieldsContainer>
          )}
        </Container>
      </Wrapper>
    </WrapperImport>
  );
};
export default ShareSelect;
