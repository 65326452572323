import { ShareType } from 'enums/shareType.enum';
import styled from 'styled-components';

import { IField, IFieldProps, IIconImage, ISelectedStyle } from './types';

export const Title = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.extraLarge};
  color: ${(props) => props.theme.colors.fiord};
  text-align: center;
  align-items: center;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

export const MatchFieldsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-directoin: row;
`;

export const CVSLists = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

export const CVSText = styled.div`
  width: 70%;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  text-align: left;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
`;

export const Line = styled.div`
  width: 80%;
  height: 0px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid ${(props) => props.theme.colors.loblolly};
  opacity: 1;
`;

export const VerticalLine = styled.div`
  margin-top: 80px;
  margin-bottom: 20px;
  margin-right: 60px;
  border: 1px solid ${(props) => props.theme.colors.loblolly};
  opacity: 1;
`;

export const CVS = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Divs = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ListDiv = styled.div`
  width: 80%;
  height: 320px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Text = styled.p<ISelectedStyle>`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  margin: 15px 0px;
  text-align: left;
  color: ${(props) =>
    props.selectedItem && !props.selectedSegment
      ? props.theme.colors.white
      : props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const ListItem = styled.div<ISelectedStyle>`
  height: 42px;
  display: flex;
  align-items: center;
  background: ${(props) =>
    props.selectedItem && !props.selectedSegment ? props.theme.colors.apricot : 'transparent'};
  transition: all 0.2s ease-out;
  cursor: pointer;
  &:hover {
    background: ${(props) =>
      props.selectedItem && !props.selectedSegment ? props.theme.colors.mandysPink : 'transparent'};
    ${Text} {
      font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
    }
  }
`;

export const Arrow = styled.div<IIconImage>`
  width: 10px;
  height: 13px;
  margin-left: 5px;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.image});
`;

export const ArrowDown = styled(Arrow)`
  margin-top: 5px;
`;

export const CVSFields = styled(CVSLists)<IFieldProps>`
  width: ${(props) => (props.shareType === ShareType.CSV ? `100%` : `60%`)};
  display: flex;
  flex-direction: column;
`;

export const FieldContainer = styled.div`
  height: 35px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 10px 20px 0;
  border-radius: 4px;
  background: ${(props) => props.theme.colors.geyser};
`;

export const MCFieldContainer = styled(FieldContainer)`
  margin: 10px 10px 10px 0;
`;

export const Field = styled.div<IField>`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  text-align: left;
  display: flex;
  letter-spacing: 0px;
  margin-bottom: 30px;
  margin-right: 10px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => (props.active ? props.theme.colors.fiord : props.theme.colors.bombay)};
  margin: 0px 10px;
  transition: ${(props) => props.theme.transition.all};
`;

export const FieldText = styled.div`
  font-size: 16px;
  margin-top: 20px;
  margin-left: 0px;
  letter-spacing: 0px;
  display: flex;
  color: ${(props) => props.theme.colors.fiord};
  text-align: left;
`;

export const RequiredDiv = styled.div`
  min-width: 60%;
  height: 40px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  padding: 0 10px;
`;

export const Fields = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SegmentDropdown = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SegmentText = styled.p<ISelectedStyle>`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => (props.selectedItem ? props.theme.colors.white : props.theme.colors.fiord)};
`;

export const Segment = styled.div<ISelectedStyle>`
  display: flex;
  justify-content: left;
  padding-left: 30px;
  align-items: center;
  height: 50px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  background-color: ${(props) => (props.selectedItem ? props.theme.colors.apricot : '')};
  &:hover {
    ${SegmentText} {
      font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
    }
    background-color: ${(props) => props.selectedItem && props.theme.colors.mandysPink};
  }
`;

export const WrapperImport = styled.div<IFieldProps>`
  height: ${(props) =>
    props.shareType === ShareType.CSV ? 'calc(100% - 252px)' : 'calc(100% - 255px)'};
  min-height: ${(props) =>
    props.shareType === ShareType.CSV ? 'calc(100vh - 252px)' : 'calc(100vh - 255px)'};
  background: ${(props) => props.theme.colors.aena};
`;

export const ShareTitle = styled.p`
  margin-top: 30px;
  color: ${(props) => props.theme.colors.fiord};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  text-align: center;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  margin-bottom: 0px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
`;

export const SubTitle = styled.p`
  color: ${(props) => props.theme.colors.fiord};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  text-align: center;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  margin-bottom: 30px;

  b {
    font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  }
`;
