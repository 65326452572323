import { ReactComponent as Play } from 'assets/play.svg';
import { ReactComponent as Replay } from 'assets/replay-circle.svg';
import styled from 'styled-components';

import { IOverlay } from './types';

export const Overlay = styled.div<IOverlay>`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: ${(props) => (props.isTemplate ? '10px' : '0')};
`;

export const PlayIcon = styled(Play)`
  width: 100px;
  height: 100px;
`;

export const ReplayIcon = styled(Replay)`
  width: 100px;
  height: 100px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

//Height Here was 30px but as the player is removed we will use this!
export const PlayerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ControlsWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
`;

export const VideoWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;
