import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'FORM', {
  PASSWORDS_DONT_MATCH: "Passwords don't match.",
  CURRENT_PASSWORD: 'The current password is incorrect',
  OLD_PASSWORD_EMPTY: 'Please enter your current password',
  NEW_PASSWORD_EMPTY: 'Please enter a new password',
  NEW_CONFIRM_PASSWORD_EMPTY: 'Please confirm your new password',
  NEW_DONT_MATCH: "New passwords don't match",
  FIRST_NAME_EMPTY: 'Please enter your first name',
  LAST_NAME_EMPTY: 'Please enter your last name',
  PASSWORD_EMPTY: 'Please enter a password',
  PASSWORD_VALID: 'Please enter a valid password',
  CONFIRM_PASSWORD_EMPTY: 'Please confirm your password',
  EMAIL_EMPTY: 'Please enter your email',
  EMAIL_VALID: 'Please enter a valid email address',
  EMAIL_REGISTERED: 'This email is already registered',
  EMAIL_NOT_REGISTERED: 'This email is not registered',
  USERNAME_INCORRECT: 'The username or password is incorrect',
});

i18next.addResources(locales.EN, 'PROFILE', {
  LOADING: 'Loading Image...',
  SUCCESFULLY: 'the data changed successfully',
  PROFILE: 'Profile',
  FIRST: 'First name',
  LAST: 'Last name',
  EMAIL: 'Email',
  OLDPASS: 'Current Password',
  NEWPASS: 'New Password',
  CONFIRMPASS: 'Confirm Password',
  SAVE: 'Save Changes',
  PASSWORDTITLE: 'Password',
  PERSONALINFOTITLE: 'Personal Information',
  BASIC_INFORMATION: 'Basic information on Data Protection: ',
  BMS_COLLECTS:
    'BMS collects your data too improve our services and, if given consent, will keep you updated on news and promotions of BMS projects. ',
  INFO: '+Info Privacy Policy',
  ACCOUNT: 'Account Information',
  YOURACCOUNT: 'Your account',
  CANCEL: 'Cancel',
});

i18next.addResources(locales.EN, 'SIGNUP', {
  PASS: 'Password',
  REMEMBER: 'Remember me',
  SIGNUP_TITLE: 'Sign up',
  FORGOT: 'Forgot password',
  CONFIRM_PASS: 'Confirm Password',
  LOGIN: 'Log in',
  SIGNUP: 'Sign Up',
  LAST_NAME: 'Last name',
  FIRST_NAME: 'First name',
  PASSWORD_REQUIREMENTS: 'Use at least 8 characters with a mix of letters, numbers & symbols.',
});

i18next.addResources(locales.EN, 'FORGOTPASSWORD', {
  SEND_EMAIL: 'Send email',
  EMAIL_INBOX: 'Check your email inbox',
  FORGOT_PASS: 'Forgot your password',
  EMAIL: 'E-mail',
});

i18next.addResources(locales.EN, 'LOGIN', {
  LOGIN_TITLE: 'Log in to your account',
  PASS: 'Password',
  REMEMBER: 'Remember me',
  FORGOT: 'Forgot password?',
  LOGIN: 'Log in',
  HAVE_ACCOUNT: 'Don´t have an account? ',
  SIGNUP: 'Sign Up',
  EMAIL: 'E-mail',
});
