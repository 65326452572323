export const isEmpty = (input: string): boolean => {
  if (input === '') {
    return true;
  }
  return false;
};

export const isEmail = (mail: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(mail)) {
    return false;
  }
  return true;
};

export const isPasswordMatch = (password: string, passwordConfirm: string): boolean => {
  if (password !== passwordConfirm) {
    return true;
  }
  return false;
};

export const isValidPassword = (password: string): boolean => {
  const validation = new RegExp(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
  );
  if (!validation.test(password)) {
    return true;
  }
  return false;
};

export const isValidColor = (color: string): boolean => {
  const re = /[0-9A-Fa-f]{6}/g;
  if (re.test(color)) {
    return true;
  }
  return false;
};
