import { DataPath, IDataLayer } from 'interfaces/layer.interface';
import { IImageVariable } from 'views/Video/types';

export const replaceImages = (dataLayer: IDataLayer, images: IImageVariable[]): DataPath => {
  const dataCopy = { ...dataLayer };
  const imagesToReplace = images;
  const dataAssets = dataCopy.assets.map((dataAsset) => {
    imagesToReplace.forEach((imagesArray) => {
      if (dataAsset.p && (dataAsset.p as string).includes(imagesArray.name)) {
        dataAsset.u = null;
        dataAsset.p = imagesArray.asset?.path;
      }
    });
    return dataAsset;
  });
  dataCopy.assets = dataAssets;
  return dataCopy as DataPath;
};
