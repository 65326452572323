import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'LOGIN', {
  ANIMAL_BOLD: 'New!',
  ANIMAL: 'Funny animal templates',
  REGISTER: 'You have registered successfully. Check your email inbox',
  LIST_USER: 'Take a list of users to use in a campaign',
  WE_GENERATE: 'For each user we generate a personalized message',
  SUBMIT_THE_CAMPAIGN: 'Submit the campaign and receive detailed posts',
});
