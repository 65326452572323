import React, { ChangeEvent, FC, useRef } from 'react';
import { useLayoutEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import ImageCropper from 'components/ImageCropper';
import { ACCEPT_TYPES } from 'components/common/fileInputAcceptTypes.contants';
import { PUBLIC_URL } from 'configs';
import convertFileToBase64 from 'helpers/convertFileToBase64.helper';
import i18n from 'i18next';

import './i18n';
import { DropzoneText, DropzoneWrapper, IconImage, Wrapper } from './styles';
import { IProps } from './types';

const Dropzone: FC<IProps> = (props) => {
  const MAX_SIZE = props.image ? 2097152 : 10485760;
  const handleFile = async (file: File | null) => {
    if (file) {
      const fileData = new FileReader();
      fileData.onloadend = onFileLoad;
      if (file.size > MAX_SIZE) {
        props.setError();
        return;
      }
      fileData.readAsDataURL(file);
      if (props.setBufferFile) {
        props.setBufferFile((await convertFileToBase64(file)) as string);
      }
      props.setFile(file);
    }
  };

  const handleChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
    handleFile(e.target && e.target.files && e.target.files[0]);
  };

  const handleDropChangeFile = async (files: File[]) => {
    handleFile(files[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept:
      props.image || props.isProfile
        ? [ACCEPT_TYPES.jpgImages, ACCEPT_TYPES.pngImages]
        : ACCEPT_TYPES.video,
    onDrop: handleDropChangeFile,
  });

  const onFileLoad = (event) => {
    if (props.setImage) {
      props.setImage(event.target.result);
    }
  };

  const dropzone = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (props.clicked && dropzone.current) {
      dropzone.current.click();
      props.setClicked(false);
    }
  }, [props.clicked]);

  return (
    <>
      <DropzoneWrapper
        asset={
          (!props.image && (!!props.imageVariable || props.isProfile)) ||
          (!props.video && !!props.videoVariable)
        }
        {...getRootProps({ className: 'dropzone' })}
        ref={dropzone}
      >
        <input {...getInputProps()} onChange={handleChangeFile} />

        <Wrapper>
          <IconImage image={`${PUBLIC_URL}/images/edit-image-modal-img.svg`} />
          <DropzoneText>{i18n.t('DROPZONE:UPLOAD')}</DropzoneText>
        </Wrapper>
      </DropzoneWrapper>
      <ImageCropper
        image={props.image}
        video={
          props.originalVideo && props.originalVideo.path ? props.originalVideo.path : props.video
        }
        zoom={props.zoom}
        setZoom={props.setZoom}
        onCropComplete={props.onCropComplete}
        imageVariable={props.imageVariable}
        videoVariable={props.videoVariable}
        loading={props.loading}
        isProfile={props.isProfile}
      />
    </>
  );
};

export default Dropzone;
