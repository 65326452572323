import { IMetricData } from 'interfaces/metricData.interface';
import { AnyAction } from 'redux';

import * as constants from '../constants/metrics.actions.constants';

export const onCreateMetricsSucceed = (id: string): AnyAction => ({
  type: constants.ON_CREATE_METRICS_SUCCEED,
  id,
});

export const onCreateMetricsFailed = (): AnyAction => ({
  type: constants.ON_CREATE_METRICS_FAILED,
});

export const onVideoStarted = (shareId: string, campaignId?: string): AnyAction => ({
  type: constants.VIDEO_STARTED,
  shareId,
  campaignId,
});

export const getVideoTimeFailed = (): AnyAction => ({
  type: constants.GET_VIDEO_TIME_PLAYED_FAILED,
});

export const onSelectAudioIcon = (): AnyAction => ({
  type: constants.ON_SELECT_AUDIO_ICON,
});

export const onSelectAudioIconSucceed = (): AnyAction => ({
  type: constants.ON_SELECT_AUDIO_ICON_SUCCEED,
});

export const onSelectAudioIconFailed = (): AnyAction => ({
  type: constants.ON_SELECT_AUDIO_ICON_FAILED,
});

export const onRedirectToMetricsDashboard = (id: string): AnyAction => ({
  type: constants.ON_REDIRECT_TO_METRICS_DASHBOARD,
  id,
});

export const onInitializeMetricsDashboard = (id: string): AnyAction => ({
  type: constants.ON_INITIALIZE_METRICS_DASHBOARD,
  id,
});

export const onInitializeMetricsDashboardSucceed = (metricData: IMetricData): AnyAction => ({
  type: constants.ON_INITIALIZE_METRICS_DASHBOARD_SUCCEED,
  metricData,
});

export const onInitializeMetricsDashboardFailed = (): AnyAction => ({
  type: constants.ON_INITIALIZE_METRICS_DASHBOARD_FAILED,
});

export const onUpdateMetrics = (lastSecondPlayed: number): AnyAction => ({
  type: constants.ON_UPDATE_METRICS_REQUESTED,
  lastSecondPlayed,
});

export const onUpdateMetricsSucceed = (): AnyAction => ({
  type: constants.ON_UPDATE_METRICS_SUCCEED,
});

export const onUpdateMetricsFailed = (): AnyAction => ({
  type: constants.ON_UPDATE_METRICS_FAILED,
});

export const onClickCTA = (): AnyAction => ({
  type: constants.ON_CLICK_CTA,
});

export const onClickCTASucceed = (): AnyAction => ({
  type: constants.ON_CLICK_CTA_SUCCEED,
});

export const onClickCTAFailed = (): AnyAction => ({
  type: constants.ON_CLICK_CTA_FAILED,
});

export const onRemoveMetricsData = (): AnyAction => ({
  type: constants.ON_REMOVE_METRICS_DATA,
});
