import { ReactComponent as Rocket } from 'assets/rocket-banner.svg';
import styled from 'styled-components';

export const BannerContainer = styled.div`
  width: 1900px;
  height: 264px;
  background-image: url(/images/hero-back.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  margin: 0 auto 30px auto;

  @media screen and (max-width: 1900px) {
    width: 100%;
  }
`;

export const BannerImage = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const IconContainer = styled.div`
  width: 1020px;
  position: relative;
`;

export const PlayIconContainer = styled.div`
  width: 52px;
  height: 52px;
  position: absolute;
  top: 194px;
  right: 258px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    transform: scale(1.1);
  }
`;

export const RocketImage = styled(Rocket)`
  width: 174px;
  height: 184px;
  position: absolute;
  top: 30px;
  left: 0;
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 45px;
`;

export const BannerTextTitle = styled.h1`
  width: 570px;
  font-size: 28px;
  text-align: center;
  letter-spacing: 0px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
`;

export const BannerText = styled.h2`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  text-align: center;
  padding-top: 6px;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;
