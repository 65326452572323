import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const SuccessMessage = styled.h2`
  font-size: 20px;
  color: ${(props) => props.theme.colors.fiord};
  margin: 30px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-top: 30px;
`;

export const BackButton = styled(Link)`
  width: 110px;
  padding: 10px;
  text-align: center;
  background: ${(props) => props.theme.colors.cello};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.porcelain};
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.colors.bigStone};
  }
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  text-decoration: none;
`;

export const WrapperLaunch = styled.div`
  height: calc(100% - 255px);
  min-height: calc(100vh - 255px);
  background: ${(props) => props.theme.colors.aena};
`;
