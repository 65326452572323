import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NewBMSLayout from 'components/newBMSLayout/newBMSLayout';
import * as templateActions from 'redux/actions/template.actions';
import getTemplateSelector from 'redux/selectors/template.selector';
import NewBMS from 'views/NewBMS/NewBMS';

const NewBMSContainer: FC = () => {
  const [isExistingTemplate, setIsExistingTemplate] = useState<boolean>(true);
  const [nextStep, setNextStep] = useState<boolean>(false);
  const dispatch = useDispatch();
  const getTemplatesData = useSelector(getTemplateSelector());

  useEffect(() => {
    dispatch(templateActions.initializeTemplates());
  }, [dispatch]);

  const onClickNextStep = (templateId: string) => {
    dispatch(templateActions.getTemplate(templateId));
    setNextStep(true);
  };

  const onStepBack = () => {
    setNextStep(false);
  };

  const onSubmitExisting = (data: string[]) => {
    dispatch(templateActions.onSendEmailExisting(data, getTemplatesData.template.title));
  };

  const changeIsSuccess = () => {
    dispatch(templateActions.changeIsSuccess());
  };

  const onSubmitCustom = (
    audience: number,
    emailProvider: string,
    containVideo: string,
    objective: string
  ) => {
    dispatch(
      templateActions.onSendEmailPersonalizedCampaign(
        audience,
        emailProvider,
        containVideo,
        objective
      )
    );
  };

  const onRemoveVideoData = () => {
    dispatch(templateActions.onRemoveVideoData());
  };

  return (
    <NewBMSLayout
      setIsExistingTemplate={setIsExistingTemplate}
      isExistingTemplate={isExistingTemplate}
      changeIsSuccess={changeIsSuccess}
      isSuccessVisible={getTemplatesData.isSuccess}
      toggleSuccess={changeIsSuccess}
    >
      {getTemplatesData.templates.length && (
        <NewBMS
          templates={getTemplatesData.templates}
          template={getTemplatesData.template}
          onClickNextStep={onClickNextStep}
          isExistingTemplate={isExistingTemplate}
          nextStep={nextStep}
          onSubmit={onSubmitExisting}
          onSubmitCustom={onSubmitCustom}
          onStepBack={onStepBack}
          onRemoveVideoData={onRemoveVideoData}
        />
      )}
    </NewBMSLayout>
  );
};

export default NewBMSContainer;
