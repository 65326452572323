import { FONT_FAMILIES, FONT_SIZES } from 'styles/fonts/fonts';

const BMS_THEME = {
  colors: {
    boulder: '#7d7d7d',
    cello: '#214569',
    apricot: '#E98573',
    white: '#FFFFFF',
    fiord: '#425061',
    fern: '#55B776',
    loblolly: '#bbc1c8',
    raven: '#7A8490',
    bigStone: '#142b41',
    silver: '#BEBEBE',
    porcelain: '#EBEDEF',
    cadetBlue: '#A4B1BE',
    jumbo: '#89898b',
    grayChateau: '#A0A7AF',
    red: '#FF0000',
    aena: '#EDF0F2',
    shadow: '#0000001a',
    black: '#000000',
    bittersweet: '#FF7572',
    geraldine: '#FA8F8C',
    gallery: '#EAEAEA',
    oxfordBlue: '#364250',
    mandysPink: '#F0AFA3',
    terracota: '#e3654f',
    cloudy: '#767F8B',
    bombay: '#B3B8BF',
    geyser: '#D8DFE4',
    silverc: '#CCCCCC',
    cousire: '#EA5564',
    coolGray: '#879BAE',
  },
  spacing: {
    spacing0: '0px',
    spacing1: '1px',
  },
  paragraph: {
    fontFamilies: {
      regular: FONT_FAMILIES.poppins.regular,
      semiBold: FONT_FAMILIES.poppins.semiBold,
      bold: FONT_FAMILIES.poppins.bold,
      italic: FONT_FAMILIES.poppins.italic,
      medium: FONT_FAMILIES.poppins.medium,
      light: FONT_FAMILIES.poppins.light,
    },
    fontSizes: {
      small: FONT_SIZES.small,
      medium: FONT_SIZES.medium,
      large: FONT_SIZES.large,
      extraLarge: FONT_SIZES.extraLarge,
    },
  },
  headers: {
    h1: {
      fontFamily: FONT_FAMILIES.poppins.regular,
    },
    h2: {
      fontFamilies: {
        regular: FONT_FAMILIES.poppins.regular,
        semiBold: FONT_FAMILIES.poppins.semiBold,
        bold: FONT_FAMILIES.poppins.bold,
        italic: FONT_FAMILIES.poppins.italic,
        medium: FONT_FAMILIES.poppins.medium,
        light: FONT_FAMILIES.poppins.light,
      },
      fontSizes: {
        small: FONT_SIZES.small,
        medium: FONT_SIZES.medium,
        large: FONT_SIZES.large,
        extraLarge: FONT_SIZES.extraLarge,
      },
    },
    h4: {
      fontFamily: FONT_FAMILIES.poppins.regular,
    },
    input: {
      fontFamily: FONT_FAMILIES.robotocondensed.bold,
    },
  },
  transition: {
    all: 'all 0.2s ease-out',
  },
  windowSizes: {
    smallPhone: 320,
    applePhone: 430,
    mediumPhone: 480,
    bigPhone: 575,
    smallTablet: 600,
    phoneLandscape: 667,
    mediumTablet: 767,
    bigPhoneLandscape: 850,
    bigTablet: 991,
    smallDesktop: 1024,
    desktop: 1199,
    bigDesktop: 1366,
  },
};
export type ITheme = typeof BMS_THEME;
export default BMS_THEME;
