import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'CONFIRMMODAL', {
  CANCEL: 'Cancel',
  DELETE_PERMANENTLY_QUESTION: 'Delete forever?',
  DELETE_WARNING: 'Are you sure want to permanently delete ',
  DELETE_CAMPAIGN: '"{{name}}"?',
  UNDO: 'You can’t undo this action.',
  DELETE_PERMANENTLY_CTA: 'Delete forever',
  DELETE: 'Delete',
});
