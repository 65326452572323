import { routerMiddleware } from 'connected-react-router';

import history from 'helpers/history';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import redirectMiddleware from './middlewares/redirect.middleware';
import reducers from './reducers';
import rootSaga from './sagas';

export const sagaMiddleware = createSagaMiddleware();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(routerMiddleware(history), redirectMiddleware, sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export default store;
