import React, { FC } from 'react';

import { CloseModal } from './styles';
import { IProps } from './types';

const CloseModalButton: FC<IProps> = (props) => {
  return (
    <CloseModal
      onClick={props.onClick}
      image={`${process.env.PUBLIC_URL}/images/close-x.svg`}
      hover={`${process.env.PUBLIC_URL}/images/close-x-h.svg`}
    />
  );
};
export default CloseModalButton;
