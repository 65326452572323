import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ColorVariableType } from 'enums/colorVariable.enum';
import { getJsonLayer } from 'helpers/get_json_layer.helper';
import { ILayer } from 'interfaces/layer.interface';
import {
  onClickCTA,
  onSelectAudioIcon,
  onUpdateMetrics,
  onVideoStarted,
} from 'redux/actions/metric.actions';
import * as sharedUserActions from 'redux/actions/sharedUser.actions';
import * as templateActions from 'redux/actions/template.actions';
import getCampaignSelector, { getVideo } from 'redux/selectors/campaign.selector';
import getSharedUserSelector from 'redux/selectors/sharedUser.selector';
import UserPlayer from 'views/UserPlayer/UserPlayer';
import { IColorVariable, ITextVariable } from 'views/Video/types';

import { CampaignShareProps } from './types';

const SharedUserPlayer: FC<CampaignShareProps> = (props) => {
  const [layersData, setLayersData] = useState<ILayer[]>([]);
  const video = useSelector(getVideo());
  const sharedPlayerId = props.match.params.sharedPlayerId;
  const dispatch = useDispatch();
  const getSharedUserData = useSelector(getSharedUserSelector());
  const getCampaignData = useSelector(getCampaignSelector());
  const [backgroundColor, setBackgroundColor] = useState<string>('#FFFFFF');

  useEffect(() => {
    dispatch(sharedUserActions.initializePlayer(sharedPlayerId));
  }, [dispatch]);

  useEffect(() => {
    if (getCampaignData.campaign && getCampaignData.campaign.textVariables) {
      const textVariables = getCampaignData.campaign.textVariables.map(
        (textVariable: ITextVariable) => {
          const found = getSharedUserData.variables.shareData.find(
            (value) => value.textId === textVariable._id
          );
          if (found) {
            textVariable.value = found.valueArray;
          }
          return textVariable;
        }
      );
      if (getCampaignData.campaign.template && !!textVariables.length) {
        getJsonLayer(
          getCampaignData.campaign,
          setLayersData,
          getCampaignData.campaign.template.layers,
          textVariables
        );
      }
    }
  }, [getCampaignData.campaign]);

  useEffect(() => {
    const color = getCampaignData.campaign.colorVariables.find(
      (color: IColorVariable) => color.type === ColorVariableType.Background
    );
    if (color) {
      setBackgroundColor(color.value);
    }
  }, [getCampaignData.campaign.colorVariables]);

  const handleVideoStart = () => {
    dispatch(onVideoStarted(sharedPlayerId));
  };

  const onUpdateWatchedTime = (lastSecondPlayed: number) => {
    dispatch(onUpdateMetrics(lastSecondPlayed));
  };

  const handleSelectAudioIcon = () => {
    dispatch(onSelectAudioIcon());
  };

  const onCTAClicked = () => {
    dispatch(onClickCTA());
  };

  const onRemoveVideoData = () => {
    dispatch(templateActions.onRemoveVideoData());
  };

  return !!layersData.length ? (
    <UserPlayer
      layers={layersData}
      backgroundColor={backgroundColor}
      colorVariables={getCampaignData.campaign.colorVariables}
      imageVariables={getCampaignData.campaign.imageVariables}
      audioFile={getCampaignData.campaign.template.audio}
      textVariables={getCampaignData.campaign.textVariables}
      handleSelectAudioIcon={handleSelectAudioIcon}
      backgroundVideo={video ? video.path : ''}
      onStart={handleVideoStart}
      onUpdate={onUpdateWatchedTime}
      onCTAClicked={onCTAClicked}
      scenes={getCampaignData.campaign.template.scenes}
      onRemoveVideoData={onRemoveVideoData}
      duration={getCampaignData.campaign.template.duration}
    />
  ) : (
    <></>
  );
};
export default SharedUserPlayer;
