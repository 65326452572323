import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'TOPBAR', {
  WHAT: 'What is BMS?',
  WHY: 'Why BMS?',
  TEMPLATES: 'Templates',
  CONTACT: 'Contact Us',
  LOGIN: 'Log in',
  SIGNUP: 'Sign up',
  CAMPAIGNS: 'My Campaigns',
  NEW_BMS: '+ New BMS',
});
