import styled from 'styled-components';

export const WrapperImport = styled.div`
  height: calc(100% - 255px);
  min-height: calc(100vh - 255px);
  background: ${(props) => props.theme.colors.aena};
`;

export const OptionText = styled.div`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  margin-top: 5px;
  text-align: center;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  margin-top: 180px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  color: ${(props) => props.theme.colors.fiord};
`;

export const OptionsContainer = styled.div`
  width: 470px;
  height: 200px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  grid-template-rows: 110px 110px;
  margin: 20px auto 0;
  padding-top: 95px;
  position: relative;
`;

export const Icon = styled.img`
  background-repeat: no-repeat;
  align-items: center;
  background-position: center;
  transition: ${(props) => props.theme.transition.all};
  position: absolute;
`;

export const IconHover = styled.img`
  background-repeat: no-repeat;
  align-items: center;
  background-position: center;
  transition: ${(props) => props.theme.transition.all};
  opacity: 0;
  position: absolute;
`;

export const OptionsContCSV = styled.div`
  width: 158px;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: ${(props) => props.theme.transition.all};
  cursor: pointer;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 6px 12px #00000029;
  border-radius: 8px;
  &:hover {
    ${OptionText} {
      font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
    }
    ${IconHover} {
      opacity: 1;
    }
    ${Icon} {
      opacity: 0;
    }
  }
`;

export const Or = styled.h2`
  font-size: 18px;
  margin-left: 30px;
  margin-right: 30px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.extraLarge};
  text-align: center;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  margin-bottom: 0px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => props.theme.colors.fiord};
`;
