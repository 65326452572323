import { Controls, Player } from '@lottiefiles/react-lottie-player';

import { ReactComponent as soundBlockedHover } from 'assets/icon-sound-X_fill.svg';
import { ReactComponent as soundBlocked } from 'assets/icon-sound-x.svg';
import { ReactComponent as sound } from 'assets/sound-icon-01.svg';
import { ReactComponent as soundHover } from 'assets/sound-icon_fill-01.svg';
import styled from 'styled-components';

import { DISABLED_VOLUME_VALUE } from './constants';
import { IAnimationContainer, ILayerZoom, IVisible } from './types';

export const AnimationContainer = styled.div<IAnimationContainer>`
  width: 100%;
  height: ${(props) => props.isEdit && '100%'};
  position: relative;
  background-color: ${(props) => props.backgroundColor};
  margin: 0 auto;
  border-radius: ${(props) =>
    props.isPreview && !props.isSharedPlayer ? '10px 10px 0 0' : props.isSharedPlayer ? '0' : '10px'};
  display: ${(props) => (props.isLoading ? 'none' : 'block')};
  overflow: hidden;
`;

export const AnimationLayer = styled.div<ILayerZoom>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${(props) => (props.isZoom ? 'scale(1.1)' : '0')};
  transition: all 0.7s ease;
`;

export const AnimationLayerPreview = styled(Player)`
  width: 100%;
  height: 100%;
  .lf-player-container {
    .lf-player-controls {
      height: 30px;
    }
  }
`;

export const SoundWrapper = styled.div`
  width: 14%;
  height: 45px;
  background: ${(props) => props.theme.colors.cello};
  z-index: 2;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${(props) => props.theme.windowSizes.smallDesktop}px) {
    width: 24%;
  }
`;

export const Sound = styled(sound)`
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  opacity: 1;
`;

export const SoundBlockedHover = styled(soundBlockedHover)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  opacity: 0;
`;

export const SoundHover = styled(soundHover)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  opacity: 0;
`;

export const SoundBlock = styled(soundBlocked)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
`;

export const SoundContainer = styled.div<IVisible>`
  width: 25px;
  height: 25px;
  display: flex;
  flex-direction: row;
  position: relative;
  &:hover {
    ${Sound} {
      opacity: ${(props) => !props.replayVisible && '0'};
    }
    ${SoundHover} {
      opacity: ${(props) => !props.replayVisible && '1'};
    }
    ${SoundBlock} {
      opacity: ${(props) => !props.replayVisible && '0'};
    }
    ${SoundBlockedHover} {
      opacity: ${(props) => !props.replayVisible && '1'};
    }
  }
`;

export const SoundClickContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const SoundDisabled = styled(soundBlocked)`
  width: 100%;
  height: 100%;
  cursor: not-allowed;
  opacity: 0.5;
  position: absolute;
`;

export const VolumeBar = styled.input`
  width: 40%;
  height: 4px;
  border-radius: 10px;
  -webkit-appearance: none;
  -moz-apperance: none;
  cursor: ${(props) => (props.value === DISABLED_VOLUME_VALUE ? 'default' : 'pointer')};
  margin: 0 0 0 10px;
  background-image: ${(props) =>
    `-webkit-gradient(linear, left top, right top, color-stop(${
      parseFloat((props.value ? props.value.toString() : 0).toString()) * 100
    }%, ${props.theme.colors.aena}), color-stop(${
      parseFloat((props.value ? props.value.toString() : 0).toString()) * 100
    }%, ${props.theme.colors.coolGray}))`};
  &::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    height: 6px;
    width: 6px;
    border: 0;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.white};
    cursor: ${(props) => (props.value === -1 ? 'default' : 'pointer')};
  }
  &:focus {
    outline: none;
  }
`;

export const ControlsPreview = styled(Controls)`
  .lf-player-controls {
    height: 30px;
  }
`;

export const VideoComponent = styled.video`
  width: 100%;
  height: 100%;
`;
