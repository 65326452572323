import React, { FC, Fragment } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import CampaignEditContainer from 'containers/campaign/CampaignEdit';
import ConfirmAccount from 'containers/confirmAccount/confirmAccount';
import ForgotPassord from 'containers/forgotPassword/ForgotPassword';
import ShareLaunchContainer from 'containers/launchContainer/ShareLaunchContainer';
import MailchimpPlayerContainer from 'containers/mailchimPlayer/MailchimpPlayerContainer';
import ShareMatchContainer from 'containers/matchContainer/ShareMatchContainer';
import MetricsDashboard from 'containers/metricsDashboard/MetricsDashboard';
import MyCampaigns from 'containers/myCampaigns/myCampaigns';
import NewBMSContainer from 'containers/newBMSContainer/NewBMSContainer';
import ShareContainer from 'containers/share/ShareContainer';
import ShareImportCSVContainer from 'containers/shareImportCSV/ShareImportCSVContainer';
import ShareSelectContainer from 'containers/shareSelect/ShareSelectContainer';
import SharedUserPlayer from 'containers/sharedUserPlayer/SharedUserPlayer';
import UserProfileContainer from 'containers/user/UserProfile';

import PrivacyPolicy from '../src/views/PrivacyPolicy/PrivacyPolicy';
import TermsConditions from '../src/views/TermsConditions/TermsConditions';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import TemplateContainer from './containers/template/Template';

const App: FC = () => {
  return (
    <Fragment>
      <Switch>
        <PublicRoute restricted={false} exact path="/" component={TemplateContainer} />
        <PublicRoute restricted={false} path="/my-campaigns" component={MyCampaigns} />
        <PublicRoute restricted={false} path="/privacy" component={PrivacyPolicy} />
        <PublicRoute restricted={false} path="/terms-conditions" component={TermsConditions} />
        <PrivateRoute path="/campaign/:campaignId/edit/" component={CampaignEditContainer} />
        <PrivateRoute path="/profile" component={UserProfileContainer} />
        <PrivateRoute exact path="/campaign/:campaignId/share" component={ShareContainer} />
        <PrivateRoute
          exact
          path="/campaign/:campaignId/share/import/csv/"
          component={ShareImportCSVContainer}
        />
        <PrivateRoute
          exact
          path="/campaign/:campaignId/share/select/"
          component={ShareSelectContainer}
        />
        <PrivateRoute exact path="/new-bms/" component={NewBMSContainer} />
        <PrivateRoute
          exact
          path="/campaign/:campaignId/share/match/"
          component={ShareMatchContainer}
        />
        <PrivateRoute
          exact
          path="/campaign/:campaignId/share/launch/"
          component={ShareLaunchContainer}
        />
        <PrivateRoute exact path="/reports/:campaignId/" component={MetricsDashboard} />
        <PublicRoute
          restricted={false}
          path="/share/:sharedPlayerId/player/"
          component={SharedUserPlayer}
        />
        <PublicRoute
          restricted={false}
          path="/:campaignId/:mailchimpUniqueId/player/"
          component={MailchimpPlayerContainer}
        />
        <PublicRoute restricted={false} path="/forgotPassword/:token" component={ForgotPassord} />
        <PublicRoute restricted={false} path="/confirm-account/:token" component={ConfirmAccount} />
        <Redirect to="/" />
      </Switch>
    </Fragment>
  );
};

export default App;
