export const ACCEPT_TYPES = {
  all: '/*',
  images: 'image/*',
  jpgImages: 'image/jpeg',
  pngImages: 'image/png',
  audio: 'audio/*',
  video: 'video/*',
  jsons: 'application/JSON',
  csv: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
};
