import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 70vh;
`;

export const LogInText = styled.h2`
  font-size: 20px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  color: ${(props) => props.theme.colors.fiord};
  margin-bottom: 30px;
`;

export const Button = styled(Link)`
  width: 145px;
  height: 40px;
  text-decoration: none;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.cello};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  &:hover {
    background: ${(props) => props.theme.colors.apricot};
  }
`;

export const IconContainer = styled.div`
  width: 300px;
  height: 60px;
  display: flex;
  margin-bottom: 40px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;
