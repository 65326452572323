import { IObject } from 'helpers/types';
import { AnyAction } from 'redux';

import * as constants from '../constants/userShared.action.constants';

export const initializePlayer = (shareId: string): AnyAction => ({
  type: constants.ON_INITIALIZE_PLAYER_REQUESTED,
  shareId,
});

export const initializePlayerSuccedeed = (data: IObject): AnyAction => ({
  type: constants.ON_INITIALIZE_PLAYER_SUCCEDEED,
  data,
});

export const initializePlayerFailed = (): AnyAction => ({
  type: constants.ON_INITIALIZE_PLAYER_FAILED,
});

export const initializeMailchimpPlayer = (
  campaignId: string,
  mailchimpUniqueId: string
): AnyAction => ({
  type: constants.ON_INITIALIZE_MAILCHIMP_PLAYER_REQUESTED,
  campaignId,
  mailchimpUniqueId,
});

export const initializeMailchimpPlayerSuccedeed = (data: IObject): AnyAction => ({
  type: constants.ON_INITIALIZE_MAILCHIMP_PLAYER_SUCCEDEED,
  data,
});

export const initializeMailchimpPlayerFailed = (): AnyAction => ({
  type: constants.ON_INITIALIZE_MAILCHIMP_PLAYER_FAILED,
});
