import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Topbar from 'components/common/Topbar/Topbar';
import * as userActions from 'redux/actions/user.actions';
import errorSelector from 'redux/selectors/error.selector';
import userSelector from 'redux/selectors/user.selector';

import { IProps } from './types';

const StoreLayout: FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const getUserData = useSelector(userSelector());
  const errorData = useSelector(errorSelector());

  const submitLogin = (email: string, password: string) => {
    dispatch(userActions.submitLogin(email, password));
  };

  useEffect(() => {
    if (getUserData.user.isLogged) {
      dispatch(userActions.initializeUser());
    }
  }, [dispatch, getUserData.user.isLogged]);

  const userLogout = () => {
    dispatch(userActions.userLogout());
  };

  const resetPassword = (email: string) => {
    dispatch(userActions.forgotPassword(email));
  };

  const changeStatusSignUpFalse = () => {
    dispatch(userActions.changeStatusSignUpFalse());
  };

  const setErrorFalse = () => {
    dispatch(userActions.changeErrorValue());
  };

  const submitSignUp = (firstName: string, lastName: string, email: string, password: string) => {
    dispatch(userActions.submitSignUp(firstName, lastName, email, password));
  };

  return (
    <>
      <Topbar
        successSendEmail={getUserData.user.successSendEmail}
        setErrorFalse={setErrorFalse}
        changeStatusSignUp={changeStatusSignUpFalse}
        userInfo={getUserData.user.user}
        onSubmitSignUp={submitSignUp}
        onSubmitLogin={submitLogin}
        isLogged={getUserData.user.isLogged}
        userLogout={userLogout}
        statusSignUp={getUserData.user.statusSignUp}
        error={errorData.status}
        resetPassword={resetPassword}
        isTemplate={props.isTemplate}
      />
      {props.children}
    </>
  );
};

export default StoreLayout;
