import { useState } from 'react';

export const useResize = (): (number | (() => void))[] => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  return [width, handleWindowSizeChange, height];
};
