import { AnyAction } from 'redux';

import * as campaignConstants from '../constants/campaign.action.constants';
import * as userConstants from '../constants/user.actions.constants';

interface IErrorReducerState {
  status: boolean;
}

const defaultState: IErrorReducerState = {
  status: false,
};

const changeStatusTrue = (state: IErrorReducerState) => {
  return {
    ...state,
    status: true,
  };
};

const changeStatusFalse = (state: IErrorReducerState) => {
  return {
    ...state,
    status: false,
  };
};

const errorReducer = (
  state: IErrorReducerState = defaultState,
  action: AnyAction
): IErrorReducerState => {
  switch (action.type) {
    case userConstants.USER_ON_LOGIN_FAILED:
    case userConstants.USER_ON_SIGNUP_FAILED:
    case campaignConstants.ON_CHANGE_CAMPAIGN_IMAGE_FAILED:
    case userConstants.ON_CHANGE_USER_AVATAR_FAILED:
    case userConstants.ON_CHANGE_USER_FIRSTNAME_FAILED:
    case userConstants.ON_CHANGE_USER_LASTNAME_FAILED:
    case userConstants.ON_CHANGE_USER_PASSWORD_FAILED:
    case userConstants.ON_CHANGE_ERROR_VALUE_TRUE:
    case userConstants.ON_USER_LOGIN_AND_CREATE_CAMPAIGN_FAILED:
    case userConstants.ON_FORGOT_PASSWORD_FAILED:
      return changeStatusTrue(state);
    case userConstants.ON_CHANGE_ERROR_VALUE:
    case userConstants.USER_ON_LOGIN_SUCCEEDED:
    case userConstants.USER_ON_SIGNUP_SUCCEEDED:
      return changeStatusFalse(state);
    default:
      return state;
  }
};

export default errorReducer;
