import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

import { IApplyButton, IIconImage } from './types';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
`;

export const ModalContainer = styled.div`
  width: 460px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 2px 6px #00000029;
  background: ${(props) => props.theme.colors.white};
`;

export const CloseModalWrapper = styled.div`
  width: 460px;
  height: 35px;
  justify-content: flex-end;
  display: flex;
  position: absolute;
`;

export const ContentWrapper = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const IconImage = styled.div<IIconImage>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  background-repeat: no-repeat;
  background-image: url(${(props) => props.image});
  margin: auto;
`;

export const IconWrapper = styled.div`
  display: flex;
`;

export const ModalTitle = styled.h2`
  font-size: 24px;
  color: ${(props) => props.theme.colors.fiord};
  margin: 26px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  text-align: center;
`;

export const ModalSubtitle = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const ApplyButton = styled.button<IApplyButton>`
  text-align: center;
  background: ${(props) =>
    props.disable ? props.theme.colors.cadetBlue : props.theme.colors.cello};
  border: 0;
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  cursor: ${(props) => (props.disable ? 'default' : 'pointer')};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  width: 140px;
  height: 40px;
  border-radius: 2px;
  margin: auto;
  &:hover {
    background: ${(props) => !props.disable && props.theme.colors.bigStone};
  }
`;

export const ButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px;
`;

export const DropzoneContainer = styled.div`
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const UploadText = styled.div`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.apricot};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  cursor: pointer;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  border: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const InputFile = styled.input`
  width: 200px;
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  border: 1px solid ${(props) => props.theme.colors.porcelain};
  background-color: white;
`;

export const SliderWrapper = styled.div`
  width: 70%;
  margin: 20px auto 0 auto;
  display: flex;
`;

export const CropperSlider = withStyles({
  root: {
    color: '#ebedef',
    height: 4,
    margin: '10px',
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: '#e98573',
    marginTop: -4,
    marginLeft: -6,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
})(Slider);
