import React, { FC, useState } from 'react';

import PreviewPlayer from 'containers/PreviewPlayerContainer/PreviewPlayerContainer';
import { ShareStep } from 'enums/shareStep.enum';
import i18n from 'i18next';

import './i18n';
import {
  BMSIcon,
  BackArrow,
  ButtonBack,
  ButtonContentWrapper,
  ButtonNext,
  Linked,
  NameTooltipText,
  NextArrow,
  PreviewButtonContentWrapper,
  PreviewIcon,
  TextContainer,
  Tootltip,
  TopbarContainer,
  TopbarStyle,
  TopbarTitle,
} from './styles';
import { IProps } from './types';

const TopbarShare: FC<IProps> = (props) => {
  const [previewIsOpen, setPreviewIsOpen] = useState(false);

  const onNext = () => {
    if (!props.disabledNextButton && props.onNext) {
      props.onNext();
    }
  };

  const onBack = () => {
    if (props.onBack) {
      props.onBack();
    }
  };

  const onClickPreview = () => {
    setPreviewIsOpen(true);
  };

  const closeModal = () => {
    setPreviewIsOpen(false);
  };

  return (
    <>
      <TopbarStyle>
        <TopbarContainer>
          <Linked to="/">
            <BMSIcon />
          </Linked>
          <TopbarTitle>{i18n.t('TOPBAR:LAUNCH')}</TopbarTitle>
          <TextContainer>
            {!props.success && (
              <ButtonBack onClick={onBack}>
                <ButtonContentWrapper>
                  <BackArrow />
                  {i18n.t('TOPBAR:BACK')}
                </ButtonContentWrapper>
                {props.hasTooltip && (
                  <Tootltip>
                    <NameTooltipText>{i18n.t('TOPBAR:BACKEDIT')}</NameTooltipText>
                  </Tootltip>
                )}
              </ButtonBack>
            )}
            {!props.success &&
              (props.step === ShareStep.SELECT ||
                props.step === ShareStep.MATCH ||
                (props.step === ShareStep.LAUNCH &&
                  props.selectedOption === 'share' &&
                  !props.isCSVFormVisible)) && (
                <ButtonNext onClick={onNext} disabledNextButton={props.disabledNextButton}>
                  <ButtonContentWrapper>
                    {i18n.t('TOPBAR:NEXT')}
                    <NextArrow />
                  </ButtonContentWrapper>
                </ButtonNext>
              )}
            {!props.success &&
              props.step === ShareStep.LAUNCH &&
              (props.shareType === ShareStep.SELECT || props.selectedOption === '') &&
              !props.isMailchimpFormVisible && (
                <ButtonNext onClick={onClickPreview} disabledNextButton={false}>
                  <PreviewButtonContentWrapper>
                    {i18n.t('TOPBAR:PREVIEW')}
                    <PreviewIcon />
                  </PreviewButtonContentWrapper>
                </ButtonNext>
              )}
            {!props.success &&
              props.step === ShareStep.LAUNCH &&
              (props.isCSVFormVisible ||
                (props.isMailchimpFormVisible && props.isCreateAndSend)) && (
                <ButtonNext onClick={onNext} disabledNextButton={props.disabledNextButton}>
                  {i18n.t('TOPBAR:SEND')}
                </ButtonNext>
              )}
            {!props.success &&
              props.step === ShareStep.LAUNCH &&
              props.isMailchimpFormVisible &&
              !props.isCreateAndSend && (
                <ButtonNext onClick={onNext} disabledNextButton={props.disabledNextButton}>
                  {i18n.t('TOPBAR:SAVE')}
                </ButtonNext>
              )}
          </TextContainer>
        </TopbarContainer>
      </TopbarStyle>
      {previewIsOpen && (
        <PreviewPlayer id={props.campaignId} onClose={closeModal} isTemplate={false} />
      )}
    </>
  );
};
export default TopbarShare;
