import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ColorVariableType } from 'enums/colorVariable.enum';
import { TextVariableType } from 'enums/textVariable.enum';
import { getJsonLayer } from 'helpers/get_json_layer.helper';
import i18n from 'i18next';
import { ILayer } from 'interfaces/layer.interface';
import * as templateActions from 'redux/actions/template.actions';
import { getPreviewData } from 'redux/selectors/campaign.selector';
import {
  getPreviewTemplate,
  getTemplateDescription,
  getTemplateName,
} from 'redux/selectors/template.selector';
import CampaignPreview from 'views/Campaign/CampaignPreview/CampaignPreview';
import TemplatePreview from 'views/Template/components/TemplatePreview/TemplatePreview';
import { IColorVariable, ITextVariable } from 'views/Video/types';

import './i18n';
import { IPreviewData, IProps } from './types';

const PreviewPlayerContainer: FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const getTemplatesData = useSelector(getPreviewTemplate());
  const getCampaignVideo = useSelector(getPreviewData());
  const templateTitle = useSelector(getTemplateName(props.id));
  const templateDescription = useSelector(getTemplateDescription(props.id));
  const [backgroundColor, setBackgroundColor] = useState<string>('#FFFFFF');
  const [previewData, setPreviewData] = useState<IPreviewData>({
    layers: [],
    textVariables: [],
    colorVariables: [],
    duration: 0,
    imageVariables: [],
    audio: '',
    video: '',
    scenes: [],
  });
  const [layersData, setLayersData] = useState<ILayer[]>([]);
  const [audioPath, setAudioPath] = useState<string>('');
  const [elements, setElements] = useState([]);
  const [readedJSON, setReadedJSON] = useState<boolean>(false);
  useEffect(() => {
    dispatch(templateActions.onSelectPreview(props.id, !!props.isTemplate));
  }, [dispatch]);

  const handleClose = () => {
    props.onClose();
  };

  useEffect(() => {
    if (props.isTemplate) {
      setPreviewData(getTemplatesData);
    } else {
      setPreviewData(getCampaignVideo);
    }
  }, [getCampaignVideo.layers, getTemplatesData.layers]);

  useEffect(() => {
    if (previewData.layers?.length && !readedJSON) {
      getJsonLayer(
        previewData,
        setLayersData,
        previewData.layers,
        previewData.textVariables ? previewData.textVariables : []
      );
      setReadedJSON(true);
    }
  }, [previewData]);

  useEffect(() => {
    return () => {
      setLayersData([]);
    };
  }, []);

  useEffect(() => {
    if (previewData.layers?.length) {
      const color = previewData.colorVariables.find(
        (color: IColorVariable) => color.type === ColorVariableType.Background
      );
      if (color) {
        setBackgroundColor(color.value);
      }
    }
  }, [getCampaignVideo]);

  useEffect(() => {
    setAudioPath(previewData.audio || '');
  }, [getCampaignVideo]);

  useEffect(() => {
    const elems = [];
    if (getTemplatesData.textVariables.length) {
      elems.push(i18n.t('PREVIEW:TEXTS'));
      if (
        getTemplatesData.textVariables.some(
          (variable: ITextVariable) => variable.type === TextVariableType.CTA
        )
      ) {
        elems.push(i18n.t('PREVIEW:CTAS'));
      }
    }
    if (getTemplatesData.imageVariables.length) {
      elems.push(i18n.t('PREVIEW:IMAGES'));
    }
    if (getTemplatesData.colorVariables.length) {
      elems.push(i18n.t('PREVIEW:COLORS'));
    }

    setElements(elems);
  }, [layersData]);

  const onRemoveVideoData = () => {
    dispatch(templateActions.onRemoveVideoData());
  };
  return layersData.length ? (
    props.isTemplate ? (
      <TemplatePreview
        onCloseModal={handleClose}
        title={templateTitle}
        description={templateDescription}
        layers={layersData}
        backgroundColor={backgroundColor}
        audio={audioPath}
        imageVariables={getTemplatesData.imageVariables}
        colorVariables={getTemplatesData.colorVariables}
        textVariables={getTemplatesData.textVariables}
        id={props.id}
        onEditTemplate={props.onEditTemplate}
        elements={elements.join(' - ')}
        backgroundVideo={getTemplatesData.video}
        scenes={getTemplatesData.scenes}
        onRemoveVideoData={onRemoveVideoData}
        duration={getTemplatesData.duration}
      />
    ) : (
      <CampaignPreview
        textVariables={previewData.textVariables}
        duration={previewData.duration}
        onCloseModal={handleClose}
        imageVariables={previewData.imageVariables}
        colorVariables={previewData.colorVariables}
        layers={layersData}
        id={props.id}
        backgroundColor={backgroundColor}
        audio={audioPath}
        backgroundVideo={getCampaignVideo.video}
        scenes={getCampaignVideo.scenes}
        onRemoveVideoData={onRemoveVideoData}
      />
    )
  ) : null;
};

export default PreviewPlayerContainer;
