import React, { FC, useEffect, useState } from 'react';

import CloseModalButton from 'components/CloseModalButton/CloseModalButton';
import { CampaignError } from 'enums/campaignError.enum';
import i18n from 'i18next';
import { ICampaign } from 'views/CampaignEdit/types';

import products from './helpers/products';
import './i18n';
import {
  CloseModalWrapper,
  ContentWrapper,
  ModalContainer,
  ModalWrapper,
  Subtitle,
  Title,
  UpgradeButton,
  UpgradeSubtitle,
} from './styles';
import { IProps } from './types';

const PaywallModal: FC<IProps> = (props) => {
  const [product, setProduct] = useState(products[0]);

  useEffect(() => {
    const userProduct = products.find((product) => product.id === props.productId);
    setProduct(userProduct ? userProduct : products[0]);
  }, [props.productId]);

  const ExpiredAlertSubtitle = () => {
    let subtitle = '';
    if (props.aboutToExpireCampaignsToAlert && props.aboutToExpireCampaignsToAlert.length) {
      subtitle = props.aboutToExpireCampaignsToAlert
        .map((campaign: ICampaign) => campaign.name)
        .join(', ')
        .replace(/, ([^,]*)$/, ' and $1');
      subtitle += i18n.t(`PAYWALL_MODAL:SUBTITLE_ALERT_CAMPAIGN_LIFE_TIME_LIMIT_ABOUT_TO`);
    }
    if (props.expiredCampaignsToAlert && props.expiredCampaignsToAlert.length) {
      subtitle += props.expiredCampaignsToAlert
        .map((campaign: ICampaign) => campaign.name)
        .join(', ')
        .replace(/, ([^,]*)$/, ' and $1');
      subtitle += i18n.t(`PAYWALL_MODAL:SUBTITLE_ALERT_CAMPAIGN_LIFE_TIME_LIMIT_EXPIRED`);
    }
    return subtitle;
  };

  const title = () => {
    if (props.paywallMessage === CampaignError.MASSIVE_SHARE_CAMPAIGN_LIMIT) {
      return i18n.t(`PAYWALL_MODAL:TITLE_${props.paywallMessage}`, {
        count: product.limit_2,
      });
    }
    return i18n.t(`PAYWALL_MODAL:TITLE_${props.paywallMessage}`);
  };
  const subtitle = () => {
    if (props.paywallMessage === CampaignError.CREATE_CAMPAIGN_LIMIT_EXCEEDED) {
      return i18n.t(`PAYWALL_MODAL:SUBTITLE_${props.paywallMessage}`, {
        count: product.limit_0,
      });
    }
    if (props.paywallMessage === CampaignError.SHARED_SHARE_CAMPAIGN_LIMIT) {
      return i18n.t(`PAYWALL_MODAL:SUBTITLE_${props.paywallMessage}`, {
        count: product.limit_1,
      });
    }
    return i18n.t(`PAYWALL_MODAL:SUBTITLE_${props.paywallMessage}`);
  };

  return (
    <ModalWrapper>
      <ModalContainer>
        <CloseModalWrapper>
          <CloseModalButton onClick={props.onClose} />
        </CloseModalWrapper>
        <ContentWrapper>
          <Title>{props.paywallMessage && title()}</Title>
          <Subtitle>
            {props.paywallMessage === CampaignError.ALERT_CAMPAIGN_LIFE_TIME_LIMIT
              ? ExpiredAlertSubtitle()
              : subtitle()}
          </Subtitle>
          <UpgradeButton
            onClick={props.onRequestUpgrade}
            succededUpgradeRequest={props.succededUpgradeRequest}
          >
            {i18n.t('PAYWALL_MODAL:UPGRADE')}
          </UpgradeButton>
          <UpgradeSubtitle succededUpgradeRequest={props.succededUpgradeRequest}>
            {i18n.t('PAYWALL_MODAL:UPGRADE_SUBTITLE')}
          </UpgradeSubtitle>
        </ContentWrapper>
      </ModalContainer>
    </ModalWrapper>
  );
};
export default PaywallModal;
