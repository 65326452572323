import React, { FC } from 'react';

import Wrapper from 'components/common/Wrapper/Wrapper';
import i18n from 'i18next';

import './i18n';
import { FormContainer, Input, InputContainer, Label, WrapperLaunch } from './styles';
import { IProps } from './types';

const LaunchMailchimpForm: FC<IProps> = (props) => {
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setInput: (value: string) => void
  ) => {
    setInput(event.target.value);
  };

  return (
    <WrapperLaunch>
      <Wrapper>
        <FormContainer>
          <InputContainer>
            <Label>{i18n.t('SHAREEMAILFORM:BODY')}</Label>
            <Input
              type="text"
              onChange={(e) => handleInputChange(e, props.setBody)}
              maxLength={50}
              value={props.body}
            />
          </InputContainer>
          <InputContainer>
            <Label>{i18n.t('SHAREEMAILFORM:SUBJECT')}</Label>
            <Input
              type="text"
              onChange={(e) => handleInputChange(e, props.setSubject)}
              maxLength={50}
              value={props.subject}
            />
          </InputContainer>
          <InputContainer>
            <Label>{i18n.t('SHAREEMAILFORM:FROM')}</Label>
            <Input
              type="text"
              onChange={(e) => handleInputChange(e, props.setFrom)}
              maxLength={50}
              value={props.from}
            />
          </InputContainer>
        </FormContainer>
      </Wrapper>
    </WrapperLaunch>
  );
};
export default LaunchMailchimpForm;
