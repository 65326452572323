import React, { FC } from 'react';

import MessageModal from 'components/MessageModal/MessageModal';
import { MESSAGE } from 'constants/messages/messages.constants';
import StoreLayout from 'containers/StoreLayout/StoreLayout';
import i18n from 'i18next';

import './i18n';
import { Header, OptionsContainer, ShareTitle, SubTitle, Text, Wrapper } from './styles';
import { IProps } from './types';

const NewBMSLayout: FC<IProps> = (props) => {
  return (
    <Wrapper>
      <StoreLayout isTemplate="template">
        <Header>
          <ShareTitle>{i18n.t('NEW_LAYOUT:NEW_BMS')}</ShareTitle>
          <SubTitle>{i18n.t('NEW_LAYOUT:SELECT')}</SubTitle>
        </Header>
        <OptionsContainer>
          <Text
            isSelected={props.isExistingTemplate}
            onClick={() => props.setIsExistingTemplate(true)}
          >
            {i18n.t('NEW_LAYOUT:EXISTING_TEMPLATE')}
          </Text>
          <Text
            isSelected={!props.isExistingTemplate}
            onClick={() => props.setIsExistingTemplate(false)}
          >
            {i18n.t('NEW_LAYOUT:CREATE')}
          </Text>
        </OptionsContainer>
        {props.children}
      </StoreLayout>
      {props.isSuccessVisible && (
        <MessageModal
          title={MESSAGE.success.title}
          icon={MESSAGE.success.icon}
          closeIcon={MESSAGE.success.close}
          bgColor={MESSAGE.success.bgColor}
          onClose={props.toggleSuccess}
          message={i18n.t('NEW_LAYOUT:SUCCESS')}
        />
      )}
    </Wrapper>
  );
};
export default NewBMSLayout;
