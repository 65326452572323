import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'MESSAGE', {
  SUCCESS: 'Success!',
  ERROR: 'Error',
  WARNING: 'Warning!',
  INFO: 'Info!',
  FILE_SIZE_WARNING: 'The import file is too large to upload.',
  FILE_TYPE_ERROR: 'Only supports CSV files.',
  EMPTY_FILE_ERROR: 'Import failed. Your CSV file is empty.',
  PARSE_FILE_ERROR: 'Import failed. An error occurred during parsing.',
  COPIED_SUCCESS: 'Copied to clipboard.',
  UPDATE_SUCCESS: 'Your profile was successfully updated.',
  UPDATE_PASSWORD: 'Your password changed succesfully',
  UPDATE_SUCCESS_PICTURE: 'Your profile picture was successfully updated',
  DELETE_CAMPAIGN_SUCCESS: 'Your campaign was successfully deleted',
  DELETE_CAMPAIGN_FAILED: 'Delete error',
});
