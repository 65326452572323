import styled from 'styled-components';

export const ShareTitle = styled.p`
  width: 52%;
  text-align: left;
  color: ${(props) => props.theme.colors.fiord};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.extraLarge};
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  margin-bottom: 10px;
`;

export const SubTitle = styled.p`
  color: ${(props) => props.theme.colors.fiord};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.extraLarge};
  text-align: center;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  margin-bottom: 30px;
`;

export const Header = styled.div`
  height: 180px;
`;

export const OptionsContainer = styled.div`
  height: 25px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 10px;
  border-radius: 4px;
  background: ${(props) => props.theme.colors.geyser};
`;

export const Text = styled.p`
  color: ${(props) => props.theme.colors.fiord};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.extraLarge};
  text-align: center;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  margin-bottom: 0px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
`;

export const Container = styled.div`
  height: 100%;
  width: 60%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 25px;
`;

export const InputSelect = styled.select`
  width: 500px;
  height: 50px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  border: 1px solid ${(props) => props.theme.colors.porcelain};
  background-color: white;
  border-radius: 6px;
  padding-left: 10px;
  margin-right: 30px;
`;

export const Thumbnail = styled.div`
  width: 284px;
  height: 160px;
  display: flex;
  justify-content: left;
  background: url(https://bms-stage.storage.googleapis.com/assets/ac290c8d-d30b-4578-9598-a79ea25aa858.jpg);
  background-position: center;
  background-size: cover;
  transition: all 0.2s ease-out;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-top: 20px;
`;

export const Next = styled.div`
  width: 96px;
  height: 40px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.cello};
  cursor: pointer;
  margin-top: 15px;
  transition: ${(props) => props.theme.transition.all};
  &:hover {
    background: ${(props) => props.theme.colors.bigStone};
  }
`;

export const Campaign = styled.div`
  width: 160px;
  height: 90px;
  position: relative;
  border: 1px solid ${(props) => props.theme.colors.silverc};
  border-radius: 10px;
`;

export const CKEditorContainer = styled.div`
  width: 434px;
  position: relative;
  border: 1px solid ${(props) => props.theme.colors.silverc};
  border-radius: 10px;
`;

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  width: 22%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
