import { AnyAction } from 'redux';

import * as userConstants from '../constants/user.actions.constants';

interface ICorrectReducer {
  status: boolean;
}

const defaultState: ICorrectReducer = {
  status: false,
};

const changeStatusTrue = (state: ICorrectReducer) => {
  return {
    ...state,
    status: true,
  };
};

const changeStatusFalse = (state: ICorrectReducer) => {
  return {
    ...state,
    status: false,
  };
};

const errorReducer = (
  state: ICorrectReducer = defaultState,
  action: AnyAction
): ICorrectReducer => {
  switch (action.type) {
    case userConstants.USER_ON_SIGNUP_SUCCEEDED:
    case userConstants.ON_CHANGE_USER_FIRSTNAME_SUCCEDED:
    case userConstants.ON_CHANGE_USER_LASTNAME_SUCCEDED:
    case userConstants.ON_CHANGE_USER_PASSWORD_SUCCEDED:
      return changeStatusTrue(state);
    case userConstants.ON_CHANGE_ERROR_VALUE:
      return changeStatusFalse(state);
    default:
      return state;
  }
};

export default errorReducer;
