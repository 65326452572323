import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as metricsActions from 'redux/actions/metric.actions';
import * as myCampaignsActions from 'redux/actions/myCampaigns.actions';
import * as templateActions from 'redux/actions/template.actions';
import * as userActions from 'redux/actions/user.actions';
import getUserSelector from 'redux/selectors/user.selector';
import { ICampaign } from 'views/CampaignEdit/types';
import MyCampaigns from 'views/myCampaigns/MyCampaigns';

const MyCampaignsContainer: FC = () => {
  const dispatch = useDispatch();
  const getUserData = useSelector(getUserSelector());

  const onDeleteCampaign = (id: string) => {
    dispatch(myCampaignsActions.deleteCampaign(id));
  };

  useEffect(() => {
    if (getUserData.user.isLogged) {
      dispatch(templateActions.initializeCampaignsUser(1));
    }
  }, [getUserData.user.isLogged]);

  const onSelectCampaign = (id: string) => {
    dispatch(myCampaignsActions.selectCampaign(id));
  };

  const onRelaunchCampaign = (id: string) => {
    const userCampaign = getUserData.user.campaign.find(
      (campaign: ICampaign) => campaign._id === id
    );
    dispatch(
      myCampaignsActions.relaunchCampaign(id, userCampaign.shareType, userCampaign.shareData)
    );
  };

  const changeIsSuccess = () => {
    dispatch(userActions.changeIsSuccess());
  };

  const loadMore = (page: number) => {
    dispatch(templateActions.initializeCampaignsUser(page));
  };

  const onSelectCampaignMetrics = (id: string) => {
    dispatch(metricsActions.onRedirectToMetricsDashboard(id));
  };

  const onRemoveVideoData = () => {
    dispatch(templateActions.onRemoveVideoData());
  };

  return (
    <MyCampaigns
      selectCampaign={onSelectCampaign}
      campaigns={getUserData.user.campaign}
      deleteCampaign={onDeleteCampaign}
      relaunchCampaign={onRelaunchCampaign}
      changeIsSuccess={changeIsSuccess}
      loadMoreCampaigns={loadMore}
      allCampaignsFetched={getUserData.user.allCampaignsFetched}
      selectMetricsCampaign={onSelectCampaignMetrics}
      isSuccessDeleteCampaign={getUserData.user.successDelete}
      isLoadingDeleteCampaign={getUserData.user.isLoadingDeleteCampaign}
      deleteCampaignError={getUserData.user.errorDeleteCampaign}
      onRemoveVideoData={onRemoveVideoData}
      isLoadingCampaigns={getUserData.user.loading}
    />
  );
};

export default MyCampaignsContainer;
