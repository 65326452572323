import { CampaignError } from 'enums/campaignError.enum';
import { AnyAction } from 'redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as paywallActions from 'redux/actions/paywall.actions';
import { ON_DELETE_CAMPAIGN_SUCCEDED } from 'redux/constants/myCampaigns.action.constants';
import * as templateServices from 'service/template.service';
import * as userServices from 'service/user.service';

import * as templateActions from '../actions/template.actions';
import * as constants from '../constants/template.action.contants';

function* getTemplates() {
  try {
    const response = yield call(templateServices.getTemplates);
    yield put(templateActions.initializeTemplatesSucceeded(response));
  } catch (err) {
    yield put(templateActions.initializeTemplatesFailed());
  }
}

function* getCampaignsUser(action: AnyAction) {
  try {
    const response = yield call(userServices.getCampaigns, action.page);
    yield put(templateActions.initializeCampaignsUserSucceeded(response));
    if (
      !!response.campaignsToAlert &&
      (!!response.campaignsToAlert.alertAboutToExpire.length ||
        !!response.campaignsToAlert.alertExpired.length)
    ) {
      yield put(
        paywallActions.alertCampaignsExpired(
          CampaignError.ALERT_CAMPAIGN_LIFE_TIME_LIMIT,
          response.campaignsToAlert.alertAboutToExpire,
          response.campaignsToAlert.alertExpired
        )
      );
    }
  } catch (err) {
    yield put(templateActions.initializeTemplatesFailed());
  }
}

function* getTemplate(action: AnyAction) {
  try {
    if (action.isTemplate) {
      const response = yield call(templateServices.getTemplate, action.id);
      yield put(templateActions.onSelectPreviewSucceeded(response));
    }
  } catch (err) {
    yield put(templateActions.onSelectPreviewFailed());
  }
}

function* getCategories() {
  try {
    const response = yield call(templateServices.getCategories);
    yield put(templateActions.initializeCategoriesSucceeded(response));
  } catch (err) {
    yield put(templateActions.initializeCategoriesFailed());
  }
}

function* getTemplateNewBMS(action: AnyAction) {
  try {
    const response = yield call(templateServices.getTemplateNewBMS, action.templateId);
    yield put(templateActions.getTemplateSucceed(response));
  } catch (err) {
    yield put(templateActions.getTemplateFailed());
  }
}

function* sendEmailNewBMS(action: AnyAction) {
  try {
    yield call(templateServices.sendEmail, action.data, action.templateName);
    yield put(templateActions.onSendEmailSucceed());
  } catch (err) {
    yield put(templateActions.onSendEmailFailed());
  }
}

function* sendPersonalizedCampaignEmail(action: AnyAction) {
  try {
    yield call(
      templateServices.sendPersonalizedCampaignEmail,
      action.audience,
      action.emailProvider,
      action.containVideo,
      action.objective
    );
    yield put(templateActions.onSendEmailPersonalizedCampaignSucceed());
  } catch (err) {
    yield put(templateActions.onSendEmailPersonalizedCampaignFailed());
  }
}

export default all([
  takeLatest(constants.ON_INITIALIZE_TEMPLATES_REQUESTED, getTemplates),
  takeLatest(constants.ON_INITIALIZE_CAMPAIGN_USER_REQUESTED, getCampaignsUser),
  takeLatest(ON_DELETE_CAMPAIGN_SUCCEDED, getCampaignsUser),
  takeLatest(constants.ON_INITIALIZE_TEMPLATES_REQUESTED, getCategories),
  takeLatest(constants.ON_SELECT_PREVIEW_REQUESTED, getTemplate),
  takeLatest(constants.ON_SELECT_TEMPLATE_NEW_BMS_REQUESTED, getTemplateNewBMS),
  takeLatest(constants.ON_SEND_EMAIL_REQUESTED, sendEmailNewBMS),
  takeLatest(
    constants.ON_SEND_PERSONALIZED_CAMPAIGN_EMAIL_REQUESTED,
    sendPersonalizedCampaignEmail
  ),
]);
