import { useEffect, useState } from 'react';

import { Howl } from 'howler';

import { IAudioReturn } from './types';

export const fadeOut = (audio: Howl, duration: number): void => {
  const value = audio.volume() / 10;
  const fadeInterval = setInterval(() => {
    if (audio.volume() > 0) {
      const newVolume = audio.volume() - value;
      audio.volume(newVolume.toFixed(2));
    } else {
      audio.stop();
      clearInterval(fadeInterval);
    }
  }, duration);
};

export const useAudio = (url: string): IAudioReturn[] => {
  const [audio] = useState<Howl>(
    url
      ? new Howl({
          src: [url],
          format: ['mp3'],
          loop: true,
        })
      : {}
  );

  const [mute, setMute] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const toggleMute = () => setMute(!mute);

  const changeIsPlaying = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    if (url) {
      if (!isPlaying && !mute) {
        setIsPlaying(true);
      }
      audio.volume(mute ? 0 : 1);
    }
  }, [mute]);

  useEffect(() => {
    return () => {
      if (url) {
        audio.pause();
      }
    };
  }, []);

  return [mute, toggleMute, audio, isPlaying, changeIsPlaying];
};
