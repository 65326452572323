import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'LAUNCHSHARE', {
  CONTACTLIST: 'Remember your plan allows you to send this campaign to 10 users.',
  FILTER: 'Filter by A/Z',
  SEARCH: 'Search ...',
  NAME: 'Name',
  EMAIL: 'Email',
  SELECT_ALL: 'Select all',
  COPY_LINK: 'Copy link',
  EMPTY: 'Empty field',
  EMAIL_ERROR: 'Hmm… that doesn’t look like an email address',
});
