import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
  z-index: 150;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
`;

export const ModalContainer = styled.div`
  width: 525px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const InputContainer = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 50px;
`;

export const Input = styled.input.attrs({
  type: 'text',
  spellCheck: 'false',
})`
  width: 100%;
  font-size: 30px;
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.headers.input.fontFamily};
  border: 0;
  outline: 0;
  background: transparent;
  border: 2px solid ${(props) => props.theme.colors.white};
  border-radius: 8px;
`;

export const CancelButton = styled.button`
  text-align: center;
  background: transparent;
  border: 1px solid ${(props) => props.theme.colors.white};
  border-radius: 2px;
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  cursor: pointer;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  width: 140px;
  height: 40px;
  transition: all 0.2s ease-out;
  margin-right: 10px;
  &:hover {
    font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  }
`;

export const DoneButton = styled.button`
  text-align: center;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.cello};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  cursor: pointer;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  width: 140px;
  height: 40px;
  border-radius: 2px;
  transition: all 0.2s ease-out;
  &:hover {
    color: ${(props) => props.theme.colors.bigStone};
    font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const Remaining = styled.p`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  color: ${(props) => props.theme.colors.cloudy};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  padding-top: 5px;
`;

export const InputTitle = styled.p`
  color: white;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  font-weight: 400;
  margin-bottom: 5px;
`;
