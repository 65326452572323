import styled from 'styled-components';

import { ISize } from './types';

export const LoadingContainer = styled.div<ISize>`
  width: ${(props) => (props.isFullscreen ? '100vw' : '100%')};
  height: ${(props) => (props.isFullscreen ? '100vh' : '100%')};
  ${(props) => props.isFullscreen && 'position: absolute'};
  ${(props) => props.isFullscreen && 'top: 0'};
  ${(props) => props.isFullscreen && 'left: 0'};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.isFullscreen && 'background: rgba(0, 0, 0, 0.8)'};
  z-index: 150;
  backdrop-filter: blur(2px);
`;

export const AnimationContainer = styled.div<ISize>`
  width: ${(props) => (props.isFullscreen ? '25%' : '45%')};
  height: ${(props) => (props.isFullscreen ? '25%' : '45%')};
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AnimationLayer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
