import { CSVLink } from 'react-csv';

import styled from 'styled-components';

import { IBolder, IButtonProps } from './types';

export const WrapperImport = styled.div`
  height: calc(100% - 255px);
  min-height: calc(100vh - 255px);
  background: ${(props) => props.theme.colors.aena};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto 0;
  padding-top: 25px;
`;

export const ChooseFile = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  text-align: center;
  align-items: center;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.apricot};
  margin-top: 30px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  font-family: ${(props) => props.theme.paragraph.fontFamilies.regular};
  &:hover {
    font-family: ${(props) => props.theme.paragraph.fontFamilies.bold};
  }
`;

export const ButtonImport = styled.button<IButtonProps>`
  width: 144px;
  height: 40px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  font-family: ${(props) => props.theme.paragraph.fontFamilies.medium};
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  align-items: center;
  background: ${(props) => props.theme.colors.cello};
  border: none;
  border-radius: 2px;
  margin-top: 60px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    background: ${(props) => props.theme.colors.bigStone};
  }
`;

export const ButtonDownload = styled(ButtonImport)`
  background: ${(props) => props.theme.colors.cello};
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    background: ${(props) => props.theme.colors.bigStone};
  }
`;

export const CSV = styled(CSVLink)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.black};
`;

export const ImportText = styled.div`
  width: 80%;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.extraLarge};
  text-align: center;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => props.theme.colors.fiord};
  margin-bottom: 20px;
`;

export const Bolder = styled.b<IBolder>`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.extraLarge};
  text-align: left;
  cursor: ${(props) => (props.colorApricot ? 'pointer' : 'default')};
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => (props.colorApricot ? props.theme.colors.apricot : props.theme.colors.cello)};
  font-family: ${(props) =>
    props.colorApricot
      ? props.theme.headers.h2.fontFamilies.regular
      : props.theme.headers.h2.fontFamilies.bold};
  &:hover {
    text-decoration: ${(props) => props.colorApricot && 'underline'};
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ConfirmButton = styled.button`
  width: 124px;
  height: 40px;
  background: ${(props) => props.theme.colors.cello};
  border-radius: 3px;
  &:hover {
    background: ${(props) => props.theme.colors.bigStone};
  }
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  font-family: ${(props) => props.theme.paragraph.fontFamilies.medium};
  color: ${(props) => props.theme.colors.white};
  border: none;
  cursor: pointer;
  margin-top: 30px;
`;
