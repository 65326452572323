export const USER_ON_LOGIN_REQUESTED = 'USER_ON_LOGIN_REQUESTED';

export const USER_ON_LOGIN_SUCCEEDED = 'USER_ON_LOGIN_SUCCEEDED';

export const USER_ON_LOGIN_FAILED = 'USER_ON_LOGIN_FAILED';

export const USER_ON_SIGNUP_REQUESTED = 'USER_ON_SIGNUP_REQUESTED';

export const USER_ON_SIGNUP_SUCCEEDED = 'USER_ON_SIGNUP_SUCCEEDED';

export const USER_ON_SIGNUP_FAILED = 'USER_ON_SIGNUP_FAILED';

export const ON_CHANGE_USER_AVATAR_REQUESTED = 'ON_CHANGE_USER_AVATAR_REQUESTED';

export const ON_CHANGE_USER_AVATAR_SUCCEDED = 'ON_CHANGE_USER_AVATAR_SUCCEDED';

export const ON_CHANGE_USER_AVATAR_FAILED = 'ON_CHANGE_USER_AVATAR_FAILED';

export const ON_INITIALIZE_USER_REQUESTED = 'ON_INITIALIZE_USER_REQUESTED';

export const ON_INITIALIZE_USER_SUCCEDED = 'ON_INITIALIZE_USER_SUCCEDED';

export const ON_INITIALIZE_USER_FAILED = 'ON_INITIALIZE_USER_FAILED';

export const ON_CHANGE_USER_PASSWORD_REQUESTED = 'ON_CHANGE_USER_PASSWORD_REQUESTED';

export const ON_CHANGE_USER_PASSWORD_SUCCEDED = 'ON_CHANGE_USER_PASSWORD_SUCCEDED';

export const ON_CHANGE_USER_PASSWORD_FAILED = 'ON_CHANGE_USER_PASSWORD_FAILED';

export const ON_USER_LOGOUT = 'ON_USER_LOGOUT';

export const ON_CHANGE_USER_FIRSTNAME_REQUESTED = 'ON_CHANGE_USER_FIRSTNAME_REQUESTED';

export const ON_CHANGE_USER_FIRSTNAME_SUCCEDED = 'ON_CHANGE_USER_FIRSTNAME_SUCCEDED';

export const ON_CHANGE_USER_FIRSTNAME_FAILED = 'ON_CHANGE_USER_FIRSTNAME_FAILED';

export const ON_CHANGE_USER_LASTNAME_REQUESTED = 'ON_CHANGE_USER_LASTNAME_REQUESTED';

export const ON_CHANGE_USER_LASTNAME_SUCCEDED = 'ON_CHANGE_USER_LASTNAME_SUCCEDED';

export const ON_CHANGE_USER_LASTNAME_FAILED = 'ON_CHANGE_USER_LASTNAME_FAILED';

export const ON_USER_LOGIN_AND_CREATE_CAMPAIGN_REQUESTED =
  'ON_USER_LOGIN_AND_CREATE_CAMPAIGN_REQUESTED';

export const ON_USER_LOGIN_AND_CREATE_CAMPAIGN_SUCCEEDED =
  'ON_USER_LOGIN_AND_CREATE_CAMPAIGN_SUCCEEDED';

export const ON_USER_LOGIN_AND_CREATE_CAMPAIGN_FAILED = 'ON_USER_LOGIN_AND_CREATE_CAMPAIGN_FAILED';

export const ON_CHANGE_ERROR_VALUE = 'ON_CHANGE_ERROR_VALUE';

export const ON_CHANGE_ERROR_VALUE_TRUE = 'ON_CHANGE_ERROR_VALUE_TRUE';

export const ON_FORGOT_PASSWORD_REQUESTED = 'ON_FORGOT_PASSWORD_REQUESTED';

export const ON_FORGOT_PASSWORD_SUCCEDED = 'ON_FORGOT_PASSWORD_SUCCEDED';

export const ON_FORGOT_PASSWORD_FAILED = 'ON_FORGOT_PASSWORD_FAILED';

export const ON_RESET_PASSWORD_REQUESTED = 'ON_RESET_PASSWORD_REQUESTED';

export const ON_RESET_PASSWORD_SUCCEDED = 'ON_RESET_PASSWORD_SUCCEDED';

export const ON_RESET_PASSWORD_FAILED = 'ON_RESET_PASSWORD_FAILED';

export const ON_INITIALIZE_CONFIRM_ACCOUNT_REQUESTED = 'ON_INITIALIZE_CONFIRM_ACCOUNT_REQUESTED';

export const ON_INITIALIZE_CONFIRM_ACCOUNT_SUCCEEDED = 'ON_INITIALIZE_CONFIRM_ACCOUNT_SUCCEEDED';

export const ON_INITIALIZE_CONFIRM_ACCOUNT_FAILED = 'ON_INITIALIZE_CONFIRM_ACCOUNT_FAILED';

export const ON_CHANGE_STATUS_SIGNUP_FALSE = 'ON_CHANGE_STATUS_SIGNUP_FALSE';

export const ON_CHANGE_STATUS_PASSWORD = 'ON_CHANGE_STATUS_PASSWORD';

export const ON_CHANGE_STATUS_PROFILE = 'ON_CHANGE_STATUS_PROFILE';

export const ON_INITIALIZE_FORGOT_PASSWORD_REQUESTED = 'ON_INITIALIZE_FORGOT_PASSWORD_REQUESTED';

export const ON_INITIALIZE_FORGOT_PASSWORD_SUCCEEDED = 'ON_INITIALIZE_FORGOT_PASSWORD_SUCCEEDED';

export const ON_INITIALIZE_FORGOT_PASSWORD_FAILED = 'ON_INITIALIZE_FORGOT_PASSWORD_FAILED';

export const ON_CHANGE_IS_SUCCESS = 'ON_CHANGE_IS_SUCCESS';

export const ON_REQUEST_UPGRADE = 'ON_REQUEST_UPGRADE';

export const ON_REQUEST_UPGRADE_SUCCEDED = 'ON_REQUEST_UPGRADE_SUCCEDED';

export const ON_REQUEST_UPGRADE_FAILED = 'ON_REQUEST_UPGRADE_FAILED';

export const ON_CLOSE_LOGIN_MODAL = 'ON_CLOSE_LOGIN_MODAL';
