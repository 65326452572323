import { Layer } from 'interfaces/layer.interface';
import { AnyAction } from 'redux';
import { ICategory, ITemplate } from 'views/TemplateList/types';
import { IColorVariable, IImageVariable, IScene, ITextVariable } from 'views/Video/types';

import * as constants from '../constants/template.action.contants';

export interface ITemplateReducerState {
  templates: ITemplate[];
  categories: ICategory[];
  categorySelected: string;
  layers: Layer[];
  textVariables: ITextVariable[];
  colorVariables: IColorVariable[];
  imageVariables: IImageVariable[];
  audio: string;
  template: ITemplate;
  isSuccess: boolean;
  video: string;
  scenes: IScene[];
}

export const defaultState: ITemplateReducerState = {
  templates: [],
  categories: [],
  isSuccess: false,
  categorySelected: 'All',
  layers: [],
  textVariables: [],
  colorVariables: [],
  imageVariables: [],
  audio: '',
  template: {
    _id: '',
    title: '',
    description: '',
    thumbnail: {
      _id: '',
      originalname: '',
      mimetype: '',
      filename: '',
      size: '',
    },
    scenes: [],
    layers: [],
    textVariables: [],
    colorVariables: [],
    imageVariables: [],
    productId: 0,
    emailTitle: '',
    emailBody: '',
    audio: '',
    duration: 0,
    video: {
      asset: '',
    },
  },
  video: '',
  scenes: [],
};

const addTemplates = (state: ITemplateReducerState, action: AnyAction) => {
  return {
    ...state,
    templates: action.data,
  };
};
const addCategories = (state: ITemplateReducerState, action: AnyAction) => {
  return {
    ...state,
    categories: action.data,
  };
};
const changeCategory = (state: ITemplateReducerState, action: AnyAction) => {
  return {
    ...state,
    categorySelected: action.data,
  };
};
const addPreviewData = (state: ITemplateReducerState, action: AnyAction) => {
  return {
    ...state,
    layers: action.data.layers,
    textVariables: action.data.textVariables,
    colorVariables: action.data.colorVariables,
    imageVariables: action.data.imageVariables,
    audio: action.data.audio !== null ? action.data.audio : '',
    video: action.data.video !== null ? action.data.video.path : '',
    scenes: action.data.scenes,
    duration: action.data.duration,
  };
};

const changeTemplate = (state: ITemplateReducerState, action: AnyAction) => {
  return {
    ...state,
    template: action.data,
  };
};

const changeSuccess = (state: ITemplateReducerState) => {
  return {
    ...state,
    isSuccess: false,
  };
};

const successEmail = (state: ITemplateReducerState) => {
  return {
    ...state,
    isSuccess: true,
  };
};

const removeVideoData = (state: ITemplateReducerState) => {
  return {
    ...state,
    layers: defaultState.layers,
    textVariables: defaultState.textVariables,
    colorVariables: defaultState.colorVariables,
    imageVariables: defaultState.imageVariables,
    audio: defaultState.audio,
    video: defaultState.video,
    scenes: defaultState.scenes,
  };
};

const templateReducer = (
  state: ITemplateReducerState = defaultState,
  action: AnyAction
): ITemplateReducerState => {
  switch (action.type) {
    case constants.ON_INITIALIZE_TEMPLATES_SUCCEEDED:
      return addTemplates(state, action);
    case constants.ON_INITIALIZE_CATEGORIES_SUCCEEDED:
      return addCategories(state, action);
    case constants.ON_SELECT_CATEGORY_REQUESTED:
      return changeCategory(state, action);
    case constants.ON_SELECT_PREVIEW_SUCCEEDED:
      return addPreviewData(state, action);
    case constants.ON_SELECT_TEMPLATE_NEW_BMS_SUCCEED:
      return changeTemplate(state, action);
    case constants.ON_CHANGE_IS_SUCCESS:
      return changeSuccess(state);
    case constants.ON_SEND_EMAIL_SUCCEED:
    case constants.ON_SEND_PERSONALIZED_CAMPAIGN_EMAIL_SUCCEED:
      return successEmail(state);
    case constants.ON_REMOVE_VIDEO_DATA:
      return removeVideoData(state);
    default:
      return state;
  }
};

export default templateReducer;
