import React, { FC, useState } from 'react';

import Wrapper from 'components/common/Wrapper/Wrapper';
import i18n from 'i18next';

import './i18n';
import { Container, Input, InputContainer, InputSelect, Send, ShareTitle } from './styles';
import { IProps } from './types';

const NewBMSPersonalizedCampaign: FC<IProps> = (props) => {
  const [audience, setAudience] = useState<number>(0);
  const [emailProvider, setEmailProvider] = useState<string>('');
  const [containVideo, setContainVideo] = useState<string>('');
  const [objective, setObjective] = useState<string>('');

  const onChangeAudience = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setAudience(parseInt(event.target.value, 10));
  };

  const onChangeEmailProvider = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setEmailProvider(event.target.value);
  };

  const onChangeContainVideo = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setContainVideo(event.target.value);
  };

  const onChangeObjective = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObjective(event.target.value);
  };

  return (
    <Wrapper>
      <Container>
        <InputContainer>
          <ShareTitle>{i18n.t('PERSONALIZED_CAMPAIGN:AUDIENCE')}</ShareTitle>
          <InputSelect onChange={onChangeAudience} value={audience}>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </InputSelect>
        </InputContainer>
        <InputContainer>
          <ShareTitle>{i18n.t('PERSONALIZED_CAMPAIGN:PROVIDER')}</ShareTitle>
          <InputSelect onChange={onChangeEmailProvider} value={emailProvider}>
            <option value={'Yes'}>Yes</option>
            <option value={'No'}>No</option>
          </InputSelect>
        </InputContainer>
        <InputContainer>
          <ShareTitle>{i18n.t('PERSONALIZED_CAMPAIGN:VIDEO')}</ShareTitle>
          <InputSelect onChange={onChangeContainVideo} value={containVideo}>
            <option value={'Yes'}>Yes</option>
            <option value={'No'}>No</option>
          </InputSelect>
        </InputContainer>
        <InputContainer>
          <ShareTitle>{i18n.t('PERSONALIZED_CAMPAIGN:OBJECTIVE')}</ShareTitle>
          <Input
            type="textarea"
            value={objective}
            onChange={onChangeObjective}
            placeholder="Write a brief description of your campaign"
          />
        </InputContainer>
        <Send onClick={() => props.onSubmit(audience, emailProvider, containVideo, objective)}>
          Send
        </Send>
      </Container>
    </Wrapper>
  );
};
export default NewBMSPersonalizedCampaign;
