import React, { FC } from 'react';

import CloseModalButton from 'components/CloseModalButton/CloseModalButton';
import PreviewPlayer from 'components/PreviewPlayer/PreviewPlayer';
import i18n from 'i18next';

import './i18n';
import {
  CloseModalWrapper,
  ModalContainer,
  ModalWrapper,
  Title,
  TopModalWrapper,
  VideoWrapper,
} from './styles';
import { IProps } from './types';

const CampaignPreview: FC<IProps> = (props) => {
  const handleClose = () => {
    props.onCloseModal();
  };
  return (
    <ModalWrapper>
      <ModalContainer>
        <CloseModalWrapper>
          <CloseModalButton onClick={handleClose} />
        </CloseModalWrapper>
        <TopModalWrapper>
          <Title>{i18n.t('PREVIEW:VIDEO_TITLE')}</Title>
        </TopModalWrapper>
        <VideoWrapper>
          <PreviewPlayer
            textVariables={props.textVariables}
            layers={props.layers}
            colorVariables={props.colorVariables}
            imageVariables={props.imageVariables}
            backgroundColor={props.backgroundColor}
            audio={props.audio}
            key={props.id}
            backgroundVideo={props.backgroundVideo}
            scenes={props.scenes}
            duration={props.duration}
            isCampaignPreview
          />
        </VideoWrapper>
      </ModalContainer>
    </ModalWrapper>
  );
};
export default CampaignPreview;
