import React, { FC, useState } from 'react';

import Wrapper from 'components/common/Wrapper/Wrapper';
import i18n from 'i18next';
import { ITemplate } from 'views/TemplateList/types';

import './i18n';
import { Container, InputSelect, Next, ShareTitle, Thumbnail } from './styles';
import { IProps } from './types';

const NewBMSTemplate: FC<IProps> = (props) => {
  const [template, setTemplate] = useState<string>(props.templates[0]._id);
  const [templateThumbnail, setTemplateThumbnail] = useState<string | undefined>(
    props.templates[0].thumbnail.path
  );

  const onChangeTemplate = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTemplate(event.target.value);
    const newTemplate = props.templates.find((template) => template._id === event.target.value);
    if (newTemplate?.thumbnail.path) {
      setTemplateThumbnail(newTemplate.thumbnail.path);
    }
  };

  return (
    <Wrapper>
      <Container>
        <ShareTitle>{i18n.t('NEW_BMS_TEMPLATE:SEARCH')}</ShareTitle>
        <InputSelect onChange={onChangeTemplate} value={template}>
          {props.templates.map((template: ITemplate) => {
            return <option value={template._id}>{template.title}</option>;
          })}
        </InputSelect>
        <Thumbnail src={templateThumbnail}></Thumbnail>
        <Next onClick={() => props.onClickNextStep(template)}>
          {i18n.t('NEW_BMS_TEMPLATE:NEXT')}
        </Next>
      </Container>
    </Wrapper>
  );
};
export default NewBMSTemplate;
