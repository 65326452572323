import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { isUserLogged } from 'helpers/user_validation';

interface PublicRouteProps extends RouteProps {
  restricted?: boolean;
}

const PublicRoute: FC<PublicRouteProps> = ({ component: Component, restricted, ...rest }) => {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={(props) =>
        isUserLogged() && restricted ? (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        ) : (
          !!Component && <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
