import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const TopbarStyle = styled.div`
  width: auto;
  height: 75px;
  background: ${(props) => props.theme.colors.white};
  position: sticky;
  top: 0;
  z-index: 100;
  border-bottom: 1px solid ${(props) => props.theme.colors.porcelain};
`;

export const TopbarContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const IconContainer = styled(Link)`
  width: 156px;
  height: 40px;
  display: flex;
  margin-left: 30px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const TextContainer = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 45%;
  align-items: center;
  height: 100%;
`;

export const Text = styled.li`
  width: 120px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  margin-right: 10px;
`;

export const Links = styled(Link)`
  text-align: center;
  margin: 0 auto;
  padding: 0;
  color: ${(props) => props.theme.colors.fiord};
  transition: all 0.2s ease;
  position: relative;
  text-decoration: none; 
  cursor: pointer;
  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: -1px;
    width: 0px;
    height: 2px;
    transition: all 0.2s ease;
    transition-duration: 0.3s;
    background-color: ${(props) => props.theme.colors.fiord};
    left: 0;
  }
  &:hover {
      &:before,
      &:after {
        width: 100%;
        opacity: 1;
  }
`;

export const LinkToExternal = styled.a`
  text-align: center;
  margin: 0 auto;
  padding: 0;
  color: ${(props) => props.theme.colors.fiord};
  transition: all 0.2s ease;
  position: relative;
  text-decoration: none; 
  cursor: pointer;
  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: -1px;
    width: 0px;
    height: 2px;
    transition: all 0.2s ease;
    transition-duration: 0.3s;
    background-color: ${(props) => props.theme.colors.fiord};
    left: 0;
  }
  &:hover {
      &:before,
      &:after {
        width: 100%;
        opacity: 1;
  }
`;

export const LinksSelected = styled(Link)`
  text-align: center;
  margin: 0 auto;
  padding: 0;
  position: relative;
  text-decoration: none;
  color: ${(props) => props.theme.colors.fiord};
  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    width: 0px;
    height: 2px;
    background-color: ${(props) => props.theme.colors.fiord};
    right: 0;
    width: 100%;
    opacity: 1;
  }
`;

export const LogIn = styled(Text)`
  color: ${(props) => props.theme.colors.fiord};
  border-left: 1px solid ${(props) => props.theme.colors.porcelain};
  transition: all 0.2s ease-out;
  margin: 0 0 0 25px;
  &:hover {
    background-color: ${(props) => props.theme.colors.porcelain};
    color: #142b41;
    cursor: pointer;
  }
`;

export const SignUp = styled(Text)`
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.cello};
  transition: all 0.2s ease-out;
  margin: 0;
  &:hover {
    background: ${(props) => props.theme.colors.apricot};
    cursor: pointer;
  }
`;

export const ProfilePicture = styled.img`
  width: 55px;
  height: 55px;
  border-radius: 50%;
  cursor: pointer;
  transition: ${(props) => props.theme.transition.all};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.fiord};
  }
`;

export const ProfilePictureWrapper = styled.div`
  width: 95px;
  height: 75px;
  border: 1px solid ${(props) => props.theme.colors.porcelain};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
`;

export const TextContainerLogged = styled(TextContainer)`
  width: 35%;
`;

export const ProfilePictureContainer = styled(Link)``;

export const OptionsListContainer = styled.div`
  width: 280px;
  height: 220px;
  background-color: ${(props) => props.theme.colors.cello};
  position: absolute;
  top: 75px;
  right: 20px;
  border-radius: 6px;
`;

export const ProfileContainer = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid ${(props) => props.theme.colors.bigStone};
`;

export const RoundContainer = styled.div`
  width: 65px;
  border-radius: 50%;
`;

export const ProfImage = styled.img`
  width: 100%;
  border-radius: 50%;
`;

export const DataContainer = styled.div`
  width: calc(85% - 65px);
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const Name = styled.h2`
  width: 100%;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Email = styled.p`
  width: 100%;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const OptionsList = styled.ul`
  width: 100%;
  height: 50%;
`;

export const ListItems = styled.li`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const List = styled(Link)`
  width: 100%;
  height: 100%;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: ${(props) => props.theme.transition.all};
  &:hover {
    background-color: ${(props) => props.theme.colors.bigStone};
    font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  }
`;

export const NewBms = styled(Link)`
  width: 80%;
  height: 50%;
  border-radius: 3px;
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.cello};
  cursor: pointer;
  margin-top: 5px;
  transition: ${(props) => props.theme.transition.all};
  &:hover {
    background: ${(props) => props.theme.colors.bigStone};
  }
`;

export const ButtonWrapper = styled.div`
  width: 120px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
