import React, { FC, useState } from 'react';
import { useEffect } from 'react';

import { useResize } from 'helpers/resize.helper';
import Video from 'views/Video/Video';
import { useAudio } from 'views/Video/audio';
import { DISABLED_VOLUME_VALUE } from 'views/Video/constants';

import { aspectRatio } from './constants';
import { TemplateContainer, VideoContainer, Wrapper } from './styles';
import { IProps } from './types';

const UserPlayer: FC<IProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [width, handleWindowSizeChange, height]: any[] = useResize();
  const [toggle, setToggle] = useState<boolean>(false);
  const [replayVisible, setReplayVisible] = useState<boolean>(false);
  const [replay, setReplay] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [frameToChange, setFrameToChange] = useState<number>(0);
  const [volumeToChange, setVolumeToChange] = useState<number>(DISABLED_VOLUME_VALUE);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [mute, toggleMute, audio, isPlaying, changeIsPlaying]: any[] = useAudio(props.audioFile);
  const [currentTime, setCurrentTime] = useState(0);

  const controlsHeight = 40;

  const onComplete = () => {
    setIsCompleted(true);
    setReplayVisible(true);
    setReplay(false);
    setToggle(false);
    changeIsPlaying();
  };

  const onChangeToggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    if (props.handleSelectAudioIcon && toggle) {
      props.handleSelectAudioIcon();
    }
  }, [toggle]);

  const onReplay = () => {
    setReplay(true);
    setReplayVisible(false);
    setIsCompleted(false);
  };

  window.addEventListener('resize', handleWindowSizeChange);

  const heightSum = height - controlsHeight;
  let newHeight = width / aspectRatio;
  let newWidth = heightSum * aspectRatio;

  if (newWidth > width) {
    newWidth = width;
  } else {
    newHeight = heightSum;
  }

  useEffect(() => {
    if (props.audioFile) {
      if (toggle) {
        setVolumeToChange(1);
      } else {
        setVolumeToChange(0);
      }
    }
    if (!props.audioFile || (!!props.audioFile && replayVisible)) {
      setVolumeToChange(DISABLED_VOLUME_VALUE);
    }
  }, [replayVisible, toggle]);

  useEffect(() => {
    if (!mute || toggle) {
      toggleMute();
    }
  }, [toggle]);

  useEffect(() => {
    if (volumeToChange !== DISABLED_VOLUME_VALUE) {
      setToggle(volumeToChange > 0);
    }
  }, [volumeToChange]);

  useEffect(() => {
    if (frameToChange > 0 && isCompleted) {
      setIsCompleted(false);
      setReplayVisible(false);
    }
  }, [frameToChange, isCompleted]);

  const onStart = () => {
    setCurrentTime(0);
    props.onStart();
  };

  return (
    <Wrapper>
      <TemplateContainer>
        <VideoContainer width={newWidth} height={newHeight}>
          {!!audio && (
            <Video
              isPlaying={true}
              textVariables={props.textVariables}
              layers={props.layers}
              colorVariables={props.colorVariables}
              imageVariables={props.imageVariables}
              backgroundColor={props.backgroundColor}
              isEdit={false}
              autoplay
              audio={audio}
              onComplete={onComplete}
              complete={isCompleted}
              audioIsPlaying={isPlaying}
              replay={replay}
              toggle={toggle}
              onSelectAudioIcon={props.handleSelectAudioIcon}
              onStart={onStart}
              backgroundVideo={props.backgroundVideo}
              autoplayBackgroundVideo
              onUpdate={props.onUpdate}
              isSharedPlayer
              onCTAClicked={props.onCTAClicked}
              setFrameToChange={setFrameToChange}
              hasAudio={!!props.audioFile}
              frameToChange={frameToChange}
              volume={volumeToChange}
              removeVideoData={props.onRemoveVideoData}
              onChangeToggle={onChangeToggle}
              setVolume={setVolumeToChange}
              currentTime={currentTime}
              setCurrentTime={setCurrentTime}
              onReplay={onReplay}
              isCampaignPreview
              isPreview
            />
          )}
        </VideoContainer>
      </TemplateContainer>
    </Wrapper>
  );
};

export default UserPlayer;
