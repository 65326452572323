import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'SHARECSV', {
  IMPORTCSV: 'Import a .csv file with the variables needed to customize your BMS campaign. ',
  IMPORTCSVSECOND:
    'If you don’t remember the variables the template selected has, you can download the ready-to-use .csv, fill it out, and upload it to use in your campaign. Download .csv ',
  FILE: 'file here',
  CHOOSE: 'Choose file',
  SELECTED: 'You have selected the csv file please confirm it (is correct) to continue.',
  SELECTEDFILE: 'Selected file: ',
  REPLACE: 'Replace file',
  IMPORT: 'Import',
  DOWNLOAD: 'Download CSV',
  CONFIRM: 'Confirm',
  PIPE: ' | ',
});
