import { CampaignError } from 'enums/campaignError.enum';
import { IAPIError } from 'interfaces/api.interface';
import { AnyAction } from 'redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as paywallActions from 'redux/actions/paywall.actions';
import * as campaignServices from 'service/campaign.service';
import * as userServices from 'service/user.service';

import * as userActions from '../actions/user.actions';
import * as constants from '../constants/user.actions.constants';

function* login(action: AnyAction) {
  try {
    const response = yield call(userServices.login, action.email, action.password);
    yield put(userActions.submitLoginSucceeded(response));
  } catch (err) {
    yield put(userActions.submitLoginFailed());
  }
}

function* signUp(action: AnyAction) {
  try {
    const response = yield call(
      userServices.signUp,
      action.firstName,
      action.lastName,
      action.email,
      action.password
    );
    yield put(userActions.submitSignUpSucceeded(response));
  } catch (err) {
    yield put(userActions.submitSignUpFailed(err as string));
  }
}

function* changeAvatar(action: AnyAction) {
  try {
    const assetResponse = yield call(campaignServices.uploadAsset, action.file);
    const response = yield call(userServices.changeAvatar, assetResponse._id);
    yield put(userActions.changeAvatarSucceded(response));
  } catch (err) {
    yield put(userActions.changeAvatarFailed());
  }
}

function* getUser() {
  try {
    const response = yield call(userServices.getUser);
    yield put(userActions.initializeUserSucceded(response));
  } catch (err) {
    yield put(userActions.initializeUserFailed());
  }
}

function* changePassword(action: AnyAction) {
  try {
    const response = yield call(
      userServices.changePassword,
      action.oldPassword,
      action.newPassword
    );
    yield put(userActions.changePasswordSucceded(response));
  } catch (err) {
    yield put(userActions.changePasswordFailed());
  }
}

function* changeFirstName(action: AnyAction) {
  try {
    const response = yield call(userServices.changeFirstName, action.firstName);
    yield put(userActions.changeFirstNameSucceded(response));
  } catch (err) {
    yield put(userActions.changeFirstNameFailed());
  }
}

function* changeLastName(action: AnyAction) {
  try {
    const response = yield call(userServices.changeLastName, action.lastName);
    yield put(userActions.changeLastNameSucceded(response));
  } catch (err) {
    yield put(userActions.changeLastNameFailed());
  }
}

function* loginAndCreateCampaign(action: AnyAction) {
  try {
    const loginResponse = yield call(userServices.login, action.email, action.password);
    if (loginResponse.token) {
      yield put(userActions.submitLoginSucceeded(loginResponse));
      const createCampaignResponse = yield call(campaignServices.createCampaign, action.templateId);
      yield put(userActions.submitLoginAndCreateCampaignSucceeded(createCampaignResponse._id));
    } else {
      yield put(userActions.submitLoginFailed());
    }
  } catch (err) {
    const errorMessage = (err as IAPIError).errorMessage;
    if (errorMessage !== CampaignError.CREATE_CAMPAIGN_LIMIT_EXCEEDED) {
      yield put(userActions.submitLoginAndCreateCampaignFailed());
    } else {
      yield put(paywallActions.campaignLimitExceeded(errorMessage));
    }
  }
}

function* forgotPassword(action: AnyAction) {
  try {
    yield call(userServices.forgotPassword, action.email);
    yield put(userActions.forgotPasswordSucceded());
  } catch (err) {
    yield put(userActions.forgotPasswordFailed());
  }
}

function* resetPassword(action: AnyAction) {
  try {
    yield call(userServices.resetPassword, action.token, action.password);
    yield put(userActions.resetPasswordSucceded());
  } catch (err) {
    yield put(userActions.resetPasswordFailed());
  }
}

function* confirmAccount(action: AnyAction) {
  try {
    yield call(userServices.confirmAccount, action.token);
    yield put(userActions.initializeConfirmAccountSucceeded());
  } catch (err) {
    yield put(userActions.initializeConfirmAccountFailed());
  }
}

function* initializeForgotPassword(action: AnyAction) {
  try {
    yield call(userServices.initializeForgotPassword, action.token);
    yield put(userActions.initializeForgotPasswordSucceeded());
  } catch (err) {
    yield put(userActions.initializeForgotPasswordFailed());
  }
}

function* onRequestUpgrade() {
  try {
    yield call(userServices.setPendingUpgrade);
    yield put(userActions.onRequestUpgradeSucceded());
  } catch (err) {
    yield put(userActions.onRequestUpgradeFailed());
  }
}

export default all([
  takeLatest(constants.USER_ON_LOGIN_REQUESTED, login),
  takeLatest(constants.USER_ON_SIGNUP_REQUESTED, signUp),
  takeLatest(constants.ON_CHANGE_USER_AVATAR_REQUESTED, changeAvatar),
  takeLatest(constants.ON_INITIALIZE_USER_REQUESTED, getUser),
  takeLatest(constants.ON_CHANGE_USER_PASSWORD_REQUESTED, changePassword),
  takeLatest(constants.ON_CHANGE_USER_FIRSTNAME_REQUESTED, changeFirstName),
  takeLatest(constants.ON_CHANGE_USER_LASTNAME_REQUESTED, changeLastName),
  takeLatest(constants.ON_FORGOT_PASSWORD_REQUESTED, forgotPassword),
  takeLatest(constants.ON_RESET_PASSWORD_REQUESTED, resetPassword),
  takeLatest(constants.ON_INITIALIZE_FORGOT_PASSWORD_REQUESTED, initializeForgotPassword),
  takeLatest(constants.ON_INITIALIZE_CONFIRM_ACCOUNT_REQUESTED, confirmAccount),
  takeLatest(constants.ON_USER_LOGIN_AND_CREATE_CAMPAIGN_REQUESTED, loginAndCreateCampaign),
  takeLatest(constants.ON_REQUEST_UPGRADE, onRequestUpgrade),
]);
