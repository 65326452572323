import { connectRouter } from 'connected-react-router';

import history from 'helpers/history';
import { combineReducers } from 'redux';

import campaignReducer from './campaign.reducer';
import correctReducer from './correctMessage.reducer';
import errorReducer from './error.reducer';
import metricReducer from './metric.reducer';
import paywallReducer from './paywall.reducer';
import shareReducer from './share.reducer';
import sharedUserReducer from './sharedUser.reducer';
import templateReducer from './template.reducer';
import userReducer from './user.reducer';

const rootReducer = combineReducers({
  router: connectRouter(history),
  user: userReducer,
  template: templateReducer,
  campaign: campaignReducer,
  share: shareReducer,
  sharedUser: sharedUserReducer,
  error: errorReducer,
  correctMessage: correctReducer,
  metrics: metricReducer,
  paywall: paywallReducer,
});

export default rootReducer;
