import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'PROGRESS_BAR', {
  ONE: '1',
  TWO: '2',
  THREE: '3',
  FOUR: '4',
  EDIT: 'Edit template',
  IMPORT: 'Import',
  SELECT: 'Select',
  VERIFY: 'Verify',
  MATCH: 'Match',
  LAUNCH: 'Launch',
});
