import React, { FC, useEffect, useState } from 'react';

import { PUBLIC_URL } from 'configs/index';
import 'helpers/inputHelper/i18n';
import {
  isEmail,
  isEmpty,
  isPasswordMatch,
  isValidPassword,
} from 'helpers/inputHelper/inputHelper';
import { IObject } from 'helpers/types';
import i18n from 'i18next';

import {
  BackToLogin,
  ButtonContainer,
  ErrorMessage,
  IconBlock,
  InputContainer,
  Inputs,
  LogInText,
  NameInput,
  NamesContainer,
  SignUpButton,
  TextPasswordRequirements,
} from './styles';
import { IProps } from './types';

const SignUp: FC<IProps> = (props) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [validPassword, setValidPassword] = useState<boolean>(false);
  const [errors, setErrors] = useState<IObject>({
    firstName: '',
    lastName: '',
    email: '',
    confirmPassword: '',
  });
  const [block, setBlock] = useState<{
    password: boolean;
    confirmPassword: boolean;
  }>({ password: false, confirmPassword: false });

  const handleEye = (key: string) => {
    const blockEye = { ...block };
    blockEye[key] = !blockEye[key];
    setBlock(blockEye);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    handleValidation('password', firstName, lastName, email, event.target.value, confirmPassword);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    handleValidation('email', firstName, lastName, event.target.value, password, confirmPassword);
    props.changeError();
  };

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
    handleValidation('firstName', event.target.value, lastName, email, password, confirmPassword);
  };

  const handleConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
    handleValidation('confirmPassword', firstName, lastName, email, password, event.target.value);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
    handleValidation('lastName', firstName, event.target.value, email, password, confirmPassword);
  };

  const handleClickSignUp = () => {
    if (handleValidation('', firstName, lastName, email, password, confirmPassword)) {
      props.onSubmitSignUp(firstName, lastName, email, password);
    }
    props.changeError();
  };

  useEffect(() => {
    const error = { ...errors };
    if (props.error) {
      error.email = i18n.t('FORM:EMAIL_REGISTERED');
    }
    setErrors(error);
  }, [props.error]);

  const handleValidation = (
    inputToValidate = '',
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    confirmPassword: string
  ) => {
    let formIsValid = true;
    let error = { ...errors };
    if ((inputToValidate === '' || inputToValidate === 'firstName') && isEmpty(firstName)) {
      error = { ...error, firstName: i18n.t('FORM:FIRST_NAME_EMPTY') };
      formIsValid = false;
    } else {
      if ((inputToValidate === '' || inputToValidate === 'firstName') && !isEmpty(firstName)) {
        error = { ...error, firstName: '' };
      }
    }
    if ((inputToValidate === '' || inputToValidate === 'lastName') && isEmpty(lastName)) {
      error = { ...error, lastName: i18n.t('FORM:LAST_NAME_EMPTY') };
      formIsValid = false;
    } else {
      if ((inputToValidate === '' || inputToValidate === 'lastName') && !isEmpty(lastName)) {
        error = { ...error, lastName: '' };
      }
    }
    if ((inputToValidate === '' || inputToValidate === 'password') && isEmpty(password)) {
      setValidPassword(true);
      formIsValid = false;
    } else {
      if ((inputToValidate === '' || inputToValidate === 'password') && isValidPassword(password)) {
        setValidPassword(true);
        formIsValid = false;
      } else {
        if (
          (inputToValidate === '' || inputToValidate === 'password') &&
          !isEmpty(password) &&
          !isValidPassword(password)
        ) {
          setValidPassword(false);
        }
      }
    }
    if (
      (inputToValidate === '' || inputToValidate === 'confirmPassword') &&
      isEmpty(confirmPassword)
    ) {
      error = {
        ...error,
        confirmPassword: i18n.t('FORM:NEW_CONFIRM_PASSWORD_EMPTY'),
      };
      formIsValid = false;
    } else {
      if (
        (inputToValidate === '' || inputToValidate === 'confirmPassword') &&
        !isEmpty(confirmPassword)
      ) {
        error = { ...error, confirmPassword: '' };
      }
    }
    if ((inputToValidate === '' || inputToValidate === 'email') && isEmpty(email)) {
      error = { ...error, email: i18n.t('FORM:EMAIL_EMPTY') };
      formIsValid = false;
    } else {
      if ((inputToValidate === '' || inputToValidate === 'email') && !isEmail(email)) {
        formIsValid = false;
        error = { ...error, email: i18n.t('FORM:EMAIL_VALID') };
      } else {
        if (
          (inputToValidate === '' || inputToValidate === 'email') &&
          !isEmpty(email) &&
          isEmail(email)
        ) {
          error = { ...error, email: '' };
        }
      }
    }
    if (
      !isEmpty(password) &&
      !isValidPassword(password) &&
      !isEmpty(confirmPassword) &&
      isPasswordMatch(password, confirmPassword)
    ) {
      formIsValid = false;
      error = {
        ...error,
        confirmPassword: i18n.t('FORM:PASSWORDS_DONT_MATCH'),
      };
    } else {
      if (
        !isEmpty(password) &&
        !isEmpty(confirmPassword) &&
        !isPasswordMatch(password, confirmPassword)
      ) {
        error = { ...error, confirmPassword: '' };
      }
    }
    setErrors(error);
    return formIsValid;
  };
  return (
    <>
      <input type="hidden" value="something" />
      <LogInText>{i18n.t('SIGNUP:SIGNUP_TITLE')}</LogInText>
      <NamesContainer>
        <InputContainer>
          <NameInput
            margin={true}
            type="text"
            value={firstName}
            onChange={handleFirstNameChange}
            placeholder={i18n.t('SIGNUP:FIRST_NAME')}
            maxLength={20}
          ></NameInput>
          <ErrorMessage>{errors.firstName}</ErrorMessage>
        </InputContainer>
        <InputContainer>
          <NameInput
            margin={false}
            type="text"
            value={lastName}
            onChange={handleLastNameChange}
            placeholder={i18n.t('SIGNUP:LAST_NAME')}
            maxLength={40}
          ></NameInput>
          <ErrorMessage>{errors.lastName}</ErrorMessage>
        </InputContainer>
      </NamesContainer>
      <Inputs
        type="email"
        value={email}
        onChange={handleEmailChange}
        placeholder="E-mail"
        maxLength={255}
        autoComplete="new-password"
      ></Inputs>
      <ErrorMessage>{errors.email}</ErrorMessage>
      <InputContainer>
        <Inputs
          type={block.password ? 'text' : 'password'}
          value={password}
          onChange={handlePasswordChange}
          placeholder={i18n.t('SIGNUP:PASS')}
          autoComplete="new-password"
          maxLength={40}
        ></Inputs>
        {block.password ? (
          <IconBlock
            onClick={() => handleEye('password')}
            src={`${PUBLIC_URL}/images/eye-n.svg`}
          ></IconBlock>
        ) : (
          <IconBlock
            onClick={() => handleEye('password')}
            src={`${PUBLIC_URL}/images/see_pass.svg`}
          ></IconBlock>
        )}
        <TextPasswordRequirements validPassword={validPassword}>
          {i18n.t('SIGNUP:PASSWORD_REQUIREMENTS')}
        </TextPasswordRequirements>
      </InputContainer>
      <InputContainer>
        <Inputs
          type={block.confirmPassword ? 'text' : 'password'}
          value={confirmPassword}
          onChange={handleConfirmPassword}
          placeholder={i18n.t('SIGNUP:CONFIRM_PASS')}
          autoComplete="new-password"
          maxLength={40}
        ></Inputs>
        {block.confirmPassword ? (
          <IconBlock
            onClick={() => handleEye('confirmPassword')}
            src={`${PUBLIC_URL}/images/eye-n.svg`}
          ></IconBlock>
        ) : (
          <IconBlock
            onClick={() => handleEye('confirmPassword')}
            src={`${PUBLIC_URL}/images/see_pass.svg`}
          ></IconBlock>
        )}
      </InputContainer>
      <ErrorMessage>{errors.confirmPassword}</ErrorMessage>
      <ButtonContainer>
        <SignUpButton onClick={handleClickSignUp}>{i18n.t('SIGNUP:SIGNUP')}</SignUpButton>
        <BackToLogin onClick={props.login}>{i18n.t('SIGNUP:LOGIN')}</BackToLogin>
      </ButtonContainer>
    </>
  );
};
export default SignUp;
