import React, { FC } from 'react';

import Video from 'views/Video/Video';
import { IScene } from 'views/Video/types';

import {
  SceneNumber,
  SceneWrapper,
  ScenesSlider,
  Slick,
  Slide,
  SliderContainer,
  settings,
} from './styles';
import { IProps } from './types';

const ScenesSliderEdit: FC<IProps> = (props) => {
  return (
    <ScenesSlider>
      <SliderContainer>
        <Slick {...settings}>
          {props.scenes.map((scene: IScene) => {
            return (
              <SceneWrapper key={scene._id}>
                <Slide
                  isActive={props.currentScene === scene._id}
                  onClick={() => props.onClickScene(scene)}
                >
                  <Video
                    layers={props.layers}
                    textVariables={props.textVariables}
                    imageVariables={props.imageVariables}
                    colorVariables={props.colorVariables}
                    currentFrameDistance={scene.frameEnd}
                    isPlaying={false}
                    frame={scene.frameEnd}
                    backgroundColor={props.backgroundColor}
                    backgroundVideo={props.backgroundVideo}
                    autoplayBackgroundVideo={false}
                    isEdit={true}
                    replay={false}
                    removeVideoData={props.onRemoveVideoData}
                  />
                  <SceneNumber>S{scene.order}</SceneNumber>
                </Slide>
              </SceneWrapper>
            );
          })}
        </Slick>
      </SliderContainer>
    </ScenesSlider>
  );
};
export default ScenesSliderEdit;
