/* eslint-disable */
import { createSelector } from 'reselect';

const getUserData = (state: any) => state.user;

const userSelector = () =>
  createSelector([getUserData], (userData) => ({
    user: { ...userData },
  }));

export const userProductId = () =>
  createSelector([getUserData], (userData) => userData.user.productId);

export default userSelector;
