import React, { FC, useEffect, useState } from 'react';

import 'helpers/inputHelper/i18n';
import { isEmail, isEmpty } from 'helpers/inputHelper/inputHelper';
import { IObject } from 'helpers/types';
import i18n from 'i18next';

import {
  BackToLogin,
  ButtonContainer,
  ChangePassButton,
  ErrorMessage,
  Inputs,
  LogInText,
  SendText,
} from './styles';
import { IProps } from './types';

const ForgotPassword: FC<IProps> = (props) => {
  const [email, setEmail] = useState<string>('');
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [errors, setErrors] = useState<IObject>({});

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    props.changeError();
    handleValidation(event.target.value, false);
    if (sendEmail) {
      setSendEmail(false);
    }
  };

  useEffect(() => {
    const error = { ...errors };
    if (props.successSendEmail) {
      setSuccessMessage(true);
      error.email = '';
    } else {
      if (props.error) {
        error.email = i18n.t('FORM:EMAIL_NOT_REGISTERED');
      }
    }
    setErrors(error);
  }, [props.successSendEmail, props.error]);

  const handleValidation = (email: string, checkEmail: boolean) => {
    let formIsValid = true;
    let error = {
      email: '',
    };
    if (isEmpty(email)) {
      error = { ...error, email: i18n.t('FORM:EMAIL_EMPTY') };
      formIsValid = false;
    } else {
      if (!isEmail(email)) {
        formIsValid = false;
        error = { ...error, email: i18n.t('FORM:EMAIL_VALID') };
      }
    }
    if (checkEmail && !isEmpty(email) && isEmail(email) && props.error) {
      error.email = i18n.t('FORM:EMAIL_NOT_REGISTERED');
    }
    setErrors(error);
    return formIsValid;
  };

  const handleResetPassword = () => {
    if (!sendEmail && handleValidation(email, true)) {
      setSendEmail(true);
      props.resetPassword(email);
    }
  };

  return (
    <>
      <LogInText>{i18n.t('FORGOTPASSWORD:FORGOT_PASS')}</LogInText>
      <Inputs
        type="email"
        value={email}
        required
        onChange={handleEmailChange}
        placeholder={i18n.t('FORGOTPASSWORD:EMAIL')}
      ></Inputs>
      <ErrorMessage>{errors.email}</ErrorMessage>
      <SendText sendText={successMessage}>{i18n.t('FORGOTPASSWORD:EMAIL_INBOX')}</SendText>
      <ButtonContainer>
        <ChangePassButton onClick={handleResetPassword} sendEmail={sendEmail}>
          {i18n.t('FORGOTPASSWORD:SEND_EMAIL')}
        </ChangePassButton>
        <BackToLogin onClick={props.login}>{i18n.t('SIGNUP:LOGIN')}</BackToLogin>
      </ButtonContainer>
    </>
  );
};
export default ForgotPassword;
