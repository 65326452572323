import { PUBLIC_URL } from 'configs';
import { ShareStep } from 'enums/shareStep.enum';
import styled from 'styled-components';

import { IFinishedStep, IStepDot, IStepNumber, IStepText, IStepWrapper } from './types';

const buildLineBackground = (
  step: number,
  currentStep: number,
  inactiveColor: string,
  activeColor: string
) => {
  let background;
  if (currentStep === step) {
    background =
      'transparent linear-gradient(90deg, ' +
      activeColor +
      ' 0%, ' +
      inactiveColor +
      ' 100%)0% 0% no-repeat padding-box;';
  } else if (step < currentStep) {
    background = activeColor;
  } else {
    background = inactiveColor;
  }
  return background;
};

const stepWrapperMargin = (step: ShareStep) => {
  switch (step) {
    case ShareStep.NONE:
      return '0 27px 0 0';
    case ShareStep.IMPORT:
      return '0 30px 0 0';
    case ShareStep.SELECT:
    case ShareStep.MATCH:
      return '0 30px';
    case ShareStep.LAUNCH:
      return '0 0 0 30px';
    default:
      return '0';
  }
};

export const Wrapper = styled.div`
  margin: auto;
  flex-direction: column;
  align-items: center;
`;

export const DotsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StepWrapper = styled.div<IStepWrapper>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${(props) => stepWrapperMargin(props.step)};
`;

export const StepDot = styled.div<IStepDot>`
  width: ${(props) => (props.step === props.currentStep ? '32px' : '28px')};
  height: ${(props) => (props.step === props.currentStep ? '32px' : '28px')};
  display: flex;
  align-items: center;
  border-radius: 100%;
  border: ${(props) =>
    props.step === props.currentStep ? '2px solid ' + props.theme.colors.apricot : 'none'};
  background-color: ${(props) =>
    props.step === props.currentStep ? props.theme.colors.white : props.theme.colors.grayChateau};
  position: relative;

  ${(props) =>
    props.step !== ShareStep.LAUNCH &&
    `
    &:after {
      content: "";
      width: 90px;
      height: 4px;
      background:
      ${buildLineBackground(
        props.step,
        props.currentStep,
        props.theme.colors.raven,
        props.theme.colors.apricot
      )};
      position: absolute;
      z-index: -1;
      top: 45%;
      left: 95%;
    }`}
  ${(props) =>
    props.step === 0 &&
    `
    &:after {
      content: "";
      width: 90px;
      height: 4px;
      background:
      ${buildLineBackground(
        props.step,
        props.currentStep,
        props.theme.colors.raven,
        props.theme.colors.apricot
      )};
      position: absolute;
      z-index: -1;
      top: 45%;
      left: 15%;
    }`}
`;

export const StepDotEdit = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: ${(props) => props.theme.colors.cello};
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    background-color: ${(props) => props.theme.colors.bigStone};
  }

  &:after {
    width: 98px;
    height: 4px;
    background-image: url(${PUBLIC_URL}/images/dashIcon.svg);
    background-position: center;
    background-size: contain;
    content: '';
    position: absolute;
    z-index: -1;
    top: 45%;
    left: 15%;
  }
`;

export const EditIcon = styled.img`
  width: 60%;
`;

export const FinishedStep = styled.div<IFinishedStep>`
  position: relative;
  ${(props) =>
    props.step !== ShareStep.LAUNCH &&
    `
    &:after {
      content: "";
      width: 90px;
      height: 4px;
      background:
      ${buildLineBackground(
        props.step,
        props.currentStep,
        props.theme.colors.raven,
        props.theme.colors.apricot
      )};
      position: absolute;
      z-index: -1;
      top: 45%;
      left:95%;
    }`}
`;

export const StepNumber = styled.div<IStepNumber>`
  margin: auto;
  color: ${(props) =>
    props.step === props.currentStep ? props.theme.colors.apricot : props.theme.colors.white};
  font-size: ${(props) =>
    props.step === props.currentStep ? '20px' : props.theme.headers.h2.fontSizes.large};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
`;

export const StepText = styled.p<IStepText>`
  color: ${(props) =>
    props.currentStep === props.step || props.step < props.currentStep
      ? props.theme.colors.apricot
      : props.theme.colors.grayChateau};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  font-family: ${(props) =>
    props.step === props.currentStep
      ? props.theme.headers.h2.fontFamilies.bold
      : props.theme.headers.h2.fontFamilies.medium};
  margin: 5px 0;
`;

export const StepEdit = styled.p<IStepText>`
  text-align: center;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: #214569;
  margin: 5px 0;
`;
