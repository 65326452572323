import styled from 'styled-components';

import { IName, IValidPass } from './types';

export const LogInText = styled.h2`
  font-size: 20px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  color: ${(props) => props.theme.colors.fiord};
  margin-bottom: 25px;
`;

export const Inputs = styled.input`
  width: 355px;
  height: 44px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  border: 1px solid ${(props) => props.theme.colors.porcelain};
  border-radius: 6px;
  padding-left: 15px;
`;

export const SignUpButton = styled.a`
  width: 120px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.cello};
  border-radius: 3px;
  border: none;
  cursor: pointer;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  transition: all 0.2s ease-out;
  margin-right: 25px;
  text-decoration: none;
  &:hover {
    background: ${(props) => props.theme.colors.bigStone};
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const NameInput = styled(Inputs)<IName>`
  width: 161px;
  height: 44px;
  margin-right: ${(props) => (props.margin ? '13px' : '0')};
`;

export const NamesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ErrorMessage = styled.p`
  font-size: 10px;
  height: 10px;
  text-align: left;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.red};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  margin-bottom: 9px;
  margin-top: 3px;
`;

export const BackToLogin = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  text-align: left;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.apricot};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TextPasswordRequirements = styled.p<IValidPass>`
  font-size: 10px;
  text-align: left;
  color: ${(props) => (props.validPassword ? props.theme.colors.red : props.theme.colors.bombay)};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  margin-top: 3px;
  margin-bottom: 3px;
`;

export const IconBlock = styled.img`
  position: absolute;
  width: 21px;
  height: 19px;
  cursor: pointer;
  top: 15px;
  left: 335px;
`;
