import React, { FC, Fragment, useEffect, useState } from 'react';

import ImageEditModal from 'components/ImageEditModal/EditModal';
import MessageModal from 'components/MessageModal/MessageModal';
import ModalLoading from 'components/common/ModalLoading/ModalLoading';
import Wrapper from 'components/common/Wrapper/Wrapper';
import { PUBLIC_URL } from 'configs/index';
import { MESSAGE } from 'constants/messages/messages.constants';
import StoreLayout from 'containers/StoreLayout/StoreLayout';
import 'helpers/inputHelper/i18n';
import { isEmpty, isPasswordMatch, isValidPassword } from 'helpers/inputHelper/inputHelper';
import i18n from 'i18next';

import {
  BMSCollectText,
  BasicInformationText,
  Button,
  ButtonsContainer,
  Container,
  EditIconProfile,
  ErrorMessage,
  Form,
  FormContainer,
  IconBlock,
  InputContainer,
  Inputs,
  InputsContainer,
  LabelYourAccount,
  LeftContainer,
  Links,
  ProfilePicture,
  ProfilePictureWrapper,
  RightContainer,
  TextInformationContainer,
  TextPasswordRequirements,
  TitleAccount,
  TitleContainer,
} from './styles';
import { IProps } from './types';

const UserProfile: FC<IProps> = (props) => {
  const [password, setPassword] = useState<string>('');
  const [oldPassword, setOldPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [changePhoto, setChangePhoto] = useState<string>(props.userInfo.thumbnail?.path || '');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProfilePicture, setIsProfilePicture] = useState<boolean>(false);
  const [block, setBlock] = useState({
    oldPassword: false,
    password: false,
    confirmPassword: false,
  });
  const [errors, setErrors] = useState({
    oldPassword: '',
    confirmPassword: '',
  });
  const [isVisibleError, setIsVisibleError] = useState<boolean>(false);
  const [isSuccessVisible, setIsSuccessVisible] = useState<boolean>(false);
  const [validPassword, setValidPassword] = useState<boolean>(false);
  const toggleError = () => {
    setIsVisibleError((prevState) => !prevState);
  };

  const toggleSuccess = () => {
    setIsSuccessVisible((prevState) => !prevState);
  };

  useEffect(() => {
    setFirstName(props.userInfo.firstName);
    setLastName(props.userInfo.lastName);
    setEmail(props.userInfo.email);
  }, [props.userInfo]);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    handleValidation('password', oldPassword, event.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
    handleValidation('confirmPassword', oldPassword, password, event.target.value);
  };

  const handleFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const handleLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  useEffect(() => {
    if (props.userInfo.thumbnail && props.userInfo.thumbnail.path) {
      setChangePhoto(props.userInfo.thumbnail.path);
    }
  }, [props.userInfo]);

  const handleOldPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(event.target.value);
    handleValidation('oldPassword', event.target.value, password, confirmPassword);
  };

  const onCloseModal = () => {
    setIsVisible(false);
  };

  const onOpenModal = () => {
    setIsVisible(true);
  };

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
      setIsProfilePicture(false);
      setIsSuccessVisible(true);
    }
  }, [props.userInfo.thumbnail]);

  useEffect(() => {
    if (props.statusPassword) {
      props.changeFirstName(firstName);
      props.changeLastName(lastName);
      props.changeStatusPassword();
    }
  }, [props.statusPassword]);

  useEffect(() => {
    if (props.statusProfile) {
      setIsSuccessVisible(true);
      setIsProfilePicture(true);
    }
  }, [props.statusProfile]);

  const save = () => {
    if (password !== '' || confirmPassword !== '' || oldPassword !== '') {
      if (handleValidation('', oldPassword, password, confirmPassword)) {
        props.changePass(oldPassword, password);
      }
    } else {
      props.changeFirstName(firstName);
      props.changeLastName(lastName);
    }
    props.changeStatusPassword();
    props.changeStatusProfile();
  };

  useEffect(() => {
    const error = { ...errors };
    if (props.error) {
      error.oldPassword = i18n.t('FORM:CURRENT_PASSWORD');
    }
    setErrors(error);
    props.changeError();
  }, [props.error]);

  const handleValidation = (
    inputToValidate = '',
    oldPassword: string,
    password: string,
    confirmPassword: string
  ) => {
    let formIsValid = true;
    let error = { ...errors };
    if ((inputToValidate === '' || inputToValidate === 'oldPassword') && isEmpty(oldPassword)) {
      error = { ...error, oldPassword: i18n.t('FORM:OLD_PASSWORD_EMPTY') };
      formIsValid = false;
    } else {
      if ((inputToValidate === '' || inputToValidate === 'oldPassword') && !isEmpty(oldPassword)) {
        error = { ...error, oldPassword: '' };
      }
    }
    if ((inputToValidate === '' || inputToValidate === 'password') && isEmpty(password)) {
      setValidPassword(true);
      formIsValid = false;
    } else {
      if ((inputToValidate === '' || inputToValidate === 'password') && isValidPassword(password)) {
        setValidPassword(true);
        formIsValid = false;
      } else {
        if (
          (inputToValidate === '' || inputToValidate === 'password') &&
          !isEmpty(password) &&
          !isValidPassword(password)
        ) {
          setValidPassword(false);
        }
      }
    }
    if (
      (inputToValidate === '' || inputToValidate === 'confirmPassword') &&
      isEmpty(confirmPassword)
    ) {
      error = {
        ...error,
        confirmPassword: i18n.t('FORM:NEW_CONFIRM_PASSWORD_EMPTY'),
      };
      formIsValid = false;
    } else {
      if (
        (inputToValidate === '' || inputToValidate === 'confirmPassword') &&
        !isEmpty(confirmPassword)
      ) {
        error = { ...error, confirmPassword: '' };
      }
    }
    if (
      !isEmpty(password) &&
      !isEmpty(confirmPassword) &&
      !isValidPassword(password) &&
      isPasswordMatch(password, confirmPassword)
    ) {
      error = { ...error, confirmPassword: i18n.t('FORM:NEW_DONT_MATCH') };
      formIsValid = false;
    } else {
      if (
        !isEmpty(password) &&
        !isEmpty(confirmPassword) &&
        !isPasswordMatch(password, confirmPassword)
      ) {
        error = { ...error, confirmPassword: '' };
      }
    }
    if (isEmpty(password) && isEmpty(oldPassword) && isEmpty(confirmPassword)) {
      error = { oldPassword: '', confirmPassword: '' };
      setValidPassword(false);
      formIsValid = true;
    }
    setErrors(error);
    return formIsValid;
  };

  const handleEye = (key: string) => {
    const blockEye = { ...block };
    blockEye[key] = !blockEye[key];
    setBlock(blockEye);
  };

  return (
    <Fragment>
      <StoreLayout isTemplate="">
        <Container>
          <TitleContainer>
            <TitleAccount>{i18n.t('PROFILE:ACCOUNT')}</TitleAccount>
            {/* <ProfileTitle>{i18n.t("PROFILE:PROFILE")}</ProfileTitle> */}
          </TitleContainer>
          <Wrapper>
            <FormContainer>
              <LeftContainer>
                {props.userInfo.thumbnail ? (
                  <ProfilePictureWrapper>
                    <EditIconProfile
                      onClick={onOpenModal}
                      src={`${PUBLIC_URL}/images/edit-profile_v2-01.svg`}
                    ></EditIconProfile>
                    <ProfilePicture onClick={onOpenModal} src={changePhoto} />
                  </ProfilePictureWrapper>
                ) : (
                  <ProfilePictureWrapper>
                    <EditIconProfile
                      onClick={onOpenModal}
                      src={`${PUBLIC_URL}/images/edit_icon_profile.svg`}
                    ></EditIconProfile>
                    <ProfilePicture onClick={onOpenModal} src={`${PUBLIC_URL}/images/bms.jpg`} />
                  </ProfilePictureWrapper>
                )}
              </LeftContainer>
              <RightContainer>
                <Form>
                  <LabelYourAccount>{i18n.t('PROFILE:YOURACCOUNT')}</LabelYourAccount>
                  <InputsContainer hasWrap>
                    <InputContainer>
                      <Inputs
                        type="text"
                        value={firstName}
                        onChange={handleFirstName}
                        placeholder={i18n.t('PROFILE:FIRST')}
                        maxLength={20}
                        editName
                      ></Inputs>
                    </InputContainer>
                    <InputContainer>
                      <Inputs
                        type="text"
                        value={lastName}
                        onChange={handleLastName}
                        placeholder={i18n.t('PROFILE:LAST')}
                        maxLength={40}
                        editName
                      ></Inputs>
                    </InputContainer>
                    <InputContainer>
                      <Inputs
                        type="text"
                        value={email}
                        placeholder={i18n.t('PROFILE:EMAIL')}
                        editName
                        disabled
                      ></Inputs>
                    </InputContainer>
                  </InputsContainer>
                </Form>
                <Form>
                  <LabelYourAccount>{i18n.t('PROFILE:PASSWORDTITLE')}</LabelYourAccount>
                  <InputsContainer>
                    <InputContainer>
                      <Inputs
                        type={block.password ? 'text' : 'password'}
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder={i18n.t('PROFILE:NEWPASS')}
                        maxLength={40}
                        autoComplete="new-password"
                      ></Inputs>
                      {block.password ? (
                        <IconBlock
                          onClick={() => handleEye('password')}
                          src={`${PUBLIC_URL}/images/eye-n.svg`}
                        ></IconBlock>
                      ) : (
                        <IconBlock
                          onClick={() => handleEye('password')}
                          src={`${PUBLIC_URL}/images/see_pass.svg`}
                        ></IconBlock>
                      )}
                      <TextPasswordRequirements validPassword={validPassword}>
                        {i18n.t('SIGNUP:PASSWORD_REQUIREMENTS')}
                      </TextPasswordRequirements>
                    </InputContainer>
                    <InputContainer>
                      <Inputs
                        type={block.confirmPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        placeholder={i18n.t('PROFILE:CONFIRMPASS')}
                        maxLength={40}
                        autoComplete="new-password"
                      ></Inputs>
                      {block.confirmPassword ? (
                        <IconBlock
                          onClick={() => handleEye('confirmPassword')}
                          src={`${PUBLIC_URL}/images/eye-n.svg`}
                        ></IconBlock>
                      ) : (
                        <IconBlock
                          onClick={() => handleEye('confirmPassword')}
                          src={`${PUBLIC_URL}/images/see_pass.svg`}
                        ></IconBlock>
                      )}
                      <ErrorMessage>{errors.confirmPassword}</ErrorMessage>
                    </InputContainer>
                    <InputContainer>
                      <Inputs
                        type={block.oldPassword ? 'text' : 'password'}
                        value={oldPassword}
                        onChange={handleOldPasswordChange}
                        placeholder={i18n.t('PROFILE:OLDPASS')}
                        maxLength={40}
                        autoComplete="new-password"
                      ></Inputs>
                      {block.oldPassword ? (
                        <IconBlock
                          onClick={() => handleEye('oldPassword')}
                          src={`${PUBLIC_URL}/images/eye-n.svg`}
                        ></IconBlock>
                      ) : (
                        <IconBlock
                          onClick={() => handleEye('oldPassword')}
                          src={`${PUBLIC_URL}/images/see_pass.svg`}
                        ></IconBlock>
                      )}
                      <ErrorMessage>{errors.oldPassword}</ErrorMessage>
                    </InputContainer>
                  </InputsContainer>
                </Form>
                <TextInformationContainer>
                  <BMSCollectText>
                    <BasicInformationText raven={true}>
                      {i18n.t('PROFILE:BASIC_INFORMATION')}
                    </BasicInformationText>
                    {i18n.t('PROFILE:BMS_COLLECTS')}
                    <Links to="/privacy" target="_blank">
                      <BasicInformationText raven={false}>
                        {i18n.t('PROFILE:INFO')}
                      </BasicInformationText>
                    </Links>
                  </BMSCollectText>
                </TextInformationContainer>
                <ButtonsContainer>
                  <Button onClick={save}>{i18n.t('PROFILE:SAVE')}</Button>
                  {/* <ButtonCancel>{i18n.t("PROFILE:CANCEL")}</ButtonCancel> */}
                </ButtonsContainer>
              </RightContainer>
            </FormContainer>
          </Wrapper>
        </Container>
        {isLoading && <ModalLoading text={i18n.t('PROFILE:LOADING')}></ModalLoading>}
      </StoreLayout>
      {isVisible && (
        <ImageEditModal
          setIsLoading={setIsLoading}
          onClose={onCloseModal}
          onSubmitImageAvatar={props.onSubmitImage}
          setError={toggleError}
          thumbnail={props.userInfo.thumbnail}
        />
      )}
      {isVisibleError && (
        <MessageModal
          title={MESSAGE.error.title}
          icon={MESSAGE.error.icon}
          closeIcon={MESSAGE.error.close}
          bgColor={MESSAGE.error.bgColor}
          onClose={toggleError}
          message={i18n.t('MESSAGE:FILE_SIZE_WARNING')}
        />
      )}
      {isSuccessVisible && (
        <MessageModal
          title={MESSAGE.success.title}
          icon={MESSAGE.success.icon}
          closeIcon={MESSAGE.success.close}
          bgColor={MESSAGE.success.bgColor}
          onClose={toggleSuccess}
          message={
            isProfilePicture
              ? i18n.t('MESSAGE:UPDATE_SUCCESS')
              : i18n.t('MESSAGE:UPDATE_SUCCESS_PICTURE')
          }
        />
      )}
    </Fragment>
  );
};
export default UserProfile;
