import styled from 'styled-components';

export const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AnimationContainer = styled.div`
  width: 50%;
  height: 50%;
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AnimationLayer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
