import styled from 'styled-components';

export const NameTooltip = styled.div`
  display: none;
  padding: 12px;
  background: ${(props) => props.theme.colors.bigStone};
  position: absolute;
  top: 185px;
  left: 0;
  z-index: 100;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000041;
  &:after {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: -10px;
    left: 10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${(props) => props.theme.colors.bigStone};
  }
`;

export const NameTooltipText = styled.p`
  width: 100%;
  color: ${(props) => props.theme.colors.white};
  word-break: break-all;
  white-space: break-spaces;
`;

export const CampaignContainer = styled.div`
  width: 249px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  cursor: default;
  position: relative;
`;

export const CampaignName = styled.div`
  width: 140px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  margin-top: 15px;
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &:hover ${NameTooltip} {
    display: block;
  }
`;

export const DraftDate = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.light};
`;

export const SentDate = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.cello};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
`;
