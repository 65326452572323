import { IMetricData } from 'interfaces/metricData.interface';
import { AnyAction } from 'redux';

import * as constants from '../constants/metrics.actions.constants';

interface IMetricReducerState {
  id: string;
  isFetching: boolean;
  audioPlayed: boolean;
  metricData: IMetricData;
  ctaClicked: boolean;
  isLoading: boolean;
}

const defaultState: IMetricReducerState = {
  id: '',
  isFetching: false,
  audioPlayed: false,
  ctaClicked: false,
  metricData: {
    averageSecondsView: '',
    soundPlayedTime: [],
    totalWatchTime: '',
    viewsQuantity: '',
    campaignName: '',
    duration: [],
    secondsView: [],
    finishedPercentage: { finished: 0, percentage: 0 },
    dataSeconds: [],
  },
  isLoading: true,
};

const addMetric = (state: IMetricReducerState, action: AnyAction) => {
  return {
    ...state,
    id: action.id,
    audioPlayed: false,
    ctaClicked: false,
  };
};

const setFetching = (state: IMetricReducerState) => {
  return {
    ...state,
    isFetching: !state.isFetching,
  };
};

const setCTAAlreadyClicked = (state: IMetricReducerState) => {
  return {
    ...state,
    ctaClicked: true,
  };
};

const setAudioAlreadyPlayed = (state: IMetricReducerState) => {
  return {
    ...state,
    audioPlayed: true,
  };
};

const addMetrics = (state: IMetricReducerState, action: AnyAction) => {
  return {
    ...state,
    metricData: action.metricData,
    isLoading: false,
  };
};

const removeData = () => {
  return defaultState;
};

const metricReducer = (
  state: IMetricReducerState = defaultState,
  action: AnyAction
): IMetricReducerState => {
  switch (action.type) {
    case constants.ON_CREATE_METRICS_SUCCEED:
      return addMetric(state, action);
    case constants.GET_VIDEO_TIME_PLAYED_FAILED:
      return setFetching(state);
    case constants.ON_SELECT_AUDIO_ICON_SUCCEED:
      return setAudioAlreadyPlayed(state);
    case constants.ON_CLICK_CTA_SUCCEED:
      return setCTAAlreadyClicked(state);
    case constants.ON_INITIALIZE_METRICS_DASHBOARD_SUCCEED:
      return addMetrics(state, action);
    case constants.ON_REMOVE_METRICS_DATA:
      return removeData();
    default:
      return state;
  }
};

export default metricReducer;
