import React, { FC, useState } from 'react';

import Wrapper from 'components/common/Wrapper/Wrapper';
import i18n from 'i18next';

import './i18n';
import {
  Button,
  Container,
  CreateCampaignContainer,
  CreateContainer,
  LabelTitle,
  Or,
  TextBelow,
  WrapperLaunch,
} from './styles';
import { IProps } from './types';

const LaunchMailchimp: FC<IProps> = (props) => {
  const [disabledButtons, setDisabledButtons] = useState<boolean>(false);

  const createDraftCampaign = () => {
    if (!disabledButtons) {
      props.setIsCreateAndSend(false);
      props.setIsMailchimpFormVisible(true);
      setDisabledButtons(true);
    }
  };

  const sendCampaign = () => {
    if (!disabledButtons) {
      props.setIsCreateAndSend(true);
      props.setIsMailchimpFormVisible(true);
      setDisabledButtons(true);
    }
  };

  return (
    <WrapperLaunch>
      <Wrapper>
        <Container>
          <LabelTitle>{i18n.t('LAUNCHMAILCHIMP:WHAT')}</LabelTitle>
          <CreateCampaignContainer>
            <CreateContainer>
              <Button onClick={createDraftCampaign} disabled={disabledButtons}>
                {i18n.t('LAUNCHMAILCHIMP:DRAFT')}
              </Button>
              <TextBelow>{i18n.t('LAUNCHMAILCHIMP:CREATEDRAFTTEXT')}</TextBelow>
            </CreateContainer>
            <Or>OR</Or>
            <CreateContainer>
              <Button onClick={sendCampaign} disabled={disabledButtons}>
                {i18n.t('LAUNCHMAILCHIMP:SAVE')}
              </Button>
              <TextBelow>{i18n.t('LAUNCHMAILCHIMP:CREATETEXT')}</TextBelow>
            </CreateContainer>
          </CreateCampaignContainer>
        </Container>
      </Wrapper>
    </WrapperLaunch>
  );
};
export default LaunchMailchimp;
