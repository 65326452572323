import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'TOPBAR', {
  BACK: 'Back',
  NEXT: 'Next',
  PREVIEW: 'Preview',
  SEND: 'Send',
  SAVE: 'Save',
  BACKEDIT: 'Back to editing',
  LAUNCH: 'Launch your campaign',
});
