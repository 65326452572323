import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import { ColorVariableType } from 'enums/colorVariable.enum';
import { hexToRgb } from 'helpers/hexToRGB.helper';
import LottieAPI from 'lottie-api';
import Lottie, { AnimationItem } from 'lottie-web';
import {
  AnimationContainer,
  AnimationLayer,
  AnimationLayerPreview,
  ControlsPreview,
  Sound,
  SoundBlock,
  SoundBlockedHover,
  SoundClickContainer,
  SoundContainer,
  SoundDisabled,
  SoundHover,
  SoundWrapper,
  VolumeBar,
} from 'views/Video/styles';

import { fadeOut } from './audio';
import { DISABLED_VOLUME_VALUE } from './constants';
import addInteractionCTAHover from './helper/addInteractionCTA.helper';
import addInteractionHover from './helper/addInteractionHover.helper';
import replaceImages from './helper/replaceImages.helper';
import replaceTexts from './helper/replaceTexts.helper';
import './style.css';
import { IColorRGB, IColorVariable, IImageVariable, IProps } from './types';

const Video: FC<IProps> = (props) => {
  const animationRef = useRef<HTMLDivElement>(null);
  const [animation, setAnimations] = useState<AnimationItem>({} as AnimationItem);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [animationsApi, setAnimationsApi] = useState<Animation>();
  const [isPause, setIsPause] = useState<boolean>(false);
  const [animationPlayer, setAnimationPlayer] = useState<{ lottie: AnimationItem } | undefined>();
  const [CTAInteractionAdded, setCTAINteractionAdded] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      if (props.removeVideoData) {
        props.removeVideoData();
      }
    };
  }, []);

  useEffect(() => {
    if (animationRef.current && animation !== ({} as AnimationItem)) {
      const lottie: AnimationItem = Lottie.loadAnimation({
        container: animationRef.current,
        renderer: 'svg',
        autoplay: true,
        loop: false,
        animationData: props.layers[0].dataPath,
        name: 'lottieAnimation',
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet',
        },
      });
      setAnimations(lottie);
    }
  }, [animationRef, props.layers]);

  useEffect(() => {
    if (animation && animation.name) {
      animation.addEventListener('DOMLoaded', () => {
        const api = LottieAPI.createAnimationApi(animationPlayer ? animationPlayer : animation);
        setAnimationsApi(api);
        if (props.isEdit === true && props.handleTextChange) {
          addInteractionHover(api, props.textVariables, props.handleTextChange);
        }
        if (props.imageVariables) {
          const imagesToReplace = props.imageVariables.map((imageVariable: IImageVariable) => {
            return imageVariable.asset?.path as string;
          });
          replaceImages(api, props.imageVariables, imagesToReplace);
        }
        const textsIdToReplace = props.textVariables.map((textVariable) => textVariable.name);
        const colors: IColorRGB[] = props.textVariables.map((text) => {
          const textColorId: string = text.name.split('_')[2];
          const textsColors = props.colorVariables?.filter(
            (color: IColorVariable) => color.type === ColorVariableType.Text
          );
          const color = textsColors?.find((color: IColorVariable) => color.name === textColorId);
          let textColorRGB: IColorRGB = { r: 0, g: 0, b: 0 };
          if (color) {
            textColorRGB = hexToRgb(color.value);
          }
          return textColorRGB;
        });
        const textsValueToReplace = props.textVariables.map((textVariable, index: number) => {
          return {
            t: textVariable.value,
            fc: [colors[index].r, colors[index].g, colors[index].b],
          };
        });
        replaceTexts(api, textsIdToReplace, textsValueToReplace);
        if (props.frame) {
          if (!props.isPlaying) {
            animation.goToAndStop(props.frame, true);
          } else {
            animation.goToAndPlay(props.frame, true);
          }
        }
        if (props.setTotalFrames) {
          props.setTotalFrames(animation.getDuration(true) - 1);
        }
      });
    }
  }, [animation]);

  useEffect(() => {
    if (animationsApi && props.textVariables && props.colorVariables) {
      const textsIdToReplace = props.textVariables.map((textVariable) => textVariable.name);
      const colors: IColorRGB[] = props.textVariables.map((text) => {
        const textColorId: string = text.name.split('_')[2];
        const textsColors = props.colorVariables?.filter(
          (color: IColorVariable) => color.type === ColorVariableType.Text
        );
        const color = textsColors?.find((color: IColorVariable) => color.name === textColorId);
        let textColorRGB: IColorRGB = { r: 0, g: 0, b: 0 };
        if (color) {
          textColorRGB = hexToRgb(color.value);
        }
        return textColorRGB;
      });

      const colorVariableFiltered = props.colorVariables.find(
        (colorVariable: IColorVariable) => colorVariable.type === ColorVariableType.Object
      );
      const CTAButtons = document.querySelectorAll(`[id*="${colorVariableFiltered?.name}"] path`);
      if (colorVariableFiltered) {
        CTAButtons.forEach((CTAButton) => {
          (CTAButton as HTMLElement).style.fill = colorVariableFiltered.value;
        });
      }

      const textsValueToReplace = props.textVariables.map((textVariable, index: number) => {
        return {
          t: textVariable.value,
          fc: [colors[index].r, colors[index].g, colors[index].b],
        };
      });
      replaceTexts(animationsApi, textsIdToReplace, textsValueToReplace);
      if (props.currentFrameDistance) {
        animation.goToAndPlay(props.currentFrameDistance - 1, true);
        animation.goToAndStop(props.currentFrameDistance, true);
      }
    }
  }, [props.textVariables, props.colorVariables, animationsApi]);

  useEffect(() => {
    if (animationsApi && props.imageVariables) {
      const imagesToReplace = props.imageVariables.map((imageVariable: IImageVariable) => {
        return imageVariable.asset?.path as string;
      });
      replaceImages(animationsApi, props.imageVariables, imagesToReplace);
    }
  }, [animationsApi, props.imageVariables, props.layers]);

  useEffect(() => {
    if (animation && animation.name) {
      if (props.isPlaying) {
        animation.togglePause();
      } else if (!props.isPlaying && props.complete) {
        animation.goToAndStop(1);
      } else {
        animation.pause();
      }
    }
  }, [props.isPlaying, props.autoplay]);

  useEffect(() => {
    if (animation && animation.name) {
      let fadeOutStarted = false;
      const lastFrame = 0;
      animation.addEventListener('enterFrame', () => {
        if (animation.currentFrame === 1) {
          fadeOutStarted = false;
        }
        if (lastFrame > animation.currentFrame) {
          fadeOutStarted = false;
        }
        const currentSecond = animation.currentFrame / animation.frameRate;
        if (
          !fadeOutStarted &&
          props.audio &&
          Math.floor(currentSecond) === Math.floor(animation.getDuration(false)) - 2
        ) {
          fadeOutStarted = true;
          fadeOut(props.audio, 200);
        }
      });
    }
  }, [animation]);

  useEffect(() => {
    if (animationPlayer) {
      let secondToSave = 1;
      let metricsStarted = false;
      let fadeOutStarted = false;
      const lastFrame = 0;
      animationPlayer.lottie.addEventListener('enterFrame', () => {
        if (animationPlayer.lottie.currentFrame === 1) {
          secondToSave = 1;
          metricsStarted = false;
          fadeOutStarted = false;
        }
        if (lastFrame > animationPlayer.lottie.currentFrame) {
          fadeOutStarted = false;
        }

        const currentSecond =
          animationPlayer.lottie.currentFrame / animationPlayer.lottie.frameRate;

        if (!metricsStarted && props.onStart && Math.floor(currentSecond) === 0) {
          metricsStarted = true;
          props.onStart();
        }

        if (props.onUpdate && Math.floor(currentSecond) >= secondToSave) {
          if (videoRef.current) {
            if (currentSecond > videoRef.current.currentTime + 0.2) {
              videoRef.current.currentTime = currentSecond;
            }
          }
          // eslint-disable-next-line no-console
          console.log('SECOND: ', secondToSave);
          props.onUpdate(secondToSave);
          secondToSave++;
        }
        if (
          !fadeOutStarted &&
          props.audio &&
          Math.floor(currentSecond) === Math.floor(animationPlayer.lottie.getDuration(false)) - 2
        ) {
          fadeOutStarted = true;
          fadeOut(props.audio, 200);
        }
      });
    }
  }, [animationPlayer]);

  useEffect(() => {
    if (props.frame && animation && animation.name) {
      if (
        props.setIsCompleted &&
        animation.currentFrame.toFixed(2) !== (animation.getDuration(true) - 1).toFixed(2)
      ) {
        props.setIsCompleted(false);
      }
      if (!props.isPlaying) {
        animation.goToAndStop(props.frame, true);
      } else {
        animation.goToAndPlay(props.frame, true);
      }
    }
  }, [props.frame]);

  useEffect(() => {
    if (props.audio && props.volume && props.volume > -1) {
      props.audio.volume(props.volume);
    }
  }, [props.volume]);

  useEffect(() => {
    if (props.replay && animation && animation.name) {
      if (props.onStart) {
        props.onStart();
      }
      if (props.audio) {
        props.audio.unload();
        props.audio.load();
        if (props.audioIsPlaying) {
          props.audio.play();
        }
      }
      animation.goToAndPlay(1, true);
      if (props.backgroundVideo && videoRef.current) {
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      }
    }
  }, [props.replay]);

  useEffect(() => {
    if (props.frameToChange && props.frameToChange >= 0 && props.setFrameToChange) {
      if (props.audio) {
        props.audio.unload();
        props.audio.load();
        if (props.audioIsPlaying) {
          props.audio.play();
        }
      }
      if (props.frameToChange && props.setFrameToChange) {
        animation.goToAndPlay(props.frameToChange, true);
        props.setFrameToChange(0);
      }
    }
  }, [props.frameToChange]);

  useEffect(() => {
    if (animation && animation.name && !!props.segments?.length) {
      animation.playSegments([props.segments[0], props.segments[1]], true);
    }
  }, [animation, props.segments]);

  const onChangeVolume = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.setVolume && props.volume && props.volume > DISABLED_VOLUME_VALUE) {
      props.setVolume(parseFloat(event.target.value));
    }
  };

  useEffect(() => {
    if (props.toggle && !isPause) {
      props.audio.volume(1);
    }
  }, [props.toggle]);

  const onLottiePlayerEvent = (event: string) => {
    switch (event) {
      case 'pause':
        props.audio.pause();
        setIsPause(true);
        break;
      case 'play':
        if (animationPlayer && props.complete) {
          animationPlayer.lottie.goToAndPlay(1, true);
        }
        if (props.onReplay) {
          props.audio.play();
          setIsPause(false);
          props.onReplay();
        }
        break;
      case 'complete':
        if (!CTAInteractionAdded && props.onCTAClicked) {
          addInteractionCTAHover(props.textVariables, props.colorVariables, props.onCTAClicked);
          setCTAINteractionAdded(true);
        }
        if (props.onComplete) {
          fadeOut(props.audio, 200);
          props.onComplete();
          if (videoRef.current) {
            videoRef.current.pause();
          }
        }

        break;
      default:
        break;
    }
  };

  return props.isEdit ? (
    <AnimationContainer
      backgroundColor={props.backgroundColor}
      onClick={props.onClick}
      isLoading={props.isLoading}
      isEdit
      isZoom={props.isZoom}
      isPreview={props.isPreview}
      isSharedPlayer={props.isSharedPlayer}
    >
      <AnimationLayer isZoom={props.isZoom} ref={animationRef} />
    </AnimationContainer>
  ) : (
    <AnimationContainer
      backgroundColor={props.backgroundColor}
      onClick={props.onClick}
      isLoading={props.isLoading}
      isZoom={props.isZoom}
      isPreview={props.isPreview}
      isSharedPlayer={props.isSharedPlayer}
    >
      {props.isPreview && (
        <SoundWrapper>
          <SoundContainer>
            {props.hasAudio && (
              <>
                {props.complete ? (
                  <SoundDisabled />
                ) : props.toggle ? (
                  <SoundClickContainer onClick={props.onChangeToggle}>
                    <Sound />
                    <SoundHover />
                  </SoundClickContainer>
                ) : (
                  <SoundClickContainer onClick={props.onChangeToggle}>
                    <SoundBlock />
                    <SoundBlockedHover />
                  </SoundClickContainer>
                )}
              </>
            )}
          </SoundContainer>
          <VolumeBar
            type="range"
            min={0}
            max={1}
            step={0.1}
            value={props.volume}
            onChange={onChangeVolume}
          />
        </SoundWrapper>
      )}
      <AnimationLayerPreview
        autoplay={props.isPreview}
        lottieRef={(instance) => {
          setAnimationPlayer({ lottie: instance });
        }}
        onEvent={onLottiePlayerEvent}
        src={props.layers[0].dataPath}
        keepLastFrame
      >
        {props.isPreview && <ControlsPreview visible buttons={['play']} />}
      </AnimationLayerPreview>
    </AnimationContainer>
  );
};

export default Video;
