import React, { FC } from 'react';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
  SliderArrowLeft,
  SliderArrowRight,
} from 'views/Template/components/SliderArrows/SliderArrows';

import Filter from '../Filter/Filter';
import TemplateThumbnail from '../TemplateThumbnail/TemplateThumbnail';
import {
  Slick,
  Slide,
  SliderContainer,
  TemplateContainer,
  TemplateTitle,
  TemplatesViewStyle,
} from './styles';
import { IProps } from './types';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3.6,
  slidesToScroll: 1.5,
  useTransform: false,
  prevArrow: <SliderArrowLeft />,
  nextArrow: <SliderArrowRight />,
};

const TemplatesView: FC<IProps> = (props) => {
  return (
    <TemplatesViewStyle>
      <TemplateTitle>{props.text}</TemplateTitle>
      {!!props.filter && (
        <Filter
          setCategory={props.onSelectCategory}
          categories={props.categories}
          category={props.category}
        />
      )}
      <TemplateContainer>
        <SliderContainer>
          <Slick key={`templates-${props.id}`} {...settings}>
            {props.templates.map((template) => {
              return (
                <Slide key={template._id}>
                  <TemplateThumbnail
                    id={template._id}
                    title={template.title}
                    thumbnail={template.thumbnail.path ? template.thumbnail.path : ''}
                    selectTemplate={props.selectTemplate}
                    onClickPreview={props.onClickPreview}
                    error={props.error}
                    resetPassword={props.resetPassword}
                    onSubmitLoginAndCreateCampaign={props.onSubmitLoginAndCreateCampaign}
                    onSubmitSignUp={props.onSubmitSignUp}
                    isLogged={props.isLogged}
                    statusSignUp={props.statusSignUp}
                    setErrorFalse={props.setErrorFalse}
                  />
                </Slide>
              );
            })}
          </Slick>
        </SliderContainer>
      </TemplateContainer>
    </TemplatesViewStyle>
  );
};
export default TemplatesView;
