import { sortCampaignByDate } from 'helpers/sorter';
import { isUserLogged } from 'helpers/user_validation';
import { IAsset } from 'interfaces/asset.interface';
import { AnyAction } from 'redux';
import { ICampaign } from 'views/CampaignEdit/types';

import * as constantsMyCampaigns from '../constants/myCampaigns.action.constants';
import * as constantsTemplate from '../constants/template.action.contants';
import * as constants from '../constants/user.actions.constants';

export interface IUser {
  thumbnail: IAsset;
  email: string;
  firstName: string;
  lastName: string;
  productId: number;
}

interface IUserReducerState {
  token: string;
  isLogged: boolean;
  campaign: ICampaign[];
  user: IUser;
  statusPassword: boolean | null;
  statusSignUp: boolean;
  statusProfile: boolean;
  successChangePassword: boolean;
  statusForgotPassword: string;
  successDelete: boolean;
  successSendEmail: boolean;
  aboutToExpireCampaignsToAlert: ICampaign[];
  expiredCampaignsToAlert: ICampaign[];
  allCampaignsFetched: boolean;
  loading: boolean;
  isLoadingDeleteCampaign: boolean;
  errorDeleteCampaign: boolean;
}

const defaultState: IUserReducerState = {
  token: '',
  isLogged: isUserLogged(),
  campaign: [],
  user: {
    thumbnail: {
      _id: '',
      originalname: '',
      mimetype: '',
      filename: '',
      size: '',
    },
    email: '',
    firstName: '',
    lastName: '',
    productId: 0,
  },
  statusPassword: null,
  statusSignUp: false,
  statusProfile: false,
  successChangePassword: false,
  statusForgotPassword: '',
  successDelete: false,
  successSendEmail: false,
  aboutToExpireCampaignsToAlert: [],
  expiredCampaignsToAlert: [],
  allCampaignsFetched: false,
  loading: true,
  isLoadingDeleteCampaign: false,
  errorDeleteCampaign: false,
};

const saveUser = (state: IUserReducerState, action: AnyAction) => {
  return {
    ...state,
    token: action.token,
    isLogged: true,
  };
};

const addUserInfo = (state: IUserReducerState, action: AnyAction) => {
  return {
    ...state,
    user: action.data,
  };
};

const signUpFailed = (state: IUserReducerState) => {
  return {
    ...state,
    statusSignUp: false,
  };
};

const signUpSucceded = (state: IUserReducerState) => {
  return {
    ...state,
    statusSignUp: true,
  };
};

const addCampaign = (state: IUserReducerState, action: AnyAction) => {
  let newCampaigns = [...state.campaign];
  action.data.campaigns.forEach((newCampaign: ICampaign) => {
    const index = newCampaigns.findIndex((campaign: ICampaign) => campaign._id === newCampaign._id);
    newCampaign.sent = newCampaign.sentDate;
    if (index >= 0) {
      newCampaigns.splice(index, 1, newCampaign);
    } else {
      newCampaigns.push(newCampaign);
    }
  });
  newCampaigns = newCampaigns.sort((a, b) => sortCampaignByDate(a, b));
  return {
    ...state,
    campaign: [...newCampaigns],
    aboutToExpireCampaignsToAlert: action.data.campaignsToAlert.aboutToExpireCampaignsToAlert,
    expiredCampaignsToAlert: action.data.campaignsToAlert.expiredCampaignsToAlert,
    allCampaignsFetched:
      action.data.campaigns.length && newCampaigns.length === action.data.campaignCount,
    loading: false,
  };
};

const changeStatusTrue = (state: IUserReducerState, action: AnyAction) => {
  return {
    ...state,
    statusPassword: action.data,
    statusProfile: true,
  };
};

const changeFirstName = (state: IUserReducerState, action: AnyAction) => {
  return {
    ...state,
    statusProfile: true,
    user: {
      ...state.user,
      firstName: action.data.firstname,
    },
  };
};

const changeLastName = (state: IUserReducerState, action: AnyAction) => {
  return {
    ...state,
    statusProfile: true,
    user: {
      ...state.user,
      lastName: action.data.lastname,
    },
  };
};

const changeStatusPassword = (state: IUserReducerState) => {
  return {
    ...state,
    statusPassword: false,
  };
};

const changeStatusProfile = (state: IUserReducerState) => {
  return {
    ...state,
    statusProfile: false,
  };
};

const successChangePassword = (state: IUserReducerState) => {
  return {
    ...state,
    successChangePassword: true,
  };
};

const resetLoading = (state: IUserReducerState) => {
  return {
    ...state,
    loading: true,
  };
};

const deleteCampaignRequest = (state: IUserReducerState) => {
  return {
    ...state,
    isLoadingDeleteCampaign: true,
  };
};

const successDeleteCampaign = (state: IUserReducerState, action: AnyAction) => {
  const campaigns = state.campaign.filter((campaign: ICampaign) => campaign._id !== action.data.id);

  return {
    ...state,
    successDelete: true,
    campaign: campaigns,
    isLoadingDeleteCampaign: false,
  };
};

const deleteCampaignError = (state: IUserReducerState) => {
  return {
    ...state,
    isLoadingDeleteCampaign: false,
    errorDeleteCampaign: true,
  };
};

const successFalse = (state: IUserReducerState) => {
  return {
    ...state,
    successDelete: false,
    successChangePassword: false,
    isLoadingDeleteCampaign: false,
    errorDeleteCampaign: false,
  };
};

const changeStatusForgotPassword = (state: IUserReducerState) => {
  return {
    ...state,
    statusForgotPassword: 'validToken',
  };
};

const changeStatusForgotPasswordFalse = (state: IUserReducerState) => {
  return {
    ...state,
    statusForgotPassword: 'invalidToken',
  };
};

const setAvatarImage = (state: IUserReducerState, action: AnyAction) => {
  return {
    ...state,
    user: {
      ...state.user,
      thumbnail: action.data,
    },
  };
};

const successSendEmail = (state: IUserReducerState) => {
  return {
    ...state,
    successSendEmail: true,
  };
};

const successSendEmailFailed = (state: IUserReducerState) => {
  return {
    ...state,
    successSendEmail: false,
  };
};

const userReducer = (
  state: IUserReducerState = defaultState,
  action: AnyAction
): IUserReducerState => {
  switch (action.type) {
    case constants.USER_ON_LOGIN_SUCCEEDED:
      return saveUser(state, action);
    case constants.ON_INITIALIZE_USER_SUCCEDED:
      return addUserInfo(state, action);
    case constantsTemplate.ON_INITIALIZE_CAMPAIGN_USER_SUCCEEDED:
      return addCampaign(state, action);
    case constants.ON_CHANGE_USER_AVATAR_SUCCEDED:
      return setAvatarImage(state, action);
    case constants.ON_CHANGE_USER_PASSWORD_SUCCEDED:
      return changeStatusTrue(state, action);
    case constants.ON_CHANGE_USER_FIRSTNAME_SUCCEDED:
      return changeFirstName(state, action);
    case constants.ON_CHANGE_USER_LASTNAME_SUCCEDED:
      return changeLastName(state, action);
    case constants.ON_CHANGE_USER_PASSWORD_FAILED:
    case constants.ON_CHANGE_STATUS_PASSWORD:
      return changeStatusPassword(state);
    case constants.USER_ON_SIGNUP_FAILED:
    case constants.ON_CHANGE_STATUS_SIGNUP_FALSE:
      return signUpFailed(state);
    case constants.USER_ON_SIGNUP_SUCCEEDED:
      return signUpSucceded(state);
    case constants.ON_CHANGE_STATUS_PROFILE:
    case constants.ON_INITIALIZE_USER_REQUESTED:
      return changeStatusProfile(state);
    case constants.ON_RESET_PASSWORD_SUCCEDED:
      return successChangePassword(state);
    case constants.ON_INITIALIZE_FORGOT_PASSWORD_SUCCEEDED:
      return changeStatusForgotPassword(state);
    case constants.ON_INITIALIZE_FORGOT_PASSWORD_FAILED:
      return changeStatusForgotPasswordFalse(state);
    case constantsMyCampaigns.ON_DELETE_CAMPAIGN_REQUESTED:
      return deleteCampaignRequest(state);
    case constantsMyCampaigns.ON_DELETE_CAMPAIGN_SUCCEDED:
      return successDeleteCampaign(state, action);
    case constantsMyCampaigns.ON_DELETE_CAMPAIGN_FAILED:
      return deleteCampaignError(state);
    case constants.ON_CHANGE_IS_SUCCESS:
      return successFalse(state);
    case constants.ON_FORGOT_PASSWORD_SUCCEDED:
      return successSendEmail(state);
    case constants.ON_FORGOT_PASSWORD_FAILED:
      return successSendEmailFailed(state);
    case constantsTemplate.ON_INITIALIZE_CAMPAIGN_USER_REQUESTED:
      return resetLoading(state);
    default:
      return state;
  }
};

export default userReducer;
