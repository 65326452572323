import { useState } from 'react';

export const useResize = (): (boolean | (() => void))[] => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  const isMobile: boolean = width < 1000 || height < 620;

  return [isMobile, handleWindowSizeChange];
};
