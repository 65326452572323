import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { onSelectEditCreatedCampaign, selectTemplate } from 'redux/actions/campaign.actions';
import * as metricsActions from 'redux/actions/metric.actions';
import * as myCampaignsActions from 'redux/actions/myCampaigns.actions';
import * as paywallActions from 'redux/actions/paywall.actions';
import * as templateActions from 'redux/actions/template.actions';
import * as userActions from 'redux/actions/user.actions';
import { ITemplateReducerState, defaultState } from 'redux/reducers/template.reducer';
import errorSelector from 'redux/selectors/error.selector';
import getPaywallData from 'redux/selectors/paywall.selector';
import getTemplateSelector, {
  getCategoriesFiltered,
  getTemplatesFiltered,
} from 'redux/selectors/template.selector';
import getUserSelector, { userProductId } from 'redux/selectors/user.selector';
import { ICampaign } from 'views/CampaignEdit/types';
import TemplateList from 'views/TemplateList';

const TemplateContainer: FC = () => {
  const dispatch = useDispatch();
  const getTemplatesData = useSelector(getTemplateSelector());
  const getFilteredTemplated = useSelector(getTemplatesFiltered());
  const getFilteredCategories = useSelector(getCategoriesFiltered());
  const getUserData = useSelector(getUserSelector());
  const [templateForPreview, setTemplateForPreview] = useState<string>('');
  const [templateData, setTemplateData] = useState<ITemplateReducerState>(defaultState);
  const errorData = useSelector(errorSelector());
  const [templatePreviewOpen, setTemplatePreviewOpen] = useState(false);
  const paywallData = useSelector(getPaywallData());
  const productId = useSelector(userProductId());

  const onSelectTemplate = (id: string) => {
    dispatch(selectTemplate(id));
  };

  const changeStatusSignUpFalse = () => {
    dispatch(userActions.changeStatusSignUpFalse());
  };

  const onEditCreatedCampaign = (id: string) => {
    dispatch(onSelectEditCreatedCampaign(id));
  };

  useEffect(() => {
    setTemplateData(getTemplatesData);
  }, [getTemplatesData]);

  const onDeleteCampaign = (id: string) => {
    dispatch(myCampaignsActions.deleteCampaign(id));
  };
  const onSelectCampaign = (id: string) => {
    dispatch(myCampaignsActions.selectCampaign(id));
  };
  const onRelaunchCampaign = (id: string) => {
    const userCampaign = getUserData.user.campaign.find(
      (campaign: ICampaign) => campaign._id === id
    );
    dispatch(
      myCampaignsActions.relaunchCampaign(id, userCampaign.shareType, userCampaign.shareData)
    );
  };

  useEffect(() => {
    dispatch(templateActions.initializeTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (getUserData.user.isLogged) {
      dispatch(templateActions.initializeCampaignsUser(1));
    }
  }, [getUserData.user.isLogged]);

  const onCloseModal = () => {
    setTemplateForPreview('');
    setTemplatePreviewOpen(false);
  };

  const onCloseModalPreview = () => {
    setTemplatePreviewOpen(false);
  };

  const onClickPreview = (templateId: string) => {
    const template = templateData.templates.find((template) => template._id === templateId);
    setTemplateForPreview(template ? template._id : '');
    setTemplatePreviewOpen(true);
  };

  const submitLoginAndCreateCampaign = (email: string, password: string, id: string) => {
    dispatch(userActions.submitLoginAndCreateCampaign(email, password, id));
  };

  const submitSignUp = (firstName: string, lastName: string, email: string, password: string) => {
    dispatch(userActions.submitSignUp(firstName, lastName, email, password));
  };

  const resetPassword = (email: string) => {
    dispatch(userActions.forgotPassword(email));
  };

  const changeCategory = (category: string) => {
    dispatch(templateActions.selectCategory(category));
  };

  const changeIsSuccess = () => {
    dispatch(userActions.changeIsSuccess());
  };

  const setErrorFalse = () => {
    dispatch(userActions.changeErrorValue());
  };

  const onClosePaywallModal = () => {
    dispatch(paywallActions.onClosePaywall());
  };

  const onRequestUpgrade = () => {
    if (!paywallData.succededUpgradeRequest) {
      dispatch(userActions.onRequestUpgrade());
    }
  };

  const onSelectCampaignMetrics = (id: string) => {
    dispatch(metricsActions.onRedirectToMetricsDashboard(id));
  };

  return (
    <TemplateList
      onCloseModalEditTemplate={onCloseModalPreview}
      templatePreviewOpen={templatePreviewOpen}
      changeIsSuccess={changeIsSuccess}
      isSuccessDeleteCampaign={getUserData.user.successDelete}
      isSuccessForgotPassword={getUserData.user.successChangePassword}
      filteredTemplates={getFilteredTemplated}
      templates={getTemplatesData.templates}
      onSelectCategory={changeCategory}
      category={getTemplatesData.categorySelected}
      categories={getFilteredCategories}
      selectTemplate={onSelectTemplate}
      userCampaigns={getUserData.user.campaign}
      deleteCampaign={onDeleteCampaign}
      selectCampaign={onSelectCampaign}
      relaunchCampaign={onRelaunchCampaign}
      isLogged={getUserData.user.isLogged}
      onCloseModal={onCloseModal}
      onClickPreview={onClickPreview}
      templateForPreview={templateForPreview}
      statusSignUp={getUserData.user.statusSignUp}
      onSubmitSignUp={submitSignUp}
      submitLoginAndCreateCampaign={submitLoginAndCreateCampaign}
      setTemplateForPreview={setTemplateForPreview}
      error={errorData.status}
      resetPassword={resetPassword}
      setErrorFalse={setErrorFalse}
      onEditCreatedCampaign={onEditCreatedCampaign}
      paywallVisible={paywallData.showPaywall}
      onClosePaywallModal={onClosePaywallModal}
      setTemplatePreviewOpen={setTemplatePreviewOpen}
      paywallMessage={paywallData.paywallMessage}
      succededUpgradeRequest={paywallData.succededUpgradeRequest}
      onRequestUpgrade={onRequestUpgrade}
      aboutToExpireCampaignsToAlert={paywallData.aboutToExpireCampaignsToAlert}
      expiredCampaignsToAlert={paywallData.expiredCampaignsToAlert}
      selectMetricsCampaign={onSelectCampaignMetrics}
      productId={productId}
      changeStatusSignUp={changeStatusSignUpFalse}
      isLoadingCampaigns={getUserData.user.loading}
      isLoadingDeleteCampaign={getUserData.user.isLoadingDeleteCampaign}
      deleteCampaignError={getUserData.user.errorDeleteCampaign}
    />
  );
};

export default TemplateContainer;
