import { IAsset } from 'interfaces/asset.interface';
import { AnyAction } from 'redux';
import { ON_REMOVE_VIDEO_DATA } from 'redux/constants/template.action.contants';
import { ICampaign } from 'views/CampaignEdit/types';
import { IImageVariable } from 'views/Video/types';

import * as constants from '../constants/campaign.action.constants';
import * as shareConstants from '../constants/share.action.constants';
import * as userSharedConstants from '../constants/userShared.action.constants';

interface ICampaignReducerState {
  campaign: ICampaign;
  loading: boolean;
  originalVideo: IAsset;
  videoCropSucceed: boolean;
  loadingShareData: boolean;
}

const defaultState: ICampaignReducerState = {
  loading: false,
  campaign: {
    _id: '',
    name: '',
    shareType: 0,
    textVariables: [],
    colorVariables: [],
    imageVariables: [],
    video: {
      asset: '',
    },
    template: {
      _id: '',
      title: '',
      description: '',
      thumbnail: {
        _id: '',
        originalname: '',
        mimetype: '',
        filename: '',
        size: '',
      },
      scenes: [],
      layers: [],
      textVariables: [],
      colorVariables: [],
      imageVariables: [],
      productId: 0,
      emailTitle: '',
      emailBody: '',
      audio: '',
      duration: 0,
      video: {
        asset: '',
      },
    },
    updatedAt: '',
    createdAt: '',
    shareCampaigns: [],
  },
  originalVideo: {
    _id: '',
    originalname: '',
    mimetype: '',
    filename: '',
    size: '',
  },
  videoCropSucceed: false,
  loadingShareData: true,
};

const setLoadingShareData = (state: ICampaignReducerState) => {
  return {
    ...state,
    loadingShareData: true,
  };
};

const addCampaign = (state: ICampaignReducerState, action: AnyAction) => {
  return {
    ...state,
    campaign: action.data,
    loadingShareData: false,
  };
};

const initializeCampaign = (state: ICampaignReducerState) => {
  return {
    ...state,
    campaign: {
      ...defaultState.campaign,
    },
  };
};

const addShareData = (state: ICampaignReducerState, action: AnyAction) => {
  return {
    ...state,
    campaign: {
      ...state.campaign,
      _id: action.data.campaign._id,
      textVariables: action.data.campaign.textVariables,
      shareData: action.data.campaign.shareData,
      shareType: action.data.campaign.shareType,
      template: {
        ...state.campaign.template,
        emailBody: action.data.campaign.template.emailBody,
        emailSubject: action.data.campaign.template.emailSubject,
      },
    },
  };
};

const addShareDataFile = (state: ICampaignReducerState, action: AnyAction) => {
  return {
    ...state,
    campaign: {
      ...state.campaign,
      _id: action.data._id,
      shareData: action.data.shareData,
      shareType: action.data.shareType,
    },
  };
};

const addPlayerCampaign = (state: ICampaignReducerState, action: AnyAction) => {
  return {
    ...state,
    campaign: action.data.campaign,
  };
};

const setTextVariable = (state: ICampaignReducerState, action: AnyAction) => {
  const newTextVariables = [...state.campaign.textVariables];
  const index = newTextVariables.findIndex((textVariable) => textVariable._id === action.textId);
  if (index >= 0) {
    const textVariable = {
      ...newTextVariables[index],
      ctaLink: action.link,
      value: action.text,
    };
    newTextVariables.splice(index, 1, textVariable);
  }
  return {
    ...state,
    campaign: {
      ...state.campaign,
      textVariables: newTextVariables,
    },
  };
};

const setImageVariable = (state: ICampaignReducerState, action: AnyAction) => {
  const newImageVariables = [...state.campaign.imageVariables];
  const index = newImageVariables.findIndex(
    (imageVariable) => imageVariable._id === action.imageId
  );
  if (index >= 0) {
    const imageVariable = {
      ...newImageVariables[index],
      _id: action.imageId,
      asset: {
        ...newImageVariables[index].asset,
        size: action.file.size,
        path: action.file.src,
      },
    };
    newImageVariables.splice(index, 1, imageVariable as IImageVariable);
  }
  return {
    ...state,
    campaign: {
      ...state.campaign,
      imageVariables: newImageVariables,
    },
  };
};

const setColorVariable = (state: ICampaignReducerState, action: AnyAction) => {
  const newColorVariables = [...state.campaign.colorVariables];
  const index = newColorVariables.findIndex(
    (colorVariable) => colorVariable._id === action.colorId
  );
  if (index >= 0) {
    const colorVariable = { ...newColorVariables[index], value: action.color };
    newColorVariables.splice(index, 1, colorVariable);
  }
  return {
    ...state,
    campaign: {
      ...state.campaign,
      colorVariables: newColorVariables,
    },
  };
};

const changeCampaignName = (state: ICampaignReducerState, action: AnyAction) => {
  return {
    ...state,
    campaign: {
      ...state.campaign,
      name: action.name,
    },
  };
};

const setShareType = (state: ICampaignReducerState, action: AnyAction) => {
  return {
    ...state,
    campaign: {
      ...state.campaign,
      shareType: action.campaign.shareType,
    },
  };
};

const addPreviewData = (state: ICampaignReducerState, action: AnyAction) => {
  return {
    ...state,
    campaign: {
      ...state.campaign,
      textVariables: action.data.textVariables,
      colorVariables: action.data.colorVariables,
      imageVariables: action.data.imageVariables,
      template: {
        ...state.campaign.template,
        layers: action.data.template.layers,
        audio: action.data.template.audio ? action.data.template.audio : '',
        video: action.data.template.video,
        scenes: action.data.template.scenes,
        duration: action.data.template.duration,
      },
    },
  };
};

const setOriginalVideo = (state: ICampaignReducerState, action: AnyAction) => {
  return {
    ...state,
    loading: false,
    originalVideo: action.data,
  };
};

const setVideo = (state: ICampaignReducerState, action: AnyAction) => {
  return {
    ...state,
    videoCropSucceed: true,
    loading: false,
    originalVideo: defaultState.originalVideo,
    campaign: {
      ...state.campaign,
      video: {
        _id: action.data._id,
        asset: action.data.asset?._id,
        path: action.data.asset?.path,
      },
    },
  };
};

const setLoading = (state: ICampaignReducerState) => {
  return {
    ...state,
    loading: true,
  };
};

const setFalseVideoCropSucceed = (state: ICampaignReducerState) => {
  return {
    ...state,
    videoCropSucceed: false,
  };
};

const setChangeVideoLoading = (state: ICampaignReducerState) => {
  return {
    ...state,
    videoCropSucceed: false,
    loading: true,
  };
};

const removeVideoData = (state: ICampaignReducerState) => {
  return {
    ...state,
    campaign: {
      ...state.campaign,
      textVariables: defaultState.campaign.textVariables,
      colorVariables: defaultState.campaign.colorVariables,
      imageVariables: defaultState.campaign.imageVariables,
      template: {
        ...state.campaign.template,
        layers: defaultState.campaign.template.layers,
        audio: defaultState.campaign.template.audio,
        video: defaultState.campaign.template.video,
        scenes: defaultState.campaign.template.scenes,
      },
    },
  };
};

const campaignReducer = (
  state: ICampaignReducerState = defaultState,
  action: AnyAction
): ICampaignReducerState => {
  switch (action.type) {
    case constants.ON_INITIALIZE_CAMPAIGN_REQUESTED:
      return initializeCampaign(state);
    case shareConstants.ON_INITIALIZE_SELECT_VIEW_REQUESTED:
      return setLoadingShareData(state);
    case constants.ON_INITIALIZE_CAMPAIGN_SUCCEDEED:
    case shareConstants.ON_INITIALIZE_SELECT_VIEW_SUCCEEDED:
      return addCampaign(state, action);
    case shareConstants.ON_INITIALIZE_SHARE_MATCH_SUCCEDED:
    case constants.ON_SHARE_CSV_IMPORT_SUCCEEDED:
    case constants.ON_INITIALIZE_SHARE_LAUNCH_SUCCEEDED:
      return addShareData(state, action);
    case constants.ON_SELECT_IMPORT_CSV_SUCCEDED:
      return addShareDataFile(state, action);
    case userSharedConstants.ON_INITIALIZE_PLAYER_SUCCEDEED:
    case userSharedConstants.ON_INITIALIZE_MAILCHIMP_PLAYER_SUCCEDEED:
      return addPlayerCampaign(state, action); //TODO GUIDOG FIXME Review this method
    case constants.ON_CHANGE_CAMPAIGN_NAME_SUCCEEDED:
      return changeCampaignName(state, action);
    case constants.ON_CHANGE_CAMPAIGN_TEMPLATE_TEXTS_REQUESTED:
      return setTextVariable(state, action);
    case constants.ON_CHANGE_CAMPAIGN_COLOR_REQUESTED:
      return setColorVariable(state, action);
    case constants.ON_CHANGE_CAMPAIGN_IMAGE_REQUESTED:
      return setImageVariable(state, action);
    case shareConstants.ON_SELECT_MAILCHIMP_SUCCEDED:
    case shareConstants.ON_SELECT_CSV_SUCCEEDED:
      return setShareType(state, action);
    case constants.ON_INITIALIZE_PREVIEW_SUCCEEDED:
      return addPreviewData(state, action);
    case constants.ON_CHANGE_CAMPAIGN_VIDEO_REQUESTED:
      return setChangeVideoLoading(state);
    case constants.ON_SELECT_EDIT_VIDEO:
      return setFalseVideoCropSucceed(state);
    case constants.ON_CHANGE_CAMPAIGN_VIDEO_SUCCEED:
      return setVideo(state, action);
    case constants.ON_UPLOAD_ORIGINAL_VIDEO_SUCCEED:
      return setOriginalVideo(state, action);
    case constants.ON_UPLOAD_ORIGINAL_VIDEO_REQUESTED:
      return setLoading(state);
    case ON_REMOVE_VIDEO_DATA:
      return removeVideoData(state);
    default:
      return state;
  }
};

export default campaignReducer;
