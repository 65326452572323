import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
  z-index: 150;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
`;

export const ModalContainer = styled.div`
  width: 870px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
  background: ${(props) => props.theme.colors.white};
  justify-content: flex-start;
`;

export const CloseModalWrapper = styled.div`
  width: 960px;
  height: 35px;
  justify-content: flex-end;
  display: flex;
  position: absolute;
`;

export const FormContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 35px 40px 20px 50px;
`;

export const ForgotPassword = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  color: ${(props) => props.theme.colors.apricot};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  cursor: pointer;
`;

export const SignUp = styled(Link)`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  text-decoration: underline;
  color: ${(props) => props.theme.colors.cello};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
`;

export const CatContainer = styled.div`
  width: 50%;
  background: ${(props) => props.theme.colors.porcelain};
  border-radius: 0 8px 8px 0;
  display: flex;
  flex-direction: column;
  padding: 45px 10px 40px 10px;
  align-items: center;
`;

export const Image = styled.img`
  width: 375px;
  height: 100%;
  margin: 0 auto 15px auto;
`;

export const TextBelowImage = styled.p`
  width: 70%;
  display: flex;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  text-align: center;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  margin: 10px auto 20px auto;
`;

export const SuccessMessage = styled.p`
  width: 360px;
  font-size: 18px;
  text-align: center;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  margin-top: 120px;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const SliderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Slick = styled(Slider)`
  width: 100%;
  .slick-slider .slick-slide > div {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .slick-list > div {
    margin-left: 0;
  }

  .slick-dots li {
    margin: 0;
  }

  .slick-dots li button:before {
    font-size: 10px;
    color: ${(props) => props.theme.colors.cello};
  }

  .slick-next {
    right: 0;
    border-radius: 100%;
    box-shadow: 0px 2px 6px ${(props) => props.theme.colors.shadow};
    background-color: ${(props) => props.theme.colors.white};
  }
  .slick-prev {
    border-radius: 100%;
    box-shadow: 0px 2px 6px ${(props) => props.theme.colors.shadow};
    background-color: ${(props) => props.theme.colors.white};
    z-index: 10;
    left: 0;
  }
`;
export const Slide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;
`;
