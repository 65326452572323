/* eslint-disable */
import { createSelector } from 'reselect';
import { ITextVariable } from 'views/Video/types';

const getSharedUser = (state: any) => state.sharedUser;

const getSharedUserSelector = () => createSelector([getSharedUser], (sharedUser) => sharedUser);
export default getSharedUserSelector;

export const getMCTextVariables = (textVariables: ITextVariable[]) =>
  createSelector([getSharedUser], (sharedUser) => {
    return textVariables.map((textVariable) => {
      sharedUser.sharedData.forEach((value) => {
        if (value.textId === textVariable._id) {
          Object.entries(sharedUser.mcUserFields).forEach((field) => {
            if (field[0] === value.valueArray) {
              textVariable.value =
                field[0] === 'ADDRESS' ? (field[1] as string[])['addr1'] : field[1];
            }
          });
        }
      });
      return textVariable;
    });
  });
