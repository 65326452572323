import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'SHARELAUNCH', {
  TITLECSV: 'Your campaign was successfully saved.',
  TITLESHARECSV:
    'Choose if to share your campaign on a 1:1 basis with your contacts or select all to send it through BMS',
  TITLEMAILCHIMP: 'Your campaign was successfully saved.',
  TITLESUCCESS: 'Your campaign was successfully shared.',
  TITLEPREVIEW: 'Your BMS campaign preview',
  TITLEMAILCHIMPFORM: 'Define and save your campaign data',
  TITLEMAILCHIMPFORMSAVE: 'Your campaign was successfully saved.',
  SUBTITLEOPTIONS: 'Download the CSV to use in your email sender or share your campaign.',
});
