import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'SHARESELECT', {
  VERIFY: 'Verify that your CSV fields are correct and match the required fields.',
  REQUIRED: 'Required fields for this template:',
  LISTS: 'Lists and segments',
  REQUIREDFIELDS: '#Name | #Items | #Discount',
  SELECTED: 'The selected segment has the following fields',
  CSVFIELDS: 'CSV Fields',
});
