import React, { FC, useEffect, useState } from 'react';

import { PUBLIC_URL } from 'configs';
import { isValidColor } from 'helpers/inputHelper/inputHelper';
import { IObject } from 'helpers/types';
import i18n from 'i18next';
import { IColorVariable } from 'views/Video/types';

import { colors } from './colors';
import './i18n';
import {
  Arrow,
  Color,
  ColorPickerContainer,
  ColorSeparetorLine,
  ColorValueContainer,
  Colors,
  CurrentColor,
  HexText,
  Input,
  LeftContainer,
  Line,
  RightContainer,
  Title,
  VariableContainer,
  VariableDataContainer,
  VariableName,
} from './styles';
import { IProps } from './types';

const ColorsTab: FC<IProps> = (props) => {
  const [isVisible, setIsVisible] = useState('');
  const [colorTexts, setColorTexts] = useState<IObject>({});

  useEffect(() => {
    props.colorVariables.forEach((variable) => {
      setColorTexts((prevState) => ({
        ...prevState,
        [variable._id]: variable.value,
      }));
    });
  }, [props.colorVariables]);

  const handleOnBlur = (
    event: React.ChangeEvent<HTMLInputElement>,
    colorId: string,
    currentColor: string
  ) => {
    const color = event.target.value;
    if (!isValidColor(color)) {
      setColorTexts((prevState) => ({
        ...prevState,
        [colorId]: currentColor,
      }));
    }
  };

  const handleInputChangeColor = (event: React.ChangeEvent<HTMLInputElement>, colorId: string) => {
    const color = event.target.value;
    if (isValidColor(color)) {
      props.onSubmitColor(color, colorId);
    }
    setColorTexts((prevState) => ({ ...prevState, [colorId]: color }));
  };

  const handleColorSubmit = (color: string, colorId: string) => {
    props.onSubmitColor(color, colorId);
    setColorTexts((prevState) => ({ ...prevState, [colorId]: color }));
  };

  return (
    <>
      <Title>{i18n.t('COLORS:TITLE')}</Title>
      <Line />
      {props.colorVariables.map((colorVariable: IColorVariable, index: number) => {
        return (
          <VariableContainer key={colorVariable._id}>
            <VariableDataContainer
              onClick={() =>
                setIsVisible((prevState) =>
                  prevState === colorVariable._id ? '' : colorVariable._id
                )
              }
            >
              <LeftContainer>
                <CurrentColor color={colorVariable.value} />
                <VariableName>{colorVariable.textName}</VariableName>
              </LeftContainer>
              <RightContainer>
                {isVisible === colorVariable._id ? (
                  <Arrow src={`${PUBLIC_URL}/images/arrow-up.svg`} />
                ) : (
                  <Arrow src={`${PUBLIC_URL}/images/arrow-down.svg`} />
                )}
              </RightContainer>
            </VariableDataContainer>
            {isVisible === colorVariable._id && (
              <ColorPickerContainer>
                <ColorValueContainer>
                  <HexText>{i18n.t('COLORS:HEXRGB')}</HexText>
                  <Input
                    maxLength={7}
                    onBlur={(e) => handleOnBlur(e, colorVariable._id, colorVariable.value)}
                    value={colorTexts[colorVariable._id] as string}
                    onChange={(e) => handleInputChangeColor(e, colorVariable._id)}
                  />
                </ColorValueContainer>
                <Colors>
                  {colors.map((color: string, i: number) => {
                    return (
                      <Color
                        color={color}
                        onClick={() => handleColorSubmit(color, colorVariable._id)}
                        key={i}
                      />
                    );
                  })}
                </Colors>
                {index !== props.colorVariables.length - 1 && <ColorSeparetorLine />}
              </ColorPickerContainer>
            )}
          </VariableContainer>
        );
      })}
    </>
  );
};

export default ColorsTab;
