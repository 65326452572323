import { ColorVariableType } from 'enums/colorVariable.enum';
import { hexToRgb } from 'helpers/hexToRGB.helper';
import { DataPath, IDataLayer } from 'interfaces/layer.interface';
import { IColorRGB, IColorVariable, ITextVariable } from 'views/Video/types';

export const replaceTexts = (
  dataLayer: IDataLayer,
  texts: ITextVariable[],
  colors: IColorVariable[]
): DataPath => {
  const textToReplace = texts;
  const dataCopy = { ...dataLayer };
  const textsColors: IColorVariable[] = colors.filter(
    (color: IColorVariable) => color.type === ColorVariableType.Text
  );
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const dataAssets = dataCopy.assets.map((dataAsset: any) => {
    let dataAssetLayers;
    if ((dataAsset.id as string).split('_')[0] === 'comp') {
      dataAssetLayers = dataAsset.layers.map((dataAssetLayer) => {
        textToReplace.forEach((text) => {
          if (dataAssetLayer.nm === text.name) {
            const textColorId: string = text.name.split('_')[2];
            const color = textsColors.find((color: IColorVariable) => color.name === textColorId);
            let textColorRGB: IColorRGB | null = { r: 0, g: 0, b: 0 };
            if (color) {
              textColorRGB = hexToRgb(color.value);
            }
            if (dataAssetLayer.t && dataAssetLayer.t.d && dataAssetLayer.t.d.k) {
              dataAssetLayer.t.d.k[0].s.t = text.value;
              if (textColorRGB) {
                dataAssetLayer.t.d.k[0].s.fc[0] = textColorRGB.r;
                dataAssetLayer.t.d.k[0].s.fc[1] = textColorRGB.g;
                dataAssetLayer.t.d.k[0].s.fc[2] = textColorRGB.b;
              }
            }
          }
        });
        return dataAssetLayer;
      });
    }
    dataAsset.layers = dataAssetLayers;
    return dataAsset;
  });
  dataCopy.assets = dataAssets;
  return dataCopy as DataPath;
};
