import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'METRICS', {
  QUICK: 'Quick Stats',
  WATCHTIME: 'Total watch time (min)',
  VIEWS: '# VIEWS (COUNT)',
  AVERAGEVIEW: 'Average view duration (sec)',
  SOUNDPLAY: 'Total sound plays',
  SUMMARY: 'Watch % Summary',
  COUNT: 'PLAY COUNT',
  DURATION: 'DURATION',
  NOVIEWS:
    'Your campaign has not been launched yet, so there is no visualization data to show. Launch it to access useful stats!',
});
