import { IAPIError } from 'interfaces/api.interface';
import { AnyAction } from 'redux';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getMetricId } from 'redux/selectors/metric.selector';
import * as metricServices from 'service/metric.service';
import * as shareService from 'service/share.service';

import * as metricActions from '../actions/metric.actions';
import * as constants from '../constants/metrics.actions.constants';

function* getCampaignMetrics(action: AnyAction) {
  try {
    const response = yield call(metricServices.getCampaignMetrics, action.id);
    yield put(metricActions.onInitializeMetricsDashboardSucceed(response));
  } catch (err) {
    yield put(metricActions.onInitializeMetricsDashboardFailed());
  }
}

function* watchVideoTimePlayed(action: AnyAction) {
  try {
    const response = yield call(shareService.createMetrics, action.shareId, action.campaignId);
    yield put(metricActions.onCreateMetricsSucceed(response));
  } catch (e) {
    const errorMessage = (e as IAPIError).errorMessage;
    if (errorMessage === 'CREATE_METRICS_FAILED') {
      yield put(metricActions.onCreateMetricsFailed());
    } else {
      yield put(metricActions.getVideoTimeFailed());
    }
  }
}

function* setVideoTimePlayed(action: AnyAction) {
  try {
    const id = yield select(getMetricId());
    if (id) {
      yield call(shareService.setVideoTimePlayed, id, action.lastSecondPlayed);
      yield put(metricActions.onUpdateMetricsSucceed());
    }
  } catch (e) {
    yield put(metricActions.onUpdateMetricsFailed());
  }
}

export default all([
  takeLatest(constants.ON_INITIALIZE_METRICS_DASHBOARD, getCampaignMetrics),
  takeLatest(constants.VIDEO_STARTED, watchVideoTimePlayed),
  takeLatest(constants.ON_UPDATE_METRICS_REQUESTED, setVideoTimePlayed),
]);
