import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LogInText = styled.h2`
  font-size: 20px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  color: ${(props) => props.theme.colors.fiord};
  margin-bottom: 30px;
`;

export const RandomText = styled.p`
  font-size: 14px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => props.theme.colors.fiord};
`;
