import styled from 'styled-components';

import { IWrapper } from './types';

export const Wrapper = styled.div<IWrapper>`
  display: ${(props) => (props.asset ? 'block' : 'none')};
`;

export const CropperWrapper = styled.div`
  position: relative;
  width: 350px;
  height: 215px;
`;
