import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { FC } from 'react';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import './style.css';
import { IEditorProps } from './types';

const Editor: FC<IEditorProps> = (props) => {
  return (
    <div>
      <CKEditor
        type=""
        name={props.name}
        editor={ClassicEditor}
        data={props.value}
        onChange={(event, editor) => {
          const data = editor.getData();
          props.onChange(data, props.index);
        }}
      />
    </div>
  );
};

export default Editor;
