import { ReactComponent as grid } from 'assets/awesome-grid.svg';
import { ReactComponent as list } from 'assets/awesome-list.svg';
import { ReactComponent as sortAlpha } from 'assets/awesome-sort-alpha-down.svg';
import { ReactComponent as arrow } from 'assets/icon_filter_campaign-01.svg';
import styled from 'styled-components';

export const CampaignContainerRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
`;

export const CampaignContainerColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 90px;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  font-size: 20px;
  text-align: left;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.fiord};
  opacity: 1;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
`;

export const MyCampaignsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 38px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
  padding-bottom: 5px;
  border-bottom: 1px solid ${(props) => props.theme.colors.porcelain};
`;

export const Filters = styled.div`
  width: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const FilterAlpha = styled(sortAlpha)`
  width: 20px;
  height: 16px;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

export const FilterGrid = styled(grid)`
  width: 20px;
  height: 16px;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

export const FilterList = styled(list)`
  width: 20px;
  height: 16px;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

export const TemplateName = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  margin-top: 15px;
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const SortContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.porcelain};
  padding-bottom: 5px;
`;

export const LeftContainer = styled.div`
  width: 65%;
  height: 100%;
  display: flex;
`;

export const RightContainer = styled.div`
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const SortText = styled.p`
  font-size: 14px;
  text-align: center;
  margin-right: 80px;
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  cursor: pointer;
`;

export const IconArrow = styled(arrow)`
  width: 10px;
  height: 5px;
  position: absolute;
  cursor: pointer;
  top: 8px;
  right: 62px;
`;

export const IconArrowUp = styled(arrow)`
  width: 10px;
  height: 5px;
  transform: rotate(180deg);
  position: absolute;
  cursor: pointer;
  top: 8px;
  right: 62px;
`;

export const LoadingPointsWrapper = styled.div`
  width: 100%;
  height: 200px;
`;
