export const ON_INITIALIZE_TEMPLATES_REQUESTED = 'ON_INITIALIZE_TEMPLATES_REQUESTED';

export const ON_INITIALIZE_TEMPLATES_SUCCEEDED = 'ON_INITIALIZE_TEMPLATES_SUCCEEDED';

export const ON_INITIALIZE_TEMPLATES_FAILED = 'ON_INITIALIZE_TEMPLATES_FAILED';

export const ON_INITIALIZE_CAMPAIGN_USER_REQUESTED = 'ON_INITIALIZE_CAMPAIGN_USER_REQUESTED';

export const ON_INITIALIZE_CAMPAIGN_USER_SUCCEEDED = 'ON_INITIALIZE_CAMPAIGN_USER_SUCCEEDED';

export const ON_INITIALIZE_CATEGORIES_SUCCEEDED = 'ON_INITIALIZE_CATEGORIES_SUCCEEDED';

export const ON_INITIALIZE_CATEGORIES_FAILED = 'ON_INITIALIZE_CATEGORIES_FAILED';

export const ON_SELECT_CATEGORY_REQUESTED = 'ON_SELECT_CATEGORY_REQUESTED';

export const ON_SELECT_PREVIEW_REQUESTED = 'ON_SELECT_PREVIEW_REQUESTED';

export const ON_SELECT_PREVIEW_SUCCEEDED = 'ON_SELECT_PREVIEW_SUCCEEDED';

export const ON_SELECT_PREVIEW_FAILED = 'ON_SELECT_PREVIEW_FAILED';
export const ON_SELECT_TEMPLATE_NEW_BMS_REQUESTED =
  'ON_SELECT_TEMPLATE_NEW_BMS_REQUESTED_REQUESTED';
export const ON_SELECT_TEMPLATE_NEW_BMS_SUCCEED = 'ON_SELECT_TEMPLATE_NEW_BMS_SUCCEED';
export const ON_SELECT_TEMPLATE_NEW_BMS_FAILED = 'ON_SELECT_TEMPLATE_NEW_BMS_FAILED';
export const ON_SEND_EMAIL_REQUESTED = 'ON_SEND_EMAIL_REQUESTED';
export const ON_SEND_EMAIL_SUCCEED = 'ON_SEND_EMAIL_SUCCEED';
export const ON_SEND_EMAIL_FAILED = 'ON_SEND_EMAIL_FAILED';
export const ON_SEND_PERSONALIZED_CAMPAIGN_EMAIL_REQUESTED =
  'ON_SEND_PERSONALIZED_CAMPAIGN_EMAIL_REQUESTED';
export const ON_SEND_PERSONALIZED_CAMPAIGN_EMAIL_SUCCEED =
  'ON_SEND_PERSONALIZED_CAMPAIGN_EMAIL_SUCCEED';
export const ON_SEND_PERSONALIZED_CAMPAIGN_EMAIL_FAILED =
  'ON_SEND_PERSONALIZED_CAMPAIGN_EMAIL_FAILED';
export const ON_CHANGE_IS_SUCCESS = 'ON_CHANGE_IS_SUCCESS';
export const ON_REMOVE_VIDEO_DATA = 'ON_REMOVE_VIDEO_DATA';
