/* eslint-disable */
import { generateBMSLink } from 'helpers/BMSLinkGenerator.helper';
import { createSelector } from 'reselect';

const getShare = (state: any) => state.share;

const getShareSelector = () => createSelector([getShare], (share) => share);
export default getShareSelector;

export const getExportData = () =>
  createSelector([getShare], (share) => {
    const exportData = share.shareData.map((data: any) => {
      const values = data.shareData.map((value: any) => {
        return { header: value.header, value: value.valueArray };
      });
      return {
        id: data._id,
        values: values,
        bms_share_link: generateBMSLink(data._id),
      };
    });
    return exportData;
  });

export const getEmailStatus = () => createSelector([getShare], (share) => share.success);

export const getShareData = () => createSelector([getShare], (share) => share.shareData);

export const getShareCampaignToCopy = () =>
  createSelector([getShare], (share) => share.shareCampaignToCopy);

export const getLoading = () => createSelector([getShare], (share) => share.loading);
