import { ReactComponent as NoImageIcon } from 'assets/ilus-no-image.svg';
import { PUBLIC_URL } from 'configs';
import styled from 'styled-components';

import { IImage, ISubtitle } from './types';

export const Title = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  font-weight: 400;
  text-align: left;
  margin-bottom: 10px;
`;

export const Line = styled.div`
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid ${(props) => props.theme.colors.white};
  opacity: 1;
`;

export const Subtitle = styled.p<ISubtitle>`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 15px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  font-weight: 400;
  text-align: ${(props) => props.noImage && 'center'};
`;

export const Image = styled.div<IImage>`
  width: 235px;
  height: 150px;
  border-radius: 4px;
  margin-bottom: 30px;
  cursor: pointer;
  background-image: ${(props) =>
    props.path ? "url('" + props.path + "')" : "url('https://via.placeholder.com/400')"};
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
  margin-bottom: 30px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.3s linear;
  ${Image}:hover & {
    background: #142b4180 0% 0% no-repeat;
  }
`;

export const EditIcon = styled.div`
  display: none;
  position: absolute;
  width: 15px;
  height: 15px;
  margin: 5px 0 0 210px;
  transition: all 0.3s linear;
  cursor: pointer;
  background-image: url(${PUBLIC_URL}/images/edit-pencil.svg);
  z-index: 1;
  ${Image}:hover & {
    display: block;
  }
`;

export const NoImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
`;

export const NoImage = styled(NoImageIcon)`
  width: 150px;
  height: auto;
  margin: 20px;
`;
