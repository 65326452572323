import React, { FC, useCallback, useState } from 'react';

import CloseModalButton from 'components/CloseModalButton/CloseModalButton';
import Dropzone from 'components/Dropzone/Dropzone';
import { IAreaPixels } from 'components/Dropzone/types';
import { PUBLIC_URL } from 'configs';
import getCroppedImg from 'helpers/imageCropper.helper';
import i18n from 'i18next';
import { IAsset } from 'interfaces/asset.interface';

import './i18n';
import {
  ApplyButton,
  ButtonWrapper,
  CloseModalWrapper,
  CropperSlider,
  DropzoneContainer,
  IconImage,
  IconWrapper,
  ModalContainer,
  ModalSubtitle,
  ModalTitle,
  ModalWrapper,
  SliderWrapper,
  UploadText,
} from './styles';
import { IProps } from './types';

const ImageEditModal: FC<IProps> = (props) => {
  const [zoom, setZoom] = useState(2);
  const [image, setImage] = useState(props.thumbnail?.path);
  const [file, setFile] = useState<File>();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<IAreaPixels>();
  const [clicked, setClicked] = useState<boolean>(false);

  const onSliderChange = (_, zoom: number | number[]) => {
    if (image) {
      setZoom(zoom as number);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onClickSaveImage = useCallback(async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels);
    const newFile: IAsset = {
      originalname: file ? file.name : props.thumbnail.originalname,
      filename: file ? file.name : props.thumbnail.filename,
      mimetype: file ? file.type : props.thumbnail.mimetype,
      size: file ? file.size.toString() : props.thumbnail.size,
      src: croppedImage,
    };
    if (props.onSubmitImageVariable && props.imageVariable) {
      props.onSubmitImageVariable(newFile, props.imageVariable._id);
    } else if (props.onSubmitImageAvatar && props.setIsLoading) {
      props.onSubmitImageAvatar(newFile);
      props.setIsLoading(true);
    }
    props.onClose();
  }, [croppedAreaPixels]);

  const handleClickeUploadNew = () => {
    setClicked(true);
  };

  return (
    <ModalWrapper>
      <ModalContainer>
        <CloseModalWrapper>
          <CloseModalButton onClick={props.onClose} />
        </CloseModalWrapper>
        <ModalTitle>{i18n.t('IMAGE:MODAL_TITLE')}</ModalTitle>
        <DropzoneContainer>
          <ModalSubtitle>{i18n.t('IMAGE:MODAL_SUBTITLE')}</ModalSubtitle>
          <UploadText onClick={handleClickeUploadNew}>{i18n.t('IMAGE:NEW')}</UploadText>
        </DropzoneContainer>
        <Dropzone
          image={image}
          setImage={setImage}
          zoom={zoom}
          setZoom={setZoom}
          file={file}
          setFile={setFile}
          imageVariable={props.imageVariable}
          onCropComplete={onCropComplete}
          setError={props.setError}
          clicked={clicked}
          setClicked={setClicked}
          isProfile={!!props.onSubmitImageAvatar}
        />
        <SliderWrapper>
          <IconWrapper>
            <IconImage image={`${PUBLIC_URL}/images/edit-image-modal-img.svg`} size="28px" />
          </IconWrapper>
          <CropperSlider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            track={false}
            onChange={onSliderChange}
          />
          <IconWrapper>
            <IconImage image={`${PUBLIC_URL}/images/edit-image-modal-img.svg`} size="40px" />
          </IconWrapper>
        </SliderWrapper>
        <ButtonWrapper>
          <ApplyButton disable={!image} onClick={onClickSaveImage}>
            {i18n.t('IMAGE:APPLY_BUTTON')}
          </ApplyButton>
        </ButtonWrapper>
      </ModalContainer>
    </ModalWrapper>
  );
};
export default ImageEditModal;
