import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'LAUNCHMAILCHIMP', {
  DRAFT: 'Create draft campaign',
  SAVE: 'Create and send campaign',
  WHAT: 'What do you want to do?',
  CREATEDRAFTTEXT:
    'Create a draft of your campaign in Mailchimp. You can log in to Mailchimp at any time and find it there.',
  CREATETEXT:
    'Send your BMS campaign now - remember your campaign will be created in your Mailchimp account and sent through BMS.',
});
