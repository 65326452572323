import React, { FC, useCallback, useEffect, useState } from 'react';

import CloseModalButton from 'components/CloseModalButton/CloseModalButton';
import Dropzone from 'components/Dropzone/Dropzone';
import { PUBLIC_URL } from 'configs';
import i18n from 'i18next';
import { IAsset, ICropVideoSettings } from 'interfaces/asset.interface';

import './i18n';
import {
  ApplyButton,
  ButtonWrapper,
  CloseModalWrapper,
  CropperSlider,
  DropzoneContainer,
  IconImage,
  IconWrapper,
  ModalContainer,
  ModalSubtitle,
  ModalTitle,
  ModalWrapper,
  SliderWrapper,
  UploadText,
} from './styles';
import { IProps } from './types';

const VideoEditModal: FC<IProps> = (props) => {
  const [clicked, setClicked] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [bufferFile, setBufferFile] = useState<string>();
  const [video, setVideo] = useState(props.video?.path || '');
  const [zoom, setZoom] = useState(2);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<ICropVideoSettings>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  const onClickSaveVideo = () => {
    const id = props.originalVideo._id ? props.originalVideo._id : props.video.asset;
    if (id) {
      props.onSubmitVideo(id, croppedAreaPixels);
    }
  };

  useEffect(() => {
    if (props.videoCropSucceed) {
      props.onClose();
    }
  }, [props.videoCropSucceed]);

  const onClickedUploadNew = () => {
    setClicked(true);
  };

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onSliderChange = (e, zoom) => {
    if (video) {
      setZoom(zoom);
    }
  };

  useEffect(() => {
    if (file) {
      const newFile: IAsset = {
        originalname: file.name,
        filename: file.name,
        mimetype: file.type,
        size: file.size.toString(),
        src: bufferFile ? bufferFile : '',
      };
      props.uploadOriginalVideo(newFile);
    }
  }, [file]);

  return (
    <ModalWrapper>
      <ModalContainer>
        <CloseModalWrapper>
          <CloseModalButton onClick={props.onClose} />
        </CloseModalWrapper>
        <ModalTitle>{i18n.t('VIDEO:MODAL_TITLE')}</ModalTitle>
        <DropzoneContainer>
          <ModalSubtitle>{i18n.t('VIDEO:MODAL_SUBTITLE')}</ModalSubtitle>
          <UploadText onClick={onClickedUploadNew}>{i18n.t('VIDEO:NEW')}</UploadText>
        </DropzoneContainer>
        <Dropzone
          video={video}
          setVideo={setVideo}
          zoom={zoom}
          setZoom={setZoom}
          file={file}
          setFile={setFile}
          videoVariable={props.video}
          onCropComplete={onCropComplete}
          setError={props.setError}
          clicked={clicked}
          setClicked={setClicked}
          originalVideo={props.originalVideo}
          setBufferFile={setBufferFile}
          loading={props.loading}
        />
        <SliderWrapper>
          <IconWrapper>
            <IconImage image={`${PUBLIC_URL}/images/edit-image-modal-img.svg`} size="28px" />
          </IconWrapper>
          <CropperSlider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            track={false}
            onChange={onSliderChange}
          />
          <IconWrapper>
            <IconImage image={`${PUBLIC_URL}/images/edit-image-modal-img.svg`} size="40px" />
          </IconWrapper>
        </SliderWrapper>
        <ButtonWrapper>
          <ApplyButton disable={!video} onClick={onClickSaveVideo}>
            {i18n.t('VIDEO:APPLY_BUTTON')}
          </ApplyButton>
        </ButtonWrapper>
      </ModalContainer>
    </ModalWrapper>
  );
};
export default VideoEditModal;
