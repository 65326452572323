import styled from 'styled-components';

export const ArrowWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Next = styled.img`
  width: 35px;
  height: 35px;
`;

export const Prev = styled.img`
  transform: rotate(180deg);
  width: 35px;
  height: 35px;
`;
