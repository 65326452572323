/* eslint-disable */
import { createSelector } from 'reselect';
import { ICategory, ITemplate } from 'views/TemplateList/types';

const getTemplates = (state: any) => state.template;

const getTemplateSelector = () => createSelector([getTemplates], (templates) => templates);

export default getTemplateSelector;

export const getTemplatesFiltered = () =>
  createSelector([getTemplates], (templates) => {
    const filteredTemplates = templates.templates.filter((template: ITemplate) => {
      return (
        templates.categorySelected === 'All' ||
        (template.category && templates.categorySelected === template.category.name)
      );
    });
    filteredTemplates.sort((a, b) => (a.title > b.tilte ? 1 : -1));
    return filteredTemplates;
  });

export const getTemplate = () => createSelector([getTemplates], (templates) => templates.template);

export const getCategoriesFiltered = () =>
  createSelector([getTemplates], (templates) => {
    const filteredCategories: ICategory[] = [];
    templates.templates.forEach((template: ITemplate) => {
      if (
        template.category &&
        template.category.published &&
        !filteredCategories.some((category) => category.name === template.category?.name)
      ) {
        filteredCategories.push(template.category);
      }
    });
    filteredCategories.sort((a, b) => (a.order > b.order ? 1 : -1));
    filteredCategories.splice(0, 0, {
      name: 'All',
      published: true,
      order: 0,
    });

    return filteredCategories;
  });

export const getPreviewTemplate = () =>
  createSelector([getTemplates], (templates: ITemplate) => {
    return {
      layers: templates.layers,
      textVariables: templates.textVariables,
      colorVariables: templates.colorVariables,
      imageVariables: templates.imageVariables,
      audio: templates.audio as string,
      video: templates.video as string,
      scenes: templates.scenes,
      duration: templates.duration,
    };
  });

export const getTemplateName = (id: string) =>
  createSelector([getTemplates], (templates: { templates: ITemplate[] }) => {
    return templates?.templates.find((template: ITemplate) => template._id === id)?.title || '';
  });

export const getTemplateDescription = (id: string) =>
  createSelector([getTemplates], (templates: { templates: ITemplate[] }) => {
    return (
      templates?.templates.find((template: ITemplate) => template._id === id)?.description || ''
    );
  });
