import React, { FC } from 'react';

import i18n from 'i18next';

import './i18n';
import { TemplateImage, Title } from './styles';
import { IProps } from './types';

const TemplateTab: FC<IProps> = (props) => {
  return (
    <>
      <Title>{i18n.t('TEMPLATE:RECENTLY')}</Title>
      <TemplateImage src={props.thumbnail}></TemplateImage>
    </>
  );
};

export default TemplateTab;
