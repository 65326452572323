import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { onInitializeMetricsDashboard, onRemoveMetricsData } from 'redux/actions/metric.actions';
import getMetricsSelector from 'redux/selectors/metric.selector';
import Metrics from 'views/Metrics/Metrics';

import { MetricsDashboardProps } from './types';

const MetricsDashboard: FC<MetricsDashboardProps> = (props) => {
  const dispatch = useDispatch();
  const campaignId = props.match.params.campaignId;
  const metrics = useSelector(getMetricsSelector());

  useEffect(() => {
    dispatch(onInitializeMetricsDashboard(campaignId));
  }, [dispatch, campaignId]);

  const onRemoveData = () => {
    dispatch(onRemoveMetricsData());
  };

  return (
    <div>
      {!metrics.isLoading && <Metrics metrics={metrics.metricData} onRemoveData={onRemoveData} />}
    </div>
  );
};
export default MetricsDashboard;
