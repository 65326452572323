import React, { FC, Fragment } from 'react';

import i18n from 'i18next';

import './i18n';
import { TextInformationContainer, WarningImage, WarningSubTitle, WarningTitle } from './styles';

const WarningMobile: FC = () => {
  return (
    <Fragment>
      <TextInformationContainer>
        <WarningImage />
        <WarningTitle>{i18n.t('WARNING:SWITCH')}</WarningTitle>
        <WarningSubTitle>{i18n.t('WARNING:WARNING')}</WarningSubTitle>
      </TextInformationContainer>
    </Fragment>
  );
};

export default WarningMobile;
