export const ON_CREATE_METRICS_SUCCEED = 'ON_CREATE_METRICS_SUCCEED';

export const ON_CREATE_METRICS_FAILED = 'ON_CREATE_METRICS_FAILED';

export const GET_VIDEO_TIME_PLAYED_FAILED = 'GET_VIDEO_TIME_PLAYED_FAILED';

export const VIDEO_STARTED = 'VIDEO_STARTED';

export const ON_INITIALIZE_VIDEO_MEASURING = 'ON_INITIALIZE_VIDEO_MEASURING';

export const ON_SELECT_AUDIO_ICON = 'ON_SELECT_AUDIO_ICON';

export const ON_SELECT_AUDIO_ICON_SUCCEED = 'ON_SELECT_AUDIO_ICON_SUCCEED';

export const ON_SELECT_AUDIO_ICON_FAILED = 'ON_SELECT_AUDIO_ICON_FAILED';

export const ON_REDIRECT_TO_METRICS_DASHBOARD = 'ON_REDIRECT_TO_METRICS_DASHBOARD';

export const ON_INITIALIZE_METRICS_DASHBOARD = 'ON_INITIALIZE_METRICS_DASHBOARD';

export const ON_INITIALIZE_METRICS_DASHBOARD_SUCCEED = 'ON_INITIALIZE_METRICS_DASHBOARD_SUCCEED';

export const ON_INITIALIZE_METRICS_DASHBOARD_FAILED = 'ON_INITIALIZE_METRICS_DASHBOARD_FAILED';

export const ON_UPDATE_METRICS_REQUESTED = 'ON_UPDATE_METRICS_REQUESTED';

export const ON_UPDATE_METRICS_SUCCEED = 'ON_UPDATE_METRICS_SUCCEED';

export const ON_UPDATE_METRICS_FAILED = 'ON_UPDATE_METRICS_FAILED';

export const ON_CLICK_CTA = 'ON_CLICK_CTA';

export const ON_CLICK_CTA_SUCCEED = 'ON_CLICK_CTA_SUCCEED';

export const ON_CLICK_CTA_FAILED = 'ON_CLICK_CTA_FAILED';

export const ON_REMOVE_METRICS_DATA = 'ON_REMOVE_METRICS_DATA';
