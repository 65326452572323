/* eslint-disable */
import { createSelector } from 'reselect';

const getMetrics = (state: any) => state.metrics;

const getMetricsSelector = () => createSelector([getMetrics], (metrics) => metrics);

export const getMetricId = () => createSelector([getMetrics], (metrics) => metrics.id);

export default getMetricsSelector;
