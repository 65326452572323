import React, { FC } from 'react';

import CloseModalButton from 'components/CloseModalButton/CloseModalButton';
import { PUBLIC_URL } from 'configs';

import { CloseModalWrapper, ContentWrapper, ModalContainer, ModalWrapper, Video } from './styles';
import { IProps } from './types';

const VideoModal: FC<IProps> = (props) => {
  return (
    <ModalWrapper>
      <ModalContainer>
        <CloseModalWrapper>
          <CloseModalButton onClick={props.onClose} />
        </CloseModalWrapper>
        <ContentWrapper>
          <Video controls src={`${PUBLIC_URL}/bms_hiw.mp4`} autoPlay={props.autoplay} />
        </ContentWrapper>
      </ModalContainer>
    </ModalWrapper>
  );
};
export default VideoModal;
