import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
  z-index: 150;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
`;

export const ModalContainer = styled.div`
  width: 870px;
  height: 440px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0px 2px 6px #00000029;
  background: ${(props) => props.theme.colors.white};
`;

export const CloseModalWrapper = styled.div`
  width: 870px;
  height: 35px;
  justify-content: flex-end;
  display: flex;
  position: absolute;
`;

export const ContentWrapper = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Video = styled.video`
  width: 700px;
  height: 405px;
  outline: none;
`;
