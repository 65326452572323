import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { IValidPass } from './types';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
`;

export const WrapperForgot = styled.div`
  height: 100%;
  min-height: calc(100vh - 201px);
  background: ${(props) => props.theme.colors.aena};
`;

export const Button = styled.button`
  width: 201px;
  height: 40px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.cello};
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  transition: all 0.2s ease-out;
  &:hover {
    background: ${(props) => props.theme.colors.bigStone};
  }
`;

export const LogInText = styled.h2`
  font-size: 20px;
  text-align: center;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  color: ${(props) => props.theme.colors.fiord};
  margin-bottom: 68px;
  margin-top: 27px;
`;

export const ErrorText = styled.p`
  font-size: 11px;
  height: 11px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  color: ${(props) => props.theme.colors.red};
  text-align: left;
`;

export const NormalLabel = styled.p`
  width: 44%;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  color: ${(props) => props.theme.colors.fiord};
  margin-left: 3px;
  margin-bottom: 10px;
  text-align: left;
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

export const Inputs = styled.input`
  width: 443px;
  height: 44px;
  padding-left 9px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  border: 1px solid ${(props) => props.theme.colors.porcelain};
  border-radius: 6px;
  margin-bottom: 6px;
  margin-top: 10px;
`;

export const ButtonContainer = styled.div`
  width: 22%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TokenInvalid = styled.p`
  font-size: 20px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  color: ${(props) => props.theme.colors.fiord};
  margin-bottom: 30px;
`;

export const ButtonHome = styled(Link)`
  width: 145px;
  height: 40px;
  text-decoration: none;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.cello};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  &:hover {
    background: ${(props) => props.theme.colors.apricot};
  }
`;

export const IconContainer = styled.div`
  width: 300px;
  height: 60px;
  display: flex;
  margin-bottom: 40px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const ContainerError = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 70vh;
`;

export const TextPasswordRequirements = styled.p<IValidPass>`
  width: 44%;
  font-size: 11px;
  text-align: left;
  color: ${(props) => (props.validPassword ? props.theme.colors.red : props.theme.colors.bombay)};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
`;

export const IconBlock = styled.img`
  position: absolute;
  width: 21px;
  height: 19px;
  cursor: pointer;
  top: 25px;
  left: 700px;
`;
