import React, { FC } from 'react';

import i18n from 'i18next';

import './i18n';
import { EmptyContainer, Rocket, TextContainer, TextSubtitle, TextTitle } from './styles';

const NoCampaigns: FC = () => {
  return (
    <EmptyContainer>
      <Rocket />
      <TextContainer>
        <TextTitle>{i18n.t('NO_CAMPAIGNS:EMPTY')}</TextTitle>
        <TextSubtitle>{i18n.t('NO_CAMPAIGNS:SELECT')}</TextSubtitle>
      </TextContainer>
    </EmptyContainer>
  );
};

export default NoCampaigns;
