export const ON_INITIALIZE_PLAYER_REQUESTED = 'ON_INITIALIZE_PLAYER_REQUESTED';

export const ON_INITIALIZE_PLAYER_SUCCEDEED = 'ON_INITIALIZE_PLAYER_SUCCEDEED';

export const ON_INITIALIZE_PLAYER_FAILED = 'ON_INITIALIZE_PLAYER_FAILED';

export const ON_INITIALIZE_MAILCHIMP_PLAYER_REQUESTED = 'ON_INITIALIZE_MAILCHIMP_PLAYER_REQUESTED';

export const ON_INITIALIZE_MAILCHIMP_PLAYER_SUCCEDEED = 'ON_INITIALIZE_MAILCHIMP_PLAYER_SUCCEDEED';

export const ON_INITIALIZE_MAILCHIMP_PLAYER_FAILED = 'ON_INITIALIZE_MAILCHIMP_PLAYER_FAILED';
