import React, { FC, Fragment, useEffect, useRef, useState } from 'react';

import ModalLogin from 'components/ModalLogin/ModalLogin';
import { PUBLIC_URL } from 'configs/index';
import { CONTACT_URL, WHY_URL } from 'constants/externalUrls.constants';
import useOutsideClick from 'helpers/outsideClick.helper';
import i18n from 'i18next';

import './i18n';
import {
  ButtonWrapper,
  DataContainer,
  Email,
  IconContainer,
  Image,
  LinkToExternal,
  Links,
  LinksSelected,
  List,
  ListItems,
  LogIn,
  Name,
  OptionsList,
  OptionsListContainer,
  ProfImage,
  ProfileContainer,
  ProfilePicture,
  ProfilePictureWrapper,
  RoundContainer,
  SignUp,
  Text,
  TextContainer,
  TextContainerLogged,
  TopbarContainer,
  TopbarStyle,
} from './styles';
import { IProps } from './types';

const Topbar: FC<IProps> = (props) => {
  const [isOpen, setIsOpenLogIn] = useState<boolean>(false);
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [isSignUp, setIsSignUp] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState(false);
  const [registeredSuccesfully, setRegisteredSuccesfully] = useState<boolean>(false);
  const ref = useRef(null);

  useOutsideClick(ref, () => {
    setShowOptions(false);
  });

  const handleModal = () => {
    setIsSignUp(false);
    setIsOpenLogIn(true);
  };

  const handleModalSignUp = () => {
    setIsSignUp(true);
    setIsOpenLogIn(true);
  };

  const handleModalClose = () => {
    setRegisteredSuccesfully(false);
    setIsOpenLogIn(false);
    props.changeStatusSignUp();
    props.setErrorFalse();
  };

  const logout = () => {
    props.userLogout();
  };

  useEffect(() => {
    if (props.isLogged) {
      setIsOpenLogIn(false);
      setIsLogged(true);
    }
  }, [props.isLogged]);

  const onClickProfilePicture = () => {
    setShowOptions((prevState) => !prevState);
  };

  return (
    <Fragment>
      <TopbarStyle>
        <TopbarContainer>
          <IconContainer to="/">
            <Image src={`${PUBLIC_URL}/images/bms_logo.svg`} />
          </IconContainer>
          {isLogged ? (
            <TextContainerLogged>
              {props.isTemplate === '' ? (
                <>
                  {/* <NewBms to="/new-bms">{i18n.t("TOPBAR:NEW_BMS")}</NewBms> */}
                  <Text>
                    <Links to="/">{i18n.t('TOPBAR:TEMPLATES')}</Links>
                  </Text>
                  <Text>
                    <Links to="/my-campaigns">{i18n.t('TOPBAR:CAMPAIGNS')}</Links>
                  </Text>
                </>
              ) : (
                <>
                  <ButtonWrapper>
                    {/* <NewBms to="/new-bms">{i18n.t("TOPBAR:NEW_BMS")}</NewBms> */}
                  </ButtonWrapper>
                  <Text>
                    {props.isTemplate === 'template' ? (
                      <LinksSelected to="/">{i18n.t('TOPBAR:TEMPLATES')}</LinksSelected>
                    ) : (
                      <Links to="/">{i18n.t('TOPBAR:TEMPLATES')}</Links>
                    )}
                  </Text>
                  <Text>
                    {props.isTemplate === 'myCampaigns' ? (
                      <LinksSelected to="/my-campaigns">{i18n.t('TOPBAR:CAMPAIGNS')}</LinksSelected>
                    ) : (
                      <Links to="/my-campaigns">{i18n.t('TOPBAR:CAMPAIGNS')}</Links>
                    )}
                  </Text>
                </>
              )}
              <ProfilePictureWrapper>
                {props.userInfo.thumbnail ? (
                  <ProfilePicture
                    ref={ref}
                    onClick={onClickProfilePicture}
                    src={props.userInfo.thumbnail.path}
                  />
                ) : (
                  <ProfilePicture
                    ref={ref}
                    onClick={onClickProfilePicture}
                    src={`${PUBLIC_URL}/images/bms.jpg`}
                  />
                )}
              </ProfilePictureWrapper>
              {showOptions && (
                <OptionsListContainer>
                  <ProfileContainer>
                    {/* TODO FIXME @GUIDOG Add deafult image directly from the database when a user is created */}
                    <RoundContainer>
                      {props.userInfo.thumbnail ? (
                        <ProfImage src={props.userInfo.thumbnail.path} />
                      ) : (
                        <ProfImage src={`${PUBLIC_URL}/images/bms.jpg`} />
                      )}
                    </RoundContainer>
                    <DataContainer>
                      <Name>
                        {props.userInfo.firstName} {props.userInfo.lastName}
                      </Name>
                      <Email>{props.userInfo.email}</Email>
                    </DataContainer>
                  </ProfileContainer>
                  <OptionsList>
                    <ListItems>
                      <List to="/profile">Profile</List>
                      <List to="/" onClick={logout}>
                        Log out
                      </List>
                    </ListItems>
                  </OptionsList>
                </OptionsListContainer>
              )}
            </TextContainerLogged>
          ) : (
            <TextContainer>
              <Text>
                <LinkToExternal href={WHY_URL} target={'blank'}>
                  {i18n.t('TOPBAR:WHY')}
                </LinkToExternal>
              </Text>
              <Text>
                {props.isTemplate === 'template' ? (
                  <LinksSelected to="/">{i18n.t('TOPBAR:TEMPLATES')}</LinksSelected>
                ) : (
                  <Links to="/">{i18n.t('TOPBAR:TEMPLATES')}</Links>
                )}
              </Text>
              <Text>
                <LinkToExternal href={CONTACT_URL} target={'blank'}>
                  {i18n.t('TOPBAR:CONTACT')}
                </LinkToExternal>
              </Text>
              <LogIn onClick={handleModal}>{i18n.t('TOPBAR:LOGIN')}</LogIn>
              <SignUp onClick={handleModalSignUp}>{i18n.t('TOPBAR:SIGNUP')}</SignUp>
            </TextContainer>
          )}
        </TopbarContainer>
      </TopbarStyle>
      {isOpen && (
        <ModalLogin
          successSendEmail={props.successSendEmail}
          setErrorFalse={props.setErrorFalse}
          error={props.error}
          isSignUp={isSignUp}
          changeSignUp={setIsSignUp}
          onClose={handleModalClose}
          statusSignUp={props.statusSignUp}
          onSubmitSignUp={props.onSubmitSignUp}
          onSubmitLogin={props.onSubmitLogin}
          resetPassword={props.resetPassword}
          setRegisteredSuccesfully={setRegisteredSuccesfully}
          registeredSuccesfully={registeredSuccesfully}
        />
      )}
    </Fragment>
  );
};
export default Topbar;
