import React, { FC } from 'react';

import i18n from 'i18next';
import moment from 'moment';
import CampaignThumbnail from 'views/CampaignThumbnail/CampaignThumbnail';

import './i18n';
import {
  CampaignContainer,
  CampaignName,
  DraftDate,
  NameTooltip,
  NameTooltipText,
  SentDate,
} from './styles';
import { IProps } from './types';

const CampaignDataThumbnail: FC<IProps> = (props) => {
  return (
    <CampaignContainer>
      <CampaignThumbnail
        id={props.id}
        title={props.title}
        deleteCampaign={props.deleteCampaign}
        selectCampaign={props.selectCampaign}
        selectMetricsCampaign={props.selectMetricsCampaign}
        relaunchCampaign={props.relaunchCampaign}
        updatedAt={props.updatedAt}
        campaign={props.campaign}
        isLoadingDeleteCampaign={props.isLoadingDeleteCampaign}
        onRemoveVideoData={props.onRemoveVideoData}
      />
      <CampaignName>
        {props.title}
        <NameTooltip>
          <NameTooltipText>{props.title}</NameTooltipText>
        </NameTooltip>
      </CampaignName>
      {props.campaign.sent ? (
        <SentDate>
          {i18n.t('CAMPAIGN:SENT')} {moment(props.campaign.sent).format('MM.DD.YYYY')}
        </SentDate>
      ) : (
        <DraftDate>
          {i18n.t('CAMPAIGN:DRAFT')} {moment(props.updatedAt).format('MM.DD.YYYY')}
        </DraftDate>
      )}
    </CampaignContainer>
  );
};

export default CampaignDataThumbnail;
