const products = [
  {
    id: 0,
    name: 'Free',
    limit_0: 3,
    limit_1: 10,
    limit_2: 10,
    limit_3: 30,
  },
  {
    id: 1,
    name: 'Basic',
    limit_0: 20,
    limit_1: 100,
    limit_2: 500,
    limit_3: 365,
  },
  {
    id: 2,
    name: 'Pro',
    limit_0: 50,
    limit_1: -1,
    limit_2: 1000,
    limit_3: -1,
  },
  {
    id: 3,
    name: 'Premium',
    limit_0: -1,
    limit_1: -1,
    limit_2: 10000,
    limit_3: -1,
  },
];

export default products;
