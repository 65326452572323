import { IObject } from 'helpers/types';
import { IAsset } from 'interfaces/asset.interface';
import { AnyAction } from 'redux';
import { IUser } from 'redux/reducers/user.reducer';

import * as constants from '../constants/user.actions.constants';

export const submitLogin = (email: string, password: string): AnyAction => ({
  type: constants.USER_ON_LOGIN_REQUESTED,
  email,
  password,
});

export const submitLoginSucceeded = (token: string): AnyAction => ({
  type: constants.USER_ON_LOGIN_SUCCEEDED,
  token,
});

export const submitLoginFailed = (): AnyAction => ({
  type: constants.USER_ON_LOGIN_FAILED,
});

export const submitSignUp = (
  firstName: string,
  lastName: string,
  email: string,
  password: string
): AnyAction => ({
  type: constants.USER_ON_SIGNUP_REQUESTED,
  firstName,
  lastName,
  email,
  password,
});

export const submitSignUpSucceeded = (status: string): AnyAction => ({
  type: constants.USER_ON_SIGNUP_SUCCEEDED,
  status,
});

export const submitSignUpFailed = (data: string): AnyAction => ({
  type: constants.USER_ON_SIGNUP_FAILED,
  data,
});

export const changeAvatar = (file: IAsset): AnyAction => ({
  type: constants.ON_CHANGE_USER_AVATAR_REQUESTED,
  file,
});

export const changeAvatarSucceded = (data: IAsset): AnyAction => ({
  type: constants.ON_CHANGE_USER_AVATAR_SUCCEDED,
  data,
});

export const changeAvatarFailed = (): AnyAction => ({
  type: constants.ON_CHANGE_USER_AVATAR_FAILED,
});

export const initializeUser = (): AnyAction => ({
  type: constants.ON_INITIALIZE_USER_REQUESTED,
});

export const initializeUserSucceded = (data: IUser): AnyAction => ({
  type: constants.ON_INITIALIZE_USER_SUCCEDED,
  data,
});

export const initializeUserFailed = (): AnyAction => ({
  type: constants.ON_INITIALIZE_USER_FAILED,
});

export const changePassword = (oldPassword: string, newPassword: string): AnyAction => ({
  type: constants.ON_CHANGE_USER_PASSWORD_REQUESTED,
  oldPassword,
  newPassword,
});

export const changePasswordSucceded = (data: IObject): AnyAction => ({
  type: constants.ON_CHANGE_USER_PASSWORD_SUCCEDED,
  data,
});

export const changePasswordFailed = (): AnyAction => ({
  type: constants.ON_CHANGE_USER_PASSWORD_FAILED,
});

export const userLogout = (): AnyAction => ({
  type: constants.ON_USER_LOGOUT,
});

export const changeFirstName = (firstName: string): AnyAction => ({
  type: constants.ON_CHANGE_USER_FIRSTNAME_REQUESTED,
  firstName,
});

export const changeFirstNameSucceded = (data: IUser): AnyAction => ({
  type: constants.ON_CHANGE_USER_FIRSTNAME_SUCCEDED,
  data,
});

export const changeFirstNameFailed = (): AnyAction => ({
  type: constants.ON_CHANGE_USER_FIRSTNAME_FAILED,
});

export const changeLastName = (lastName: string): AnyAction => ({
  type: constants.ON_CHANGE_USER_LASTNAME_REQUESTED,
  lastName,
});

export const changeLastNameSucceded = (data: IUser): AnyAction => ({
  type: constants.ON_CHANGE_USER_LASTNAME_SUCCEDED,
  data,
});

export const changeLastNameFailed = (): AnyAction => ({
  type: constants.ON_CHANGE_USER_LASTNAME_FAILED,
});

export const changeErrorValue = (): AnyAction => ({
  type: constants.ON_CHANGE_ERROR_VALUE,
});

export const changeErrorValueTrue = (): AnyAction => ({
  type: constants.ON_CHANGE_ERROR_VALUE_TRUE,
});

export const submitLoginAndCreateCampaign = (
  email: string,
  password: string,
  templateId: string
): AnyAction => ({
  type: constants.ON_USER_LOGIN_AND_CREATE_CAMPAIGN_REQUESTED,
  email,
  password,
  templateId,
});

export const submitLoginAndCreateCampaignSucceeded = (id: string): AnyAction => ({
  type: constants.ON_USER_LOGIN_AND_CREATE_CAMPAIGN_SUCCEEDED,
  id,
});

export const submitLoginAndCreateCampaignFailed = (): AnyAction => ({
  type: constants.ON_USER_LOGIN_AND_CREATE_CAMPAIGN_FAILED,
});

export const forgotPassword = (email: string): AnyAction => ({
  type: constants.ON_FORGOT_PASSWORD_REQUESTED,
  email,
});

export const forgotPasswordSucceded = (): AnyAction => ({
  type: constants.ON_FORGOT_PASSWORD_SUCCEDED,
});

export const forgotPasswordFailed = (): AnyAction => ({
  type: constants.ON_FORGOT_PASSWORD_FAILED,
});

export const resetPassword = (token: string, password: string): AnyAction => ({
  type: constants.ON_RESET_PASSWORD_REQUESTED,
  token,
  password,
});

export const resetPasswordSucceded = (): AnyAction => ({
  type: constants.ON_RESET_PASSWORD_SUCCEDED,
});

export const resetPasswordFailed = (): AnyAction => ({
  type: constants.ON_RESET_PASSWORD_FAILED,
});

export const initializeConfirmAccount = (token: string): AnyAction => ({
  type: constants.ON_INITIALIZE_CONFIRM_ACCOUNT_REQUESTED,
  token,
});

export const initializeConfirmAccountSucceeded = (): AnyAction => ({
  type: constants.ON_INITIALIZE_CONFIRM_ACCOUNT_SUCCEEDED,
});

export const initializeConfirmAccountFailed = (): AnyAction => ({
  type: constants.ON_INITIALIZE_CONFIRM_ACCOUNT_FAILED,
});

//TODO change name of action
export const changeStatusSignUpFalse = (): AnyAction => ({
  type: constants.ON_CHANGE_STATUS_SIGNUP_FALSE,
});

export const changeStatusPassword = (): AnyAction => ({
  type: constants.ON_CHANGE_STATUS_PASSWORD,
});

export const changeStatusProfile = (): AnyAction => ({
  type: constants.ON_CHANGE_STATUS_PROFILE,
});

export const initializeForgotPassword = (token: string): AnyAction => ({
  type: constants.ON_INITIALIZE_FORGOT_PASSWORD_REQUESTED,
  token,
});

export const initializeForgotPasswordSucceeded = (): AnyAction => ({
  type: constants.ON_INITIALIZE_FORGOT_PASSWORD_SUCCEEDED,
});

export const initializeForgotPasswordFailed = (): AnyAction => ({
  type: constants.ON_INITIALIZE_FORGOT_PASSWORD_FAILED,
});

export const changeIsSuccess = (): AnyAction => ({
  type: constants.ON_CHANGE_IS_SUCCESS,
});

export const onRequestUpgrade = (): AnyAction => ({
  type: constants.ON_REQUEST_UPGRADE,
});

export const onRequestUpgradeSucceded = (): AnyAction => ({
  type: constants.ON_REQUEST_UPGRADE_SUCCEDED,
});

export const onRequestUpgradeFailed = (): AnyAction => ({
  type: constants.ON_REQUEST_UPGRADE_FAILED,
});
