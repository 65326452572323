import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'IMAGE', {
  MODAL_TITLE: 'Upload your image',
  MODAL_SUBTITLE: 'Position and size your image',
  CANCEL_BUTTON: 'X',
  APPLY_BUTTON: 'Apply',
  NEW: 'Upload new',
});
