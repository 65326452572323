import React, { FC } from 'react';

import Wrapper from 'components/common/Wrapper/Wrapper';
import { PUBLIC_URL } from 'configs';
import i18n from 'i18next';

import './i18n';
import {
  CopyrightContainer,
  CopyrightText,
  FormContainer,
  IconLogo,
  IconPlay,
  ImageBMS,
  Input,
  InputContainer,
  Label,
  LabelBody,
  LeftContainer,
  Line,
  RightContainer,
  TemplateContainer,
  TemplateThumbnail,
  ThumbnWrapper,
  ThumbnailContainer,
  UsernameText,
  VideoButton,
  WrapperLaunch,
} from './styles';
import { IProps } from './types';

const ShareEmailForm: FC<IProps> = (props) => {
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setInput: (value: string) => void
  ) => {
    setInput(event.target.value);
  };

  return (
    <WrapperLaunch>
      <Wrapper>
        <FormContainer>
          <LeftContainer>
            <InputContainer>
              <Label>{i18n.t('SHAREEMAILFORM:SUBJECT')}</Label>
              <Input
                type="text"
                onChange={(e) => handleInputChange(e, props.setSubject)}
                maxLength={50}
                value={props.subject}
              />
            </InputContainer>
            <InputContainer>
              <Label>{i18n.t('SHAREEMAILFORM:BODY')}</Label>
              <Input
                type="text"
                onChange={(e) => handleInputChange(e, props.setBody)}
                maxLength={50}
                value={props.body}
              />
            </InputContainer>
          </LeftContainer>
          <RightContainer>
            <TemplateContainer>
              <IconLogo />
              <ThumbnailContainer>
                <UsernameText>{i18n.t('SHAREEMAILFORM:HI')}</UsernameText>
                <LabelBody>{props.body}</LabelBody>
                <ThumbnWrapper>
                  <TemplateThumbnail src={props.templateThumbnail} />
                  <IconPlay></IconPlay>
                </ThumbnWrapper>
                <VideoButton>{i18n.t('SHAREEMAILFORM:WATCH')}</VideoButton>
              </ThumbnailContainer>
              <Line />
              <CopyrightContainer>
                <CopyrightText>{i18n.t('SHAREEMAILFORM:COPYRIGHT')}</CopyrightText>
                <ImageBMS src={`${PUBLIC_URL}/images/bms_logo.svg`} />
              </CopyrightContainer>
            </TemplateContainer>
          </RightContainer>
        </FormContainer>
      </Wrapper>
    </WrapperLaunch>
  );
};
export default ShareEmailForm;
