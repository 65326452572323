import styled from 'styled-components';

import { ICategoryLink } from './types';

export const FilterContainer = styled.ul`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const Filters = styled.li`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  padding-right: 25px;
  color: ${(props) => props.theme.colors.raven};
`;

export const Link = styled.a<ICategoryLink>`
  color: ${({ active }) => (active ? '#425061' : '#7a8490')};
  border-bottom: ${({ active }) => (active ? '2px solid #e98573' : 'none')};
  &:hover {
    color: ${(props) => props.theme.colors.fiord};
    cursor: pointer;
    border-bottom: 2px solid #e98573;
  }
`;
