import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ShareLayout from 'containers/ShareLayout/ShareLayout';
import { ShareStep } from 'enums/shareStep.enum';
import { ICSVRow, IObject } from 'helpers/types';
import i18n from 'i18next';
import * as campaignActions from 'redux/actions/campaign.actions';
import * as actions from 'redux/actions/share.actions';
import getCampaignSelector, {
  getCSVHeadersCampaignSelector,
} from 'redux/selectors/campaign.selector';
import ImportCSV from 'views/share/ImportCSV/ImportCSV';

import './i18n';
import { CampaignShareParameters } from './types';

const ShareImportCSVContainer: FC<CampaignShareParameters> = (props) => {
  const campaignId = props.match.params.campaignId;
  const [file, setFile] = useState<IObject[]>([]);
  const [fileData, setFileData] = useState<ICSVRow[]>([]);
  const CSVHeaders = useSelector(getCSVHeadersCampaignSelector());
  const dispatch = useDispatch();
  const campaignSelector = useSelector(getCampaignSelector());

  useEffect(() => {
    dispatch(campaignActions.onShareCSVImport(campaignId));
  }, [dispatch, campaignId]);

  useEffect(() => {
    /*TODO @PSOKOLOWICZ CHECK UNDEFINED AND EMPTY VALUES IN CSV*/
    setFileData(file.filter((data) => !Object.values(data).includes(undefined)) as ICSVRow[]);
  }, [file]);

  const onSelectImport = (fileName: string) => {
    const file = { csv: fileData, fileName };
    dispatch(campaignActions.onSelectImport(campaignId, file));
  };

  const onBack = () => {
    dispatch(actions.onSelectBackImportCSV(campaignId));
  };

  const onRedirectToSelect = () => {
    dispatch(campaignActions.onConfirmCSVFile({ _id: campaignId }));
  };

  return (
    <ShareLayout
      campaignId={campaignId}
      step={ShareStep.IMPORT}
      onBack={onBack}
      disabledNextButton={false}
      title={i18n.t('SHAREIMPORT:TITLE')}
    >
      <ImportCSV
        exportData={CSVHeaders}
        setFile={setFile}
        onSelectImport={onSelectImport}
        fileData={fileData}
        shareData={campaignSelector.campaign.shareData}
        onRedirectToSelect={onRedirectToSelect}
      />
    </ShareLayout>
  );
};

export default ShareImportCSVContainer;
