import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
  z-index: 150;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
`;

export const ModalContainer = styled.div`
  width: 818px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  position: relative;
`;

export const CloseModalWrapper = styled.div`
  width: 820px;
  justify-content: flex-end;
  display: flex;
  position: absolute;
  top: 50px;
`;

export const Title = styled.h2`
  font-size: 20px;
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  text-align: center;
  margin-bottom: 20px;
  width: 815px;
`;

export const TopModalWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const VideoWrapper = styled.div`
  width: 823px;
  height: 520px;
`;

export const ControlsWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
`;
