import React, { FC, useState } from 'react';

import PlayIconComponent from 'components/PlayIcon/PlayIcon';
import i18n from 'i18next';
import VideoModal from 'views/Template/components/ModalVideo/ModalVideo';

import './i18n';
import {
  BannerContainer,
  BannerImage,
  BannerText,
  BannerTextTitle,
  IconContainer,
  PlayIconContainer,
  RocketImage,
  TextContainer,
} from './styles';
import { IProps } from './types';

const Banner: FC<IProps> = (props) => {
  const [openVideo, setOpenVideo] = useState<boolean>(false);

  const onCloseModal = () => {
    setOpenVideo(false);
  };

  const onOpenModal = () => {
    setOpenVideo(true);
  };
  return (
    <BannerContainer>
      <BannerImage>
        <IconContainer>
          <RocketImage />
          <PlayIconContainer onClick={onOpenModal}>
            <PlayIconComponent />
          </PlayIconContainer>
        </IconContainer>
        <TextContainer>
          <BannerTextTitle>{i18n.t('BANNER:TITLE')}</BannerTextTitle>
          <BannerText>{i18n.t('BANNER:SUBTITLE')}</BannerText>
        </TextContainer>
      </BannerImage>
      {openVideo && <VideoModal onClose={onCloseModal} autoplay={!props.isMobile} />}
    </BannerContainer>
  );
};
export default Banner;
