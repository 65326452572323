import { Link } from 'react-router-dom';

import { ReactComponent as Edit } from 'assets/edit_icon-01-01.svg';
import { ReactComponent as BMSLogo } from 'assets/iso_bms-01.svg';
import { ReactComponent as Preview } from 'assets/preview.svg';
import { ReactComponent as HoverShare } from 'assets/share-arrow-h.svg';
import { ReactComponent as Share } from 'assets/share-arrow.svg';
import styled from 'styled-components';

export const TopbarStyle = styled.div`
  width: 100%;
  height: 66px;
  background: ${(props) => props.theme.colors.white};
  position: sticky;
  top: 0;
  z-index: 100;
`;

export const TopbarContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const BMSIcon = styled(BMSLogo)`
  width: 40px;
  cursor: pointer;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TemplateName = styled.input`
  width: 210px;
  height: 40px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  letter-spacing: ${(props) => props.theme.spacing.spacing1};
  border: 2px solid ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  outline: none;
  border-radius: 6px;
  padding-left: 10px;
  transition: all 0.2s ease-in;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover,
  &:focus {
    border: 2px solid ${(props) => props.theme.colors.fiord};
  }
`;

export const EditIcon = styled(Edit)`
  width: 11px;
  height: 11px;
`;

export const TextChanges = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.silver};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  margin-right: 25px;
  align-self: center;
`;

export const PreviewLogo = styled(Preview)`
  margin-right: 7px;
  transition: ${(props) => props.theme.transition.all};
`;

export const ShareLogo = styled(Share)`
  opacity: 1;
  position: absolute;
  width: 15px;
  height: 20px;
  transition: ${(props) => props.theme.transition.all};
`;

export const HoverShareLogo = styled(HoverShare)`
  opacity: 0;
  position: absolute;
  width: 15px;
  height: 20px;
  transition: ${(props) => props.theme.transition.all};
`;

export const IconWrapper = styled.div`
  position: relative;
  display: flex;
  margin-right: 5px;
  width: 15px;
  height: 20px;
  bottom: 5px;
`;

export const ButtonPreview = styled.div`
  width: 135px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-left: 2px solid ${(props) => props.theme.colors.aena};
  background: ${(props) => props.theme.colors.white};
  cursor: pointer;
  transition: ${(props) => props.theme.transition.all};
  &:hover {
    background: ${(props) => props.theme.colors.porcelain};
    ${PreviewLogo} {
      transform: scale(1.1);
    }
  }
`;

export const ButtonShare = styled(Link)`
  width: 120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: ${(props) => props.theme.colors.cello};
  cursor: pointer;
  transition: ${(props) => props.theme.transition.all};
  &:hover {
    background: ${(props) => props.theme.colors.apricot};
    ${ShareLogo} {
      opacity: 0;
    }
    ${HoverShareLogo} {
      opacity: 1;
    }
  }
`;

export const ListContainer = styled.ul`
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ListItem = styled.li`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LeftContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const RightContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const IconContainer = styled(Link)`
  width: 75px;
  border-right: 2px solid #edf0f2;
  margin-right: 20px;
  display: flex;
  justify-content: center;
`;

export const Text = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.cello};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  text-decoration: none;
`;

export const PreviewText = styled(Text)`
  color: ${(props) => props.theme.colors.cello};
`;

export const ShareText = styled(Text)`
  color: ${(props) => props.theme.colors.white};
`;
