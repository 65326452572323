import styled from 'styled-components';

import { IUpgradeButton, IUpgradeSubtitle } from './types';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
  z-index: 150;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
`;

export const ModalContainer = styled.div`
  width: 440px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0px 2px 6px #00000029;
  background: ${(props) => props.theme.colors.white};
`;

export const CloseModalWrapper = styled.div`
  width: 440px;
  height: 35px;
  justify-content: flex-end;
  display: flex;
  position: absolute;
`;

export const ContentWrapper = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.colors.fiord};
  width: 420px;
  font-size: 20px;
  text-align: center;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  margin: 10px 0;
`;

export const Subtitle = styled.h2`
  color: ${(props) => props.theme.colors.fiord};
  width: 360px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.light};
  text-align: center;
  margin: 15px 0;
`;

export const UpgradeButton = styled.button<IUpgradeButton>`
  width: 130px;
  margin: 10px 0;
  padding: 10px;
  text-align: center;
  background: ${(props) =>
    props.succededUpgradeRequest ? props.theme.colors.grayChateau : props.theme.colors.cello};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.porcelain};
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  cursor: ${(props) => !props.succededUpgradeRequest && 'pointer'};
  transition: all 0.2s ease-out;
  &:hover {
    background: ${(props) => !props.succededUpgradeRequest && props.theme.colors.bigStone};
  }
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  text-decoration: none;
`;

export const UpgradeSubtitle = styled.h2<IUpgradeSubtitle>`
  color: ${(props) => props.theme.colors.cloudy};
  width: 350px;
  font-size: ${(props) => (props.succededUpgradeRequest ? '15px' : '11px')};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.light};
  text-align: center;
  margin: 5px;
  transition: all 0.2s ease-out;
`;
