import React, { FC, useState } from 'react';

import { PUBLIC_URL } from 'configs';

import { ArrowWrapper, Next, Prev } from './styles';
import { IProps } from './types';

export const SliderArrowRight: FC<IProps> = (props) => {
  const [arrowHover, setArrowHover] = useState<boolean>(false);
  const { currentSlide, slideCount, onClick, className } = props;
  return (
    <button
      className={className}
      aria-hidden="true"
      aria-disabled={slideCount && currentSlide === slideCount - 1 ? true : false}
      type="button"
      onClick={onClick}
    >
      <ArrowWrapper
        onMouseEnter={() => setArrowHover(true)}
        onMouseLeave={() => setArrowHover(false)}
      >
        {arrowHover ? (
          <Next src={`${PUBLIC_URL}/images/arrow-slide-h.svg`} />
        ) : (
          <Next src={`${PUBLIC_URL}/images/arrow-slide-b.svg`} />
        )}
      </ArrowWrapper>
    </button>
  );
};

export const SliderArrowLeft: FC<IProps> = (props) => {
  const [arrowHover, setArrowHover] = useState<boolean>(false);
  const { currentSlide, onClick, className } = props;

  return (
    <button
      className={className}
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
      onClick={onClick}
    >
      <ArrowWrapper
        onMouseEnter={() => setArrowHover(true)}
        onMouseLeave={() => setArrowHover(false)}
      >
        {arrowHover ? (
          <Prev src={`${PUBLIC_URL}/images/arrow-slide-h.svg`} />
        ) : (
          <Prev src={`${PUBLIC_URL}/images/arrow-slide-b.svg`} />
        )}
      </ArrowWrapper>
    </button>
  );
};
