import React, { FC } from 'react';

import Wrapper from 'components/common/Wrapper/Wrapper';
import { PUBLIC_URL } from 'configs/index';
import StoreLayout from 'containers/StoreLayout/StoreLayout';
import i18n from 'i18next';

import './i18n';
import { Button, Container, IconContainer, Image, LogInText } from './styles';

const ConfirmAccount: FC = () => {
  return (
    <StoreLayout isTemplate="">
      <Wrapper>
        <Container>
          <IconContainer>
            <Image src={`${PUBLIC_URL}/images/bms_logo.svg`} />
          </IconContainer>
          <LogInText>{i18n.t('CONFIRM:TITLE')}</LogInText>
          <Button to="/">{i18n.t('CONFIRM:HOME')}</Button>
        </Container>
      </Wrapper>
    </StoreLayout>
  );
};
export default ConfirmAccount;
