import { ReactComponent as EditIcon } from 'assets/editPencil.svg';
import { ReactComponent as ReportIcon } from 'assets/icon-report.svg';
import { ReactComponent as MoreIcon } from 'assets/more.svg';
import { ReactComponent as RelaunchIcon } from 'assets/relaunch.svg';
import { ReactComponent as DeleteIcon } from 'assets/trash.svg';
import styled from 'styled-components';

import { IOptions } from './types';

export const Options = styled.div<IOptions>`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: ${(props) => (!props.listItem ? 'absolute' : 'relative')};
  border-radius: 8px;
  right: ${(props) => !props.listItem && '10px'};
  top: ${(props) => !props.listItem && '10px'};
  margin-bottom: ${(props) => !props.listItem && '20px'};
  background-color: ${(props) => props.theme.colors.fiord};
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.oxfordBlue};
  }
`;

export const OptionsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0px;
  top: 45px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 3px 6px #00000029;
  z-index: 10;
`;

export const OptionsList = styled.ul`
  width: 100%;
  height: 100%;
  border-radius: 6px;
`;

export const ListItem = styled.li`
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
  transition: all 0.2s ease-out;
  border-radius: 6px;
  &:hover {
    background: ${(props) => props.theme.colors.gallery};
    font-weight: bold;
  }
`;

export const List = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const More = styled(MoreIcon)``;

export const Edit = styled(EditIcon)`
  width: 15.9px;
  height: 17.5px;
  margin-right: 20px;
`;

export const Delete = styled(DeleteIcon)`
  width: 15.9px;
  height: 17.5px;
  margin-right: 20px;
`;

export const Relaunch = styled(RelaunchIcon)`
  width: 15.9px;
  height: 17.5px;
  margin-right: 20px;
`;

export const Report = styled(ReportIcon)`
  width: 15.9px;
  height: 17.5px;
  margin-right: 20px;
`;
