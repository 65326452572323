import React, { FC, Fragment, useState } from 'react';

import ImageEditModal from 'components/ImageEditModal/EditModal';
import MessageModal from 'components/MessageModal/MessageModal';
import { MESSAGE } from 'constants/messages/messages.constants';
import i18n from 'i18next';
import { IAsset } from 'interfaces/asset.interface';
import { IImageVariable } from 'views/Video/types';

import './i18n';
import {
  EditIcon,
  IconWrapper,
  Image,
  Line,
  NoImage,
  NoImageWrapper,
  Subtitle,
  Title,
} from './styles';
import { IProps } from './types';

const ImageTab: FC<IProps> = (props) => {
  const [isVisible, setIsVisible] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<IAsset>();

  const toggleError = () => {
    setIsError((prevState) => !prevState);
  };

  const onSetIsVisible = (id: string) => {
    const asset = props.imageVariables.find((img) => img._id === id)?.asset;
    setCurrentImage(asset);
    setIsVisible(id);
  };

  return (
    <>
      <Title>{i18n.t('IMAGE:TITLE')}</Title>
      <Line />
      {!!props.imageVariables.length && <Subtitle>{i18n.t('IMAGE:CHANGE_PHOTOS')}</Subtitle>}
      {props.imageVariables.length ? (
        props.imageVariables
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((image: IImageVariable) => {
            return (
              <Fragment key={image._id}>
                <Image path={image.asset?.path} onClick={() => onSetIsVisible(image._id)}>
                  <IconWrapper>
                    <EditIcon />
                  </IconWrapper>
                </Image>
                {isVisible === image._id && currentImage && (
                  <ImageEditModal
                    imageVariable={image}
                    onClose={() => setIsVisible('')}
                    onSubmitImageVariable={props.onSubmitImage}
                    setError={toggleError}
                    thumbnail={currentImage}
                  />
                )}
              </Fragment>
            );
          })
      ) : (
        <NoImageWrapper>
          <NoImage />
          <Subtitle noImage>{i18n.t('IMAGE:NO_IMAGE')}</Subtitle>
        </NoImageWrapper>
      )}
      {isError && (
        <MessageModal
          title={MESSAGE.error.title}
          icon={MESSAGE.error.icon}
          closeIcon={MESSAGE.error.close}
          bgColor={MESSAGE.error.bgColor}
          onClose={toggleError}
          message={i18n.t('MESSAGE:FILE_SIZE_WARNING')}
        />
      )}
    </>
  );
};

export default ImageTab;
