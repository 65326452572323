import styled from 'styled-components';

import { ISubtitle } from './types';

export const Wrapper = styled.div`
  min-height: 100vh;
  position: relative;
  z-index: 0;
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  padding: 30px 0 30px;
`;

export const ShareTitle = styled.p`
  color: ${(props) => props.theme.colors.fiord};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.extraLarge};
  text-align: center;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  margin-bottom: 0px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
`;

export const SubTitle = styled.p`
  color: ${(props) => props.theme.colors.fiord};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.extraLarge};
  text-align: center;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  margin-bottom: 30px;
`;

export const Header = styled.div<ISubtitle>`
  height: ${(props) => (props.subtitle ? '180px' : '155px')};
`;

export const RequiredDiv = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Bolder = styled.b`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.extraLarge};
  text-align: left;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.fiord};
  margin-right: 10px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
`;

export const RequiredFieldsContainer = styled.div`
  height: 25px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 10px;
  border-radius: 4px;
  background: ${(props) => props.theme.colors.geyser};
`;

export const RequiredFields = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  text-align: left;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  margin: 0px 10px;
`;
