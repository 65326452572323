import { ReactComponent as HoverEdit } from 'assets/bt-edit-h.svg';
import { ReactComponent as Edit } from 'assets/bt-edit.svg';
import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
  z-index: 150;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
`;

export const ModalContainer = styled.div`
  width: 870px;
  height: 435px;
  display: flex;
  border-radius: 6px;
  box-shadow: 0px 2px 6px #00000029;
  background: ${(props) => props.theme.colors.white};
  position: absolute;
  flex-direction: column;
`;

export const TemplateInformation = styled.div`
  height: calc(100% - 75px);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LeftContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px 15px 0 30px;
`;

export const RightContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Footer = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 6px 6px;
  background: ${(props) => props.theme.colors.aena};
`;

export const PlayerWrapper = styled.div`
  width: 513px;
  height: 340px;
  border: 1px solid ${(props) => props.theme.colors.silverc};
  border-radius: 10px;
  margin: 0 30px 0 15px;
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.colors.cello};
  font-size: 20px;
  text-align: left;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  margin-bottom: 5px;
`;

export const Text = styled.label`
  color: ${(props) => props.theme.colors.cello};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const Subtitle = styled.label`
  color: ${(props) => props.theme.colors.cello};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  margin-bottom: 5px;
`;

export const Inputs = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const InputName = styled.div`
  background: ${(props) => props.theme.colors.porcelain};
  color: ${(props) => props.theme.colors.cello};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  padding: 2px 5px;
  border-radius: 4px;
  margin: 5px 10px 0 0;
`;

export const Section = styled.div`
  margin-bottom: 15px;
  flex-direction: column;
  display: flex;
  max-width: 250px;
`;

export const EditIcon = styled(Edit)`
  width: 15px;
  height: 15px;
  opacity: 1;
  transition: all 0.4s ease-out;
  position: absolute;
`;

export const HoverEditIcon = styled(HoverEdit)`
  width: 15px;
  height: 15px;
  opacity: 0;
  transition: all 0.4s ease-out;
  position: absolute;
`;

export const IconWrapper = styled.div`
  position: relative;
  width: 15px;
  height: 15px;
  display: flex;
  margin-right: 5px;
`;

export const ButtonWrapper = styled.div`
  &:hover {
    ${EditIcon} {
      opacity: 0;
    }
    ${HoverEditIcon} {
      opacity: 1;
    }
  }
`;
