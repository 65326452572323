import { IColorRGB } from 'views/Video/types';

export const hexToRgb = (hex: string): IColorRGB => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseFloat((parseInt(result[1], 16) / 255).toFixed(2)),
        g: parseFloat((parseInt(result[2], 16) / 255).toFixed(2)),
        b: parseFloat((parseInt(result[3], 16) / 255).toFixed(2)),
      }
    : { r: 0, g: 0, b: 0 };
};
