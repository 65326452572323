import styled from 'styled-components';

import { ICampaignWrapper } from './types';

export const DotsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0px;
  top: 0px;
`;

export const Campaign = styled.div<ICampaignWrapper>`
  width: ${(props) => (!props.listItem ? '249px' : '160px')};
  height: ${(props) => (!props.listItem ? '145px' : '90px')};
  position: relative;
  cursor: ${(props) => !props.listItem && 'pointer'};
  border: 1px solid ${(props) => props.theme.colors.silverc};
  border-radius: 10px;
`;
