import styled from 'styled-components';

export const BGWrapper = styled.div`
  height: calc(100% - 76px);
  background: ${(props) => props.theme.colors.porcelain};
  min-height: calc(100vh - 76px);
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`;

export const Title = styled.p`
  font-size: 30px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  color: ${(props) => props.theme.colors.fiord};
  text-align: center;
  margin-bottom: 40px;
  margin-top: 30px;
`;

export const QuickStatsContianer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const QuickStats = styled.div`
  width: 30%;
  height: 130px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.grayChateau};
  border: 0.5px solid ${(props) => props.theme.colors.grayChateau};
`;

export const QuickText = styled.p`
  font-size: 45px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  line-height: 50px;
`;

export const StatsContainer = styled.div`
  width: 25%;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.colors.grayChateau};
`;

export const Text = styled.p`
  width: 80%;
  font-size: 20px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => props.theme.colors.fiord};
  text-align: center;
  margin-bottom: 10px;
`;

export const Stat = styled.p`
  font-size: 26px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  color: ${(props) => props.theme.colors.fiord};
  text-align: center;
`;

export const SummaryContainer = styled.div`
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

export const WatchSummary = styled.div`
  width: 30%;
  height: 130px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const SummaryTextContainer = styled.div`
  width: 40%;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Line = styled.div`
  height: 60px;
  border: 2px solid ${(props) => props.theme.colors.fiord};
  opacity: 1;
  margin-right: 15px;
`;

export const Summary = styled.p`
  width: 50%;
  font-size: 26px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  color: ${(props) => props.theme.colors.fiord};
  text-align: left;
`;

export const SummaryText = styled.p`
  font-size: 16px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => props.theme.colors.fiord};
  text-align: center;
`;

export const ChartContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PlayCount = styled.p`
  height: 100%;
  font-size: 16px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => props.theme.colors.fiord};
  text-align: left;
  transform: rotate(-90deg);
  white-space: nowrap;
`;

export const PlayDuration = styled.p`
  font-size: 16px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => props.theme.colors.fiord};
  text-align: left;
  margin: 20px 0px 0px 60px;
`;

export const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};
