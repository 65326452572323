import { CampaignError } from 'enums/campaignError.enum';
import { IAPIError } from 'interfaces/api.interface';
import { AnyAction } from 'redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as paywallActions from 'redux/actions/paywall.actions';
import * as campaignService from 'service/campaign.service';

import * as campaignActions from '../actions/campaign.actions';
import * as constants from '../constants/campaign.action.constants';
import * as constantsShare from '../constants/share.action.constants';
import * as constantsTemplate from '../constants/template.action.contants';

function* createCampaign(action: AnyAction) {
  try {
    const response = yield call(campaignService.createCampaign, action.id);
    yield put(campaignActions.selectTemplateSucceeded(response._id));
  } catch (err) {
    const errorMessage = (err as IAPIError).errorMessage;
    if (errorMessage === CampaignError.TEMPLATE_NOT_AVAILABLE_FOR_SUB) {
      yield put(paywallActions.onSelectTemplateFailedTemplateNotAvailable(errorMessage));
    } else if (errorMessage === CampaignError.CREATE_CAMPAIGN_LIMIT_EXCEEDED) {
      yield put(paywallActions.campaignLimitExceeded(errorMessage));
    } else {
      yield put(campaignActions.selectTemplateFailed());
    }
  }
}

function* getCampaign(action: AnyAction) {
  try {
    const response = yield call(campaignService.getCampaign, action.id);
    yield put(campaignActions.initializeCampaignSuccedeed(response));
  } catch (err) {
    yield put(campaignActions.initializeCampaignFailed());
  }
}

//TODO FIXME @GUIDOG THIS IS NOT GOOD WE SHOULD DISPATCH ONLY ONE ACTION AND MORE THAN ONE SUCCEED THIS IS AN ERROR
function* initializeShareLaunch(action: AnyAction) {
  try {
    const response = yield call(campaignService.getCampaignShareData, action.id);
    const data = {
      campaign: response,
    };
    yield put(campaignActions.onInitializeShareLaunchSucceeded(data));
  } catch (err) {
    yield put(campaignActions.onInitializeShareLaunchFailed());
  }
}

function* changeCampaignName(action: AnyAction) {
  try {
    const response = yield call(campaignService.changeCampaignName, action.name, action.id);
    yield put(campaignActions.changeCampaignNameSucceeded(response.name));
  } catch (err) {
    yield put(campaignActions.changeCampaignNameFailed());
  }
}

function* changeTextVariables(action: AnyAction) {
  try {
    const response = yield call(
      campaignService.changeTextVariables,
      action.id,
      action.text,
      action.textId,
      action.link
    );
    yield put(campaignActions.changeTextVariableSucceeded(response));
  } catch (err) {
    yield put(campaignActions.changeTextVariableFailed());
  }
}

function* changeColorVariable(action: AnyAction) {
  try {
    const response = yield call(
      campaignService.changeColorVariable,
      action.id,
      action.color,
      action.colorId
    );
    yield put(campaignActions.changeColorVariableSucceeded(response));
  } catch (err) {
    yield put(campaignActions.changeColorVariableFailed());
  }
}

function* changeImageVariables(action: AnyAction) {
  try {
    const assetResponse = yield call(campaignService.uploadAsset, action.file);
    const response = yield call(
      campaignService.changeImageVariables,
      action.id,
      assetResponse._id,
      action.imageId
    );
    yield put(campaignActions.changeImageVariableSucceeded(response));
  } catch (err) {
    yield put(campaignActions.changeImageVariableFailed());
  }
}

function* uploadOriginalVideo(action: AnyAction) {
  try {
    const response = yield call(campaignService.uploadAsset, action.file);
    yield put(campaignActions.onUploadOriginalVideoSucceed(response));
  } catch (err) {
    yield put(campaignActions.onUploadOriginalVideoFailed());
  }
}

function* changeVideo(action: AnyAction) {
  try {
    const assetResponse = yield call(
      campaignService.cropAndUploadVideo,
      action.cropVideoSettings,
      action.originalVideoId
    );
    const response = yield call(campaignService.changeVideo, action.id, assetResponse._id);
    yield put(campaignActions.changeVideoSucceed(response));
  } catch (err) {
    yield put(campaignActions.changeVideoFailed());
  }
}

function* createShareData(action: AnyAction) {
  try {
    const response = yield call(campaignService.createShareData, action.id, action.shareData);
    yield put(campaignActions.onSelectNextSelectViewSucceeded(response));
  } catch (err) {
    yield put(campaignActions.onSelectNextSelectViewFailed());
  }
}

function* createCSVShareData(action: AnyAction) {
  try {
    const response = yield call(campaignService.createShareData, action.id, action.shareData);
    yield put(campaignActions.onSelectImportSucceeded(response));
  } catch (err) {
    yield put(campaignActions.onSelectImportFailed());
  }
}

function* getCampaignVideo(action: AnyAction) {
  try {
    if (!action.isTemplate) {
      const response = yield call(campaignService.getCampaign, action.id);
      yield put(campaignActions.onSelectPreviewSucceeded(response));
    }
  } catch (err) {
    yield put(campaignActions.onSelectPreviewFailed());
  }
}

export default all([
  takeLatest(constants.ON_INITIALIZE_CAMPAIGN_REQUESTED, getCampaign),
  takeLatest(constants.ON_SELECT_IMPORT_CSV_REQUESTED, createCSVShareData),
  takeLatest(constants.ON_SELECT_TEMPLATE_REQUESTED, createCampaign),
  takeLatest(constants.ON_CHANGE_CAMPAIGN_NAME_REQUESTED, changeCampaignName),
  takeLatest(constants.ON_CHANGE_CAMPAIGN_TEMPLATE_TEXTS_REQUESTED, changeTextVariables),
  takeLatest(constants.ON_CHANGE_CAMPAIGN_IMAGE_REQUESTED, changeImageVariables),
  takeLatest(constants.ON_CHANGE_CAMPAIGN_VIDEO_REQUESTED, changeVideo),
  takeLatest(constants.ON_CHANGE_CAMPAIGN_COLOR_REQUESTED, changeColorVariable),
  takeLatest(constants.ON_SELECT_MAILCHIMP_SEGMENT_REQUESTED, createShareData),
  takeLatest(constantsShare.ON_INITIALIZE_SHARE_LAUNCH_REQUESTED, initializeShareLaunch),
  takeLatest(constantsTemplate.ON_SELECT_PREVIEW_REQUESTED, getCampaignVideo),
  takeLatest(constants.ON_UPLOAD_ORIGINAL_VIDEO_REQUESTED, uploadOriginalVideo),
]);
