import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'FORGOT', {
  CHANGEPASS: 'Change Password',
  PASS: 'Password',
  NEWPASS: 'New Password',
  CONFIRMPASS: 'Confirm Password',
  PASSDONTMATCH: "The passwords don't match",
  HOME: 'Home',
  RESET: 'Your password reset link is no longer valid. Please request a new password reset link.',
});
