import { IObject } from 'helpers/types';
import { IAsset, ICropVideoSettings } from 'interfaces/asset.interface';

import { IResponse, apiDelete, get, post, put } from './api';

export const createCampaign = async (id: string): Promise<IObject> => {
  const data = { id };
  const response = await post('campaign/', data);
  return response.data;
};

export const getCampaign = async (id: string): Promise<string | undefined> => {
  const response = await get(`campaign/${id}`);
  return response.data;
};

export const getCampaignShareData = async (id: string): Promise<string | undefined> => {
  const response = await get(`campaign/${id}/share-data/`);
  return response.data;
};

export const changeCampaignName = async (id: string, name: string): Promise<string | undefined> => {
  const data = { name };
  const response = await put(`campaign/${id}`, data);
  return response.data;
};

export const changeTextVariables = async (
  id: string,
  text: string,
  textId: string,
  link?: string
): Promise<string | undefined> => {
  const data = { text, link };
  const response = await put(`campaign/${id}/texts/${textId}`, data);
  return response.data;
};

export const changeColorVariable = async (
  id: string,
  color: string,
  colorId: string
): Promise<string | undefined> => {
  const data = { color };
  const response = await put(`campaign/${id}/color/${colorId}`, data);
  return response.data;
};

export const uploadAsset = async (file: IAsset): Promise<IObject> => {
  const assetData = { file };
  const response = await post('assets/', assetData);
  return response.data;
};

export const cropAndUploadVideo = async (
  cropSettings: ICropVideoSettings,
  originalVideoId: string
): Promise<IObject> => {
  const assetData = { originalVideoId, cropSettings };
  const response = await post('assets/create-cropped-video', assetData);
  return response.data;
};

export const changeImageVariables = async (
  id: string,
  assetId: string,
  imageId: string
): Promise<string | undefined> => {
  const data = { assetId };
  const response = await put(`campaign/${id}/image/${imageId}`, data);
  return response.data;
};

export const changeVideo = async (id: string, assetId: string): Promise<string | undefined> => {
  const data = { assetId };
  const response = await put(`campaign/video/${id}`, data);
  return response.data;
};

export const changeShareType = async (
  id: string,
  shareType: number
): Promise<string | undefined> => {
  const data = { shareType };
  const response = await put(`campaign/${id}/share-type`, data);
  return response.data;
};

export const createShareData = async (
  id: string,
  shareData: IObject | null
): Promise<string | undefined> => {
  const data = { shareData };
  const response = await put(`campaign/${id}/`, data);
  return response.data;
};

export const getCampaignMetric = async (id: string): Promise<string | undefined> => {
  const response = await get(`campaign/${id}/metric`);
  return response.data;
};

export const deleteCampaign = async (id: string): Promise<IResponse> => {
  const response = await apiDelete(`campaign/${id}`, {});
  return response;
};
