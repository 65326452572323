import { IObject } from './types';

export const addHeadersToCSV = (csv: string[][]): IObject[] => {
  const result: IObject[] = [];
  const headers = csv[0];

  csv.forEach((row: string[]) => {
    const obj = {};
    headers.forEach((header: string, index: number) => {
      obj[header] = row[index];
    });
    result.push(obj);
  });

  return result;
};
