import { PUBLIC_URL } from 'configs';
import i18n from 'i18next';

import './i18n';

const GREEN_TICK = `${PUBLIC_URL}/images/message-modals/icon-green.svg`;

const GREEN_CLOSE = `${PUBLIC_URL}/images/message-modals/close-green.svg`;

const RED_TICK = `${PUBLIC_URL}/images/message-modals/icon-red.svg`;

const RED_CLOSE = `${PUBLIC_URL}/images/message-modals/close-red.svg`;

const YELLOW_TICK = `${PUBLIC_URL}/images/message-modals/icon-yellow.svg`;

const YELLOW_CLOSE = `${PUBLIC_URL}/images/message-modals/close-yellow.svg`;

const BLUE_TICK = `${PUBLIC_URL}/images/message-modals/icon-blue.svg`;

const BLUE_CLOSE = `${PUBLIC_URL}/images/message-modals/close-blue.svg`;

const GREEN_COLOR = '#52D365';

const RED_COLOR = '#FF7572';

const YELLOW_COLOR = '#FEDA40';

const BLUE_COLOR = '#8FC6E6';

export const MESSAGE = {
  success: {
    icon: GREEN_TICK,
    close: GREEN_CLOSE,
    title: `${i18n.t('MESSAGE:SUCCESS')}`,
    bgColor: GREEN_COLOR,
  },
  error: {
    icon: RED_TICK,
    close: RED_CLOSE,
    title: `${i18n.t('MESSAGE:ERROR')}`,
    bgColor: RED_COLOR,
  },
  warning: {
    icon: YELLOW_TICK,
    close: YELLOW_CLOSE,
    title: `${i18n.t('MESSAGE:WARNING')}`,
    bgColor: YELLOW_COLOR,
  },
  info: {
    icon: BLUE_TICK,
    close: BLUE_CLOSE,
    title: `${i18n.t('MESSAGE:INFO')}`,
    bgColor: BLUE_COLOR,
  },
};
