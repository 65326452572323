import styled from 'styled-components';

import { PUBLIC_URL } from '../../../configs/index';

export const Title = styled.p`
  font-size: 18px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => props.theme.colors.fiord};
  text-align: center;
  align-items: center;
  margin-bottom: 40px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
`;

export const MatchFieldsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-top: 40px;
`;

export const CVSLists = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

export const Line = styled.div`
  width: 100%;
  height: 0px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid ${(props) => props.theme.colors.loblolly};
  opacity: 1;
`;

export const CVS = styled.div`
  width: 80%;
  height: 42px;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: space-between;
`;

export const Text = styled.div`
  width: 47%;
  height: 100%;
  font-size: 16px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.white};
  margin-top: 10px;
  border-radius: 4px;
  background: ${(props) => props.theme.colors.apricot};
  text-align: left;
`;

export const DragAndDropImage = styled.div`
  width: 20%;
  height: 200px;
  margin-top: 150px;
  margin-right: 20px;
  background-repeat: no-repeat;
  background-image: url(${PUBLIC_URL}/images/drag-drop.svg);
`;

export const CVSFields = styled(CVSLists)`
  width: 40%;
`;

export const Fields = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Field = styled.div`
  font-size: 16px;
  text-align: left;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.fiord};
  margin-right: 100px;
  margin-bottom: 30px;
`;

export const FieldText = styled.div`
  width: 20%;
  height: 100%;
  font-size: 16px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.fiord};
  text-align: left;
`;

export const DropHere = styled.div`
  width: 70%;
  height: 100%;
  font-size: 16px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.grayChateau};
  border: 1px dashed ${(props) => props.theme.colors.grayChateau};
  border-radius: 4px;
  text-align: left;
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormLabel = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  align-self: center;
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
`;

export const FormSelect = styled.select`
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const FormSubmit = styled.button`
  width: 251px;
  height: 40px;
  font-size: 14px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => props.theme.colors.white};
  align-self: center;
  text-transform: capitalize;
  text-align: center;
  align-items: center;
  background: #214569;
  border: none;
  border-radius: 2px;
  margin-top: 20px;
  cursor: pointer;
`;

export const LeftContainer = styled.div`
  width: 38%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const RightContainer = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const SegmentText = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  text-align: left;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const WrapperImport = styled.div`
  height: calc(100% - 255px);
  min-height: calc(100vh - 255px);
  background: ${(props) => props.theme.colors.aena};
`;
