import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const CampaignsViewStyle = styled.div`
  width: 100%;
  height: 270px;
  margin-top: 38px;
  margin-bottom: 50px;
`;

export const CampaignTitle = styled.h2`
  font-size: 20px;
  text-align: left;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.fiord};
  opacity: 1;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
`;

export const ViewAll = styled(Link)`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  text-decoration: none;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.fiord};
  margin-top: 30px;
  color: ${(props) => props.theme.colors.apricot};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.cello};
  }
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
`;

export const CampaignsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
