import { AnyAction } from 'redux';
import * as constants from 'redux/constants/paywall.action.constants';
import { ICampaign } from 'views/CampaignEdit/types';

export const onSelectTemplateFailedTemplateNotAvailable = (data: string): AnyAction => ({
  type: constants.ON_SELECT_TEMPLATE_FAILED_TEMPLATE_NOT_AVAILABLE_FOR_SUB,
  data,
});

export const campaignLimitExceeded = (data: string): AnyAction => ({
  type: constants.CREATE_CAMPAIGN_LIMIT_EXCEEDED,
  data,
});

export const massiveShareCampaignLimit = (data: string): AnyAction => ({
  type: constants.MASSIVE_SHARE_CAMPAIGN_LIMIT,
  data,
});

export const sharedShareCampaignsLimit = (data: string): AnyAction => ({
  type: constants.SHARED_SHARE_CAMPAIGN_LIMIT,
  data,
});

export const onClosePaywall = (): AnyAction => ({
  type: constants.ON_CLOSE_PAYWALL_MODAL,
});

export const alertCampaignsExpired = (
  data: string,
  aboutToExpireCampaignsToAlert: ICampaign[],
  expiredCampaignsToAlert: ICampaign
): AnyAction => ({
  type: constants.ALERT_CAMPAIGN_LIFE_TIME_LIMIT,
  data,
  aboutToExpireCampaignsToAlert,
  expiredCampaignsToAlert,
});
