import React, { FC, useEffect, useState } from 'react';

import Button from 'components/Button/Button';
import CloseModalButton from 'components/CloseModalButton/CloseModalButton';
import i18n from 'i18next';

import './i18n';
import {
  ButtonContainer,
  CloseModalWrapper,
  ModalContainer,
  Subtitle,
  TextContainer,
  Title,
  TransitionModal,
} from './styles';
import { IProps } from './types';

const FieldDataWarningModal: FC<IProps> = (props) => {
  const [fadeType, setFadeType] = useState('');

  useEffect(() => {
    setTimeout(() => setFadeType('in'), 0);
  }, []);

  const handleClose = () => {
    setTimeout(() => setFadeType('out'), 0);
    props.onClose();
  };

  if (fadeType === 'out') {
    props.onClose();
  }

  return (
    <TransitionModal fadeType={fadeType}>
      <ModalContainer>
        <CloseModalWrapper>
          <CloseModalButton onClick={handleClose} />
        </CloseModalWrapper>
        <TextContainer>
          <Title>{i18n.t('FIELD_DATA_WARNING_MODAL:REMINDER')}</Title>
          <Subtitle>{i18n.t('FIELD_DATA_WARNING_MODAL:SUBTITLE')}</Subtitle>
        </TextContainer>
        <ButtonContainer>
          <Button onClick={handleClose}>{i18n.t('FIELD_DATA_WARNING_MODAL:GOT_IT')}</Button>
        </ButtonContainer>
      </ModalContainer>
    </TransitionModal>
  );
};

export default FieldDataWarningModal;
