import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ColorVariableType } from 'enums/colorVariable.enum';
import { getJsonLayer } from 'helpers/get_json_layer.helper';
import { IAsset, ICropVideoSettings, IVideo } from 'interfaces/asset.interface';
import { ILayer } from 'interfaces/layer.interface';
import * as campaignActions from 'redux/actions/campaign.actions';
import * as resizeActions from 'redux/actions/resize.actions';
import * as templateActions from 'redux/actions/template.actions';
import getCampaignSelector, {
  getImageVariablesSorted,
  getOriginalVideo,
  getScenesSorted,
  getVideo,
} from 'redux/selectors/campaign.selector';
import errorSelector from 'redux/selectors/error.selector';
import CampaignEdit from 'views/CampaignEdit';
import Loading from 'views/Loading/Loading';
import { IColorVariable } from 'views/Video/types';

import { CampaignEditProps } from './types';

const CampaignContainer: FC<CampaignEditProps> = (props) => {
  const dispatch = useDispatch();
  const getCampaignData = useSelector(getCampaignSelector());
  const getScenes = useSelector(getScenesSorted());
  const getImageVariables = useSelector(getImageVariablesSorted());
  const video: IVideo | undefined = useSelector(getVideo());
  const errorData = useSelector(errorSelector());
  const [layersData, setLayersData] = useState<ILayer[]>([]);
  const campaignId = props.match.params.campaignId;
  const [backgroundColor, setBackgroundColor] = useState<string>('#FFFFFF');
  const originalVideo = useSelector(getOriginalVideo());

  const changeCampaignName = (name: string) => {
    dispatch(campaignActions.changeCampaignName(name, campaignId));
  };

  const changeTextVariable = (text: string, textId: string, link: string) => {
    dispatch(campaignActions.changeTextVariable(campaignId, text, textId, link));
  };

  const changeColorVariable = (color: string, colorId: string) => {
    dispatch(campaignActions.changeColorVariable(campaignId, color, colorId));
  };

  const changeImageVariable = (file: IAsset, imageId: string) => {
    dispatch(campaignActions.changeImageVariable(campaignId, file, imageId));
  };

  const uploadOriginalVideo = (file: IAsset) => {
    dispatch(campaignActions.onUploadVideoRequested(file));
  };

  const changeVideo = (originalVideoId: string, cropVideoSettings: ICropVideoSettings) => {
    dispatch(campaignActions.changeVideo(campaignId, originalVideoId, cropVideoSettings));
  };

  const backHome = () => {
    dispatch(resizeActions.OnSelectBackHome());
  };

  useEffect(() => {
    if (!layersData.length) {
      getJsonLayer(
        getCampaignData.campaign,
        setLayersData,
        getCampaignData.campaign.template.layers,
        getCampaignData.campaign.textVariables
      );
    }
  }, [getCampaignData.campaign, getCampaignData.campaign.template.layers]);
  /**
   *
   * TODO FIXME @GUIDOG Fix this, THIS IS A HOTFIX TO DOMLOADED
   * BUG WHEN SELECTING A NEW CAMPAIGN OR RETURNING TO EDIT VIEW.
   *
   */

  useEffect(() => {
    dispatch(campaignActions.initializeCampaign(campaignId));
  }, [dispatch]);

  useEffect(() => {
    const color = getCampaignData.campaign.colorVariables.find(
      (color: IColorVariable) => color.type === ColorVariableType.Background
    );
    if (color) {
      setBackgroundColor(color.value);
    }
  }, [getCampaignData.campaign.colorVariables]);

  const onRemoveVideoData = () => {
    dispatch(templateActions.onRemoveVideoData());
  };

  const onSelectEditVideo = () => {
    dispatch(campaignActions.onSelectEditVideoVariable());
  };

  return !!layersData.length && !!getScenes.length ? (
    <CampaignEdit
      backHome={backHome}
      error={errorData.status}
      onSubmit={changeCampaignName}
      onSubmitText={changeTextVariable}
      onSubmitImage={changeImageVariable}
      onSubmitColor={changeColorVariable}
      onNextSubmit={campaignId}
      layers={layersData}
      loading={getCampaignData.loading}
      campaign={getCampaignData.campaign}
      scenes={getScenes}
      onSubmitVideo={changeVideo}
      textVariables={
        getCampaignData.campaign.textVariables ? getCampaignData.campaign.textVariables : []
      }
      imageVariables={getImageVariables}
      colorVariables={
        getCampaignData.campaign.colorVariables ? getCampaignData.campaign.colorVariables : []
      }
      backgroundColor={backgroundColor}
      uploadOriginalVideo={uploadOriginalVideo}
      video={video}
      onRemoveVideoData={onRemoveVideoData}
      originalVideo={originalVideo}
      videoCropSucceed={getCampaignData.videoCropSucceed}
      onSelectEditVideo={onSelectEditVideo}
    />
  ) : (
    <Loading />
  );
};

export default CampaignContainer;
