import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 44px 0;
  margin-top: 20px;
`;

export const InputContainer = styled.div`
  width: 50%;
  margin-bottom: 20px;
`;

export const Label = styled.p`
  width: 100%;
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  margin-bottom: 10px;
`;

export const Input = styled.input`
  width: 500px;
  height: 44px;
  border-radius: 6px;
  border: none;
  outline: none;
  padding: 0 10px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => props.theme.colors.fiord};
  font-weight: 400;
`;

export const WrapperLaunch = styled.div`
  height: calc(100% - 252px);
  min-height: calc(100vh - 252px);
  background: ${(props) => props.theme.colors.aena};
`;
