import { setCookie } from 'helpers/cookies';

import { get, post, put } from './api';

export const login = async (
  email: string,
  password: string
): Promise<{ token: string; expiresIn: string }> => {
  const data = {
    email,
    password,
  };
  const response = await post('auth/login', data);

  setCookie('accessToken', response.data.token, {
    path: '/',
    maxAge: parseFloat(response.data.expiresIn),
  });
  return response.data;
};

export const signUp = async (
  firstName: string,
  lastName: string,
  email: string,
  password: string
): Promise<{ token: string }> => {
  const data = {
    firstName,
    lastName,
    email,
    password,
  };
  const response = await post('auth/register', data);
  return response.data;
};

export const getCampaigns = async (page: number): Promise<string | undefined> => {
  const response = await get(`user/campaigns/${page}`);
  return response.data;
};

export const changeAvatar = async (assetId: string): Promise<string | undefined> => {
  const data = { assetId };
  const response = await put('user/image/', data);
  return response.data;
};

export const getUser = async (): Promise<string | undefined> => {
  const response = await get('user/');
  return response.data;
};

export const changePassword = async (
  oldPassword: string,
  newPassword: string
): Promise<{ token: string; expiresIn: string }> => {
  const data = {
    oldPassword,
    newPassword,
  };
  const response = await post('user/change-password/', data);
  return response.data;
};

export const changeFirstName = async (
  firstName: string
): Promise<{ token: string; expiresIn: string }> => {
  const data = {
    firstName,
  };
  const response = await post('user/change-firstName/', data);
  return response.data;
};

export const changeLastName = async (
  lastName: string
): Promise<{ token: string; expiresIn: string }> => {
  const data = {
    lastName,
  };
  const response = await post('user/change-lastName/', data);
  return response.data;
};

export const forgotPassword = async (
  email: string
): Promise<{ token: string; expiresIn: string }> => {
  const data = {
    email,
  };
  const response = await post('auth/forgot-password/', data);
  return response.data;
};

export const resetPassword = async (
  emailToken: string,
  newPassword: string
): Promise<{ token: string; expiresIn: string }> => {
  const data = {
    emailToken,
    newPassword,
  };
  const response = await post('auth/reset-password', data);
  return response.data;
};

export const confirmAccount = async (emailToken: string): Promise<string | undefined> => {
  const response = await get(`auth/email/verify/${emailToken}`);
  return response.data;
};

export const initializeForgotPassword = async (emailToken: string): Promise<string | undefined> => {
  const response = await get(`auth/reset-password/${emailToken}`);
  return response.data;
};

export const setPendingUpgrade = async (): Promise<string | undefined> => {
  const response = await put('user/upgrade-request', {});
  return response.data;
};
