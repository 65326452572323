import styled from 'styled-components';

import { IButton, IText } from './types';

export const LogInText = styled.h2`
  font-size: 20px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  color: ${(props) => props.theme.colors.fiord};
  margin-bottom: 25px;
`;

export const Inputs = styled.input`
  width: 355px;
  height: 44px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  border: 1px solid ${(props) => props.theme.colors.porcelain};
  border-radius: 6px;
  padding-left: 15px;
`;

export const SendText = styled.p<IText>`
  font-size: 10px;
  color: ${(props) => props.theme.colors.cello};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  opacity: ${(props) => (props.sendText ? '1' : '0')};
  margin-bottom: 30px;
`;

export const ChangePassButton = styled.button<IButton>`
  width: 146px;
  height: 40px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  color: ${(props) => props.theme.colors.white};
  background: ${(props) =>
    !props.sendEmail ? props.theme.colors.cello : props.theme.colors.grayChateau};
  border-radius: 4px;
  border: none;
  cursor: ${(props) => (!props.sendEmail ? 'pointer' : 'default')};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  transition: all 0.2s ease-out;
  &:hover {
    background: ${(props) => !props.sendEmail && props.theme.colors.bigStone};
  }
  margin-right: 30px;
`;

export const ErrorMessage = styled.p`
  font-size: 10px;
  height: 10px;
  text-align: left;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.red};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  margin-bottom: 5px;
  margin-top: 3px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const BackToLogin = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  text-align: left;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.apricot};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
