import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PaywallModal from 'components/PaywallModal/PaywallModal';
import ShareLayout from 'containers/ShareLayout/ShareLayout';
import { ShareStep } from 'enums/shareStep.enum';
import { ShareType } from 'enums/shareType.enum';
import { generateBMSLink } from 'helpers/BMSLinkGenerator.helper';
import { copyToClipboard } from 'helpers/copyToClipboard.helper';
import i18n from 'i18next';
import * as paywallActions from 'redux/actions/paywall.actions';
import * as shareActions from 'redux/actions/share.actions';
import * as userActions from 'redux/actions/user.actions';
import getCampaignSelector from 'redux/selectors/campaign.selector';
import getPaywallData from 'redux/selectors/paywall.selector';
import {
  getEmailStatus,
  getExportData,
  getShareCampaignToCopy,
  getShareData,
} from 'redux/selectors/share.selector';
import { userProductId } from 'redux/selectors/user.selector';
import ShareLaunch from 'views/share/ShareLaunch/ShareLaunch';
import ShareEmailForm from 'views/share/shareEmailForm/ShareEmailForm';
import ShareSuccessEmail from 'views/share/shareSuccessEmail/ShareSuccessEmail';

import './i18n';
import { IEmailData, ShareMatchProps } from './types';

const ShareLaunchContainer: FC<ShareMatchProps> = (props) => {
  const campaignId = props.match.params.campaignId;
  const dispatch = useDispatch();
  const campaignSelector = useSelector(getCampaignSelector());
  const [isCSVFormVisible, setIsCSVFormVisible] = useState(false);
  const [isMailchimpFormVisible, setIsMailchimpFormVisible] = useState(false);
  const [isCreateAndSend, setIsCreateAndSend] = useState(false);
  const exportData = useSelector(getExportData());
  const shareDataEmailStatus = useSelector(getEmailStatus());
  const shareEmailData = useSelector(getShareData());
  const shareCampaignToCopy = useSelector(getShareCampaignToCopy());
  const [checked, setChecked] = useState<string[]>([]);
  const [emailData, setEmailData] = useState<IEmailData[]>([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [disabledNextButton, setDisabledNextButton] = useState<boolean>(false);
  const [body, setBody] = useState<string>('');
  const [from, setFrom] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [dataToSend, setDataToSend] = useState<IEmailData[]>([]);
  const [success, setSuccess] = useState<boolean>(false);
  const [emailThumbnail, setEmailThumbnail] = useState<string>('');
  const paywallData = useSelector(getPaywallData());
  const [isVisibleMessage, setIsVisibleMessage] = useState('');
  const productId = useSelector(userProductId());
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(shareActions.onInitializeShareLaunch(campaignId));
  }, [dispatch]);

  useEffect(() => {
    setBody(campaignSelector.campaign.template.emailBody);
    setSubject(campaignSelector.campaign.template.emailSubject);
  }, [campaignSelector]);

  useEffect(() => {
    if (shareEmailData.length && shareEmailData[0].emailThumbnail) {
      setEmailThumbnail(shareEmailData[0].emailThumbnail);
    }
  }, [shareEmailData]);

  const createDraftCampaign = () => {
    dispatch(
      shareActions.selectMailchimpLaunch(campaignSelector.campaign._id, body, from, subject)
    );
  };

  const sendCampaign = () => {
    dispatch(shareActions.selectMailchimpSend(campaignSelector.campaign._id, body, from, subject));
  };

  const onSubmitSendMail = (shareEmails: IEmailData[]) => {
    if (!disabledNextButton) {
      dispatch(shareActions.onSubmitOneToOneSendMail(body, from, subject, shareEmails, campaignId));
      setDisabledNextButton(true);
    }
  };

  useEffect(() => {
    setDisabledNextButton(isMailchimpFormVisible);
  }, [isMailchimpFormVisible]);

  const onBack = () => {
    setDisabledNextButton(false);
    if (isCSVFormVisible) {
      setIsCSVFormVisible(false);
    } else if (selectedOption === '') {
      setLoading(true);
      dispatch(shareActions.onSelectBackLaunch(campaignId));
    } else {
      setSelectedOption('');
    }
  };

  const onNext = () => {
    if (!isCSVFormVisible && !isMailchimpFormVisible) {
      setIsCSVFormVisible(true);
      setDisabledNextButton(
        !body || !subject || (!from && campaignSelector.campaign.shareType === 2)
      );
    } else {
      if (isMailchimpFormVisible && isCreateAndSend) {
        sendCampaign();
        setDisabledNextButton(true);
      } else {
        if (isMailchimpFormVisible && !isCreateAndSend) {
          createDraftCampaign();
          setDisabledNextButton(true);
        } else {
          onSubmitSendMail(dataToSend);
        }
      }
    }
  };

  useEffect(() => {
    const data = emailData.filter(
      (data) => checked.includes(data.id) && data.name.trim() !== '' && data.email.trim() !== ''
    );
    setDataToSend(data);
    setDisabledNextButton(!checked.length || !data.length);
  }, [emailData, checked]);

  useEffect(() => {
    setDisabledNextButton(
      !body || !subject || (!from && campaignSelector.campaign.shareType === 2)
    );
  }, [body, subject, from]);

  useEffect(() => {
    setSuccess(shareDataEmailStatus);
  }, [shareDataEmailStatus]);

  const onClosePaywallModal = () => {
    dispatch(paywallActions.onClosePaywall());
  };

  const onRequestLink = (id: string) => {
    dispatch(shareActions.onRequestLink(id));
  };

  useEffect(() => {
    if (shareCampaignToCopy !== '') {
      copyToClipboard(generateBMSLink(shareCampaignToCopy));
      setIsVisibleMessage(shareCampaignToCopy);
      dispatch(shareActions.onRequestLinkFailed());
    }
  }, [shareCampaignToCopy]);

  const onRequestUpgrade = () => {
    if (!paywallData.succededUpgradeRequest) {
      dispatch(userActions.onRequestUpgrade());
    }
  };

  return (
    <ShareLayout
      disabledNextButton={disabledNextButton}
      campaignId={campaignId}
      step={ShareStep.LAUNCH}
      onBack={onBack}
      onNext={onNext}
      selectedOption={selectedOption}
      shareType={campaignSelector.campaign.shareType}
      isCSVFormVisible={isCSVFormVisible}
      isMailchimpFormVisible={isMailchimpFormVisible}
      success={success}
      isCreateAndSend={isCreateAndSend}
      title={
        success && (isCSVFormVisible || isCreateAndSend)
          ? i18n.t('SHARELAUNCH:TITLESUCCESS')
          : success && !isCreateAndSend
          ? i18n.t('SHARELAUNCH:TITLEMAILCHIMPFORMSAVE')
          : isMailchimpFormVisible
          ? i18n.t('SHARELAUNCH:TITLEMAILCHIMPFORM')
          : isCSVFormVisible
          ? i18n.t('SHARELAUNCH:TITLEPREVIEW')
          : campaignSelector.campaign.shareType === ShareType.MAILCHIMP
          ? i18n.t('SHARELAUNCH:TITLEMAILCHIMP')
          : selectedOption === 'share'
          ? i18n.t('SHARELAUNCH:TITLESHARECSV')
          : i18n.t('SHARELAUNCH:TITLECSV')
      }
      subTitle={
        campaignSelector.campaign.shareType === ShareType.MAILCHIMP
          ? ''
          : selectedOption === ''
          ? i18n.t('SHARELAUNCH:SUBTITLEOPTIONS')
          : ''
      }
    >
      {!isCSVFormVisible && !success ? (
        <ShareLaunch
          exportData={exportData}
          createDraftCampaign={createDraftCampaign}
          sendCampaign={sendCampaign}
          shareType={campaignSelector.campaign.shareType}
          checked={checked}
          setChecked={setChecked}
          emailData={emailData}
          setEmailData={setEmailData}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          csv={campaignSelector.campaign.shareData?.csv}
          onRequestLink={onRequestLink}
          setIsVisibleMessage={setIsVisibleMessage}
          isVisibleMessage={isVisibleMessage}
          from={from}
          subject={subject}
          body={body}
          setFrom={setFrom}
          setSubject={setSubject}
          setBody={setBody}
          setIsMailchimpFormVisible={setIsMailchimpFormVisible}
          isMailchimpFormVisible={isMailchimpFormVisible}
          setIsCreateAndSend={setIsCreateAndSend}
          couldCopy={paywallData.showPaywall}
          loading={loading}
          setLoading={setLoading}
        />
      ) : success ? (
        <ShareSuccessEmail />
      ) : (
        <ShareEmailForm
          templateThumbnail={emailThumbnail}
          setSubject={setSubject}
          setBody={setBody}
          subject={subject}
          body={body}
        />
      )}
      {paywallData.showPaywall && (
        <PaywallModal
          onClose={onClosePaywallModal}
          paywallMessage={paywallData.paywallMessage}
          succededUpgradeRequest={paywallData.succededUpgradeRequest}
          onRequestUpgrade={onRequestUpgrade}
          productId={productId}
        />
      )}
    </ShareLayout>
  );
};
export default ShareLaunchContainer;
