import React, { FC, useEffect } from 'react';

import Chart from 'chart.js/auto';
import Wrapper from 'components/common/Wrapper/Wrapper';
import StoreLayout from 'containers/StoreLayout/StoreLayout';
import i18n from 'i18next';

import './i18n';
import {
  BGWrapper,
  ChartContainer,
  Container,
  Line,
  PlayCount,
  PlayDuration,
  QuickStats,
  QuickStatsContianer,
  QuickText,
  Stat,
  StatsContainer,
  Summary,
  SummaryContainer,
  SummaryText,
  SummaryTextContainer,
  Text,
  Title,
  WatchSummary,
  options,
} from './styles';
import { IProps } from './types';

const Metrics: FC<IProps> = (props) => {
  const data = {
    labels: props.metrics.duration,
    datasets: [
      {
        label: 'Play Count',
        data: props.metrics.dataSeconds,
        backgroundColor: ['rgba(77, 5, 232, 1)'],
        borderColor: ['rgba(77, 5, 232, 1)'],
        borderWidth: 1,
      },
    ],
  };
  const ctx = 'myChart';
  let myChart: Chart<'bar', number[], number>;
  useEffect(() => {
    myChart = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: options,
    });
    return () => {
      myChart.destroy();
      props.onRemoveData();
    };
  }, []);

  return (
    <StoreLayout isTemplate={''}>
      <BGWrapper>
        <Wrapper>
          {!!props.metrics.duration && (
            <Container>
              {props.metrics.viewsQuantity !== '0' ? (
                <>
                  <Title>{props.metrics.campaignName}</Title>
                  <QuickStatsContianer>
                    <QuickStats>
                      <QuickText>{i18n.t('METRICS:QUICK')}</QuickText>
                    </QuickStats>
                    <StatsContainer>
                      <Text>{i18n.t('METRICS:WATCHTIME')}</Text>
                      <Stat>{props.metrics.totalWatchTime}</Stat>
                    </StatsContainer>
                    <StatsContainer>
                      <Text>{i18n.t('METRICS:VIEWS')}</Text>
                      <Stat>{props.metrics.viewsQuantity}</Stat>
                    </StatsContainer>
                    <StatsContainer>
                      <Text>{i18n.t('METRICS:AVERAGEVIEW')}</Text>
                      <Stat>{props.metrics.averageSecondsView}</Stat>
                    </StatsContainer>
                    {/* <StatsContainer>
              <Text>{i18n.t("METRICS:SOUNDPLAY")}</Text>
              <Stat>{props.metrics.soundPlayedTime.length}</Stat>
            </StatsContainer> */}
                  </QuickStatsContianer>
                  <SummaryContainer>
                    <WatchSummary>
                      <Line></Line>
                      <Summary>{i18n.t('METRICS:SUMMARY')}</Summary>
                    </WatchSummary>
                    <SummaryTextContainer>
                      <SummaryText>
                        Of the {props.metrics.viewsQuantity} people who started watching your video,{' '}
                        {props.metrics.finishedPercentage.finished} finished it (
                        {props.metrics.finishedPercentage.percentage}%)!
                      </SummaryText>
                    </SummaryTextContainer>
                  </SummaryContainer>
                  <ChartContainer>
                    <PlayCount>{i18n.t('METRICS:COUNT')}</PlayCount>
                    <canvas id="myChart" width="200px" height="100px"></canvas>
                  </ChartContainer>
                  <PlayDuration>{i18n.t('METRICS:DURATION')}</PlayDuration>
                </>
              ) : (
                <Title>{i18n.t('METRICS:NOVIEWS')}</Title>
              )}
            </Container>
          )}
        </Wrapper>
      </BGWrapper>
    </StoreLayout>
  );
};
export default Metrics;
