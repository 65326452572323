import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ShareLayout from 'containers/ShareLayout/ShareLayout';
import { ShareStep } from 'enums/shareStep.enum';
import { ShareType } from 'enums/shareType.enum';
import { TextVariableType } from 'enums/textVariable.enum';
import { ICSVRow } from 'helpers/types';
import i18n from 'i18next';
import * as actions from 'redux/actions/share.actions';
import getCampaignSelector from 'redux/selectors/campaign.selector';
import getShareSelector, { getLoading } from 'redux/selectors/share.selector';
import LoadingPoints from 'views/LoadingPoints/LoadingPoints';
import { ITextVariable } from 'views/Video/types';
import ShareMatch from 'views/share/ShareMatch/ShareMatch';

import './i18n';
import { IValue, ShareMatchProps } from './types';

const ShareMatchContainer: FC<ShareMatchProps> = (props) => {
  const [mailChimpMember, setMailChimpMember] = useState([]);
  const campaignId = props.match.params.campaignId;
  const dispatch = useDispatch();
  const campaignData = useSelector(getCampaignSelector());
  const shareSelector = useSelector(getShareSelector());
  const [matchedValues, setMatchedValues] = useState<IValue[][]>([]);
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [CSVData, setCSVData] = useState<ICSVRow[]>();
  const initializeLoading = useSelector(getLoading());

  useEffect(() => {
    dispatch(actions.onInitializeShareMatch(campaignId));
  }, [dispatch, campaignId]);

  let filteredTextVariables = [];
  if (campaignData.campaign.textVariables.length) {
    filteredTextVariables = campaignData.campaign.textVariables.filter(
      (textVariable: ITextVariable) => textVariable.type === TextVariableType.Variable
    );
  }

  useEffect(() => {
    if (campaignData.campaign.shareType === ShareType.CSV) {
      setCSVData(campaignData.campaign.shareData.csv);
    }
  }, [campaignData.campaign.shareData]);

  const onNext = () => {
    if (!disabled) {
      setLoading(true);
      dispatch(
        actions.onSubmitShareMatch(
          campaignId,
          matchedValues,
          filteredTextVariables,
          mailChimpMember
        )
      );
      setDisabled(true);
    }
  };

  const onBack = () => {
    dispatch(actions.onBackShareMatch(campaignId));
  };

  useEffect(() => {
    //TODO FIXME @GUIDOG This is not good, check for undefined fix this with
    // checking the user in sagas or selector.
    if (shareSelector.mailchimp.segmentMembers !== undefined)
      setMailChimpMember(shareSelector.mailchimp.segmentMembers);
  }, [shareSelector.mailchimp.segmentMembers]);

  useEffect(() => {
    let allMatched = !!matchedValues.length;
    matchedValues.forEach((userValues) => {
      userValues.forEach((value) => {
        if (!value.value) {
          allMatched = false;
        }
      });
    });
    setNextButtonDisabled(!allMatched);
  }, [matchedValues]);

  return (
    <ShareLayout
      campaignId={campaignId}
      step={ShareStep.MATCH}
      onBack={onBack}
      onNext={onNext}
      disabledNextButton={nextButtonDisabled || disabled || initializeLoading}
      shareType={campaignData.campaign.shareType}
      title={
        !initializeLoading && campaignData.campaign.shareType === ShareType.MAILCHIMP
          ? i18n.t('SHAREMATCH:MATCH')
          : !initializeLoading && campaignData.campaign.shareType === ShareType.CSV
          ? i18n.t('SHAREMATCH:MATCHCSV')
          : ''
      }
    >
      <ShareMatch
        shareType={campaignData.campaign.shareType}
        textVariables={filteredTextVariables.length ? filteredTextVariables : []}
        fields={shareSelector.mailchimp.listFields.merge_fields}
        csvData={CSVData}
        members={mailChimpMember}
        setMatchedValues={setMatchedValues}
      />
      {loading && <LoadingPoints isFullscreen />}
    </ShareLayout>
  );
};
export default ShareMatchContainer;
