export const FONT_FAMILIES = {
  poppins: {
    regular: 'poppins-regular',
    bold: 'poppins-bold',
    semiBold: 'poppins-semiBold',
    italic: 'poppins-italic',
    medium: 'poppins-medium',
    light: 'poppins-light',
  },
  robotocondensed: {
    bold: 'robotocondensed-bold',
  },
};

export const FONT_SIZES = {
  small: '12px',
  medium: '14px',
  large: '16px',
  extraLarge: '18px',
};
