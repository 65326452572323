import React, { FC, useEffect, useState } from 'react';

import { PUBLIC_URL } from 'configs';
import Lottie, { AnimationItem } from 'lottie-web';
import { v4 as uuidv4 } from 'uuid';

import { AnimationContainer, AnimationLayer, LoadingContainer } from './styles';

const Loading: FC = () => {
  const [id] = useState(uuidv4());
  const [anim, setAnim] = useState<AnimationItem>();

  useEffect(() => {
    if (anim) {
      Lottie.destroy(`loading_points_${id}`);
    }

    return () => {
      Lottie.destroy(`loading_points_${id}`);
    };
  }, []);

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: document.getElementById(`loading_${id}`) as HTMLElement,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: `${PUBLIC_URL}/lottie/loading/loading.json`,
      name: `loading_${id}`,
    });
    setAnim(animation);
  }, []);

  useEffect(() => {
    if (anim) {
      anim.play();
    }
  }, [anim]);

  return (
    <LoadingContainer>
      <AnimationContainer>
        <AnimationLayer id={`loading_${id}`} />
      </AnimationContainer>
    </LoadingContainer>
  );
};
export default Loading;
