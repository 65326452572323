import React, { FC } from 'react';

import Button from 'components/Button/Button';
import CloseModalButton from 'components/CloseModalButton/CloseModalButton';
import PreviewPlayer from 'components/PreviewPlayer/PreviewPlayer';
import { TextVariableType } from 'enums/textVariable.enum';
import i18n from 'i18next';
import { ITextVariable } from 'views/Video/types';

import './i18n';
import {
  ButtonWrapper,
  EditIcon,
  Footer,
  HoverEditIcon,
  IconWrapper,
  InputName,
  Inputs,
  LeftContainer,
  ModalContainer,
  ModalWrapper,
  PlayerWrapper,
  RightContainer,
  Section,
  Subtitle,
  TemplateInformation,
  Text,
  Title,
} from './styles';
import { IProps } from './types';

const TemplatePreview: FC<IProps> = (props) => {
  const onClickEditTemplate = () => {
    if (props.onEditTemplate) {
      props.onEditTemplate();
    }
  };

  return (
    <ModalWrapper>
      <ModalContainer>
        <CloseModalButton onClick={props.onCloseModal} />
        <TemplateInformation>
          <LeftContainer>
            <Section>
              <Title>{props.title}</Title>
              <Text>{props.description}</Text>
            </Section>
            <Section>
              <Subtitle>{i18n.t('PREVIEW:PERSONALIZED_INPUTS')}</Subtitle>
              <Inputs>
                {props.textVariables
                  .filter(
                    (textVariable: ITextVariable) => textVariable.type === TextVariableType.Variable
                  )
                  .map((textVariable: ITextVariable) => {
                    return <InputName key={textVariable._id}>{textVariable.value}</InputName>;
                  })}
              </Inputs>
            </Section>
            <Section>
              <Subtitle>{i18n.t('PREVIEW:CUSTOMIZED_ELEMENTS')}</Subtitle>
              <Text>{props.elements}</Text>
            </Section>
          </LeftContainer>
          <RightContainer>
            <PlayerWrapper>
              <PreviewPlayer
                layers={props.layers}
                backgroundColor={props.backgroundColor}
                isTemplate={true}
                audio={props.audio}
                key={props.id}
                textVariables={props.textVariables}
                colorVariables={props.colorVariables}
                imageVariables={props.imageVariables}
                backgroundVideo={props.backgroundVideo}
                scenes={props.scenes}
                onRemoveVideoData={props.onRemoveVideoData}
                duration={props.duration}
              />
            </PlayerWrapper>
          </RightContainer>
        </TemplateInformation>
        <Footer>
          <ButtonWrapper>
            <Button onClick={onClickEditTemplate}>
              <IconWrapper>
                <EditIcon />
                <HoverEditIcon />
              </IconWrapper>
              {i18n.t('PREVIEW:EDIT')}
            </Button>
          </ButtonWrapper>
        </Footer>
      </ModalContainer>
    </ModalWrapper>
  );
};

export default TemplatePreview;
