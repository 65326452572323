import React, { FC } from 'react';

import NewBMSPersonalizedCampaign from 'views/NewBMSPersonalizedCampaign/NewBMSPersonalizedCampaign';
import NewBMSTemplate from 'views/NewBMSTemplate/NewBMSTemplate';
import NewBMSTemplateScenes from 'views/NewBMSTemplateScenes/NewBMSTemplateScenes';

import { WrapperBMS } from './styles';
import { IProps } from './types';

const NewBMS: FC<IProps> = (props) => {
  return (
    <WrapperBMS>
      {props.isExistingTemplate && !props.nextStep && (
        <NewBMSTemplate onClickNextStep={props.onClickNextStep} templates={props.templates} />
      )}
      {props.isExistingTemplate && props.nextStep && !!props.template.layers.length && (
        <NewBMSTemplateScenes
          onSubmit={props.onSubmit}
          template={props.template}
          stepBack={props.onStepBack}
          onRemoveVideoData={props.onRemoveVideoData}
        />
      )}
      {!props.isExistingTemplate && <NewBMSPersonalizedCampaign onSubmit={props.onSubmitCustom} />}
    </WrapperBMS>
  );
};
export default NewBMS;
