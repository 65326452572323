import styled from 'styled-components';

export const CampaignContainer = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${(props) => props.theme.colors.porcelain};
  margin: 5px 0;
  justify-content: space-between;
`;

export const DotsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeftContainer = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NameTooltip = styled.div`
  width: 140px;
  position: absolute;
  display: none;
  padding: 12px;
  background: ${(props) => props.theme.colors.bigStone};
  top: 30px;
  left: 0;
  z-index: 100;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000041;
  &:after {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: -10px;
    left: 10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${(props) => props.theme.colors.bigStone};
  }
`;

export const NameTooltipText = styled.p`
  width: 100%;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => props.theme.colors.white};
  word-break: break-all;
  white-space: break-spaces;
`;

export const CampaignName = styled.div`
  width: 125px;
  margin-left: 20px;
  cursor: pointer;
  position: relative;

  &:hover ${NameTooltip} {
    display: block;
  }
`;

export const CampaignText = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RightContainer = styled.div`
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DraftDate = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.light};
`;

export const SentDate = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.cello};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
`;

export const CreatedDate = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  text-align: center;
  color: #a0a7af;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
`;
