import { AnyAction } from 'redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as campaignServices from 'service/campaign.service';
import * as shareServices from 'service/share.service';

import * as myCampaignsActions from '../actions/myCampaigns.actions';
import * as constants from '../constants/myCampaigns.action.constants';

function* deleteCampaign(action: AnyAction) {
  try {
    yield call(campaignServices.deleteCampaign, action.id);
    yield put(myCampaignsActions.deleteCampaignSucceded({ id: action.id }));
  } catch (err) {
    yield put(myCampaignsActions.deleteCampaignFailed());
  }
}

function* relaunchCampaign(action: AnyAction) {
  try {
    const shareCampaigns = yield call(shareServices.getShareCampaign, action.id);
    let url = SHARE_URL(action.id);
    if (!!action.shareType && action.shareType !== 0) {
      if (shareCampaigns.length > 0) {
        url = `${SHARE_URL(action.id)}/launch`;
      } else if (action.campaignShareData) {
        url = `${SHARE_URL(action.id)}/match`;
      } else {
        url =
          action.shareType === 1
            ? `${SHARE_URL(action.id)}/import/csv`
            : `${SHARE_URL(action.id)}/select`;
      }
    }
    yield put(myCampaignsActions.relaunchCampaignSucceded(url));
  } catch (err) {
    yield put(myCampaignsActions.relaunchCampaignFailed());
  }
}

const SHARE_URL = (id: string) => {
  return `/campaign/${id}/share`;
};

export default all([
  takeLatest(constants.ON_DELETE_CAMPAIGN_REQUESTED, deleteCampaign),
  takeLatest(constants.ON_RELAUNCH_CAMPAIGN_REQUESTED, relaunchCampaign),
]);
