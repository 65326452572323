import styled from 'styled-components';

import { IRemove, ISelected } from './types';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const Image = styled.img`
  width: 170px;
  height: 185px;
  background-size: cover;
  background-position: center;
`;

export const DroppableContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  align-items: center;
`;

export const DropContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 30px;
`;

export const Name = styled.p`
  width: 100%;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const DroppableStyle = styled.div`
  width: auto;
  height: auto;
`;

export const Remove = styled.div<IRemove>`
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: block;
  background-size: cover;
  margin-right: 5px;
  background-repeat: no-repeat;
  align-items: center;
  background-size: contain;
  background-image: url(${(props) => props.image});
  transition: all 0.2s ease-out;
`;

export const SelectedItem = styled.div<ISelected>`
  user-select: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.white};
  width: 215px;
  height: 40px;
  border-radius: 4px;
  background: ${(props) => (props.selected ? props.theme.colors.apricot : '')};
  cursor: ${(props) => (props.selected ? 'pointer' : 'default')};
  &:hover {
    ${Remove} {
      background-image: url(${(props) => props.hover});
    }
  }
`;

export const Text = styled.p`
  width: 80%;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const TextAndRemoveContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const RemoveContainer = styled.div`
  width: 30px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const ItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const Item = styled.div`
  width: 190px;
  height: 40px;
  user-select: none;
  margin: 0 10px 20px 0;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  flex: 0 0 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${(props) => props.theme.colors.apricot};
  transition: all 0.2s ease-out;
  &:hover {
    background: ${(props) => props.theme.colors.terracota};
  }
`;

export const SelectedItemWrapper = styled.div<ISelected>`
  border: ${(props) => (props.selected ? '' : `1px dashed ${props.theme.colors.grayChateau}`)};
  border-radius: 4px;
`;

export const LeftContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const RightContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

export const SegmentText = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  text-align: left;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
`;

export const DropText = styled.p`
  width: 100%;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  color: ${(props) => props.theme.colors.grayChateau};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;
