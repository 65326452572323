import React, { FC } from 'react';

import { WrapperStyle } from './styles';

interface IProps {
  children: React.ReactNode;
}

const Wrapper: FC<IProps> = (props) => {
  return <WrapperStyle>{props.children}</WrapperStyle>;
};
export default Wrapper;
