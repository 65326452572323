import React, { FC, useEffect, useState } from 'react';

import i18n from 'i18next';
import moment from 'moment';
import LoadingPoints from 'views/LoadingPoints/LoadingPoints';

import { IExportData } from '../types';
import './i18n';
import {
  CSV,
  Container,
  IconCSV,
  IconCSVHover,
  IconOne,
  IconOneHover,
  OptionText,
  OptionsContCSV,
  OptionsContOne,
  OptionsContainer,
  Or,
  SubTitle,
  WrapperLaunch,
} from './styles';
import { IProps } from './types';

const ShareLaunchCSV: FC<IProps> = (props: IProps) => {
  const [dataToDownload, setDataToDownload] = useState<IExportData[]>([]);

  useEffect(() => {
    setDataToDownload(
      props.exportData.map((data: IExportData) => {
        data.values = data.values.map((value) => value.value);
        return data;
      })
    );
    if (props.exportData.length) {
      props.setLoading(false);
    }
  }, [props.exportData]);

  return (
    <WrapperLaunch>
      {props.loading && <LoadingPoints isFullscreen />}
      <Container>
        <SubTitle>{i18n.t('SHARELAUNCH:WHAT')}</SubTitle>
        <OptionsContainer>
          <CSV data={dataToDownload} filename={`BMS_${moment().format('YYYY_MM_DD')}.csv`}>
            <OptionsContCSV>
              <IconCSV />
              <IconCSVHover />
              <OptionText>{i18n.t('SHARELAUNCH:DOWNLOAD')}</OptionText>
            </OptionsContCSV>
          </CSV>
          <Or>OR</Or>
          <OptionsContOne onClick={() => props.setSelectedOption('share')}>
            <IconOne />
            <IconOneHover />
            <OptionText>{i18n.t('SHARELAUNCH:SHARE')}</OptionText>
          </OptionsContOne>
        </OptionsContainer>
      </Container>
    </WrapperLaunch>
  );
};
export default ShareLaunchCSV;
