import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'SHAREMATCH', {
  MATCH: 'Match the segments fields with your BMS template',
  SEGMENTFIELDS: 'Segment fields',
  BMSFIELDS: 'BMS fields',
  DROP: 'Drop field here',
  NAME: '#Name',
  ITEMS: '#Items',
  DISCOUNT: '#Discount',
  SELECTHEADER: 'Select a Header Value',
});
