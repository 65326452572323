import React, { FC } from 'react';

import { ReactComponent as FinishedIcon } from 'assets/check.svg';
import { PUBLIC_URL } from 'configs';
import { ShareStep } from 'enums/shareStep.enum';
import { ShareType } from 'enums/shareType.enum';
import i18n from 'i18next';

import './i18n';
import {
  DotsWrapper,
  EditIcon,
  FinishedStep,
  StepDot,
  StepDotEdit,
  StepEdit,
  StepNumber,
  StepText,
  StepWrapper,
  Wrapper,
} from './styles';
import { IProps } from './types';

const ProgressBar: FC<IProps> = (props) => {
  return (
    <Wrapper>
      <DotsWrapper>
        <StepWrapper step={ShareStep.NONE}>
          <StepDotEdit onClick={props.onSelectEditTemplate}>
            <EditIcon src={`${PUBLIC_URL}/images/edit-pencil.svg`} alt="Edit pencil icon" />
          </StepDotEdit>
          <StepEdit step={ShareStep.NONE} currentStep={props.currentStep}>
            {i18n.t('PROGRESS_BAR:EDIT')}
          </StepEdit>
        </StepWrapper>
        <StepWrapper step={ShareStep.IMPORT}>
          {props.currentStep <= ShareStep.IMPORT ? (
            <StepDot step={ShareStep.IMPORT} currentStep={props.currentStep}>
              <StepNumber step={ShareStep.IMPORT} currentStep={props.currentStep}>
                {i18n.t('PROGRESS_BAR:ONE')}
              </StepNumber>
            </StepDot>
          ) : (
            <FinishedStep step={ShareStep.IMPORT} currentStep={props.currentStep}>
              <FinishedIcon />
            </FinishedStep>
          )}
          <StepText step={ShareStep.IMPORT} currentStep={props.currentStep}>
            {i18n.t('PROGRESS_BAR:IMPORT')}
          </StepText>
        </StepWrapper>
        <StepWrapper step={ShareStep.SELECT}>
          {props.currentStep <= ShareStep.SELECT ? (
            <StepDot step={ShareStep.SELECT} currentStep={props.currentStep}>
              <StepNumber step={ShareStep.SELECT} currentStep={props.currentStep}>
                {i18n.t('PROGRESS_BAR:TWO')}
              </StepNumber>
            </StepDot>
          ) : (
            <FinishedStep step={ShareStep.SELECT} currentStep={props.currentStep}>
              <FinishedIcon />
            </FinishedStep>
          )}
          <StepText step={ShareStep.SELECT} currentStep={props.currentStep}>
            {props.shareType && props.shareType === ShareType.CSV
              ? i18n.t('PROGRESS_BAR:VERIFY')
              : i18n.t('PROGRESS_BAR:SELECT')}
          </StepText>
        </StepWrapper>
        <StepWrapper step={ShareStep.MATCH}>
          {props.currentStep <= ShareStep.MATCH ? (
            <StepDot step={ShareStep.MATCH} currentStep={props.currentStep}>
              <StepNumber step={ShareStep.MATCH} currentStep={props.currentStep}>
                {i18n.t('PROGRESS_BAR:THREE')}
              </StepNumber>
            </StepDot>
          ) : (
            <FinishedStep step={ShareStep.MATCH} currentStep={props.currentStep}>
              <FinishedIcon />
            </FinishedStep>
          )}
          <StepText step={ShareStep.MATCH} currentStep={props.currentStep}>
            {i18n.t('PROGRESS_BAR:MATCH')}
          </StepText>
        </StepWrapper>
        <StepWrapper step={ShareStep.LAUNCH}>
          {props.currentStep <= ShareStep.LAUNCH && !props.success ? (
            <StepDot step={ShareStep.LAUNCH} currentStep={props.currentStep}>
              <StepNumber step={ShareStep.LAUNCH} currentStep={props.currentStep}>
                {i18n.t('PROGRESS_BAR:FOUR')}
              </StepNumber>
            </StepDot>
          ) : (
            <FinishedStep
              step={ShareStep.LAUNCH}
              currentStep={props.currentStep}
              success={props.success}
            >
              <FinishedIcon />
            </FinishedStep>
          )}
          <StepText step={ShareStep.LAUNCH} currentStep={props.currentStep}>
            {i18n.t('PROGRESS_BAR:LAUNCH')}
          </StepText>
        </StepWrapper>
      </DotsWrapper>
    </Wrapper>
  );
};
export default ProgressBar;
