import { AnyAction } from 'redux';
import * as constants from 'redux/constants/paywall.action.constants';
import * as usersConstants from 'redux/constants/user.actions.constants';
import { ICampaign } from 'views/CampaignEdit/types';

interface IPaywallReducerState {
  showPaywall: boolean;
  paywallMessage: string;
  succededUpgradeRequest: boolean;
  aboutToExpireCampaignsToAlert: ICampaign[];
  expiredCampaignsToAlert: ICampaign[];
}

const defaultState: IPaywallReducerState = {
  showPaywall: false,
  paywallMessage: '',
  succededUpgradeRequest: false,
  aboutToExpireCampaignsToAlert: [],
  expiredCampaignsToAlert: [],
};

const setPaywallVisible = (state: IPaywallReducerState, action: AnyAction) => {
  return {
    ...state,
    showPaywall: true,
    paywallMessage: action.data,
  };
};

const setPaywallInvisible = (state: IPaywallReducerState) => {
  return {
    ...state,
    showPaywall: false,
    paywallMessage: '',
    succededUpgradeRequest: false,
    aboutToExpireCampaignsToAlert: [],
    expiredCampaignsToAlert: [],
  };
};

const setUpgradeRequestSucced = (state: IPaywallReducerState) => {
  return {
    ...state,
    succededUpgradeRequest: true,
  };
};

const setCampaignsExpiredPaywallVisible = (state: IPaywallReducerState, action: AnyAction) => {
  return {
    ...state,
    showPaywall: true,
    paywallMessage: action.data,
    aboutToExpireCampaignsToAlert: action.aboutToExpireCampaignsToAlert,
    expiredCampaignsToAlert: action.expiredCampaignsToAlert,
  };
};

const paywallReducer = (
  state: IPaywallReducerState = defaultState,
  action: AnyAction
): IPaywallReducerState => {
  switch (action.type) {
    case constants.CREATE_CAMPAIGN_LIMIT_EXCEEDED:
    case constants.ON_SELECT_TEMPLATE_FAILED_TEMPLATE_NOT_AVAILABLE_FOR_SUB:
    case constants.MASSIVE_SHARE_CAMPAIGN_LIMIT:
    case constants.SHARED_SHARE_CAMPAIGN_LIMIT:
      return setPaywallVisible(state, action);
    case constants.ON_CLOSE_PAYWALL_MODAL:
      return setPaywallInvisible(state);
    case usersConstants.ON_REQUEST_UPGRADE_SUCCEDED:
      return setUpgradeRequestSucced(state);
    case constants.ALERT_CAMPAIGN_LIFE_TIME_LIMIT:
      return setCampaignsExpiredPaywallVisible(state, action);
    default:
      return state;
  }
};

export default paywallReducer;
