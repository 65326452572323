import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IAsset } from 'interfaces/asset.interface';
import * as userActions from 'redux/actions/user.actions';
import errorSelector from 'redux/selectors/error.selector';
import getUserSelector from 'redux/selectors/user.selector';
import UserProfile from 'views/UserProfile/UserProfile';

const UserProfileContainer: FC = () => {
  const dispatch = useDispatch();
  const getUserData = useSelector(getUserSelector());
  const errorData = useSelector(errorSelector());

  const changeAvatarPicture = (file: IAsset) => {
    dispatch(userActions.changeAvatar(file));
  };

  const changePassword = (oldPassword: string, newPassword: string) => {
    dispatch(userActions.changePassword(oldPassword, newPassword));
  };

  const changeFirstName = (firstName: string) => {
    dispatch(userActions.changeFirstName(firstName));
  };

  const changeLastName = (lastName: string) => {
    dispatch(userActions.changeLastName(lastName));
  };

  useEffect(() => {
    dispatch(userActions.initializeUser());
  }, [dispatch]);

  const changeStatusPassword = () => {
    dispatch(userActions.changeStatusPassword());
  };

  const changeStatusProfile = () => {
    dispatch(userActions.changeStatusProfile());
  };

  const setErrorFalse = () => {
    dispatch(userActions.changeErrorValue());
  };

  return (
    <UserProfile
      changeError={setErrorFalse}
      changeStatusProfile={changeStatusProfile}
      changeStatusPassword={changeStatusPassword}
      error={errorData.status}
      statusProfile={getUserData.user.statusProfile}
      userInfo={getUserData.user.user}
      statusPassword={getUserData.user.statusPassword}
      onSubmitImage={changeAvatarPicture}
      changePass={changePassword}
      changeFirstName={changeFirstName}
      changeLastName={changeLastName}
    />
  );
};

export default UserProfileContainer;
