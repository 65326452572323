/* eslint-disable */
// https://github.com/bodymovin/lottie-api/issues/30
interface textChangeProps {
  t?: string;
  s?: number;
  fc?: number[];
  lh?: number;
  sc?: number[];
  j?: number;
}

const replaceTexts = (animationApi, idTextToEdit: string[], textProps: textChangeProps[]): void => {
  idTextToEdit.forEach((textId: string, index: number) => {
    animationApi.getElements().forEach((element) => {
      const elem = element;
      if (elem.hasProperty(textId)) {
        elem.getProperty(textId).setDocumentData(textProps[index], 0);
      }
    });
  });
};

export default replaceTexts;
