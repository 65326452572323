import { CampaignError } from 'enums/campaignError.enum';
import { ShareType } from 'enums/shareType.enum';
import { IObject } from 'helpers/types';
import { IAPIError } from 'interfaces/api.interface';
import { AnyAction } from 'redux';
import { buffers } from 'redux-saga';
import { all, call, delay, put, spawn, takeLatest } from 'redux-saga/effects';
import {
  onShareCSVImport,
  onShareCSVImportFailed,
  onShareCSVImportSucceeded,
} from 'redux/actions/campaign.actions';
import * as paywallActions from 'redux/actions/paywall.actions';
import { ON_SHARE_CSV_IMPORT } from 'redux/constants/campaign.action.constants';
import {
  changeShareType,
  createShareData,
  getCampaign,
  getCampaignShareData,
} from 'service/campaign.service';
import * as shareService from 'service/share.service';
import { removeShareCampaigns } from 'service/share.service';

import * as actions from '../actions/share.actions';
import * as constants from '../constants/share.action.constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* channelRowCreationMailchimp(buffer: any, campaignId: string) {
  while (true) {
    const action: AnyAction = buffer.take();
    yield call(
      shareService.createShareCampaign,
      campaignId,
      action.value,
      action.textVariables,
      action.mailChimpMember
    );
    yield delay(10);
    if (buffer.isEmpty()) {
      break;
    }
  }
  yield put(actions.onSubmitShareMatchSuccedeed(campaignId));
}

function* createShareCampaign(action: AnyAction) {
  try {
    const buffer = buffers.expanding();

    yield all(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      action.values.map((row: any, index: number) => {
        return buffer.put({
          type: constants.ON_PARSE_ROW_CREATE_REQUEST,
          value: row,
          textVariables: action.textVariables,
          mailChimpMember: action.mailChimpMembers[index],
        });
      })
    );
    yield spawn(channelRowCreationMailchimp, buffer, action.campaignId);
  } catch (err) {
    yield put(actions.onSubmitShareMatchFailed());
  }
}

function* getShareCampaign(action: AnyAction) {
  try {
    const response = yield call(shareService.getShareCampaign, action.id);
    yield put(actions.initializeCampaignShareSuccedeed(response));
  } catch (err) {
    yield put(actions.initializeCampaignShareFailed());
  }
}

function* shareCampaignSendMail(action: AnyAction) {
  try {
    yield call(
      shareService.sendShareEmail,
      action.title,
      action.from,
      action.subject,
      action.shareEmails,
      action.campaignId
    );
    yield put(actions.onSubmitOneToOneSendMailSuccedeed());
  } catch (err) {
    const errorMessage = (err as IAPIError).errorMessage;
    if (errorMessage === CampaignError.MASSIVE_SHARE_CAMPAIGN_LIMIT) {
      yield put(paywallActions.massiveShareCampaignLimit(errorMessage));
    } else {
      yield put(actions.onSubmitOneToOneSendMailFailed());
    }
  }
}

function* getMailchimpLists() {
  try {
    const responseLists = yield call(shareService.getMailchimpLists);
    const { responseSegments, responseMergeFields } = yield all({
      responseSegments: yield call(
        shareService.getMailchimpListSegments,
        responseLists.lists[0].id
      ),
      responseMergeFields: yield call(
        shareService.getMailchimpListMergeFields,
        responseLists.lists[0].id
      ),
    });
    const data = {
      lists: responseLists,
      segments: responseSegments,
      mergeFields: responseMergeFields,
    };
    yield put(actions.onMailchimpImportSucceded(data));
  } catch (err) {
    yield put(actions.onMailchimpImportFailed());
  }
}

function* getCSVShareData(action: AnyAction) {
  try {
    const response = yield call(getCampaignShareData, action.id);
    const data = {
      campaign: response,
    };
    yield put(onShareCSVImportSucceeded(data));
  } catch (err) {
    yield put(onShareCSVImportFailed());
  }
}

function* selectMailchimp(action: AnyAction) {
  try {
    const campaign = yield call(changeShareType, action.campaignId, action.shareType);
    const response = yield call(shareService.mailchimpLogin, campaign._id);
    yield put(actions.onSelectMailchimpSucceded(response, campaign));
  } catch (err) {
    yield put(actions.onSelectMailchimpFailed());
  }
}

function* selectCSV(action: AnyAction) {
  try {
    const campaign = yield call(changeShareType, action.campaignId, action.shareType);
    yield put(actions.onSelectCSVSucceed(action.campaignId, campaign));
  } catch (err) {
    yield put(actions.onSelectCSVFailed());
  }
}

function* getListMergeFields(action: AnyAction) {
  try {
    const response = yield call(shareService.getListMergeFields, action.listId);
    yield put(actions.initializeShareMatchMergeFieldsSuccedeed(response));
  } catch (err) {
    yield put(actions.initializeShareMatchMergeFieldsFailed());
  }
}

function* getSegmentMembers(action: AnyAction) {
  try {
    const response = yield call(shareService.getSegmentMembers, action.listId, action.segmentId);
    yield put(actions.initializeShareMatchSegmenteMembersSuccedeed(response));
  } catch (err) {
    yield put(actions.initializeShareMatchSegmenteMembersFailed());
  }
}

function* createMailchimpDraftCampaign(action: AnyAction) {
  try {
    yield call(
      shareService.createDraftCampaign,
      action.campaignId,
      action.body,
      action.from,
      action.subject
    );
    yield put(actions.selectMailchimpLaunchSuccedeed());
  } catch (err) {
    yield put(actions.selectMailchimpLaunchFailed());
  }
}

function* sendMailchimpCampaign(action: AnyAction) {
  try {
    const mailchimpCampaign = yield call(
      shareService.createDraftCampaign,
      action.campaignId,
      action.body,
      action.from,
      action.subject
    );
    yield call(shareService.sendMailchimpEmail, mailchimpCampaign.id, action.campaignId);
    yield put(actions.selectMailchimpSendSuccedeed());
  } catch (err) {
    yield put(actions.selectMailchimpSendFailed());
  }
}

function* initializeShareMatch(action: AnyAction) {
  try {
    const campaign = yield call(getCampaignShareData, action.id);
    let mergeFields = {};
    let segmentMembers: IObject = {};
    if (campaign.shareData['listId'] && campaign.shareData['segmentId']) {
      mergeFields = yield call(shareService.getListMergeFields, campaign.shareData.listId);
      segmentMembers = yield call(
        shareService.getSegmentMembers,
        campaign.shareData.listId,
        campaign.shareData.segmentId
      );
    }
    const data = {
      campaign: campaign,
      mergeFields: mergeFields,
      members: segmentMembers.members,
    };

    yield put(actions.onInitializeShareMatchSucceded(data));
  } catch (err) {
    yield put(actions.onInitializeShareMatchFailed());
  }
}

function* validateShareCampaignsEmail(action: AnyAction) {
  try {
    yield call(shareService.validateLimit, action.id);
    yield put(actions.onRequestLinkSucceded(action.id));
  } catch (err) {
    const errorMessage = (err as IAPIError).errorMessage;
    if (errorMessage === CampaignError.SHARED_SHARE_CAMPAIGN_LIMIT) {
      yield put(paywallActions.sharedShareCampaignsLimit(errorMessage));
    }
  }
}

function* resetShareData(action: AnyAction) {
  try {
    if (action.shareType !== 1) {
      yield call(changeShareType, action.campaignId, ShareType.NONE);
      yield call(createShareData, action.campaignId, null);
    }
    yield put(actions.onBackShareSelectSucceed(action.campaignId, action.shareType));
  } catch (err) {
    yield put(actions.onBackShareSelectFailed());
  }
}

function* onSelectBackLaunch(action: AnyAction) {
  try {
    const response = yield call(removeShareCampaigns, action.campaignId);
    if (!response) {
      yield put(actions.onSelectBackLaunchFailed());
    }
    yield put(actions.onSelectBackLaunchSucceed(action.campaignId));
  } catch (err) {
    yield put(actions.onSelectBackLaunchFailed());
  }
}

function* onInitializeShareSelect(action: AnyAction) {
  try {
    const campaign = yield call(getCampaign, action.campaignId);
    if (!campaign) {
      yield put(actions.OnInitializeShareSelectFailed());
    }
    yield put(actions.OnInitializeShareSelectSucceeded(campaign));
    if (campaign.shareType === ShareType.CSV) {
      yield put(onShareCSVImport(action.campaignId));
    } else if (campaign.shareType === ShareType.MAILCHIMP) {
      yield put(actions.onMailchimpImport(action.campaignId));
    }
  } catch (err) {
    yield put(actions.onSelectBackLaunchFailed());
  }
}

export default all([
  takeLatest(constants.ON_SUBMIT_SHARE_MATCH_REQUESTED, createShareCampaign),
  takeLatest(constants.ON_SUBMIT_ONE_TO_ONE_SEND_MAIL_REQUESTED, shareCampaignSendMail),
  takeLatest(ON_SHARE_CSV_IMPORT, getCSVShareData),
  takeLatest(constants.ON_MAILCHIMP_IMPORT_REQUESTED, getMailchimpLists),

  takeLatest(constants.ON_SELECT_MAILCHIMP_REQUESTED, selectMailchimp),
  takeLatest(constants.ON_SELECT_CSV_REQUESTED, selectCSV),
  takeLatest(constants.ON_INITIALIZE_MATCH_VIEW_REQUESTED, getListMergeFields),
  takeLatest(constants.ON_INITIALIZE_MATCH_VIEW_REQUESTED, getSegmentMembers),
  takeLatest(constants.ON_SELECT_MAILCHIMP_LAUNCH_REQUESTED, createMailchimpDraftCampaign),
  takeLatest(constants.ON_SELECT_MAILCHIMP_SEND_MAIL_REQUESTED, sendMailchimpCampaign),
  takeLatest(constants.ON_INITIALIZE_SHARE_MATCH_REQUESTED, initializeShareMatch),
  takeLatest(constants.ON_INITIALIZE_SHARE_LAUNCH_REQUESTED, getShareCampaign),
  takeLatest(constants.ON_REQUEST_LINK, validateShareCampaignsEmail),
  takeLatest(constants.ON_RETRIEVE_SEGMENT_MEMBERS, getSegmentMembers),
  takeLatest(constants.ON_CLICK_BACK_SHARE_SELECT, resetShareData),
  takeLatest(constants.ON_SELECT_BACK_SHARE_LAUNCH, onSelectBackLaunch),
  takeLatest(constants.ON_INITIALIZE_SELECT_VIEW_REQUESTED, onInitializeShareSelect),
]);
