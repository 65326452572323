import React, { FC, useEffect, useState } from 'react';

import CloseModalButton from 'components/CloseModalButton/CloseModalButton';
import { PUBLIC_URL } from 'configs/index';
import i18n from 'i18next';

import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import Login from './components/Login/Login';
import SignUp from './components/SignUp/SignUp';
import './i18n';
import {
  CatContainer,
  CloseModalWrapper,
  Container,
  Form,
  FormContainer,
  Image,
  ModalContainer,
  ModalWrapper,
  Slick,
  Slide,
  SliderContainer,
  SuccessMessage,
  TextBelowImage,
} from './styles';
import { IProps } from './types';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  useTransform: false,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
};

const ModalLogin: FC<IProps> = (props) => {
  const [forgotPasword, setForgotPassword] = useState<boolean>(false);

  const signUp = () => {
    props.changeSignUp(true);
    props.setErrorFalse();
  };

  const login = () => {
    props.changeSignUp(false);
    setForgotPassword(false);
    props.setErrorFalse();
  };

  useEffect(() => {
    if (props.setRegisteredSuccesfully && props.statusSignUp) {
      props.setRegisteredSuccesfully(true);
      props.changeSignUp(false);
    }
  }, [props.statusSignUp]);

  const handleForgotPassword = () => {
    setForgotPassword(true);
  };

  return (
    <ModalWrapper>
      <ModalContainer>
        <CloseModalWrapper>
          <CloseModalButton onClick={props.onClose} />
        </CloseModalWrapper>
        <Container>
          <FormContainer>
            {forgotPasword ? (
              <ForgotPassword
                successSendEmail={props.successSendEmail}
                changeError={props.setErrorFalse}
                login={login}
                error={props.error}
                resetPassword={props.resetPassword}
              ></ForgotPassword>
            ) : (
              <Form>
                {props.isSignUp ? (
                  <SignUp
                    login={login}
                    changeError={props.setErrorFalse}
                    error={props.error}
                    onSubmitSignUp={props.onSubmitSignUp}
                  ></SignUp>
                ) : (
                  <>
                    {props.registeredSuccesfully ? (
                      <SuccessMessage>{i18n.t('LOGIN:REGISTER')}</SuccessMessage>
                    ) : (
                      <Login
                        signUp={signUp}
                        changeError={props.setErrorFalse}
                        error={props.error}
                        onSubmitLogin={props.onSubmitLogin}
                        handleForgotPassword={handleForgotPassword}
                      ></Login>
                    )}
                  </>
                )}
              </Form>
            )}
          </FormContainer>
          <CatContainer>
            <SliderContainer>
              <Slick key={`templates-${2}`} {...settings}>
                <Slide key={1}>
                  <Image src={`${PUBLIC_URL}/images/img-pop-login-1@2x-min.png`} />
                  <TextBelowImage>{i18n.t('LOGIN:LIST_USER')}</TextBelowImage>
                </Slide>
                <Slide key={2}>
                  <Image src={`${PUBLIC_URL}/images/img-pop-login-2@2x-min.png`} />
                  <TextBelowImage>{i18n.t('LOGIN:WE_GENERATE')}</TextBelowImage>
                </Slide>
                <Slide key={3}>
                  <Image src={`${PUBLIC_URL}/images/img-pop-login-3@2x-min.png`} />
                  <TextBelowImage>{i18n.t('LOGIN:SUBMIT_THE_CAMPAIGN')}</TextBelowImage>
                </Slide>
              </Slick>
            </SliderContainer>
          </CatContainer>
        </Container>
      </ModalContainer>
    </ModalWrapper>
  );
};

export default ModalLogin;
