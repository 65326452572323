/* eslint-disable */
import { IImageVariable } from '../types';

const replaceImages = (
  animationApi: any,
  imageVariables: IImageVariable[],
  paths: string[]
): void => {
  const newImageVariables = imageVariables.map((imagevariable: IImageVariable) => {
    const newImageVariable = { ...imagevariable };
    const index = newImageVariable.name.split('_')[1];
    newImageVariable.name = `img_${index}`;
    return newImageVariable;
  });
  animationApi.getElements().forEach((element: any) => {
    const elem = element;
    newImageVariables.forEach((imageVariable: IImageVariable, index: number) => {
      if (elem.hasProperty(imageVariable.name)) {
        elem.getProperty(imageVariable.name).element.innerElem.href.baseVal = paths[index];
      }
    });
  });
};

export default replaceImages;
