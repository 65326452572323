import React, { FC, useEffect, useRef, useState } from 'react';

import { IPreviewData } from 'containers/PreviewPlayerContainer/types';
import { ColorVariableType } from 'enums/colorVariable.enum';
import { getJsonLayer } from 'helpers/get_json_layer.helper';
import useOutsideClick from 'helpers/outsideClick.helper';
import { ILayer } from 'interfaces/layer.interface';
import OptionsButton from 'views/OptionsButton/OptionsButton';
import Video from 'views/Video/Video';
import { IColorVariable, IScene } from 'views/Video/types';

import { Campaign, DotsContainer } from './styles';
import { IProps } from './types';

const CampaignThumbnail: FC<IProps> = (props) => {
  const [isShown, setIsShown] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');
  const [layersData, setLayersData] = useState<ILayer[]>([]);
  const ref = useRef(null);

  let optionsShowed = false;

  useOutsideClick(ref, () => {
    setIsShown(false);
    setShowOptions(false);
  });

  const handleClick = () => {
    setShowOptions((prevState) => !prevState);
    optionsShowed = true;
  };

  const onMouseOver = () => {
    if (!props.listItem) {
      setIsShown(true);
    }
  };

  const onMouseLeave = () => {
    if (!props.listItem) {
      setIsShown(false);
    }
  };

  useEffect(() => {
    const previewData: IPreviewData = {
      colorVariables: props.campaign.colorVariables,
      imageVariables: props.campaign.imageVariables,
      textVariables: props.campaign.textVariables,
      duration: props.campaign.template.duration,
    };
    getJsonLayer(
      previewData,
      setLayersData,
      props.campaign.template.layers,
      props.campaign.textVariables
    );
  }, [props.campaign.template.layers]);

  useEffect(() => {
    const color = props.campaign.colorVariables.find(
      (color: IColorVariable) => color.type === ColorVariableType.Background
    );
    if (color) {
      setBackgroundColor(color.value);
    }
  }, [props.campaign.colorVariables]);

  const onClickOption = () => {
    setShowOptions(false);
  };

  const onClickThumbnail = () => {
    if (!optionsShowed) {
      props.selectCampaign(props.id);
    }
  };

  return (
    <Campaign
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      listItem={props.listItem}
      onClick={onClickThumbnail}
    >
      {!!layersData.length && (
        <>
          <Video
            layers={layersData}
            textVariables={props.campaign.textVariables}
            isPlaying={false}
            frame={
              props.campaign.template.scenes.sort((a: IScene, b: IScene) => a.order - b.order)[0]
                .frameEnd
            }
            imageVariables={props.campaign.imageVariables}
            colorVariables={props.campaign.colorVariables}
            currentFrameDistance={props.campaign.template.scenes[0].frameEnd}
            backgroundColor={backgroundColor}
            isEdit={true}
            isZoom={isShown}
            replay={false}
            autoplayBackgroundVideo={false}
            backgroundVideo={
              props.campaign.video && props.campaign.video.path ? props.campaign.video.path : ''
            }
            removeVideoData={props.onRemoveVideoData}
          />
          <DotsContainer ref={ref}>
            <OptionsButton
              selectCampaign={props.selectCampaign}
              id={props.id}
              title={props.title}
              deleteCampaign={props.deleteCampaign}
              relaunchCampaign={props.relaunchCampaign}
              selectMetricsCampaign={props.selectMetricsCampaign}
              showButton={isShown || showOptions}
              showOptions={showOptions}
              handleClick={handleClick}
              onClickOption={onClickOption}
              listItem={props.listItem}
              isLoadingDeleteCampaign={props.isLoadingDeleteCampaign}
            />
          </DotsContainer>
        </>
      )}
    </Campaign>
  );
};
export default CampaignThumbnail;
