import { ReactComponent as OppsImage } from 'assets/opps-img.svg';
import styled from 'styled-components';

export const WarningTitle = styled.p`
  font-size: 36px;
  text-align: center;
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  margin-bottom: 20px;
`;

export const WarningSubTitle = styled.p`
  width: 95%;
  font-size: 20px;
  text-align: center;
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const TextInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  background: ${(props) => props.theme.colors.porcelain};
`;

export const WarningImage = styled(OppsImage)`
  width: 260px;
  height: 280px;
  margin-bottom: 50px;
`;
