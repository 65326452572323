import React, { FC } from 'react';

import { Message, ModalContainer, ModalWrapper } from './styles';
import { IProps } from './types';

const ModalLoading: FC<IProps> = (props) => {
  return (
    <ModalWrapper>
      <ModalContainer>
        <Message>{props.text}</Message>
      </ModalContainer>
    </ModalWrapper>
  );
};
export default ModalLoading;
