import React, { FC } from 'react';

import LaunchMailchimp from './LaunchMailchimp/LaunchMailchimp';
import LaunchMailchimpForm from './LaunchMailchimpForm/LaunchMailchimpForm';
import LaunchShare from './LaunchShare/LaunchShare';
import ShareLaunchCSV from './ShareLaunchCSV/ShareLaunchCSV';
import { IProps } from './types';

const ShareLaunch: FC<IProps> = (props) => {
  return (
    <>
      {props.shareType === 1 && props.selectedOption === '' && (
        <ShareLaunchCSV
          exportData={props.exportData}
          setSelectedOption={props.setSelectedOption}
          loading={props.loading}
          setLoading={props.setLoading}
        />
      )}
      {props.shareType === 1 && props.selectedOption === 'share' && (
        <LaunchShare
          exportData={props.exportData}
          checked={props.checked}
          setChecked={props.setChecked}
          emailData={props.emailData}
          setEmailData={props.setEmailData}
          csv={props.csv}
          onRequestLink={props.onRequestLink}
          setIsVisibleMessage={props.setIsVisibleMessage}
          isVisibleMessage={props.isVisibleMessage}
          couldCopy={props.couldCopy}
        />
      )}
      {props.shareType === 2 && !props.isMailchimpFormVisible ? (
        <LaunchMailchimp
          sendCampaign={props.sendCampaign}
          createDraftCampaign={props.createDraftCampaign}
          setIsMailchimpFormVisible={props.setIsMailchimpFormVisible}
          setIsCreateAndSend={props.setIsCreateAndSend}
        />
      ) : (
        props.isMailchimpFormVisible && (
          <LaunchMailchimpForm
            setFrom={props.setFrom}
            setSubject={props.setSubject}
            setBody={props.setBody}
            from={props.from}
            subject={props.subject}
            body={props.body}
          />
        )
      )}
    </>
  );
};
export default ShareLaunch;
