import { ReactComponent as RocketIcon } from 'assets/rocket-log.svg';
import styled from 'styled-components';

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Rocket = styled(RocketIcon)`
  height: 185px;
  border-radius: 5%;
  margin-right: 15px;
`;

export const TextTitle = styled.p`
  font-size: 18px;
  text-align: center;
  color: ${(props) => props.theme.colors.cello};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
`;

export const TextSubtitle = styled.p`
  font-size: 14px;
  text-align: center;
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
