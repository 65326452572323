import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import * as userActions from 'redux/actions/user.actions';
import getUserSelector from 'redux/selectors/user.selector';
import ForgotPassord from 'views/ForgotPassword/ForgotPassword';

type ForgotPassordParams = {
  token: string;
};

type ForgotPassordProps = RouteComponentProps<ForgotPassordParams>;

const ForgotPassordContainer: FC<ForgotPassordProps> = (props) => {
  const dispatch = useDispatch();
  const token = props.match.params.token;
  const getUserData = useSelector(getUserSelector());

  useEffect(() => {
    dispatch(userActions.initializeForgotPassword(token));
  }, [dispatch]);

  const resetPassword = (password: string) => {
    dispatch(userActions.resetPassword(token, password));
  };

  return (
    <ForgotPassord
      resetPassword={resetPassword}
      isTokenValid={getUserData.user.statusForgotPassword}
    />
  );
};

export default ForgotPassordContainer;
