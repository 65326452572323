import React, { FC } from 'react';

import Wrapper from 'components/common/Wrapper/Wrapper';
import StoreLayout from 'containers/StoreLayout/StoreLayout';
import i18n from 'i18next';

import './i18n';
import { Container, LogInText, RandomText } from './styles';

const PrivacyPolicy: FC = () => {
  return (
    <StoreLayout isTemplate="">
      <Wrapper>
        <Container>
          <LogInText>{i18n.t('PRIVACY:TITLE')}</LogInText>
          <RandomText>{i18n.t('PRIVACY:LOREM')}</RandomText>
        </Container>
      </Wrapper>
    </StoreLayout>
  );
};
export default PrivacyPolicy;
