import React, { FC, useState } from 'react';
import { useEffect } from 'react';

import PreviewPlayerContainer from 'containers/PreviewPlayerContainer/PreviewPlayerContainer';
import i18n from 'i18next';

import './i18n';
import {
  BMSIcon,
  ButtonPreview,
  ButtonShare,
  HoverShareLogo,
  IconContainer,
  IconWrapper,
  LeftContainer,
  ListContainer,
  ListItem,
  PreviewLogo,
  PreviewText,
  RightContainer,
  ShareLogo,
  ShareText,
  TemplateName,
  TextContainer,
} from './styles';
import { IProps } from './types';

const TopbarEdit: FC<IProps> = (props) => {
  const [name, setName] = useState<string>('');

  useEffect(() => {
    setName(props.name);
  }, [props.name]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleClick = () => {
    if (name !== '') {
      props.onSubmit(name);
    } else {
      props.onSubmit('Untitled campaign');
    }
  };

  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ListContainer>
        <LeftContainer>
          <ListItem>
            <IconContainer to="/">
              <BMSIcon />
            </IconContainer>
          </ListItem>
          <ListItem>
            <TextContainer>
              <TemplateName
                placeholder="Untitled campaign"
                type="text"
                value={name}
                onChange={handleNameChange}
                onBlur={() => {
                  handleClick();
                }}
                maxLength={100}
              />
            </TextContainer>
          </ListItem>
        </LeftContainer>
        <RightContainer>
          <ListItem>{/* <TextChanges>All changes saved</TextChanges> */}</ListItem>
          <ListItem>
            <ButtonPreview onClick={openModal}>
              <PreviewLogo />
              <PreviewText>{i18n.t('TOPBAR:PREVIEW')}</PreviewText>
            </ButtonPreview>
          </ListItem>
          <ListItem>
            <ButtonShare to={`/campaign/${props.onNextSubmit}/share/`}>
              <IconWrapper>
                <ShareLogo />
                <HoverShareLogo />
              </IconWrapper>
              <ShareText>{i18n.t('TOPBAR:SHARE')}</ShareText>
            </ButtonShare>
          </ListItem>
        </RightContainer>
      </ListContainer>
      {modalIsOpen && <PreviewPlayerContainer id={props.id} onClose={closeModal} isTemplate={false} />}
    </>
  );
};
export default TopbarEdit;
