import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'SHARESELECT', {
  SELECT: 'Search and select your audience segment needed for your personalized template.',
  VERIFY: 'Verify your CSV has fields that match the ones required.',
  REMINDER: 'Empty fields',
  SUBTITLE_CSV:
    'Confirm with <0>Next</0> or replace the file using the <0>Back</0> button at the top.',
  SUBTITLE_MAILCHIMP_NO_SEGMENTS:
    'No segments to show. You should go to mailchimp and add contacts.',
});
