import React, { FC, useState } from 'react';

import { PUBLIC_URL } from 'configs';
import i18n from 'i18next';

import ColorsTab from './ColorsTab/ColorsTab';
import ImageTab from './ImageTab/ImageTab';
import TemplateTab from './TemplateTab/TemplateTab';
import VideoTab from './VideoTab/VideoTab';
import { Option } from './enums/options.enum';
import './i18n';
import {
  Icon,
  IconWrapper,
  OpenTab,
  OpenTabWrapper,
  OptionContainer,
  OptionWrapper,
  OptionsTab,
  OptionsWrapper,
  SidebarWrapper,
  Text,
} from './styles';
import { IProps } from './types';

const Sidebar: FC<IProps> = (props) => {
  const [optionSelected, setOptionSelected] = useState(Option.COLORS);

  return (
    <SidebarWrapper>
      <OptionsTab>
        <OptionsWrapper>
          <OptionWrapper
            onClick={() => setOptionSelected(Option.TEMPLATE)}
            open={optionSelected}
            current={Option.TEMPLATE}
          >
            <OptionContainer>
              <IconWrapper>
                <Icon src={`${PUBLIC_URL}/images/sidebar-template.svg`} />
              </IconWrapper>
              <Text>{i18n.t('SIDEBAR:TEMPLATE')}</Text>
            </OptionContainer>
          </OptionWrapper>
          <OptionWrapper
            onClick={() => setOptionSelected(Option.COLORS)}
            open={optionSelected}
            current={Option.COLORS}
          >
            <OptionContainer>
              <IconWrapper>
                <Icon src={`${PUBLIC_URL}/images/sidebar-colors.svg`} />
              </IconWrapper>
              <Text>{i18n.t('SIDEBAR:COLORS')}</Text>
            </OptionContainer>
          </OptionWrapper>
          <OptionWrapper
            onClick={() => setOptionSelected(Option.IMAGE)}
            open={optionSelected}
            current={Option.IMAGE}
          >
            <OptionContainer>
              <IconWrapper>
                <Icon src={`${PUBLIC_URL}/images/sidebar-image.svg`} />
              </IconWrapper>
              <Text>{i18n.t('SIDEBAR:IMAGE')}</Text>
            </OptionContainer>
          </OptionWrapper>
          <OptionWrapper
            onClick={() => setOptionSelected(Option.VIDEO)}
            open={optionSelected}
            current={Option.VIDEO}
          >
            <OptionContainer>
              <IconWrapper>
                <Icon src={`${PUBLIC_URL}/images/icon-video.svg`} />
              </IconWrapper>
              <Text>{i18n.t('SIDEBAR:VIDEO')}</Text>
            </OptionContainer>
          </OptionWrapper>
          <OptionWrapper open={optionSelected} current={Option.EMPTY}>
            <OptionContainer></OptionContainer>
          </OptionWrapper>
        </OptionsWrapper>
      </OptionsTab>
      <OpenTabWrapper>
        <OpenTab>
          {optionSelected === Option.TEMPLATE ? (
            <TemplateTab thumbnail={props.thumbnail} />
          ) : optionSelected === Option.COLORS ? (
            <ColorsTab colorVariables={props.colorVariables} onSubmitColor={props.onSubmitColor} />
          ) : optionSelected === Option.VIDEO ? (
            <VideoTab
              onSubmitVideo={props.onSubmitVideo}
              loading={props.loading}
              originalVideo={props.originalVideo}
              uploadOriginalVideo={props.uploadOriginalVideo}
              video={props.video}
              videoCropSucceed={props.videoCropSucceed}
              onSelectEditVideo={props.onSelectEditVideo}
            />
          ) : (
            <ImageTab imageVariables={props.imageVariables} onSubmitImage={props.onSubmitImage} />
          )}
        </OpenTab>
      </OpenTabWrapper>
    </SidebarWrapper>
  );
};

export default Sidebar;
