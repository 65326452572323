import cookie from 'react-cookies';

import { CookieSerializeOptions } from 'cookie';

function setCookie(name: string, value: string, options?: CookieSerializeOptions): void {
  cookie.save(name, value, options ? options : {});
}

function getCookie(name: string): string {
  return cookie.load(name);
}

function removeCookie(name: string, options?: CookieSerializeOptions): void {
  cookie.remove(name, options ? options : {});
}

export { setCookie, getCookie, removeCookie };
