import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'EDITTEXT', {
  CANCEL: 'Cancel',
  DONE: 'Done',
  INPUTTEXT: 'Text',
  BUTTONTEXT: 'Button Text',
  INPUTURL: 'Campaign destination URL',
  CHARACTERS: 'Remaining Characters: ',
});
