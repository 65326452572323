/* eslint-disable */
import { TextVariableType } from 'enums/textVariable.enum';
import { ILayer } from 'interfaces/layer.interface';
import { createSelector } from 'reselect';
import { IColorVariable, IImageVariable, IScene, ITextVariable } from 'views/Video/types';

const getCampaign = (state: any) => state.campaign;

const getCampaignSelector = () => createSelector([getCampaign], (campaign) => campaign);

export default getCampaignSelector;

export const getCSVHeadersCampaignSelector = () =>
  createSelector([getCampaign], (campaign) => {
    let filtered = [];
    if (campaign.campaign.textVariables.length) {
      filtered = campaign.campaign.textVariables.filter(
        (textVariable: ITextVariable) => textVariable.type === TextVariableType.Variable
      );
    }
    const headers = ['email'];
    filtered.reverse().forEach((textVariable: ITextVariable) => {
      headers.push(textVariable.value.replace('[', '').replace(']', ''));
    });

    return [headers];
  });

export const getCorrectSharedCampaign = () =>
  createSelector([getCampaign], (campaign) => {
    return campaign.sharedCampaign[0];
  });

export const getCampaignCTAVariables = () =>
  createSelector([getCampaign], (campaign) => {
    return campaign.campaign.textVariables.filter(
      (textVariable: ITextVariable) => textVariable.type === TextVariableType.CTA
    );
  });

export const getScenesSorted = () =>
  createSelector([getCampaign], (campaign) => {
    return campaign.campaign.template.scenes.sort((a: IScene, b: IScene) => a.order - b.order);
  });

export const getImageVariablesSorted = () =>
  createSelector([getCampaign], (campaign) => {
    const imageVariables = campaign.campaign.imageVariables;
    imageVariables.sort((a, b) => (a.name.split('_')[1] < b.name.split('_')[1] ? 1 : -1));
    return imageVariables;
  });

export const getOriginalVideo = () =>
  createSelector([getCampaign], (campaign) => campaign.originalVideo);

export const getVideo = () =>
  createSelector([getCampaign], (campaign) =>
    campaign.campaign.video ? campaign.campaign.video : campaign.campaign.template.video
  );

export const getPreviewData = () =>
  createSelector([getCampaign], (campaign: any) => {
    return {
      layers: campaign.campaign.template ? (campaign.campaign.template.layers as ILayer[]) : [],
      textVariables: campaign.campaign.textVariables as ITextVariable[],
      colorVariables: campaign.campaign.colorVariables as IColorVariable[],
      imageVariables: campaign.campaign.imageVariables as IImageVariable[],
      duration: campaign.campaign.template.duration as number,
      audio: campaign.campaign.template ? (campaign.campaign.template.audio as string) : '',
      video: campaign.campaign.template.video ? (campaign.campaign.template.video as string) : '',
      scenes: campaign.campaign.template.scenes as IScene[],
    };
  });
