import React, { FC, Fragment, useEffect, useState } from 'react';

import Banner from 'components/Banner/Banner';
import MessageModal from 'components/MessageModal/MessageModal';
import ModalLogin from 'components/ModalLogin/ModalLogin';
import PaywallModal from 'components/PaywallModal/PaywallModal';
import WarningMobile from 'components/WarningMobile/WarningMobile';
import Wrapper from 'components/common/Wrapper/Wrapper';
import { MESSAGE } from 'constants/messages/messages.constants';
import PreviewPlayer from 'containers/PreviewPlayerContainer/PreviewPlayerContainer';
import StoreLayout from 'containers/StoreLayout/StoreLayout';
import { useResize } from 'helpers/checkMobile.helper';
import i18n from 'i18next';
import MyCampaigns from 'views/Template/components/MyCampaigns/MyCampaigns';
import TemplatesView from 'views/Template/components/TemplatesView/TemplatesView';

import './i18n';
import { Line } from './styles';
import { IProps } from './types';

const TemplateList: FC<IProps> = (props) => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [isMobile, handleWindowSizeChange]: any[] = useResize();
  const [registeredSuccesfully, setRegisteredSuccesfully] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const toggleMessage = () => {
    props.changeIsSuccess();
  };

  const onCloseLogin = () => {
    setShowLogin(false);
    setIsSignUp(false);
    setRegisteredSuccesfully(false);
    props.changeStatusSignUp();
  };

  const onEditTemplate = () => {
    props.setTemplatePreviewOpen(false);
    if (props.isLogged) {
      props.selectTemplate(props.templateForPreview);
    } else {
      props.onCloseModalEditTemplate();
      setShowLogin(true);
    }
  };

  const onSubmitLogin = (email: string, password: string) => {
    if (props.submitLoginAndCreateCampaign && props.templateForPreview) {
      props.submitLoginAndCreateCampaign(email, password, props.templateForPreview);
      if (props.isLogged) {
        props.setTemplateForPreview('');
      }
    }
  };

  const onSubmitSignUp = (firstName: string, lastName: string, email: string, password: string) => {
    if (props.onSubmitSignUp) {
      props.onSubmitSignUp(firstName, lastName, email, password);
      props.setTemplateForPreview('');
    }
  };

  const onClosePaywallModal = () => {
    setShowLogin(false);
    props.onClosePaywallModal();
  };

  return (
    <Fragment>
      {isMobile ? (
        <WarningMobile />
      ) : (
        <StoreLayout isTemplate="template">
          {!props.isLogged && <Banner isMobile={isMobile} />}
          <Wrapper>
            {props.isLogged && (
              <MyCampaigns
                campaigns={props.userCampaigns}
                isLoading={props.isLoadingCampaigns}
                deleteCampaign={props.deleteCampaign}
                selectCampaign={props.onEditCreatedCampaign}
                relaunchCampaign={props.relaunchCampaign}
                selectMetricsCampaign={props.selectMetricsCampaign}
                isLoadingDeleteCampaign={props.isLoadingDeleteCampaign}
              />
            )}
            {!!props.filteredTemplates.length && (
              <>
                <TemplatesView
                  text={i18n.t('TEMPLATES:CREATE')}
                  filter={true}
                  category={props.category}
                  id={props.category}
                  onSelectCategory={props.onSelectCategory}
                  categories={props.categories}
                  templates={props.filteredTemplates}
                  selectTemplate={props.selectTemplate}
                  onClickPreview={props.onClickPreview}
                  error={props.error}
                  resetPassword={props.resetPassword}
                  onSubmitLoginAndCreateCampaign={props.submitLoginAndCreateCampaign}
                  onSubmitSignUp={props.onSubmitSignUp}
                  isLogged={props.isLogged}
                  statusSignUp={props.statusSignUp}
                  setErrorFalse={props.setErrorFalse}
                />
                <Line />
              </>
            )}
            {!!props.filteredTemplates.length && (
              <>
                <TemplatesView
                  text={i18n.t('TEMPLATES:NEW')}
                  filter={false}
                  category={props.category}
                  id={'Templates'}
                  onSelectCategory={props.onSelectCategory}
                  categories={[]}
                  templates={props.templates}
                  selectTemplate={props.selectTemplate}
                  onClickPreview={props.onClickPreview}
                  error={props.error}
                  resetPassword={props.resetPassword}
                  onSubmitLoginAndCreateCampaign={props.submitLoginAndCreateCampaign}
                  onSubmitSignUp={props.onSubmitSignUp}
                  isLogged={props.isLogged}
                  statusSignUp={props.statusSignUp}
                  setErrorFalse={props.setErrorFalse}
                />
                <Line />
              </>
            )}
            {/* <HelpTooltip></HelpTooltip> */}
          </Wrapper>
          {showLogin && !props.paywallVisible && (
            <ModalLogin
              isSignUp={isSignUp}
              changeSignUp={setIsSignUp}
              onClose={onCloseLogin}
              statusSignUp={props.statusSignUp}
              onSubmitSignUp={onSubmitSignUp}
              onSubmitLogin={onSubmitLogin}
              error={props.error}
              resetPassword={props.resetPassword}
              setErrorFalse={props.setErrorFalse}
              setRegisteredSuccesfully={setRegisteredSuccesfully}
              registeredSuccesfully={registeredSuccesfully}
            />
          )}
          {/* <Footer /> */}
        </StoreLayout>
      )}
      {(props.isSuccessDeleteCampaign || props.isSuccessForgotPassword) && (
        <MessageModal
          title={MESSAGE.success.title}
          icon={MESSAGE.success.icon}
          closeIcon={MESSAGE.success.close}
          bgColor={MESSAGE.success.bgColor}
          onClose={toggleMessage}
          message={
            props.isSuccessDeleteCampaign
              ? i18n.t('MESSAGE:DELETE_CAMPAIGN_SUCCESS')
              : i18n.t('MESSAGE:UPDATE_PASSWORD')
          }
        />
      )}
      {props.deleteCampaignError && (
        <MessageModal
          title={MESSAGE.error.title}
          icon={MESSAGE.error.icon}
          closeIcon={MESSAGE.error.close}
          bgColor={MESSAGE.error.bgColor}
          onClose={toggleMessage}
          message={i18n.t('MESSAGE:DELETE_CAMPAIGN_FAILED')}
        />
      )}
      {props.templatePreviewOpen && (
        <PreviewPlayer
          id={props.templateForPreview}
          onClose={props.onCloseModal}
          onEditTemplate={onEditTemplate}
          isTemplate
        />
      )}
      {props.paywallVisible && (
        <PaywallModal
          onClose={onClosePaywallModal}
          paywallMessage={props.paywallMessage}
          succededUpgradeRequest={props.succededUpgradeRequest}
          onRequestUpgrade={props.onRequestUpgrade}
          aboutToExpireCampaignsToAlert={props.aboutToExpireCampaignsToAlert}
          expiredCampaignsToAlert={props.expiredCampaignsToAlert}
          productId={props.productId}
        />
      )}
    </Fragment>
  );
};
export default TemplateList;
