import styled from 'styled-components';

import { ISize } from './types';

export const TemplateContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background: ${(props) => props.theme.colors.porcelain};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

export const Template = styled.div<ISize>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  display: flex;
  flex-direction: column;
  border-radius: 2%;
`;

export const VideoStyle = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
`;
