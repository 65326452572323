import { ReactComponent as Play } from 'assets/play-circle_stroke.svg';
import { PUBLIC_URL } from 'configs';
import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 35px 0;
  margin-top: 20px;
`;

export const LeftContainer = styled.div`
  width: 45%;
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const Label = styled.p`
  width: 100%;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  margin-bottom: 10px;
`;

export const LabelBody = styled(Label)`
  height: 8px;
  font-size: 8px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  margin-bottom: 13px;
`;

export const UsernameText = styled.p`
  width: 100%;
  font-size: 20px;
  color: ${(props) => props.theme.colors.cello};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  margin-bottom: 10px;
`;

export const Input = styled.input`
  width: 100%;
  height: 44px;
  border-radius: 6px;
  border: none;
  outline: none;
  padding: 0 10px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => props.theme.colors.fiord};
  font-weight: 400;
`;

export const RightContainer = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

export const IconLogo = styled.div`
  width: 60px;
  height: 60px;
  background-position: center;
  background-size: 70%;
  background-image: url(${PUBLIC_URL}/images/bms-white.png);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 5px;
`;

export const TemplateThumbnail = styled.img`
  width: 296px;
  height: 166px;
  border-radius: 8px;
`;

export const WrapperLaunch = styled.div`
  height: calc(100% - 255px);
  min-height: calc(100vh - 255px);
  background: ${(props) => props.theme.colors.aena};
`;

export const TemplateContainer = styled.div`
  width: 360px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 6px 12px #00000029;
  border-radius: 10px;
`;

export const ThumbnailContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CopyrightContainer = styled.div`
  width: 89%;
  padding-top: 10px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ThumbnWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
`;

export const CopyrightText = styled.p`
  font-size: 6px;
  color: ${(props) => props.theme.colors.raven};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
`;

export const ImageBMS = styled.img`
  width: 60px;
  height: 16px;
  margin-right: 20px;
`;

export const IconPlay = styled(Play)`
  width: 60px;
  height: 60px;
  position: absolute;
`;

export const VideoButton = styled.div`
  width: 84px;
  height: 24px;
  font-size: 8px;
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
  background: ${(props) => props.theme.colors.apricot};
  color: ${(props) => props.theme.colors.white};
`;

export const Line = styled.div`
  width: 300px;
  height: 2px;
  background: ${(props) => props.theme.colors.porcelain};
`;
