export const ON_SELECT_TEMPLATE_REQUESTED = 'ON_SELECT_TEMPLATE_REQUESTED';

export const ON_SELECT_TEMPLATE_SUCCEEDED = 'ON_SELECT_TEMPLATE_SUCCEEDED';

export const ON_SELECT_TEMPLATE_FAILED = 'ON_SELECT_TEMPLATE_FAILED';

export const ON_INITIALIZE_CAMPAIGN_REQUESTED = 'ON_INITIALIZE_CAMPAIGN_REQUESTED';

export const ON_INITIALIZE_CAMPAIGN_SUCCEDEED = 'ON_INITIALIZE_CAMPAIGN_SUCCEDEED';

export const ON_INITIALIZE_CAMPAIGN_FAILED = 'ON_INITIALIZE_CAMPAIGN_FAILED';

export const ON_CHANGE_CAMPAIGN_NAME_REQUESTED = 'ON_CHANGE_CAMPAIGN_NAME_REQUESTED';

export const ON_CHANGE_CAMPAIGN_NAME_SUCCEEDED = 'ON_CHANGE_CAMPAIGN_NAME_SUCCEEDED';

export const ON_CHANGE_CAMPAIGN_NAME_FAILED = 'ON_CHANGE_CAMPAIGN_NAME_FAILED';

export const ON_CHANGE_CAMPAIGN_TEMPLATE_TEXTS_REQUESTED =
  'ON_CHANGE_CAMPAIGN_TEMPLATE_TEXTS_REQUESTED';

export const ON_CHANGE_CAMPAIGN_TEMPLATE_TEXTS_SUCCEEDED =
  'ON_CHANGE_CAMPAIGN_TEMPLATE_TEXTS_SUCCEEDED';

export const ON_CHANGE_CAMPAIGN_TEMPLATE_TEXTS_FAILED = 'ON_CHANGE_CAMPAIGN_TEMPLATE_TEXTS_FAILED';

export const ON_CHANGE_CAMPAIGN_IMAGE_REQUESTED = 'ON_CHANGE_CAMPAIGN_IMAGE_REQUESTED';

export const ON_CHANGE_CAMPAIGN_IMAGE_SUCCEEDED = 'ON_CHANGE_CAMPAIGN_IMAGE_SUCCEEDED';

export const ON_CHANGE_CAMPAIGN_IMAGE_FAILED = 'ON_CHANGE_CAMPAIGN_IMAGE_FAILED';

export const ON_CHANGE_CAMPAIGN_COLOR_REQUESTED = 'ON_CHANGE_CAMPAIGN_COLOR_REQUESTED';

export const ON_CHANGE_CAMPAIGN_COLOR_SUCCEEDED = 'ON_CHANGE_CAMPAIGN_COLOR_SUCCEEDED';

export const ON_CHANGE_CAMPAIGN_COLOR_FAILED = 'ON_CHANGE_CAMPAIGN_COLOR_FAILED';

export const ON_CHANGE_SHARE_TYPE_REQUESTED = 'ON_CHANGE_SHARE_TYPE_REQUESTED';

export const ON_CHANGE_SHARE_TYPE_SUCCEDED = 'ON_CHANGE_SHARE_TYPE_SUCCEDED';

export const ON_CHANGE_SHARE_TYPE_FAILED = 'ON_CHANGE_SHARE_TYPE_FAILED';

export const ON_SELECT_MAILCHIMP_SEGMENT_REQUESTED = 'ON_SELECT_MAILCHIMP_SEGMENT_REQUESTED';

export const ON_SELECT_MAILCHIMP_SEGMENT_SUCCEDED = 'ON_SELECT_MAILCHIMP_SEGMENT_SUCCEDED';

export const ON_SELECT_MAILCHIMP_SEGMENT_FAILED = 'ON_SELECT_MAILCHIMP_SEGMENT_FAILED';

export const ON_SELECT_IMPORT_CSV_REQUESTED = 'ON_SELECT_IMPORT_CSV_REQUESTED';

export const ON_SELECT_IMPORT_CSV_SUCCEDED = 'ON_SELECT_IMPORT_CSV_SUCCEDED';

export const ON_SELECT_IMPORT_CSV_FAILED = 'ON_SELECT_IMPORT_CSV_FAILED';

export const ON_INITIALIZE_PREVIEW_SUCCEEDED = 'ON_INITIALIZE_PREVIEW_SUCCEEDED';

export const ON_SELECT_PREVIEW_FAILED = 'ON_SELECT_PREVIEW_FAILED';

export const ON_SELECT_EDIT_CREATED_CAMPAIGN = 'ON_SELECT_EDIT_CREATED_CAMPAIGN';

export const ON_INITIALIZE_SHARE_LAUNCH_SUCCEEDED = 'ON_INITIALIZE_SHARE_LAUNCH_SUCCEEDED';

export const ON_INITIALIZE_SHARE_LAUNCH_FAILED = 'ON_INITIALIZE_SHARE_LAUNCH_FAILED';

export const ON_CHANGE_CAMPAIGN_VIDEO_REQUESTED = 'ON_CHANGE_CAMPAIGN_VIDEO_REQUESTED';
export const ON_CHANGE_CAMPAIGN_VIDEO_SUCCEED = 'ON_CHANGE_CAMPAIGN_VIDEO_SUCCEED';
export const ON_CHANGE_CAMPAIGN_VIDEO_FAILED = 'ON_CHANGE_CAMPAIGN_VIDEO_FAILED';

export const ON_UPLOAD_ORIGINAL_VIDEO_REQUESTED = 'ON_UPLOAD_ORIGINAL_VIDEO_REQUESTED';
export const ON_UPLOAD_ORIGINAL_VIDEO_SUCCEED = 'ON_UPLOAD_ORIGINAL_VIDEO_SUCCEED';
export const ON_UPLOAD_ORIGINAL_VIDEO_FAILED = 'ON_UPLOAD_ORIGINAL_VIDEO_FAILED';

export const ON_SHARE_CSV_IMPORT = 'ON_SHARE_CSV_IMPORT';
export const ON_SHARE_CSV_IMPORT_SUCCEEDED = 'ON_SHARE_CSV_IMPORT_SUCCEEDED';
export const ON_SHARE_CSV_IMPORT_FAILED = 'ON_SHARE_CSV_IMPORT_FAILED';

export const ON_SELECT_EDIT_VIDEO = 'ON_SELECT_EDIT_VIDEO';

export const ON_CONFIRM_CSV_FILE = 'ON_CONFIRM_CSV_FILE';
