import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const LogInText = styled.h2`
  font-size: 20px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  color: ${(props) => props.theme.colors.fiord};
  margin-bottom: 25px;
`;

export const Inputs = styled.input`
  width: 355px;
  height: 44px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  border: 1px solid ${(props) => props.theme.colors.porcelain};
  border-radius: 6px;
  padding-left: 15px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

export const LogIn = styled(Link)`
  width: 104px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.cello};
  border-radius: 4px;
  border: none;
  text-decoration: none;
  cursor: pointer;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  transition: all 0.2s ease-out;
  &:hover {
    background: ${(props) => props.theme.colors.bigStone};
  }
`;

export const Label = styled.label`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const ForgotPassword = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  color: ${(props) => props.theme.colors.apricot};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  cursor: pointer;
  margin-bottom: 40px;
  &:hover {
    text-decoration: underline;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 10px;
  height: 10px;
  text-align: left;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.red};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  margin-bottom: 9px;
  margin-top: 3px;
`;

export const IconBlock = styled.img`
  position: absolute;
  width: 21px;
  height: 19px;
  cursor: pointer;
  top: 15px;
  left: 335px;
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const HaveAccountText = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => props.theme.colors.fiord};
  text-align: center;
`;

export const SignUpText = styled.b`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  text-decoration: none;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  color: ${(props) => props.theme.colors.apricot};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
