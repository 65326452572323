import Slider from 'react-slick';

import { PUBLIC_URL } from 'configs/index';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';

import { ISlide } from './types';

export const ScenesSlider = styled.div`
  width: 900px;
  padding: 20px 0;
`;

export const ScenesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
`;

export const Slide = styled.div<ISlide>`
  width: 90% !important;
  height: 76px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 auto;
  border-radius: 10px;
  ${(props) => props.theme.colors.apricot};
  border: 2px solid
    ${(props) => (props.isActive ? props.theme.colors.apricot : props.theme.colors.porcelain)};
  background-color: ${(props) => props.isActive && props.theme.colors.apricot};
  transition: ${(props) => props.theme.transition.all};
`;

export const SliderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Slick = styled(Slider)`
  width: 100%;
  .slick-slider .slick-slide > div {
    height: 100px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .slick-arrow {
    width: 25px;
    height: 80px;

    &:before {
      display: none;
    }

    &.slick-next {
      background-image: url(${PUBLIC_URL}/images/arrow_slide_video.svg);
      background-position: center;
      background-repeat: no-repeat;
      transform: rotate(0deg);
      top: 0;
      height: 100%;
    }

    &.slick-prev {
      background-image: url(${PUBLIC_URL}/images/arrow_slide_video.svg);
      background-position: center;
      background-repeat: no-repeat;
      transform: rotate(180deg);
      top: 0;
      height: 100%;
    }

    &.slick-disabled {
      display: none !important;
    }
  }
`;

export const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
};

export const SceneNumber = styled.div`
  width: 20px;
  height: 15px;
  font-size: ${(props) => props.theme.paragraph.fontSizes.small};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 2px;
  right: 12px;
  top: 59px;
  background-color: ${(props) => props.theme.colors.bigStone};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const SceneWrapper = styled.div`
  position: relative;
  outline: none;
`;
