/* eslint-disable */
import { ColorVariableType } from 'enums/colorVariable.enum';
import { TextVariableType } from 'enums/textVariable.enum';
import colorShade from 'helpers/darkenColor.helper';
import theme from 'styles/themes/default/theme';

import { IColorVariable, ITextVariable } from '../types';

const addInteractionCTAHover = (
  textVariables: ITextVariable[],
  colorVariables: IColorVariable[],
  onCTAClicked: () => void
): void => {
  const colorVariableFiltered = colorVariables.find(
    (colorVariable: IColorVariable) => colorVariable.type === ColorVariableType.Object
  );
  textVariables
    .filter((textVariable: ITextVariable) => textVariable.type === TextVariableType.CTA)
    .forEach((textVariable: ITextVariable) => {
      //TODO FIXME @GuidoG try to fix this, using refs?
      const hoverLayers = document.querySelectorAll(
        `${textVariable.name}_in path`
      )[0] as HTMLElement;

      const hoverCTA = document.querySelectorAll(
        `[id*="${colorVariableFiltered?.name}"] path`
      )[0] as HTMLElement;
      let darkenColor = '';
      if (colorVariableFiltered) {
        if (colorVariableFiltered.value === theme.colors.black) {
          darkenColor = colorShade(colorVariableFiltered.value, -40);
        } else {
          darkenColor = colorShade(colorVariableFiltered.value, 40);
        }
      }
      if (hoverLayers) {
        hoverLayers.addEventListener('mouseover', () => {
          hoverLayers.style.cursor = 'pointer';
          if (colorVariableFiltered) {
            hoverCTA.style.fill = darkenColor;
          }
        });
        hoverLayers.addEventListener('mouseleave', () => {
          if (colorVariableFiltered) {
            hoverCTA.style.fill = colorVariableFiltered.value;
          }
        });
        const openCTA = () => {
          if (onCTAClicked) {
            onCTAClicked();
          }
          window.open(textVariable.ctaLink, '_blank');
        };

        hoverLayers.addEventListener('click', openCTA);
      }
    });
};

export default addInteractionCTAHover;
