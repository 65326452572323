import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ColorVariableType } from 'enums/colorVariable.enum';
import { TextVariableType } from 'enums/textVariable.enum';
import { getJsonLayer } from 'helpers/get_json_layer.helper';
import { ILayer } from 'interfaces/layer.interface';
import {
  onClickCTA,
  onSelectAudioIcon,
  onUpdateMetrics,
  onVideoStarted,
} from 'redux/actions/metric.actions';
import { initializeMailchimpPlayer } from 'redux/actions/sharedUser.actions';
import * as templateActions from 'redux/actions/template.actions';
import getCampaignSelector, {
  getCampaignCTAVariables,
  getVideo,
} from 'redux/selectors/campaign.selector';
import { getMCTextVariables } from 'redux/selectors/sharedUser.selector';
import UserPlayer from 'views/UserPlayer/UserPlayer';
import { IColorVariable, ITextVariable } from 'views/Video/types';

import { ShareMatchProps } from './types';

const MailchimpPlayerContainer: FC<ShareMatchProps> = (props) => {
  const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');
  const [layersData, setLayersData] = useState<ILayer[]>([]);
  const video = useSelector(getVideo());
  const campaignId = props.match.params.campaignId;
  const mailchimpUniqueId = props.match.params.mailchimpUniqueId;
  const dispatch = useDispatch();
  const getCampaignData = useSelector(getCampaignSelector());
  const getSharedUserTextVariables = useSelector(
    getMCTextVariables(
      getCampaignData.campaign.textVariables.filter(
        (variable: ITextVariable) => variable.type === TextVariableType.Variable
      )
    )
  );
  const CTATextVariables = useSelector(getCampaignCTAVariables());

  useEffect(() => {
    dispatch(initializeMailchimpPlayer(campaignId, mailchimpUniqueId));
  }, [dispatch]);

  useEffect(() => {
    if (getCampaignData.campaign && getCampaignData.campaign.textVariables) {
      const textVariables = getCampaignData.campaign.textVariables.filter(
        (variable: ITextVariable) => variable.type !== TextVariableType.Variable
      );
      getSharedUserTextVariables.forEach((mcTextVariable: ITextVariable) =>
        textVariables.push(mcTextVariable)
      );

      if (getCampaignData.campaign.template && !!textVariables.length) {
        getJsonLayer(
          getCampaignData.campaign,
          setLayersData,
          getCampaignData.campaign.template.layers,
          textVariables
        );
      }
    }
  }, [getCampaignData.campaign]);

  useEffect(() => {
    const color = getCampaignData.campaign.colorVariables.find(
      (color: IColorVariable) => color.type === ColorVariableType.Background
    );
    if (color) {
      setBackgroundColor(color.value);
    }
  }, [getCampaignData.campaign.colorVariables]);

  const handleVideoStart = () => {
    dispatch(onVideoStarted(mailchimpUniqueId, campaignId));
  };

  const onUpdateWatchedTime = (lastSecondPlayed: number) => {
    dispatch(onUpdateMetrics(lastSecondPlayed));
  };

  const handleSelectAudioIcon = () => {
    dispatch(onSelectAudioIcon());
  };

  const onCTAClicked = () => {
    dispatch(onClickCTA());
  };

  const onRemoveVideoData = () => {
    dispatch(templateActions.onRemoveVideoData());
  };

  return !!layersData.length ? (
    <UserPlayer
      layers={layersData}
      backgroundColor={backgroundColor}
      textVariables={CTATextVariables}
      colorVariables={getCampaignData.campaign.colorVariables}
      imageVariables={getCampaignData.campaign.imageVariables}
      audioFile={getCampaignData.campaign.template.audio}
      handleSelectAudioIcon={handleSelectAudioIcon}
      backgroundVideo={video ? video.path : ''}
      onStart={handleVideoStart}
      onUpdate={onUpdateWatchedTime}
      onCTAClicked={onCTAClicked}
      scenes={getCampaignData.campaign.template.scenes}
      onRemoveVideoData={onRemoveVideoData}
      duration={getCampaignData.campaign.template.duration}
    />
  ) : (
    <></>
  );
};
export default MailchimpPlayerContainer;
