export const ON_SELECT_TEMPLATE_FAILED_TEMPLATE_NOT_AVAILABLE_FOR_SUB =
  'ON_SELECT_TEMPLATE_FAILED_TEMPLATE_NOT_AVAILABLE_FOR_SUB';

export const CREATE_CAMPAIGN_LIMIT_EXCEEDED = 'CREATE_CAMPAIGN_LIMIT_EXCEEDED';

export const MASSIVE_SHARE_CAMPAIGN_LIMIT = 'MASSIVE_SHARE_CAMPAIGN_LIMIT';

export const ON_CLOSE_PAYWALL_MODAL = 'ON_CLOSE_PAYWALL_MODAL';

export const ALERT_CAMPAIGN_LIFE_TIME_LIMIT = 'ALERT_CAMPAIGN_LIFE_TIME_LIMIT';

export const SHARED_SHARE_CAMPAIGN_LIMIT = 'SHARED_SHARE_CAMPAIGN_LIMIT';
