import Slider from 'react-slick';

import styled from 'styled-components';

export const TemplatesViewStyle = styled.div`
  width: 100%;
`;
export const TemplateTitle = styled.h2`
  font-size: 20px;
  text-align: left;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.fiord};
  opacity: 1;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
`;
export const TemplateContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const PersonalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DefaultImage = styled.img`
  height: 185px;
  border-radius: 5%;
  margin: 0 auto 20px auto;
`;
export const TextBelow = styled.div`
  font-size: 16px;
  text-align: center;
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;
export const SliderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const Slick = styled(Slider)`
  width: 100%;
  .slick-slider .slick-slide > div {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .slick-list > div {
    margin-left: 0;
  }
  .slick-arrow {
    width: 30px;
    height: 30px;
    top: 38%;
    &:before {
      display: none;
    }
    &.slick-disabled {
      display: none !important;
    }
  }
  .slick-next {
    right: -12px;
    border-radius: 100%;
    box-shadow: 0px 2px 6px ${(props) => props.theme.colors.shadow};
    background-color: ${(props) => props.theme.colors.white};
  }
  .slick-prev {
    margin-left: -15px;
    border-radius: 100%;
    box-shadow: 0px 2px 6px ${(props) => props.theme.colors.shadow};
    background-color: ${(props) => props.theme.colors.white};
    z-index: 10;
    left: 0;
  }
`;
export const Slide = styled.div`
  width: 100%;
  outline: none;
`;
