import { AnyAction } from 'redux';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  onClickCTAFailed,
  onClickCTASucceed,
  onSelectAudioIconFailed,
  onSelectAudioIconSucceed,
} from 'redux/actions/metric.actions';
import { ON_CLICK_CTA, ON_SELECT_AUDIO_ICON } from 'redux/constants/metrics.actions.constants';
import getMetricsSelector from 'redux/selectors/metric.selector';
import * as shareService from 'service/share.service';

import * as actions from '../actions/sharedUser.actions';
import * as constants from '../constants/userShared.action.constants';

function* getSharePlayer(action: AnyAction) {
  try {
    const response = yield call(shareService.getSharePlayer, action.shareId);
    yield put(actions.initializePlayerSuccedeed(response));
  } catch (err) {
    yield put(actions.initializePlayerFailed());
  }
}

function* onSelectAudioIcon() {
  const metrics = yield select(getMetricsSelector());
  if (!metrics.audioPlayed) {
    try {
      yield call(shareService.selectAudio, metrics.id);
      yield put(onSelectAudioIconSucceed());
    } catch (err) {
      yield put(onSelectAudioIconFailed());
    }
  }
}

function* onClickCTA() {
  const metrics = yield select(getMetricsSelector());
  if (!metrics.ctaClicked) {
    try {
      yield call(shareService.clickCTA, metrics.id);
      yield put(onClickCTASucceed());
    } catch (err) {
      yield put(onClickCTAFailed());
    }
  }
}

function* getMailchimpUserData(action: AnyAction) {
  try {
    const response = yield call(
      shareService.getMailchimpUserData,
      action.campaignId,
      action.mailchimpUniqueId
    );
    yield put(actions.initializeMailchimpPlayerSuccedeed(response));
  } catch (error) {
    yield put(actions.initializeMailchimpPlayerFailed());
  }
}

export default all([
  takeLatest(constants.ON_INITIALIZE_PLAYER_REQUESTED, getSharePlayer),
  takeLatest(constants.ON_INITIALIZE_MAILCHIMP_PLAYER_REQUESTED, getMailchimpUserData),
  takeLatest(ON_SELECT_AUDIO_ICON, onSelectAudioIcon),
  takeLatest(ON_CLICK_CTA, onClickCTA),
]);
