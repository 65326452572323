import { ICampaign } from 'views/CampaignEdit/types';

export const sortCampaign = (a: ICampaign, b: ICampaign): number => {
  return ('' + a.name).localeCompare(b.name);
};

export const sortCampaignByDate = (a: ICampaign, b: ICampaign): number => {
  const firstDate = new Date(a.updatedAt);
  const secondDate = new Date(b.updatedAt);
  return firstDate < secondDate ? 1 : firstDate > secondDate ? -1 : 0;
};

export const sortCampaignsByCreatedDate = (a: ICampaign, b: ICampaign): number => {
  const firstDate = new Date(a.createdAt);
  const secondDate = new Date(b.createdAt);
  return firstDate < secondDate ? 1 : firstDate > secondDate ? -1 : 0;
};
