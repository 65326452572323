import React, { FC } from 'react';

import Wrapper from 'components/common/Wrapper/Wrapper';
import i18n from 'i18next';

import './i18n';
import { BackButton, ButtonWrapper, WrapperLaunch } from './styles';

const ShareSuccessEmail: FC = () => {
  return (
    <WrapperLaunch>
      <Wrapper>
        <ButtonWrapper>
          <BackButton to="/">{i18n.t('SHARESUCCESSEMAIL:BACK_TO_HOME')}</BackButton>
        </ButtonWrapper>
      </Wrapper>
    </WrapperLaunch>
  );
};
export default ShareSuccessEmail;
