import { ReactComponent as LinkIcon } from 'assets/icon-link.svg';
import { ReactComponent as Tick } from 'assets/tick.svg';
import styled from 'styled-components';

import { ICell, IChecked, IInput } from './types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-top: 30px;
`;

export const LabelTitle = styled.h1`
  font-size: 20px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.fiord};
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  font-weight: 400;
`;

export const ContactListContainer = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

export const Line = styled.div`
  height: 0px;
  margin-bottom: 20px;
  border: 1px solid ${(props) => props.theme.colors.loblolly};
  opacity: 1;
`;

export const Header = styled.h1`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  text-align: left;
  font-weight: 400;
`;

export const Value = styled.h1`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.light};
  padding: 5px;
  margin: 5px;
  font-weight: 400;
`;

export const Input = styled.input<IInput>`
  width: ${(props) => (props.isName ? '220px' : '262px')};
  height: 20px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.light};
  border: 1px solid
    ${(props) => (props.emailError ? props.theme.colors.red : props.theme.colors.porcelain)};
  border-radius: 6px;
  outline: none;
  font-weight: 400;
  padding: 10px;
  &::placeholder {
    opacity: 0.5;
    transition: ${(props) => props.theme.transition.all};
  }
  &:hover {
    &::placeholder {
      opacity: 1;
    }
  }
  &:focus {
    border: 1px solid
      ${(props) => (props.emailError ? props.theme.colors.cousire : props.theme.colors.raven)};
  }
`;

export const LightHeader = styled.h1`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  font-weight: 400;
`;

export const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ButtonWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
`;

export const CopyLink = styled(LinkIcon)`
  width: 25px;
  height: 25px;
  transition: all 0.2s ease-out;
  &:hover {
    circle {
      fill: ${(props) => props.theme.colors.cello};
    }
  }
  cursor: pointer;
`;

export const Section = styled.div`
  display: table;
  width: 850px;
  margin: 0 auto;
`;

export const Row = styled.div`
  display: table-row;
  height: 100%;
`;

export const Cell = styled.div<ICell>`
  display: ${(props) => (props.isCheckbox ? 'flex' : 'table-cell')};
  justify-content: ${(props) => props.isCheckbox && 'flex-end'};
  align-items: ${(props) => props.isCheckbox && 'center'};
  max-width: ${(props) => props.isCheckbox && '200px'};
  height: 45px;
  vertical-align: middle;
  text-align: start;
  ${LightHeader} {
    margin-right: ${(props) => props.isCheckbox && '10px'};
  }
`;

export const ErrorCell = styled(Cell)`
  height: 30px;
  color: ${(props) => props.theme.colors.cousire};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const WrapperLaunch = styled.div`
  height: calc(100% - 255px);
  min-height: calc(100vh - 255px);
  background: ${(props) => props.theme.colors.aena};
`;

export const CheckboxWrapper = styled.div`
  width: 17px;
  height: 17px;
  display: inline-block;
  vertical-align: middle;
`;

export const Icon = styled(Tick)`
  width: 15px;
  height: 9px;
  fill: none;
  stroke: white;
  stroke-width: 2px;
  margin: 0 auto;
  align-self: center;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Checkbox = styled.div<IChecked>`
  display: flex;
  width: 15px;
  height: 15px;
  background: ${(props) =>
    props.checked && !props.disabled ? props.theme.colors.apricot : props.theme.colors.white};
  border-radius: 3px;
  transition: all 0.2s ease-out;
  border: 1px solid
    ${(props) =>
      props.checked && !props.disabled ? props.theme.colors.apricot : props.theme.colors.cloudy};
  opacity: ${(props) => props.disabled && '0.3'};
  cursor: ${(props) => !props.disabled && 'pointer'};
  ${Icon} {
    visibility: ${(props) => (props.checked && !props.disabled ? 'visible' : 'hidden')};
  }
`;
