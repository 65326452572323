import React, { FC } from 'react';

import i18n from 'i18next';

import './i18n';
import { Arrow, Container, Wrapper } from './styles';
import { IProps } from './types';

const SuccessMessage: FC<IProps> = (props: IProps) => {
  return (
    <Wrapper showMessage={props.showMessage}>
      <Arrow />
      <Container>{i18n.t('SUCCESS_MESSAGE:COPIED')}</Container>
    </Wrapper>
  );
};

export default SuccessMessage;
