import React, { FC, useRef, useState } from 'react';

import useOutsideClick from 'helpers/outsideClick.helper';
import i18n from 'i18next';
import moment from 'moment';
import CampaignThumbnail from 'views/CampaignThumbnail/CampaignThumbnail';
import OptionsButton from 'views/OptionsButton/OptionsButton';

import './i18n';
import {
  CampaignContainer,
  CampaignName,
  CampaignText,
  CreatedDate,
  DotsContainer,
  DraftDate,
  LeftContainer,
  NameTooltip,
  NameTooltipText,
  RightContainer,
  SentDate,
} from './styles';
import { IProps } from './types';

const CampaignDataListItem: FC<IProps> = (props) => {
  const [showOptions, setShowOptions] = useState(false);
  const ref = useRef(null);

  useOutsideClick(ref, () => {
    setShowOptions(false);
  });

  const handleClick = () => {
    setShowOptions((prevState) => !prevState);
  };

  const onClickOption = () => {
    setShowOptions(false);
  };

  return (
    <CampaignContainer>
      <LeftContainer>
        <CampaignThumbnail
          id={props.id}
          title={props.title}
          deleteCampaign={props.deleteCampaign}
          selectCampaign={props.selectCampaign}
          selectMetricsCampaign={props.selectMetricsCampaign}
          relaunchCampaign={props.relaunchCampaign}
          updatedAt={props.updatedAt}
          campaign={props.campaign}
          listItem
          isLoadingDeleteCampaign={props.isLoadingDeleteCampaign}
          onRemoveVideoData={props.onRemoveVideoData}
        />

        <CampaignName>
          <NameTooltip>
            <NameTooltipText>{props.title}</NameTooltipText>
          </NameTooltip>
          <CampaignText>{props.title}</CampaignText>
        </CampaignName>
      </LeftContainer>
      <RightContainer>
        {props.campaign.sent ? (
          <SentDate>
            {i18n.t('CAMPAIGN:SENT')} {moment(props.campaign.sent).format('MM.DD.YYYY')}
          </SentDate>
        ) : (
          <DraftDate>
            {i18n.t('CAMPAIGN:DRAFT')} {moment(props.updatedAt).format('MM.DD.YYYY')}
          </DraftDate>
        )}
        <CreatedDate>{moment(props.createdAt).format('MM/DD/YYYY')}</CreatedDate>
        <DotsContainer ref={ref}>
          <OptionsButton
            selectCampaign={props.selectCampaign}
            id={props.id}
            title={props.title}
            deleteCampaign={props.deleteCampaign}
            relaunchCampaign={props.relaunchCampaign}
            selectMetricsCampaign={props.selectMetricsCampaign}
            showButton
            showOptions={showOptions}
            handleClick={handleClick}
            onClickOption={onClickOption}
            listItem
            isLoadingDeleteCampaign={props.isLoadingDeleteCampaign}
          />
        </DotsContainer>
      </RightContainer>
    </CampaignContainer>
  );
};

export default CampaignDataListItem;
