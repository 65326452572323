export const ON_SELECT_CAMPAIGN_REQUESTED = 'ON_SELECT_CAMPAIGN_REQUESTED';

export const ON_DELETE_CAMPAIGN_REQUESTED = 'ON_DELETE_CAMPAIGN_REQUESTED';

export const ON_DELETE_CAMPAIGN_SUCCEDED = 'ON_DELETE_CAMPAIGN_SUCCEDED';

export const ON_DELETE_CAMPAIGN_FAILED = 'ON_DELETE_CAMPAIGN_FAILED';

export const ON_RELAUNCH_CAMPAIGN_REQUESTED = 'ON_RELAUNCH_CAMPAIGN_REQUESTED';

export const ON_RELAUNCH_CAMPAIGN_SUCCEDED = 'ON_RELAUNCH_CAMPAIGN_SUCCEDED';

export const ON_RELAUNCH_CAMPAIGN_FAILED = 'ON_RELAUNCH_CAMPAIGN_FAILED';
