import styled from 'styled-components';

import { ICloseModal } from './types';

export const CloseModal = styled.div<ICloseModal>`
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  background-image: url(${(props) => props.image});
  float: right;
  position: absolute;
  right: -45px;
  &:hover {
    background-image: url(${(props) => props.hover});
  }
`;
