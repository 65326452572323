import React, { FC } from 'react';

import NoCampaigns from 'components/NoCampaigns/NoCampaigns';
import i18n from 'i18next';
import CampaignDataThumbnail from 'views/CampaignDataThumbnail/CampaignDataThumbnail';
import LoadingPoints from 'views/LoadingPoints/LoadingPoints';

import './i18n';
import {
  CampaignTitle,
  CampaignsContainer,
  CampaignsViewStyle,
  TitleContainer,
  ViewAll,
} from './styles';
import { IProps } from './types';

const MyCampaigns: FC<IProps> = (props) => {
  return (
    <CampaignsViewStyle>
      <TitleContainer>
        <CampaignTitle>{i18n.t('CAMPAIGNS:TITLE')}</CampaignTitle>
        {!props.isLoading && !!props.campaigns.length && (
          <ViewAll to="/my-campaigns">{i18n.t('CAMPAIGNS:VIEW')}</ViewAll>
        )}
      </TitleContainer>

      {props.isLoading ? (
        <LoadingPoints />
      ) : props.campaigns.length ? (
        <CampaignsContainer>
          {props.campaigns.slice(0, 4).map((campaign) => {
            return (
              <CampaignDataThumbnail
                campaign={campaign}
                key={campaign._id}
                id={campaign._id}
                deleteCampaign={props.deleteCampaign}
                title={campaign.name}
                selectCampaign={props.selectCampaign}
                relaunchCampaign={props.relaunchCampaign}
                updatedAt={campaign.updatedAt}
                selectMetricsCampaign={props.selectMetricsCampaign}
                isLoadingDeleteCampaign={props.isLoadingDeleteCampaign}
              />
            );
          })}
        </CampaignsContainer>
      ) : (
        <NoCampaigns />
      )}
    </CampaignsViewStyle>
  );
};
export default MyCampaigns;
