import { ISelectedSegment } from 'containers/shareSelect/types';
import { IObject } from 'helpers/types';
import { IAsset, ICropVideoSettings, IVideo } from 'interfaces/asset.interface';
import { AnyAction } from 'redux';
import { ICampaign } from 'views/CampaignEdit/types';
import { IColorVariable, ITextVariable } from 'views/Video/types';

import * as constants from '../constants/campaign.action.constants';

export const selectTemplate = (id: string): AnyAction => ({
  type: constants.ON_SELECT_TEMPLATE_REQUESTED,
  id,
});

export const selectTemplateSucceeded = (id: string): AnyAction => ({
  type: constants.ON_SELECT_TEMPLATE_SUCCEEDED,
  id,
});

export const selectTemplateFailed = (): AnyAction => ({
  type: constants.ON_SELECT_TEMPLATE_FAILED,
});

export const initializeCampaign = (id: string): AnyAction => ({
  type: constants.ON_INITIALIZE_CAMPAIGN_REQUESTED,
  id,
});

export const initializeCampaignSuccedeed = (data: ICampaign): AnyAction => ({
  type: constants.ON_INITIALIZE_CAMPAIGN_SUCCEDEED,
  data,
});

export const initializeCampaignFailed = (): AnyAction => ({
  type: constants.ON_INITIALIZE_CAMPAIGN_FAILED,
});

export const changeCampaignName = (id: string, name: string): AnyAction => ({
  type: constants.ON_CHANGE_CAMPAIGN_NAME_REQUESTED,
  id,
  name,
});

export const changeCampaignNameSucceeded = (name: string): AnyAction => ({
  type: constants.ON_CHANGE_CAMPAIGN_NAME_SUCCEEDED,
  name,
});

export const changeCampaignNameFailed = (): AnyAction => ({
  type: constants.ON_CHANGE_CAMPAIGN_NAME_FAILED,
});

export const changeTextVariable = (
  id: string,
  text: string,
  textId: string,
  link?: string
): AnyAction => ({
  type: constants.ON_CHANGE_CAMPAIGN_TEMPLATE_TEXTS_REQUESTED,
  id,
  text,
  textId,
  link,
});

export const changeTextVariableSucceeded = (data: ITextVariable): AnyAction => ({
  type: constants.ON_CHANGE_CAMPAIGN_TEMPLATE_TEXTS_SUCCEEDED,
  data,
});

export const changeTextVariableFailed = (): AnyAction => ({
  type: constants.ON_CHANGE_CAMPAIGN_NAME_FAILED,
});

export const changeColorVariable = (id: string, color: string, colorId: string): AnyAction => ({
  type: constants.ON_CHANGE_CAMPAIGN_COLOR_REQUESTED,
  id,
  color,
  colorId,
});

export const changeColorVariableSucceeded = (data: IColorVariable): AnyAction => ({
  type: constants.ON_CHANGE_CAMPAIGN_COLOR_SUCCEEDED,
  data,
});

export const changeColorVariableFailed = (): AnyAction => ({
  type: constants.ON_CHANGE_CAMPAIGN_COLOR_FAILED,
});

export const changeImageVariable = (id: string, file: IAsset, imageId: string): AnyAction => ({
  type: constants.ON_CHANGE_CAMPAIGN_IMAGE_REQUESTED,
  id,
  file,
  imageId,
});

export const changeImageVariableSucceeded = (data: string): AnyAction => ({
  type: constants.ON_CHANGE_CAMPAIGN_IMAGE_SUCCEEDED,
  data,
});

export const changeImageVariableFailed = (): AnyAction => ({
  type: constants.ON_CHANGE_CAMPAIGN_IMAGE_FAILED,
});

export const changeVideo = (
  id: string,
  originalVideoId: string,
  cropVideoSettings: ICropVideoSettings
): AnyAction => ({
  type: constants.ON_CHANGE_CAMPAIGN_VIDEO_REQUESTED,
  id,
  originalVideoId,
  cropVideoSettings,
});

export const changeVideoSucceed = (data: IVideo): AnyAction => ({
  type: constants.ON_CHANGE_CAMPAIGN_VIDEO_SUCCEED,
  data,
});

export const changeVideoFailed = (): AnyAction => ({
  type: constants.ON_CHANGE_CAMPAIGN_VIDEO_FAILED,
});

export const onSelectNextSelectView = (
  id: string,
  shareData: IObject | ISelectedSegment
): AnyAction => ({
  type: constants.ON_SELECT_MAILCHIMP_SEGMENT_REQUESTED,
  id,
  shareData,
});

export const onSelectNextSelectViewSucceeded = (data: string): AnyAction => ({
  type: constants.ON_SELECT_MAILCHIMP_SEGMENT_SUCCEDED,
  data,
});

export const onSelectNextSelectViewFailed = (): AnyAction => ({
  type: constants.ON_SELECT_MAILCHIMP_SEGMENT_FAILED,
});

export const onSelectImport = (id: string, shareData: IObject): AnyAction => ({
  type: constants.ON_SELECT_IMPORT_CSV_REQUESTED,
  id,
  shareData,
});

export const onSelectImportSucceeded = (data: { _id: string }): AnyAction => ({
  type: constants.ON_SELECT_IMPORT_CSV_SUCCEDED,
  data,
});

export const onConfirmCSVFile = (data: { _id: string }): AnyAction => ({
  type: constants.ON_CONFIRM_CSV_FILE,
  data,
});

export const onSelectImportFailed = (): AnyAction => ({
  type: constants.ON_SELECT_IMPORT_CSV_FAILED,
});

export const onSelectPreviewSucceeded = (data: string): AnyAction => ({
  type: constants.ON_INITIALIZE_PREVIEW_SUCCEEDED,
  data,
});

export const onSelectPreviewFailed = (): AnyAction => ({
  type: constants.ON_SELECT_PREVIEW_FAILED,
});

export const onSelectEditCreatedCampaign = (id: string): AnyAction => ({
  type: constants.ON_SELECT_EDIT_CREATED_CAMPAIGN,
  id,
});

export const onShareCSVImport = (id: string): AnyAction => ({
  type: constants.ON_SHARE_CSV_IMPORT,
  id,
});

export const onShareCSVImportSucceeded = (data: { campaign: ICampaign }): AnyAction => ({
  type: constants.ON_SHARE_CSV_IMPORT_SUCCEEDED,
  data,
});

export const onShareCSVImportFailed = (): AnyAction => ({
  type: constants.ON_SHARE_CSV_IMPORT_FAILED,
});

export const onInitializeShareLaunchSucceeded = (data: { campaign: ICampaign }): AnyAction => ({
  type: constants.ON_INITIALIZE_SHARE_LAUNCH_SUCCEEDED,
  data,
});

export const onInitializeShareLaunchFailed = (): AnyAction => ({
  type: constants.ON_INITIALIZE_SHARE_LAUNCH_FAILED,
});

export const onUploadVideoRequested = (file: IAsset): AnyAction => ({
  type: constants.ON_UPLOAD_ORIGINAL_VIDEO_REQUESTED,
  file,
});

export const onUploadOriginalVideoSucceed = (data: IAsset): AnyAction => ({
  type: constants.ON_UPLOAD_ORIGINAL_VIDEO_SUCCEED,
  data,
});

export const onUploadOriginalVideoFailed = (): AnyAction => ({
  type: constants.ON_UPLOAD_ORIGINAL_VIDEO_FAILED,
});

export const onSelectEditVideoVariable = (): AnyAction => ({
  type: constants.ON_SELECT_EDIT_VIDEO,
});
