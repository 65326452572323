import styled from 'styled-components';

import { ISelected } from './types';

export const Wrapper = styled.div`
  min-height: 100vh;
  position: relative;
  z-index: 0;
`;

export const ShareTitle = styled.p`
  color: ${(props) => props.theme.colors.fiord};
  font-size: 20px;
  text-align: center;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  margin-bottom: 10px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
`;

export const SubTitle = styled.p`
  color: ${(props) => props.theme.colors.fiord};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.extraLarge};
  text-align: center;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  margin-bottom: 30px;
`;

export const Header = styled.div`
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const OptionsContainer = styled.div`
  height: 25px;
  width: 45%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding-bottom: 5px;
`;

export const Text = styled.p<ISelected>`
  height: 35px;
  color: ${(props) => props.theme.colors.fiord};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.extraLarge};
  text-align: center;
  cursor: pointer;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  border-bottom: ${(props) =>
    props.isSelected ? `3px solid ${props.theme.colors.apricot}` : `3px solid transparent`};
`;
