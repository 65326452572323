import React, { FC, useEffect, useState } from 'react';

import DragAndDrop from 'components/DragAndDrop/DragAndDrop';
import { IDDTextVariable } from 'components/DragAndDrop/types';
import Wrapper from 'components/common/Wrapper/Wrapper';
import { IValue } from 'containers/matchContainer/types';
import { ShareType } from 'enums/shareType.enum';
import i18n from 'i18next';

import './i18n';
import {
  LeftContainer,
  Line,
  MatchFieldsContainer,
  RightContainer,
  SegmentText,
  TitleContainer,
  WrapperImport,
} from './styles';
import { IField, IProps } from './types';

const ShareMatch: FC<IProps> = (props) => {
  const [matched, setMatched] = useState<IDDTextVariable[]>([]);
  const [fields, setFields] = useState<IField[]>([]);
  const [textVariablesDD, setTextVariablesDD] = useState<IDDTextVariable[]>([]);

  useEffect(() => {
    if (props.shareType === ShareType.CSV && props.csvData && !!props.csvData.length) {
      let values: IValue[][] = [];
      values = props.csvData.map((csvItem) => {
        return Object.values(matched).map((value) => {
          return {
            value: csvItem[value.selectedValue],
            id: value.key,
            header: props.csvData ? props.csvData[0][value.selectedValue] : '',
          };
        });
      });
      props.setMatchedValues(values.splice(1));
    } else if (props.members && !!props.members.length) {
      props.setMatchedValues(
        props.members.map(() => {
          return Object.values(matched).map((value) => {
            return { value: value.selected, id: value.key, header: '' };
          });
        })
      );
      //TODO @FIXME GUIDOG ADD CHECK FOR QUANTITY OF VALUES
    }
  }, [props.csvData, props.members, matched]);

  useEffect(() => {
    if (props.fields && props.members) {
      const fields: IField[] = [];
      props.fields.forEach((field) => {
        let activeColumn = true;
        props.members.forEach((member) => {
          if (member.merge_fields) {
            if (!member.merge_fields[field.tag]) {
              activeColumn = false;
            }
          }
        });
        if (activeColumn) {
          fields.push({ key: field.tag, value: field.name });
        }
        setFields(fields);
      });
    } else if (props.csvData && !!props.csvData.length) {
      const headers: string[] = Object.values(props.csvData[0]);
      const fields: IField[] = [];

      headers.forEach((header) => {
        let activeColumn = true;
        if (props.csvData) {
          props.csvData.forEach((row) => {
            if (!row[header]) {
              activeColumn = false;
            }
          });
          if (activeColumn) {
            fields.push({ key: header, value: header });
          }
        }
      });
      setFields(fields);
    }
  }, [props.fields, props.csvData, props.members]);

  useEffect(() => {
    if (props.textVariables.length) {
      setTextVariablesDD(
        props.textVariables.map((textVariable) => {
          return {
            key: textVariable._id,
            value: textVariable.value,
            selected: '',
            selectedValue: '',
          };
        })
      );
    }
  }, [props.textVariables]);

  return (
    <WrapperImport>
      <Wrapper>
        {!!fields.length && (!!props.csvData || (!!props.fields && !!props.members)) && (
          <MatchFieldsContainer>
            <TitleContainer>
              <LeftContainer>
                <SegmentText>{i18n.t('SHAREMATCH:SEGMENTFIELDS')}</SegmentText>
                <Line></Line>
              </LeftContainer>
              <RightContainer>
                <SegmentText>{i18n.t('SHAREMATCH:BMSFIELDS')}</SegmentText>
                <Line></Line>
              </RightContainer>
            </TitleContainer>
            <DragAndDrop
              textVariables={textVariablesDD}
              items={fields}
              matchedFields={setMatched}
            />
          </MatchFieldsContainer>
        )}
      </Wrapper>
    </WrapperImport>
  );
};
export default ShareMatch;
