import { initReactI18next } from 'react-i18next';

import i18next from 'i18next';

export const locales = {
  EN: 'en-US',
};

const DEFAULT_LOCALE = locales.EN;

i18next.use(initReactI18next).init({
  resources: {},
  lng: DEFAULT_LOCALE,
  fallbacks: true,
  fallbackLng: DEFAULT_LOCALE,
  initImmediate: false,
  react: {
    useSuspense: false,
  },
});
