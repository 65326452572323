import styled from 'styled-components';

import { Option } from './enums/options.enum';
import { IOptionWrapper } from './types';

export const SidebarWrapper = styled.div`
  display: flex;
  margin: auto;
  height: 100%;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  margin: auto;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

export const OptionsTab = styled.div`
  display: flex;
  margin: auto;
  height: 100%;
  width: 75px;
  background-color: ${(props) => props.theme.colors.cello};
`;

export const Text = styled.p`
  font-size: 10px;
  letter-spacing: ${(props) => props.theme.spacing.spacing1};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  transition: all 0.2s ease-out;
  font-weight: 400;
  text-align: center;
  margin-top: 5px;
`;

export const OptionContainer = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: left;
  justify-content: center;
`;

export const OptionWrapper = styled.div<IOptionWrapper>`
  display: flex;
  height: 75px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.bigStone};
  ${OptionContainer} {
    background-color: ${(props) =>
      props.open === props.current ? props.theme.colors.bigStone : props.theme.colors.cello};
    border-radius: ${(props) =>
      props.open === Option.TEMPLATE && props.current === Option.COLORS && '0 8px 0 0'};
    border-radius: ${(props) =>
      props.open === Option.COLORS && props.current === Option.TEMPLATE && '0 0 8px 0'};
    border-radius: ${(props) =>
      props.open === Option.COLORS && props.current === Option.IMAGE && '0 8px 0 0'};
    border-radius: ${(props) =>
      props.open === Option.IMAGE && props.current === Option.COLORS && '0 0 8px 0'};
    border-radius: ${(props) =>
      props.open === Option.IMAGE && props.current === Option.VIDEO && '0 8px 0 0'};
    border-radius: ${(props) =>
      props.open === Option.VIDEO && props.current === Option.IMAGE && '0 0 8px 0'};
    border-radius: ${(props) =>
      props.open === Option.VIDEO && props.current === Option.EMPTY && '0 8px 0 0'};
  }
  cursor: ${(props) =>
    props.open === props.current || props.current === Option.EMPTY ? 'default' : 'pointer'};
  &:hover {
    ${Text} {
      font-family: ${(props) =>
        props.open !== props.current && props.theme.headers.h2.fontFamilies.bold};
    }
  }
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  display: flex;
  margin-bottom: 5px;
`;

export const OpenTabWrapper = styled.div`
  display: flex;
  margin: auto;
  height: 100%;
  width: 270px;
  background-color: ${(props) => props.theme.colors.bigStone};
  flex-direction: column;
  overflow: auto;
`;

export const OpenTab = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;
