import styled from 'styled-components';

import { IDropzoneWrapper, IIconImage } from './types';

export const IconImage = styled.div<IIconImage>`
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.image});
  margin: auto;
`;

export const DropzoneWrapper = styled.div<IDropzoneWrapper>`
  width: 350px;
  height: 215px;
  outline: none;
  display: ${(props) => (props.asset ? 'flex' : 'none')};
  flex-wrap: wrap;
  border: 1px solid ${(props) => props.theme.colors.porcelain};
  border-radius: 10px;
  cursor: pointer;
  align-items: center;
  place-content: center;
`;

export const DropzoneText = styled.h2`
  width: 200px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  color: ${(props) => props.theme.colors.raven};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  text-align: center;
  margin-top: 10px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
`;
