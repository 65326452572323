import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'MY_CAMPAIGNS', {
  CAMPAIGNS: 'My Campaigns',
  LOAD_MORE: 'Load More',
  NAME: 'Name',
  LAST_MODIFICATION: 'Last modified',
  CREATED_AT: 'Created at',
});
