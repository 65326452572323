import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ShareLayout from 'containers/ShareLayout/ShareLayout';
import { ShareStep } from 'enums/shareStep.enum';
import { ShareType } from 'enums/shareType.enum';
import { TextVariableType } from 'enums/textVariable.enum';
import { IObject } from 'helpers/types';
import i18n from 'i18next';
import * as campaignActions from 'redux/actions/campaign.actions';
import * as shareActions from 'redux/actions/share.actions';
import getCampaignSelector from 'redux/selectors/campaign.selector';
import getShareSelector, { getLoading } from 'redux/selectors/share.selector';
import { ITextVariable } from 'views/Video/types';
import FieldDataWarningModal from 'views/share/ShareSelect/FieldDataWarningModal/FieldDataWarningModal';
import ShareSelect from 'views/share/ShareSelect/ShareSelect';

import './i18n';
import { CampaignShareProps, IHeader, ISelectedSegment } from './types';

const ShareSelectContainer: FC<CampaignShareProps> = (props) => {
  const dispatch = useDispatch();
  const campaignId = props.match.params.campaignId;
  const shareDataSelector = useSelector(getShareSelector());
  const isLoading = useSelector(getLoading());
  const campaignSelector = useSelector(getCampaignSelector());
  const [values, setValues] = useState<ISelectedSegment>({
    segmentId: '',
    listId: '',
  });
  const [csvHeaders, setCSVHeaders] = useState<IHeader[]>([]);
  const [listFields, setListFields] = useState<IHeader[]>([]);
  const [mailchimpTitle, setMailchimpTitle] = useState(`${i18n.t('SHARESELECT:SELECT')}`);
  const [mailchimpSubtitle, setMailchimpSubtitle] = useState('');
  const [warningModalVisibility, setWarningModalVisibility] = useState(false);
  const [disableWarning, setDisableWarning] = useState(false);

  const lists = shareDataSelector.mailchimp.lists;
  const listSegments = shareDataSelector.mailchimp.segments.segments;

  useEffect(() => {
    dispatch(shareActions.OnInitializeShareSelect(campaignId));
  }, [dispatch, campaignId]);

  useEffect(() => {
    if (
      campaignSelector.campaign.shareType === ShareType.MAILCHIMP &&
      !isLoading &&
      listSegments &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      !(listSegments as IObject[]).some((segment: any) => segment && segment.member_count > 0)
    ) {
      setMailchimpTitle(i18n.t('SHARESELECT:REMINDER'));
      setMailchimpSubtitle(i18n.t('SHARESELECT:SUBTITLE_MAILCHIMP_NO_SEGMENTS'));
    }
  }, [isLoading, listSegments]);

  useEffect(() => {
    if (!campaignSelector.loadingShareData) {
      if (
        campaignSelector.campaign.shareType === ShareType.CSV &&
        campaignSelector.campaign.shareData &&
        campaignSelector.campaign.shareData.csv
      ) {
        const headers: string[] = Object.values(campaignSelector.campaign.shareData.csv[0]);
        const filteredHeaders: IHeader[] = [];

        headers.forEach((header) => {
          let activeColumn = true;
          campaignSelector.campaign.shareData.csv.forEach((row) => {
            if (!row[header]) {
              activeColumn = false;
            }
          });
          filteredHeaders.push({ text: header, active: activeColumn });
        });
        setCSVHeaders(filteredHeaders);
        if (!warningModalVisibility && !disableWarning) {
          setDisableWarning(true);
          setWarningModalVisibility(
            filteredHeaders.filter((header) => header.active).length !== headers.length
          );
        }
      } else if (
        campaignSelector.campaign.shareType === ShareType.MAILCHIMP &&
        shareDataSelector.mailchimp &&
        !!shareDataSelector.mailchimp.listFields.length &&
        shareDataSelector.mailchimp.segmentMembers
      ) {
        const filteredHeaders: IHeader[] = [];
        shareDataSelector.mailchimp.listFields.forEach((listField) => {
          let activeColumn = true;
          shareDataSelector.mailchimp.segmentMembers.forEach((member) => {
            if (member.merge_fields) {
              if (!member.merge_fields[listField.tag]) {
                activeColumn = false;
              }
            }
          });
          activeColumn = shareDataSelector.mailchimp.segmentMembers.length ? activeColumn : false;
          filteredHeaders.push({ text: listField.name, active: activeColumn });
        });
        setListFields(filteredHeaders);
        if (
          shareDataSelector.mailchimp.segmentMembers.length &&
          !warningModalVisibility &&
          !disableWarning
        ) {
          setDisableWarning(true);
          setWarningModalVisibility(
            filteredHeaders.filter((header) => header.active).length !==
              shareDataSelector.mailchimp.listFields.length
          );
        }
      }
    }
  }, [campaignSelector, shareDataSelector.mailchimp]);

  let filtered = [];
  if (campaignSelector.campaign.textVariables.length) {
    filtered = campaignSelector.campaign.textVariables.filter(
      (textVariable: ITextVariable) => textVariable.type === TextVariableType.Variable
    );
  }

  const onNext = () => {
    if (campaignSelector.campaign.shareType === ShareType.MAILCHIMP) {
      dispatch(campaignActions.onSelectNextSelectView(campaignId, values));
    } else {
      dispatch(
        campaignActions.onSelectNextSelectView(campaignId, campaignSelector.campaign.shareData)
      );
    }
  };

  useEffect(() => {
    setValues(values);
    onChangeSegment();
  }, [values]);

  const onBack = () => {
    dispatch(shareActions.onBackShareSelect(campaignId, campaignSelector.campaign.shareType));
  };

  const onChangeSegment = () => {
    if (values.listId && values.segmentId) {
      dispatch(shareActions.onRetrieveSegmentMembers(values.listId, values.segmentId));
    }
  };

  const onCloseWarningModal = () => {
    setWarningModalVisibility(false);
  };

  return (
    <ShareLayout
      disabledNextButton={
        campaignSelector.campaign.shareType === ShareType.MAILCHIMP
          ? isLoading ||
            (shareDataSelector.mailchimp &&
              shareDataSelector.mailchimp.segmentMembers &&
              !shareDataSelector.mailchimp.segmentMembers.length)
          : false
      }
      campaignId={campaignId}
      step={ShareStep.SELECT}
      onBack={onBack}
      onNext={onNext}
      shareType={campaignSelector.campaign.shareType}
      title=""
      textVariables={filtered}
    >
      <ShareSelect
        mailChimpLists={lists}
        mailchimpListFields={listFields}
        mailchimpSegments={listSegments}
        csvData={csvHeaders}
        shareType={campaignSelector.campaign.shareType}
        setSegment={setValues}
        filters={filtered}
        title={
          isLoading
            ? ''
            : campaignSelector.campaign.shareType === ShareType.MAILCHIMP
            ? mailchimpTitle
            : i18n.t('SHARESELECT:VERIFY')
        }
        subtitle={
          campaignSelector.campaign.shareType !== ShareType.NONE &&
          campaignSelector.campaign.shareType === ShareType.MAILCHIMP
            ? mailchimpSubtitle
            : campaignSelector.campaign.shareType === ShareType.CSV
            ? i18n.t('SHARESELECT:SUBTITLE_CSV')
            : ''
        }
      />
      {warningModalVisibility && <FieldDataWarningModal onClose={onCloseWarningModal} />}
    </ShareLayout>
  );
};

export default ShareSelectContainer;
