import React, { FC } from 'react';

import { FilterContainer, Filters, Link } from './styles';
import { IProps } from './types';

const Filter: FC<IProps> = (props) => {
  const changeCategory = (category: string) => {
    props.setCategory(category);
  };

  return (
    <FilterContainer>
      {props.categories.map((filter) => {
        return (
          <Filters key={filter.name}>
            <Link
              active={filter.name === props.category}
              onClick={() => changeCategory(filter.name)}
            >
              {filter.name}
            </Link>
          </Filters>
        );
      })}
    </FilterContainer>
  );
};
export default Filter;
