import { CSVLink } from 'react-csv';

import { ReactComponent as IconOneToOnehover } from 'assets/icon-1-1-h.svg';
import { ReactComponent as IconOneToOne } from 'assets/icon-1-1.svg';
import { ReactComponent as IconCSV2Hover } from 'assets/icon-csv2-dwn-h.svg';
import { ReactComponent as IconCSV2 } from 'assets/icon-csv2.svg';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-top: 30px;
`;

export const SubTitle = styled.h2`
  font-size: 20px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.fiord};
  text-align: center;
  align-items: center;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const OptionsContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  justify-content: space-around;
  align-items: center;
`;

export const IconCSV = styled(IconCSV2)`
  width: 140px;
  height: 140px;
  background-repeat: no-repeat;
  align-items: center;
  background-size: contain;
  position: absolute;
  transition: ${(props) => props.theme.transition.all};
  margin-left: 20px;
`;

export const IconCSVHover = styled(IconCSV2Hover)`
  width: 140px;
  height: 140px;
  background-repeat: no-repeat;
  align-items: center;
  background-size: contain;
  opacity: 0;
  transition: ${(props) => props.theme.transition.all};
  position: absolute;
  margin-left: 20px;
`;

export const IconOne = styled(IconOneToOne)`
  width: 140px;
  height: 130px;
  background-repeat: no-repeat;
  align-items: center;
  background-size: contain;
  transition: ${(props) => props.theme.transition.all};
  top: 50px;
  position: absolute;
`;

export const IconOneHover = styled(IconOneToOnehover)`
  width: 140px;
  height: 130px;
  background-repeat: no-repeat;
  align-items: center;
  background-size: contain;
  opacity: 0;
  transition: ${(props) => props.theme.transition.all};
  top: 50px;
  position: absolute;
`;

export const OptionText = styled.div`
  font-size: 14px;
  margin-top: 180px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  color: ${(props) => props.theme.colors.cello};
  letter-spacing: 0px;
`;

export const OptionsContCSV = styled.div`
  width: 200px;
  height: 234px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: ${(props) => props.theme.transition.all};
  align-items: center;
  cursor: pointer;
  position: relative;
  &:hover {
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 6px 12px #00000029;
    border-radius: 8px;
    ${OptionText} {
      font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
    }
    ${IconCSV} {
      opacity: 0;
    }
    ${IconCSVHover} {
      opacity: 1;
    }
  }
`;

export const OptionsContOne = styled.div`
  width: 200px;
  height: 234px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: ${(props) => props.theme.transition.all};
  cursor: pointer;
  position: relative;
  &:hover {
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 6px 12px #00000029;
    border-radius: 8px;
    ${OptionText} {
      font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
    }
    ${IconOne} {
      opacity: 0;
    }
    ${IconOneHover} {
      opacity: 1;
    }
  }
`;

export const CSV = styled(CSVLink)`
  text-decoration: none;
  color: #000000;
`;

export const WrapperLaunch = styled.div`
  height: calc(100% - 277px);
  min-height: calc(100vh - 277px);
  background: ${(props) => props.theme.colors.aena};
`;

export const Or = styled.h2`
  font-size: 18px;
  margin-right: 20px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.extraLarge};
  text-align: center;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  margin-bottom: 0px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => props.theme.colors.fiord};
`;
