import React, { FC, useEffect, useState } from 'react';

import CloseModalButton from 'components/CloseModalButton/CloseModalButton';
import i18n from 'i18next';

import './i18n';
import {
  Button,
  ButtonContainer,
  CloseModalWrapper,
  Message,
  MessageCampaign,
  ModalContainer,
  TextContainer,
  Title,
  TransitionModal,
} from './styles';
import { IProps } from './types';

const ConfirmModal: FC<IProps> = (props) => {
  const [fadeType, setFadeType] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setTimeout(() => setFadeType('in'), 0);
  }, []);

  const handleClose = () => {
    setTimeout(() => setFadeType('out'), 0);
  };

  if (fadeType === 'out') {
    props.onClose();
  }

  const handleClick = () => {
    if (!isDisabled) {
      setIsDisabled(true);
      props.onClick();
    }
  };

  useEffect(() => {
    if (isDisabled && !props.isLoading) {
      handleClose();
    }
  }, [props.isLoading]);

  return (
    <TransitionModal fadeType={fadeType}>
      <ModalContainer>
        <CloseModalWrapper>
          <CloseModalButton onClick={handleClose} />
        </CloseModalWrapper>
        <TextContainer>
          <Title>{i18n.t('CONFIRMMODAL:DELETE_PERMANENTLY_QUESTION')}</Title>
          <Message>{i18n.t('CONFIRMMODAL:DELETE_WARNING')}</Message>
          <MessageCampaign>
            {i18n.t('CONFIRMMODAL:DELETE_CAMPAIGN', {
              name: props.campaignName,
            })}
          </MessageCampaign>
          <MessageCampaign>{i18n.t('CONFIRMMODAL:UNDO')}</MessageCampaign>
        </TextContainer>
        <ButtonContainer>
          <Button onClick={handleClose}>{i18n.t('CONFIRMMODAL:CANCEL')}</Button>
          <Button isError={props.hasError} onClick={handleClick}>
            {i18n.t('CONFIRMMODAL:DELETE')}
          </Button>
        </ButtonContainer>
      </ModalContainer>
    </TransitionModal>
  );
};

export default ConfirmModal;
