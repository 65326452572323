import axios from 'axios';
import { API_URL } from 'configs/index';
import { IObject } from 'helpers/types';

import { getCookie, removeCookie } from '../helpers/cookies';

export interface IPostResponse {
  success: boolean;
  message: string;
  errorMessage: string;
  data: { token: string; expiresIn: string };
}

export interface IResponse {
  success: boolean;
  message: string;
  errorMessage: string;
  data?: string;
}

const API = axios.create({
  baseURL: API_URL,
});

const defaultErrorResponse = { success: false, errorMessage: 'Unknown error' };

const _buildHeaders = (extraHeaders = {}) => {
  const accessToken = getCookie('accessToken');
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
    ...extraHeaders,
  };
};

const _handleErrorResponse = (error) => {
  if (error.response.data.statusCode === 401) {
    removeCookie('accessToken');
    window.location.href = '/';
  }
  return {
    success: false,
    message: error.response.statusText,
    errorMessage: error.response.statusText,
    data: error.response.status,
  };
};

const post = async (
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  params: any,
  extraHeaders = {}
): Promise<IPostResponse> => {
  // TODO FIX THIS TYPE
  let responseData = {
    success: true,
    message: '',
    errorMessage: '',
    data: { token: '', expiresIn: '' },
  };
  try {
    const config = {
      headers: _buildHeaders(extraHeaders),
    };
    const response = await API.post(url, params, config);
    responseData = response.data || defaultErrorResponse;
  } catch (error) {
    responseData = _handleErrorResponse(error);
  }

  if (!responseData.success) {
    const error = new Error(responseData.errorMessage);
    throw error;
  }

  return responseData;
};

const get = async (url: string, extraHeaders = {}): Promise<IResponse> => {
  let responseData = { success: true, message: '', errorMessage: '', data: '' };
  try {
    const config = {
      headers: _buildHeaders(extraHeaders),
    };
    const response = await API.get(url, config);
    responseData = response.data || defaultErrorResponse;
  } catch (error) {
    responseData = _handleErrorResponse(error);
  }

  if (!responseData.success) {
    const error = new Error(responseData.errorMessage);
    throw error;
  }

  return responseData;
};

const put = async (
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  params: any,
  extraHeaders = {}
): Promise<IResponse> => {
  let responseData = { success: true, message: '', errorMessage: '', data: '' };
  try {
    const config = {
      headers: _buildHeaders(extraHeaders),
    };
    const response = await API.put(url, params, config);
    responseData = response.data || defaultErrorResponse;
  } catch (error) {
    responseData = _handleErrorResponse(error);
  }

  if (!responseData.success) {
    const error = new Error(responseData.errorMessage);
    throw error;
  }

  return responseData;
};

const apiDelete = async (url: string, data: IObject, extraHeaders = {}): Promise<IResponse> => {
  let responseData = { success: true, message: '', errorMessage: '' };
  try {
    const headers = _buildHeaders(extraHeaders);
    const response = await API.delete(url, { headers, data });
    responseData = response.data || defaultErrorResponse;
  } catch (error) {
    responseData = _handleErrorResponse(error);
  }

  if (!responseData.success) {
    const error = new Error(responseData.errorMessage);
    throw error;
  }

  return responseData;
};

export default API;

export { post, get, put, apiDelete };
