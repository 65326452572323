import { IObject } from 'helpers/types';
import { AnyAction } from 'redux';

import * as constants from '../constants/myCampaigns.action.constants';

export const selectCampaign = (id: string): AnyAction => ({
  type: constants.ON_SELECT_CAMPAIGN_REQUESTED,
  id,
});

export const deleteCampaign = (id: string): AnyAction => ({
  type: constants.ON_DELETE_CAMPAIGN_REQUESTED,
  id,
});

export const deleteCampaignSucceded = (data: { id: string }, page = 1): AnyAction => ({
  type: constants.ON_DELETE_CAMPAIGN_SUCCEDED,
  data,
  page,
});

export const deleteCampaignFailed = (): AnyAction => ({
  type: constants.ON_DELETE_CAMPAIGN_FAILED,
});

export const relaunchCampaign = (
  id: string,
  shareType: number,
  campaignShareData: null | IObject
): AnyAction => ({
  type: constants.ON_RELAUNCH_CAMPAIGN_REQUESTED,
  id,
  shareType,
  campaignShareData,
});

export const relaunchCampaignSucceded = (url: string): AnyAction => ({
  type: constants.ON_RELAUNCH_CAMPAIGN_SUCCEDED,
  url,
});

export const relaunchCampaignFailed = (): AnyAction => ({
  type: constants.ON_RELAUNCH_CAMPAIGN_FAILED,
});
