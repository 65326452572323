import React, { FC, useState } from 'react';

import { TextVariableType } from 'enums/textVariable.enum';
import i18n from 'i18next';

import './i18n';
import {
  BottomWrapper,
  ButtonWrapper,
  CancelButton,
  DoneButton,
  Input,
  InputContainer,
  InputTitle,
  ModalContainer,
  ModalWrapper,
  Remaining,
} from './styles';
import { IProps } from './types';

const TextEditModal: FC<IProps> = (props) => {
  let linkInitialValue = props.variableType === TextVariableType.CTA ? 'https://' : '';
  if (props.ctaLink !== '') {
    linkInitialValue = props.ctaLink;
  }
  const [text, setText] = useState<string>(props.value);
  const [link, setLink] = useState<string>(linkInitialValue);

  const handleClose = () => {
    props.onClose();
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLink(event.target.value);
  };

  const handleSubmit = () => {
    props.onSubmit(text, props.id, link);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      props.onSubmit(text, props.id, link);
    }
    if (event.key === 'Escape') {
      props.onClose();
    }
  };

  return (
    <ModalWrapper>
      <ModalContainer>
        <InputContainer>
          {props.variableType === TextVariableType.CTA ? (
            <InputTitle>{i18n.t('EDITTEXT:BUTTONTEXT')}</InputTitle>
          ) : (
            <InputTitle>{i18n.t('EDITTEXT:INPUTTEXT')}</InputTitle>
          )}
          <Input
            onChange={handleTextChange}
            onKeyDown={handleKeyPress}
            maxLength={props.maxLength}
            autoFocus={true}
            value={text}
          />
          <Remaining>
            {i18n.t('EDITTEXT:CHARACTERS')} {props.maxLength - text.length}
          </Remaining>
        </InputContainer>
        {props.variableType === TextVariableType.CTA && (
          <InputContainer>
            <InputTitle>{i18n.t('EDITTEXT:INPUTURL')}</InputTitle>
            <Input
              placeholder="URL"
              onChange={handleLinkChange}
              value={link}
              onKeyDown={handleKeyPress}
              maxLength={255}
              autoFocus={true}
            />
          </InputContainer>
        )}
        <BottomWrapper>
          <ButtonWrapper>
            <CancelButton onClick={handleClose}>{i18n.t('EDITTEXT:CANCEL')}</CancelButton>
            <DoneButton onClick={handleSubmit}>{i18n.t('EDITTEXT:DONE')}</DoneButton>
          </ButtonWrapper>
        </BottomWrapper>
      </ModalContainer>
    </ModalWrapper>
  );
};
export default TextEditModal;
