import React, { FC, useEffect, useState } from 'react';

import { IEmailData } from 'containers/launchContainer/types';
import { generateBMSLink } from 'helpers/BMSLinkGenerator.helper';
import { copyToClipboard } from 'helpers/copyToClipboard.helper';
import { isEmail } from 'helpers/inputHelper/inputHelper';
import i18n from 'i18next';

import DisabledItemsWarningModal from './components/DisabledItemsWarningModal/DisabledItemsWarningModal';
import SuccessMessage from './components/SuccessMessage/SuccessMessage';
import './i18n';
import {
  ButtonWrapper,
  Cell,
  CenterWrapper,
  Checkbox,
  CheckboxWrapper,
  ContactListContainer,
  Container,
  CopyLink,
  ErrorCell,
  Header,
  HiddenCheckbox,
  Icon,
  Input,
  LabelTitle,
  LightHeader,
  Line,
  Row,
  Section,
  Value,
  WrapperLaunch,
} from './styles';
import { IProps } from './types';

const LaunchShare: FC<IProps> = (props) => {
  const isItemDisabled = (index: number) => {
    return (
      !props.emailData[index]['name'] ||
      !props.emailData[index]['email'] ||
      !isEmail(props.emailData[index]['email'])
    );
  };

  const itemsDisabled = () => {
    return Object.keys(props.emailData).some((index) => isItemDisabled(parseInt(index)));
  };

  const [showWarningModal, setShowWarningModal] = useState(itemsDisabled());
  const [error, setIsError] = useState({ error: false, index: 0 });

  useEffect(() => {
    props.setEmailData([]);
    const data: IEmailData[] = [];
    props.exportData.forEach((item) => {
      let firstName = '';
      let lastName = '';
      let fullName = '';
      let email = '';
      const currentCSVValues = props.csv.find((row) => {
        let isCorrect = true;
        item.values.forEach((value) => {
          if (row[value.header] !== value.value) {
            isCorrect = false;
          }
        });
        return isCorrect;
      });

      if (currentCSVValues) {
        Object.entries(currentCSVValues).forEach(([key, value]) => {
          if (
            key.trim().toUpperCase().includes('FIRST NAME') ||
            key.trim().toUpperCase().includes('FIRSTNAME') ||
            key.trim().toUpperCase().includes('FIRST_NAME') ||
            key.trim().toUpperCase() === 'NAME'
          ) {
            firstName = value;
          }
          if (
            key.trim().toUpperCase().includes('LAST NAME') ||
            key.trim().toUpperCase().includes('LASTNAME') ||
            key.trim().toUpperCase().includes('LAST_NAME')
          ) {
            lastName = value;
          }
          if (key.trim().toUpperCase().includes('EMAIL') && email === '') {
            email = value;
          }
        });
      }
      if (firstName && lastName) {
        fullName = firstName + ' ' + lastName;
      } else {
        fullName = firstName ? firstName : lastName ? lastName : '';
      }
      data.push({ id: item.id, name: fullName, email: email });
      setShowWarningModal(!fullName || !email);
    });
    props.setEmailData(data);
  }, []);

  const handleChecked = (id: string) => {
    if (props.checked.includes(id)) {
      props.setChecked((prevState) => prevState.filter((checked) => checked !== id));
    } else {
      props.setChecked((prevState) => [...prevState, id]);
    }
  };

  const handleSelectAll = () => {
    if (allChecksOn()) {
      props.setChecked([]);
    } else {
      props.setChecked(props.exportData.map((item) => item.id));
    }
  };

  const allChecksOn = () => {
    return props.checked.length === props.exportData.length;
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    key: string
  ) => {
    const value = event.target.value;
    const previousEmailData = props.emailData.map((data, i) => {
      if (index === i) {
        data[key] = value;
      }
      return data;
    });
    props.setEmailData(previousEmailData);
  };

  const handleCopy = (id: string) => {
    props.onRequestLink(id);
    if (!props.couldCopy) {
      copyToClipboard(generateBMSLink(id));
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      props.setIsVisibleMessage('');
    }, 2000);
    return () => clearInterval(interval);
  }, [props.isVisibleMessage]);

  const handleChangeCheckbox = (disabled: boolean, uuid: string) => {
    if (!disabled) {
      handleChecked(uuid);
    }
  };

  const handleBlurError = (index: number) => {
    const error = props.emailData[index]['email'] && !isEmail(props.emailData[index]['email']);
    if (error) {
      setIsError({ error: true, index: index });
    } else {
      setIsError({ error: false, index: index });
    }
  };

  const isEmailError = (index: number) => {
    return props.emailData[index]['email'] && !isEmail(props.emailData[index]['email']);
  };

  const onCloseWarningModal = () => {
    setShowWarningModal(false);
  };

  return (
    <WrapperLaunch>
      <Container>
        <LabelTitle>{i18n.t('LAUNCHSHARE:CONTACTLIST')}</LabelTitle>
        <ContactListContainer>
          {!!props.exportData.length && !!props.emailData.length && (
            <Section>
              <Row>
                <Cell />
                <Cell>
                  <Header>{i18n.t('LAUNCHSHARE:NAME')}</Header>
                </Cell>
                <Cell>
                  <Header>{i18n.t('LAUNCHSHARE:EMAIL')}</Header>
                </Cell>
                <Cell>
                  <CenterWrapper>
                    <LightHeader>{i18n.t('LAUNCHSHARE:COPY_LINK')}</LightHeader>
                  </CenterWrapper>
                </Cell>
                <Cell isCheckbox>
                  <LightHeader>{i18n.t('LAUNCHSHARE:SELECT_ALL')}</LightHeader>
                  <CheckboxWrapper>
                    <HiddenCheckbox checked={allChecksOn()} />
                    <Checkbox checked={allChecksOn()} onClick={() => handleSelectAll()}>
                      <Icon />
                    </Checkbox>
                  </CheckboxWrapper>
                </Cell>
              </Row>
              <Row>
                <Cell>
                  <Line />
                </Cell>
                <Cell>
                  <Line />
                </Cell>
                <Cell>
                  <Line />
                </Cell>
                <Cell>
                  <Line />
                </Cell>
                <Cell>
                  <Line />
                </Cell>
              </Row>
              {props.exportData.map((item, index) => (
                <>
                  <Row id={item.id} key={item.id}>
                    <Cell>
                      <Value>{index + 1}</Value>
                    </Cell>
                    <Cell>
                      <Input
                        isName
                        name={item.id}
                        onChange={(e) => handleInputChange(e, index, 'name')}
                        value={props.emailData[index] ? props.emailData[index]['name'] : ''}
                        placeholder={i18n.t('LAUNCHSHARE:EMPTY')}
                        autoComplete="off"
                      />
                    </Cell>
                    <Cell>
                      <Input
                        name={item.id}
                        onChange={(e) => handleInputChange(e, index, 'email')}
                        onBlur={() => handleBlurError(index)}
                        value={props.emailData[index] ? props.emailData[index]['email'] : ''}
                        placeholder={i18n.t('LAUNCHSHARE:EMPTY')}
                        emailError={isEmailError(index)}
                        autoComplete="off"
                      />
                    </Cell>
                    <Cell>
                      <ButtonWrapper>
                        <CenterWrapper>
                          <div onClick={() => handleCopy(item.id)}>
                            <CopyLink />
                          </div>
                        </CenterWrapper>
                        <SuccessMessage showMessage={props.isVisibleMessage === item.id} />
                      </ButtonWrapper>
                    </Cell>
                    <Cell isCheckbox>
                      <CheckboxWrapper>
                        <HiddenCheckbox checked={props.checked.includes(item.id)} />
                        <Checkbox
                          checked={props.checked.includes(item.id)}
                          onClick={() => handleChangeCheckbox(isItemDisabled(index), item.id)}
                          disabled={isItemDisabled(index)}
                        >
                          <Icon />
                        </Checkbox>
                      </CheckboxWrapper>
                    </Cell>
                  </Row>
                  {error.error && error.index === index && (
                    <Row id={item.id} key={item.id}>
                      <ErrorCell />
                      <ErrorCell />
                      <ErrorCell>{i18n.t('LAUNCHSHARE:EMAIL_ERROR')}</ErrorCell>
                      <ErrorCell />
                      <ErrorCell />
                    </Row>
                  )}
                </>
              ))}
            </Section>
          )}
        </ContactListContainer>
      </Container>
      {showWarningModal && <DisabledItemsWarningModal onClose={onCloseWarningModal} />}
    </WrapperLaunch>
  );
};
export default LaunchShare;
