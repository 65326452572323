import React, { FC, useState } from 'react';

import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import i18n from 'i18next';

import './i18n';
import {
  Delete,
  Edit,
  List,
  ListItem,
  More,
  Options,
  OptionsList,
  OptionsListContainer,
  Relaunch,
  Report,
} from './styles';
import { IProps } from './types';

const OptionsButton: FC<IProps> = (props) => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const onSelectCampaign = () => {
    props.onClickOption();
    props.selectCampaign(props.id);
  };

  const toggleDeleteModal = () => {
    props.onClickOption();
    setIsVisibleModal(!isVisibleModal);
  };

  const onRelaunchCampaign = () => {
    props.onClickOption();
    props.relaunchCampaign(props.id);
  };

  const onReportCampaign = () => {
    props.onClickOption();
    props.selectMetricsCampaign(props.id);
  };

  const onDeleteCampaign = () => {
    props.deleteCampaign(props.id);
  };

  return (
    <>
      {(props.showButton || props.showOptions) && (
        <Options listItem={props.listItem} onClick={props.handleClick}>
          <More />
          {props.showOptions && (
            <OptionsListContainer>
              <OptionsList>
                <ListItem onClick={onSelectCampaign}>
                  <Edit />
                  <List>{i18n.t('CAMPAIGN:EDIT')}</List>
                </ListItem>
                <ListItem onClick={toggleDeleteModal}>
                  <Delete />
                  <List>{i18n.t('CAMPAIGN:DELETE')}</List>
                </ListItem>
                <ListItem onClick={onRelaunchCampaign}>
                  <Relaunch />
                  <List>{i18n.t('CAMPAIGN:RELAUNCH')}</List>
                </ListItem>
                <ListItem onClick={onReportCampaign}>
                  <Report />
                  <List>{i18n.t('CAMPAIGN:REPORT')}</List>
                </ListItem>
              </OptionsList>
            </OptionsListContainer>
          )}
        </Options>
      )}
      {isVisibleModal && (
        <ConfirmModal
          onClick={onDeleteCampaign}
          onClose={toggleDeleteModal}
          hasError
          campaignName={props.title}
          isLoading={props.isLoadingDeleteCampaign}
        />
      )}
    </>
  );
};

export default OptionsButton;
