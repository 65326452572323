import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'NEW_LAYOUT', {
  NEW_BMS: 'New BMS',
  SELECT:
    'Select the type of campaign you need and we will contact you to  create a fully customized bms.',
  EXISTING_TEMPLATE: 'Based on existing template',
  CREATE: 'Create personalized campaign',
  SUCCESS: 'Email sent succesfully',
});
