import React, { FC, useState } from 'react';

import ModalLogin from 'components/ModalLogin/ModalLogin';
import i18n from 'i18next';

import './i18n';
import { ButtonsContainer, Image, TemplateContainer, Templates, Text, ThumbText } from './styles';
import { IProps } from './types';

const TemplateThumbnail: FC<IProps> = (props) => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);

  const handleModalClose = () => {
    setShowPopUp(false);
  };

  const submitLogIn = (email: string, password: string) => {
    if (props.onSubmitLoginAndCreateCampaign) {
      props.onSubmitLoginAndCreateCampaign(email, password, props.id);
    }
  };

  const onClickPreview = () => {
    props.onClickPreview(props.id);
  };

  return (
    <TemplateContainer>
      <Templates thumbnail={props.thumbnail}>
        <ButtonsContainer onClick={onClickPreview}>
          <Image />
          <Text>{i18n.t('DUAL_BUTTON:PREVIEW')}</Text>
        </ButtonsContainer>
      </Templates>
      <ThumbText>{props.title}</ThumbText>
      {showPopUp ? (
        <ModalLogin
          isSignUp={isSignUp}
          changeSignUp={setIsSignUp}
          onClose={handleModalClose}
          statusSignUp={props.statusSignUp}
          onSubmitSignUp={props.onSubmitSignUp}
          onSubmitLogin={submitLogIn}
          error={props.error}
          resetPassword={props.resetPassword}
          setErrorFalse={props.setErrorFalse}
        />
      ) : null}
    </TemplateContainer>
  );
};
export default TemplateThumbnail;
