import { ReactComponent as Preview } from 'assets/preview.svg';
import styled from 'styled-components';

import { ITemplateProps } from './types';

export const TemplateContainer = styled.div`
  width: 100%;
  height: 210px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Templates = styled.div<ITemplateProps>`
  width: 270px;
  height: 150px;
  border: 1px solid ${(props) => props.theme.colors.porcelain};
  display: flex;
  justify-content: left;
  background: url(${(props) => props.thumbnail});
  background-position: center;
  background-size: cover;
  transition: ${(props) => props.theme.transition.all};
  background-repeat: no-repeat;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background: linear-gradient(rgba(33, 69, 105, 0.9), rgba(33, 69, 105, 0.9)),
      url(${(props) => props.thumbnail});
    background-repeat: no-repeat, no-repeat;
    background-position: center;
    background-size: 100%;
  }
`;

export const ThumbText = styled.p`
  font-size: 15px;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  margin-top: 15px;
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  display: block;
`;

export const ButtonsContainer = styled.div`
  width: 132px;
  height: 40px;
  position: absolute;
  text-align: center;
  background: transparent;
  display: flex;
  opacity: 0;
  border: 1px solid #ffffff;
  top: 60px;
  left: 70px;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  border-radius: 4px;
  cursor: pointer;
  transition: ${(props) => props.theme.transition.all};
  ${Templates}:hover & {
    opacity: 1;
  }
  &:hover {
    color: ${(props) => props.theme.colors.cello};
    background: ${(props) => props.theme.colors.white};
  }
`;

export const ButtonPreview = styled.button`
  width: 100%;
  height: 100%;
  text-align: center;
  background: transparent;
  border: 1px solid #ffffff;
  outline: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${(props) => props.theme.transition.all};
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.cello};
    background: ${(props) => props.theme.colors.white};
  }
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
`;

export const Text = styled.div`
  margin-left: 4px;
`;

export const Image = styled(Preview)`
  width: 20px;
  transition: ${(props) => props.theme.transition.all};
  g {
    stroke: ${(props) => props.theme.colors.white};
  }
  ${ButtonsContainer}:hover & {
    g {
      stroke: ${(props) => props.theme.colors.cello};
    }
  }
`;
