import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'SHAREEMAILFORM', {
  FROM: 'From',
  SUBJECT: 'Subject',
  BODY: 'Body text',
  COPYRIGHT: 'Copyright BMS. All Rights Reserved.',
  WATCH: 'Watch video',
  HI: 'Hi #user',
});
