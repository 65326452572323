import styled from 'styled-components';

import { IBGProps, IFaderops } from './types';

export const TransitionModal = styled.div<IFaderops>`
  position: fixed;
  right: 10px;
  bottom: 50px;
  border: none;
  background: rgb(255, 255, 255);
  overflow: visible;
  border-radius: 8px;
  outline: none;
  padding: 0px;
  ${(props) => {
    if (props.topPosition) {
      return `top: calc(10% + 75px);`;
    } else {
      return ``;
    }
  }}
  z-index: 200;
  opacity: ${(props) => {
    if (props.fadeType === 'in') {
      return '1';
    } else {
      return '0';
    }
  }};
  transition: ${(props) => {
    if (props.fadeType === 'in') {
      return `opacity ease-in 0.25s;`;
    } else if (props.fadeType === 'out') {
      return `opacity ease-in 0.25s;`;
    } else {
      return '';
    }
  }};
`;

export const ModalContainer = styled.div<IBGProps>`
  display: flex;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 2px 6px #00000029;
  background: ${(props) => props.bgColor};
  border-radius: 8px;
`;

export const Rectangle = styled.div`
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.img`
  width: 25px;
`;

export const TextContainer = styled.div`
  width: 230px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  margin: 10px;
`;

export const Title = styled.h2`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.large};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const Error = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const CloseModal = styled.img`
  width: 11px;
  height: 11px;
  cursor: pointer;
  align-self: center;
  margin-right: 10px;
`;

export const RightContainer = styled.div`
  display: flex;
  background: ${(props) => props.theme.colors.white};
  border-radius: 0 8px 8px 0;
`;
