import React, { FC } from 'react';

import Wrapper from 'components/common/Wrapper/Wrapper';
import { PUBLIC_URL } from 'configs/index';

import { Icon, IconHover, OptionsContCSV, OptionsContainer, WrapperImport } from './styles';
import { IProps } from './types';

const ShareImport: FC<IProps> = (props) => {
  return (
    <WrapperImport>
      <Wrapper>
        <OptionsContainer>
          <OptionsContCSV onClick={props.onSelectCSV}>
            <Icon src={`${PUBLIC_URL}/images/icon-csv.svg`} />
            <IconHover src={`${PUBLIC_URL}/images/icon-csv-h.svg`} />
          </OptionsContCSV>
          <OptionsContCSV onClick={props.onSelectMailchimp}>
            <Icon src={`${PUBLIC_URL}/images/icon-mailchimp.svg`} />
            <IconHover src={`${PUBLIC_URL}/images/icon-mailchimp-h.svg`} />
          </OptionsContCSV>
        </OptionsContainer>
      </Wrapper>
    </WrapperImport>
  );
};
export default ShareImport;
