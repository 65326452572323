import { Link } from 'react-router-dom';

import { ReactComponent as Back } from 'assets/arrow-back.svg';
import { ReactComponent as bmsIcon } from 'assets/iso_bms-01.svg';
import { ReactComponent as Preview } from 'assets/preview.svg';
import styled from 'styled-components';

import { IButtonNext } from './types';

export const TopbarStyle = styled.div`
  width: auto;
  height: 75px;
  background: ${(props) => props.theme.colors.white};
  position: sticky;
  top: 0;
  z-index: 100;
  border-bottom: 1px solid ${(props) => props.theme.colors.porcelain};
`;

export const TopbarContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TextContainer = styled.ul`
  width: 33%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
`;

export const TemplateName = styled.input`
  width: 150px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  letter-spacing: ${(props) => props.theme.spacing.spacing1};
  border: none;
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  outline: none;
  margin-left: 10px;
`;

export const Linked = styled(Link)`
  width: 33%;
`;

export const TextChanges = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40%;
  height: 100%;
  align-items: center;
  font-size: 14px;
  margin-left: 20px;
  text-align: left;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.silver};
`;

export const BackArrow = styled(Back)`
  transition: ${(props) => props.theme.transition.all};
`;

export const NextArrow = styled(Back)`
  path {
    fill: ${(props) => props.theme.colors.white};
  }
  transition: ${(props) => props.theme.transition.all};
  transform: rotate(180deg);
`;

export const PreviewIcon = styled(Preview)`
  transition: ${(props) => props.theme.transition.all};
  g {
    stroke: ${(props) => props.theme.colors.white};
  }
`;

export const Tootltip = styled.div`
  display: none;
  padding: 12px;
  background: ${(props) => props.theme.colors.bigStone};
  position: absolute;
  top: 75px;
  left: -30px;
  z-index: 100;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000041;
  &:after {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: -10px;
    left: 80px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${(props) => props.theme.colors.bigStone};
  }
`;

export const NameTooltipText = styled.p`
  width: 100%;
  color: ${(props) => props.theme.colors.white};
  word-break: break-all;
  white-space: break-spaces;
`;

export const ButtonBack = styled.div`
  width: 115px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: ${(props) => props.theme.colors.cello};
  background: ${(props) => props.theme.colors.white};
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.colors.porcelain};
  position: relative;
  &:hover {
    background: ${(props) => props.theme.colors.porcelain};
    ${BackArrow} {
      transform: translateX(-5px);
    }
    ${Tootltip} {
      display: block;
    }
  }
`;

export const ButtonContentWrapper = styled.div`
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
`;

export const PreviewButtonContentWrapper = styled.div`
  width: 80px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
`;

export const ButtonNext = styled.div<IButtonNext>`
  width: 126px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) =>
    props.disabledNextButton ? props.theme.colors.grayChateau : props.theme.colors.cello};
  cursor: ${(props) => (props.disabledNextButton ? 'default' : 'pointer')};
  transition: ${(props) => props.theme.transition.all};
  &:hover {
    background: ${(props) => !props.disabledNextButton && props.theme.colors.bigStone};
    ${NextArrow} {
      transform: ${(props) => !props.disabledNextButton && 'translateX(5px) rotate(180deg)'};
    }
    ${PreviewIcon} {
      transform: scale(1.1);
    }
  }
`;

export const BMSIcon = styled(bmsIcon)`
  width: 40px;
  margin-left: 17px;
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  margin: 30px;
`;

export const TopbarTitle = styled.h2`
  width: 33%;
  text-align: center;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.extraLarge};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
`;
