import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ShareStep } from 'enums/shareStep.enum';
import i18n from 'i18next';
import * as campaignActions from 'redux/actions/campaign.actions';
import getCampaignSelector from 'redux/selectors/campaign.selector';
import { ITextVariable } from 'views/Video/types';
import ProgressBar from 'views/share/ProgressBar/ProgressBar';
import Topbar from 'views/share/Topbar/Topbar';

import * as shareActions from '../../redux/actions/share.actions';
import {
  Bolder,
  Header,
  ProgressBarWrapper,
  RequiredDiv,
  RequiredFields,
  RequiredFieldsContainer,
  ShareTitle,
  SubTitle,
  Wrapper,
} from './styles';
import { IProps } from './types';

const ShareLayout: FC<IProps> = (props) => {
  const dispatch = useDispatch();

  const changeStep = (step: number) => {
    dispatch(shareActions.changeStep(step));
  };

  const changeChosen = (chosen: string) => {
    dispatch(shareActions.changeChosen(chosen));
  };

  const changeCampaignName = (name: string) => {
    dispatch(campaignActions.changeCampaignName(name, props.campaignId));
  };

  const onSelectEditTemplate = () => {
    dispatch(shareActions.onSelectEditTemplate(props.campaignId));
  };

  const getCampaignData = useSelector(getCampaignSelector());

  return (
    <Wrapper>
      <Topbar
        changeStep={changeStep}
        changeChosen={changeChosen}
        step={props.step}
        onEditCampaignName={changeCampaignName}
        campaignName={getCampaignData.campaign.name}
        campaignId={props.campaignId}
        disabledNextButton={props.disabledNextButton}
        onBack={props.onBack}
        onNext={props.onNext}
        selectedOption={props.selectedOption}
        shareType={props.shareType}
        isCSVFormVisible={props.isCSVFormVisible}
        success={props.success}
        isCreateAndSend={props.isCreateAndSend}
        isMailchimpFormVisible={props.isMailchimpFormVisible}
        hasTooltip={props.hasTooltip}
      />
      <Header subtitle={props.subTitle}>
        <ProgressBarWrapper>
          <ProgressBar
            currentStep={props.step}
            success={props.success}
            shareType={props.shareType}
            onSelectEditTemplate={onSelectEditTemplate}
          />
        </ProgressBarWrapper>
        {props.step === ShareStep.SELECT && props.textVariables?.length && (
          <RequiredDiv>
            <Bolder>{i18n.t('SHARESELECT:REQUIRED')}</Bolder>
            {props.textVariables.map((textVariable: ITextVariable, index: number) => {
              return (
                <RequiredFieldsContainer key={index}>
                  <RequiredFields>{textVariable.value}</RequiredFields>
                </RequiredFieldsContainer>
              );
            })}
          </RequiredDiv>
        )}
        <ShareTitle>{props.title}</ShareTitle>
        <SubTitle>{props.subTitle}</SubTitle>
      </Header>
      {props.children}
    </Wrapper>
  );
};
export default ShareLayout;
