import { useState } from 'react';

import { aspectRatio } from 'views/UserPlayer/constants';

export const usePlayerResize = (): (number | (() => void))[] => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  const sidebarWidth = 345;
  const safeArea = 40;
  const topbarHeight = 75;
  const sceneHeight = 120;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  const widthSum = width - sidebarWidth - safeArea;
  const heightSum = height - topbarHeight - sceneHeight - safeArea;
  let newHeight = widthSum / aspectRatio;
  let newWidth = heightSum * aspectRatio;

  if (newWidth > widthSum) {
    newWidth = widthSum;
  } else {
    newHeight = heightSum;
  }

  return [newWidth, handleWindowSizeChange, newHeight];
};
