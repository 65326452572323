import { IObject } from 'helpers/types';
import { AnyAction } from 'redux';
import { ICampaign } from 'views/CampaignEdit/types';
import { ITemplate } from 'views/TemplateList/types';

import * as constants from '../constants/template.action.contants';

export const initializeTemplates = (): AnyAction => ({
  type: constants.ON_INITIALIZE_TEMPLATES_REQUESTED,
});

export const initializeTemplatesSucceeded = (data: ITemplate): AnyAction => ({
  type: constants.ON_INITIALIZE_TEMPLATES_SUCCEEDED,
  data,
});

export const initializeTemplatesFailed = (): AnyAction => ({
  type: constants.ON_INITIALIZE_TEMPLATES_FAILED,
});

export const initializeCampaignsUser = (page: number): AnyAction => ({
  type: constants.ON_INITIALIZE_CAMPAIGN_USER_REQUESTED,
  page,
});

export const initializeCampaignsUserSucceeded = (data: ICampaign[]): AnyAction => ({
  type: constants.ON_INITIALIZE_CAMPAIGN_USER_SUCCEEDED,
  data,
});

export const initializeCategoriesSucceeded = (data: IObject): AnyAction => ({
  type: constants.ON_INITIALIZE_CATEGORIES_SUCCEEDED,
  data,
});

export const initializeCategoriesFailed = (): AnyAction => ({
  type: constants.ON_INITIALIZE_CATEGORIES_FAILED,
});

export const selectCategory = (data: string): AnyAction => ({
  type: constants.ON_SELECT_CATEGORY_REQUESTED,
  data,
});

export const onSelectPreview = (id: string, isTemplate: boolean): AnyAction => ({
  type: constants.ON_SELECT_PREVIEW_REQUESTED,
  id,
  isTemplate,
});

export const onSelectPreviewSucceeded = (data: string): AnyAction => ({
  type: constants.ON_SELECT_PREVIEW_SUCCEEDED,
  data,
});

export const onSelectPreviewFailed = (): AnyAction => ({
  type: constants.ON_SELECT_PREVIEW_FAILED,
});

export const getTemplate = (templateId: string): AnyAction => ({
  type: constants.ON_SELECT_TEMPLATE_NEW_BMS_REQUESTED,
  templateId,
});

export const getTemplateSucceed = (data: ITemplate): AnyAction => ({
  type: constants.ON_SELECT_TEMPLATE_NEW_BMS_SUCCEED,
  data,
});

export const getTemplateFailed = (): AnyAction => ({
  type: constants.ON_SELECT_TEMPLATE_NEW_BMS_FAILED,
});

export const onSendEmailExisting = (data: string[], templateName: string): AnyAction => ({
  type: constants.ON_SEND_EMAIL_REQUESTED,
  data,
  templateName,
});

export const onSendEmailSucceed = (): AnyAction => ({
  type: constants.ON_SEND_EMAIL_SUCCEED,
});

export const onSendEmailFailed = (): AnyAction => ({
  type: constants.ON_SEND_EMAIL_FAILED,
});

export const onSendEmailPersonalizedCampaign = (
  audience: number,
  emailProvider: string,
  containVideo: string,
  objective: string
): AnyAction => ({
  type: constants.ON_SEND_PERSONALIZED_CAMPAIGN_EMAIL_REQUESTED,
  audience,
  emailProvider,
  containVideo,
  objective,
});

export const onSendEmailPersonalizedCampaignSucceed = (): AnyAction => ({
  type: constants.ON_SEND_PERSONALIZED_CAMPAIGN_EMAIL_SUCCEED,
});

export const onSendEmailPersonalizedCampaignFailed = (): AnyAction => ({
  type: constants.ON_SEND_PERSONALIZED_CAMPAIGN_EMAIL_FAILED,
});

export const changeIsSuccess = (): AnyAction => ({
  type: constants.ON_CHANGE_IS_SUCCESS,
});

export const onRemoveVideoData = (): AnyAction => ({
  type: constants.ON_REMOVE_VIDEO_DATA,
});
