import React, { FC, useEffect, useState } from 'react';

import Video from 'views/Video/Video';
import { useAudio } from 'views/Video/audio';
import { DISABLED_VOLUME_VALUE } from 'views/Video/constants';

import { PlayerWrapper, VideoWrapper } from './styles';
import { IProps } from './types';

const PreviewPlayer: FC<IProps> = (props) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [replayVisible, setReplayVisible] = useState<boolean>(false);
  const [replay, setReplay] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [frameToChange, setFrameToChange] = useState<number>(0);
  const [volumeToChange, setVolumeToChange] = useState<number>(DISABLED_VOLUME_VALUE);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [mute, toggleMute, audio, isPlaying, changeIsPlaying]: any[] = useAudio(props.audio);
  const [currentTime, setCurrentTime] = useState(0);

  const onComplete = () => {
    setIsCompleted(true);
    setReplayVisible(true);
    setReplay(false);
    setToggle(false);
    changeIsPlaying();
  };

  const onReplay = () => {
    setReplay(true);
    setReplayVisible(false);
    setIsCompleted(false);
  };

  const onChangeToggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    if (props.audio) {
      if (toggle) {
        setVolumeToChange(1);
      } else {
        setVolumeToChange(0);
      }
    }
    if (!props.audio || (!!props.audio && replayVisible)) {
      setVolumeToChange(DISABLED_VOLUME_VALUE);
    }
  }, [replayVisible, toggle]);

  useEffect(() => {
    if (volumeToChange !== DISABLED_VOLUME_VALUE) {
      setToggle(volumeToChange > 0);
    }
  }, [volumeToChange]);

  useEffect(() => {
    if (!mute || toggle) {
      toggleMute();
    }
  }, [toggle]);

  useEffect(() => {
    if (frameToChange > 0 && isCompleted) {
      setIsCompleted(false);
      setReplayVisible(false);
    }
  }, [frameToChange, isCompleted]);

  const onStart = () => {
    setCurrentTime(0);
  };

  return (
    <PlayerWrapper>
      <VideoWrapper>
        {!!audio && (
          <Video
            autoplayBackgroundVideo
            isEdit={false}
            layers={props.layers}
            autoplay={true}
            isPlaying={true}
            audioIsPlaying={isPlaying}
            textVariables={props.textVariables}
            colorVariables={props.colorVariables ? props.colorVariables : []}
            imageVariables={props.imageVariables}
            backgroundColor={props.backgroundColor}
            isLoading={false}
            audio={audio}
            replay={replay}
            isPreview
            backgroundVideo={props.backgroundVideo}
            setFrameToChange={setFrameToChange}
            frameToChange={frameToChange}
            removeVideoData={props.onRemoveVideoData}
            onStart={onStart}
            onComplete={onComplete}
            currentTime={currentTime}
            setCurrentTime={setCurrentTime}
            hasAudio={!!props.audio}
            setVolume={setVolumeToChange}
            volume={volumeToChange}
            toggle={toggle}
            onChangeToggle={onChangeToggle}
            complete={isCompleted}
            onReplay={onReplay}
            isCampaignPreview={props.isCampaignPreview}
          />
        )}
      </VideoWrapper>
    </PlayerWrapper>
  );
};

export default PreviewPlayer;
