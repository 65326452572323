/* eslint-disable */
import { ITextVariable } from '../types';

const addInteractionHover = (
  animationApi: any,
  textVariables: ITextVariable[],
  handleTextChange: (textId: string) => void
): void => {
  animationApi.getElements().forEach((element: any) => {
    const elem = element;
    textVariables.forEach((textVariable: ITextVariable) => {
      const name = `${textVariable.name}_in`;
      if (elem.hasProperty(name)) {
        elem.getProperty(name).element.baseElement.addEventListener('mouseover', () => {
          const styleOver = elem.getProperty(name).element.baseElement.style;

          styleOver.stroke = 'red';
          styleOver.visibility = 'visible';
          styleOver.strokeWidth = '10px';
          styleOver.cursor = 'pointer';
        });
        elem.getProperty(name).element.baseElement.addEventListener('mouseout', () => {
          const styleOut = elem.getProperty(name).element.baseElement.style;
          styleOut.stroke = 'transparent';
          styleOut.visibility = 'visible';
        });
        if (handleTextChange) {
          elem.getProperty(name).element.baseElement.addEventListener('click', () => {
            handleTextChange!(textVariable._id);
          });
        }
      }
    });
  });
};

export default addInteractionHover;
