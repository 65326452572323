import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import ShareLayout from 'containers/ShareLayout/ShareLayout';
import { ShareStep } from 'enums/shareStep.enum';
import { ShareType } from 'enums/shareType.enum';
import i18n from 'i18next';
import * as shareActions from 'redux/actions/share.actions';
import ShareImport from 'views/share/shareImport/ShareImport';

import './i18n';
import { CampaignShareProps } from './types';

const ShareContainer: FC<CampaignShareProps> = (props) => {
  const campaignId = props.match.params.campaignId;
  const dispatch = useDispatch();

  const selectCSV = () => {
    dispatch(shareActions.onSelectCSV(campaignId, ShareType.CSV));
  };

  const selectMailchimp = () => {
    dispatch(shareActions.onSelectMailchimp(campaignId, ShareType.MAILCHIMP));
  };

  const onBack = () => {
    dispatch(shareActions.onSelectBackImport(campaignId));
  };

  return (
    <ShareLayout
      campaignId={campaignId}
      step={ShareStep.IMPORT}
      onBack={onBack}
      disabledNextButton={false}
      title={i18n.t('SHARE:TITLE')}
      hasTooltip
    >
      <ShareImport onSelectMailchimp={selectMailchimp} onSelectCSV={selectCSV} />
    </ShareLayout>
  );
};

export default ShareContainer;
