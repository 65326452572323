import { locales } from 'configs/i18n';
import i18next from 'i18next';

i18next.addResources(locales.EN, 'PAYWALL_MODAL', {
  TITLE_TEMPLATE_NOT_AVAILABLE_FOR_SUB: 'Template not available',
  SUBTITLE_TEMPLATE_NOT_AVAILABLE_FOR_SUB: 'Template not available for your type of product',
  TITLE_CREATE_CAMPAIGN_LIMIT_EXCEEDED: 'You don’t have more campaigns',
  SUBTITLE_CREATE_CAMPAIGN_LIMIT_EXCEEDED:
    'You can only create {{count}} new campaigns per month. Upgrade now and keep spreading your personalized message!',
  TITLE_MASSIVE_SHARE_CAMPAIGN_LIMIT: 'You have {{count}} free emails',
  SUBTITLE_MASSIVE_SHARE_CAMPAIGN_LIMIT:
    'Would you like to spread your message to a wider audience? Upgrade to enable massive email sending.',
  TITLE_SHARED_SHARE_CAMPAIGN_LIMIT: 'You don’t have more links to share',
  SUBTITLE_SHARED_SHARE_CAMPAIGN_LIMIT:
    'You can only get {{count}} links per month to share your message on a 1:1 basis. Upgrade to get more personalized links!',
  TITLE_STATS_LIMIT: 'Want to get the most out of your campaigns?',
  SUBTITLE_STATS_LIMIT: 'Upgrade to access stats and reports!',
  TITLE_ALERT_CAMPAIGN_LIFE_TIME_LIMIT: 'Expiration campaigns',
  SUBTITLE_ALERT_CAMPAIGN_LIFE_TIME_LIMIT_ABOUT_TO: ' will expire in 15 days. ',
  SUBTITLE_ALERT_CAMPAIGN_LIFE_TIME_LIMIT_EXPIRED: ' already expired.',
  UPGRADE: 'Upgrade',
  UPGRADE_SUBTITLE: '*We’ll contact you within 24hs.',
});
