import { IPreviewData } from 'containers/PreviewPlayerContainer/types';
import { ColorVariableType } from 'enums/colorVariable.enum';
import { IDataLayer, ILayer } from 'interfaces/layer.interface';
import { ITextVariable } from 'views/Video/types';
import { IColorVariable } from 'views/Video/types';

import { replaceImages } from './campaignReplace/replaceImages.helper';
import { replaceObjectsColors } from './campaignReplace/replaceObjectColors.helper';
import { replaceTexts } from './campaignReplace/replaceTexts.helper';
import { getJsonData } from './get_json_data';

export const getJsonLayer = async (
  campaign: IPreviewData,
  setLayersData: (value: ILayer[]) => void,
  currentLayers: ILayer[],
  textVariables: ITextVariable[]
): Promise<void> => {
  const promises = currentLayers.map(async (layer: ILayer) => {
    if (layer.dataPath !== '') {
      const json = await getJsonData(layer.dataPath);
      return { ...layer, dataPath: json };
    }
    return '';
  });
  const layersData = (await Promise.all(promises)) as ILayer[];
  const layers = layersData.map((layer: ILayer) => layer);

  const colors = campaign.colorVariables.filter(
    (color: IColorVariable) => color.type === ColorVariableType.Object
  );
  if (colors.length) {
    layers[0].dataPath = replaceObjectsColors(layersData[0].dataPath, colors);
  }
  if (textVariables.length) {
    layers[0].dataPath = replaceTexts(layers[0].dataPath, textVariables, campaign.colorVariables);
  }
  if (campaign.imageVariables.length) {
    layers[0].dataPath = replaceImages(layers[0].dataPath as IDataLayer, campaign.imageVariables);
  }

  setLayersData(layers);
};
