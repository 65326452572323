import styled from 'styled-components';

import { IColor, ICurrentColor } from './types';

export const Title = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  font-weight: 400;
  text-align: left;
  margin-bottom: 10px;
`;

export const Line = styled.div`
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid ${(props) => props.theme.colors.white};
  opacity: 1;
`;

export const VariableName = styled.p`
  color: ${(props) => props.theme.colors.white};
  margin-left: 15px;
  width: auto;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  transition: all 0.2s ease-out;
  font-size: ${(props) => props.theme.paragraph.fontSizes.medium};
`;

export const VariableDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 230px;
  &:hover {
    ${VariableName} {
      font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
    }
  }
`;

export const ColorPickerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const LeftContainer = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const RightContainer = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const CurrentColor = styled.div<ICurrentColor>`
  width: 32px;
  height: 32px;
  border-radius: 2px;
  border: solid 1px ${(props) => props.theme.colors.cadetBlue};
  background-color: ${(props) => props.color};
`;

export const Arrow = styled.img``;

export const ColorValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
`;

export const HexText = styled.p`
  font-size: ${(props) => props.theme.paragraph.fontSizes.small};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.paragraph.fontFamilies.regular};
  font-weight: 400;
`;

export const Input = styled.input`
  width: 72px;
  height: 26px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.bigStone};
  color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.porcelain};
  margin-left: 10px;
  border-radius: 3px;
`;

export const Colors = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 10px 0;
  display: flex;
  flex-flow: row wrap;
`;

export const Color = styled.div<IColor>`
  width: 26px;
  height: 26px;
  margin: 5px;
  border-radius: 2px;
  border: solid 1px ${(props) => props.theme.colors.cadetBlue};
  background-color: ${(props) => props.color};
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    border: solid 1px ${(props) => props.theme.colors.white};
  }
`;

export const VariableContainer = styled.div`
  margin-bottom: 20px;
`;

export const ColorSeparetorLine = styled.div`
  width: 230px;
  margin-top: 10px;
  border: 1px solid ${(props) => props.theme.colors.raven};
  opacity: 1;
  background-color: ${(props) => props.theme.colors.raven};
`;
