import styled from 'styled-components';

import { IWrapper } from './types';

export const Arrow = styled.div`
  width: 0px;
  height: 0px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid ${(props) => props.theme.colors.bigStone};
  margin-right: -1px;
`;

export const Container = styled.div`
  height: 30px;
  width: 85px;
  background-color: ${(props) => props.theme.colors.bigStone};
  border-radius: 4px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div<IWrapper>`
  display: flex;
  align-items: center;
  position: absolute;
  right: -65px;
  height: 100%;
  opacity: ${(props) => (props.showMessage ? '1' : '0')};
  transition: all 0.3s ease-out;
`;
