import React, { FunctionComponent } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { isUserLogged } from 'helpers/user_validation';

const PrivateRoute: FunctionComponent<RouteProps> = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isUserLogged() && Component ? (
          <>
            <Component {...props} />
          </>
        ) : (
          <Redirect
            push={false}
            to={{
              pathname: getLoginRoute(props.location.pathname),
              state: {
                from: props.location.pathname,
                params: props.location.search,
              },
              search: props.location.search,
            }}
          />
        )
      }
    />
  );
};

const getLoginRoute = (from: string) => {
  switch (from) {
    default:
      return '/';
  }
};

export default PrivateRoute;
