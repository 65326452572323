import styled from 'styled-components';

import { IFadeProps } from './types';

export const TransitionModal = styled.div<IFadeProps>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
  z-index: 150;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
  cursor: default;
  opacity: ${(props) => {
    if (props.fadeType === 'in') {
      return '1';
    } else {
      return '0';
    }
  }};
  transition: ${(props) => {
    if (props.fadeType === 'in') {
      return `opacity ease-in 0.25s;`;
    } else if (props.fadeType === 'out') {
      return `opacity ease-in 0.25s;`;
    } else {
      return '';
    }
  }};
`;

export const ModalContainer = styled.div`
  width: 420px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0px 2px 6px #00000029;
  background: ${(props) => props.theme.colors.white};
`;

export const TextContainer = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const Title = styled.h2`
  font-size: 20px;
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  margin-bottom: 20px;
  text-align: center;
`;

export const Subtitle = styled.p`
  width: 80%;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.raven};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  text-align: center;
`;

export const Bolder = styled.p`
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  margin-top: 10px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  place-content: center;
  justify-content: center;
  margin: 20px 0;
`;

export const CloseModalWrapper = styled.div`
  width: 420px;
  height: 35px;
  justify-content: flex-end;
  display: flex;
  position: absolute;
`;
