import { hexToRgb } from 'helpers/hexToRGB.helper';
import { IObject } from 'helpers/types';
import { DataPath, IDataLayer } from 'interfaces/layer.interface';
import { IColorRGB, IColorVariable } from 'views/Video/types';

import { replaceObject } from './replace.constants';

export const replaceObjectsColors = (dataLayer: IDataLayer, colors: IColorVariable[]): DataPath => {
  const dataCopy = { ...dataLayer };
  const colorsToReplace = colors;
  const dataAssets = dataCopy.assets.map((dataAsset) => {
    if ((dataAsset.id as string).split('_')[0] === replaceObject) {
      const dataAssetLayers = (dataAsset.layers as IObject[]).map((dataAssetLayer) => {
        colorsToReplace.forEach((color) => {
          if ((dataAssetLayer.nm as string).split('_')[2] === color.name && dataAssetLayer.shapes) {
            let textColorRGB: IColorRGB | null = { r: 0, g: 0, b: 0 };
            textColorRGB = hexToRgb(color.value);
            const dataAssetLayerShapes = (dataAssetLayer.shapes as IObject[]).map(
              (dataAssetLayerShape) => {
                if (
                  dataAssetLayerShape.it &&
                  dataAssetLayerShape.it[1] &&
                  dataAssetLayerShape.it[1].c &&
                  dataAssetLayerShape.it[1].c.k
                ) {
                  if (textColorRGB) {
                    dataAssetLayerShape.it[1].c.k = [
                      textColorRGB.r,
                      textColorRGB.g,
                      textColorRGB.b,
                      1,
                    ];
                  }
                }
                return dataAssetLayerShape;
              }
            );
            dataAssetLayer.shapes = dataAssetLayerShapes;
            return dataAssetLayer;
          }
          return;
        });
        return dataAssetLayer;
      });
      dataAsset.layers = dataAssetLayers;
    }
    return dataAsset;
  });
  dataCopy.assets = dataAssets;
  return dataCopy as DataPath;
};
