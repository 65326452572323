import React, { FC, useEffect, useState } from 'react';

import { PUBLIC_URL } from 'configs/index';
import 'helpers/inputHelper/i18n';
import { isEmail, isEmpty } from 'helpers/inputHelper/inputHelper';
import i18n from 'i18next';

import {
  ButtonContainer,
  ErrorMessage,
  ForgotPassword,
  FormContainer,
  HaveAccountText,
  IconBlock,
  InputContainer,
  Inputs,
  LogIn,
  LogInText,
  SignUpText,
} from './styles';
import { IProps } from './types';

const Login: FC<IProps> = (props) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState<{ email: string; password: string }>({
    email: '',
    password: '',
  });
  const [block, setBlock] = useState({ password: false });

  const handleEye = (key: string) => {
    const blockEye = { ...block };
    blockEye[key] = !blockEye[key];
    setBlock(blockEye);
  };

  const handleClickLogin = () => {
    if (handleValidation('', email, password)) {
      props.onSubmitLogin(email, password);
    }
    props.changeError();
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    handleValidation('password', email, event.target.value);
    props.changeError();
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    handleValidation('email', event.target.value, password);
    props.changeError();
  };

  useEffect(() => {
    const error = { ...errors };
    if (props.error) {
      error.password = i18n.t('FORM:USERNAME_INCORRECT');
    } else {
      error.password = '';
    }
    setErrors(error);
  }, [props.error]);

  const handleValidation = (inputToValidate = '', email: string, password: string) => {
    let formIsValid = true;
    let error = { ...errors };
    if ((inputToValidate === '' || inputToValidate === 'password') && isEmpty(password)) {
      error = { ...error, password: i18n.t('FORM:PASSWORD_EMPTY') };
      formIsValid = false;
    } else {
      if ((inputToValidate === '' || inputToValidate === 'password') && !isEmpty(password)) {
        error = { ...error, password: '' };
      }
    }
    if ((inputToValidate === '' || inputToValidate === 'email') && isEmpty(email)) {
      error = { ...error, email: i18n.t('FORM:EMAIL_EMPTY') };
      formIsValid = false;
    } else {
      if ((inputToValidate === '' || inputToValidate === 'email') && !isEmail(email)) {
        formIsValid = false;
        error = { ...error, email: i18n.t('FORM:EMAIL_VALID') };
      } else {
        if (
          (inputToValidate === '' || inputToValidate === 'email') &&
          !isEmpty(email) &&
          isEmail(email)
        ) {
          error = { ...error, email: '' };
        }
      }
    }
    setErrors(error);
    return formIsValid;
  };

  return (
    <FormContainer>
      <LogInText>{i18n.t('LOGIN:LOGIN_TITLE')}</LogInText>
      <Inputs
        type="email"
        value={email}
        onChange={handleEmailChange}
        placeholder={i18n.t('LOGIN:EMAIL')}
      ></Inputs>
      <ErrorMessage>{errors.email}</ErrorMessage>
      <InputContainer>
        <Inputs
          type={block.password ? 'text' : 'password'}
          value={password}
          onChange={handlePasswordChange}
          placeholder={i18n.t('LOGIN:PASS')}
        ></Inputs>
        {block.password ? (
          <IconBlock
            onClick={() => handleEye('password')}
            src={`${PUBLIC_URL}/images/eye-n.svg`}
          ></IconBlock>
        ) : (
          <IconBlock
            onClick={() => handleEye('password')}
            src={`${PUBLIC_URL}/images/see_pass.svg`}
          ></IconBlock>
        )}
      </InputContainer>
      <ErrorMessage>{errors.password}</ErrorMessage>
      <ForgotPassword onClick={props.handleForgotPassword}>{i18n.t('LOGIN:FORGOT')}</ForgotPassword>
      <ButtonContainer>
        <LogIn onClick={handleClickLogin} to="">
          {i18n.t('LOGIN:LOGIN')}
        </LogIn>
      </ButtonContainer>
      <HaveAccountText>
        {i18n.t('LOGIN:HAVE_ACCOUNT')}
        <SignUpText onClick={props.signUp}>{i18n.t('LOGIN:SIGNUP')}</SignUpText>
      </HaveAccountText>
    </FormContainer>
  );
};
export default Login;
