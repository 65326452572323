import { IEmailData } from 'containers/launchContainer/types';
import { IValue } from 'containers/matchContainer/types';
import { IObject } from 'helpers/types';
import { ITextVariable } from 'views/Video/types';

import { IResponse, apiDelete, get, post, put } from './api';

export const createShareCampaign = async (
  campaignId: string,
  value: IValue[],
  textVariables: ITextVariable[],
  mailChimpMember: IObject
): Promise<{ token: string; expiresIn: string }> => {
  const data = { campaignId, value, textVariables, mailChimpMember };
  const response = await post('share/', data);
  return response.data;
};

export const getShareCampaign = async (campaignId: string): Promise<string | undefined> => {
  const response = await get(`share/${campaignId}`);
  return response.data;
};

export const getMailchimpUserData = async (
  campaignId: string,
  mailchimpUniqueId: string
): Promise<string | undefined> => {
  const response = await get(`share/${campaignId}/${mailchimpUniqueId}`);
  return response.data;
};

export const getSharePlayer = async (shareId: string): Promise<string | undefined> => {
  const response = await get(`share/player/${shareId}`);
  return response.data;
};

export const sendShareEmail = async (
  title: string,
  from: string,
  subject: string,
  shareEmails: IEmailData[],
  campaignId: string
): Promise<{ token: string; expiresIn: string }> => {
  const data = { title, from, subject, shareEmails };
  const response = await post(`share/send-email/${campaignId}`, data);
  return response.data;
};

export const getMailchimpLists = async (): Promise<string | undefined> => {
  const response = await get('share/mailchimp/list');
  return response.data;
};

export const getMailchimpListMergeFields = async (listId: string): Promise<string | undefined> => {
  const response = await get(`share/mailchimp/list/${listId}/merge-fields/`);
  return response.data;
};

export const getMailchimpListSegments = async (listId: string): Promise<string | undefined> => {
  const response = await get(`share/mailchimp/list/${listId}/segments`);
  return response.data;
};

export const mailchimpLogin = async (
  campaignId: string
): Promise<{ token: string; expiresIn: string }> => {
  const data = { campaignId };
  const response = await post(`share/mailchimp/auth/`, data);
  return response.data;
};

export const getListMergeFields = async (listId: string): Promise<string | undefined> => {
  const response = await get(`share/mailchimp/list/${listId}/merge-fields`);
  return response.data;
};

export const getSegmentMembers = async (
  listId: string,
  segmentId: string
): Promise<string | undefined> => {
  const response = await get(`share/mailchimp/list/${listId}/segments/${segmentId}/members`);
  return response.data;
};

export const createDraftCampaign = async (
  campaignId: string,
  body: string,
  from: string,
  subject: string
): Promise<{ token: string; expiresIn: string }> => {
  const data = { campaignId, body, from, subject };
  const response = await post(`share/mailchimp/campaigns`, data);
  return response.data;
};

export const sendMailchimpEmail = async (
  mailchimpCampaignId: string,
  campaignId: string
): Promise<{ token: string; expiresIn: string }> => {
  const data = { mailchimpCampaignId, campaignId };
  const response = await post(`share/mailchimp/send-email`, data);
  return response.data;
};

export const validateLimit = async (shareId: string): Promise<string | undefined> => {
  const response = await get(`share/subscription/limit/${shareId}`);
  return response.data;
};

export const createMetrics = async (
  shareId: string,
  campaignId?: string
): Promise<{ token: string; expiresIn: string }> => {
  const response = await post(`metrics`, { shareId, campaignId });
  return response.data;
};

export const setVideoTimePlayed = async (
  id: string,
  lastSecondPlayed: number
): Promise<string | undefined> => {
  const response = await put(`metrics/${id}/${lastSecondPlayed}/add`, {});
  return response.data;
};

export const selectAudio = async (id: string): Promise<string | undefined> => {
  const response = await put(`metrics/${id}/audio`, {});
  return response.data;
};

export const clickCTA = async (id: string): Promise<string | undefined> => {
  const response = await put(`metrics/${id}/cta`, {});
  return response.data;
};

export const removeShareCampaigns = async (campaignId: string): Promise<IResponse> => {
  const response = await apiDelete(`share/${campaignId}`, {});
  return response;
};
