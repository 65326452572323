import styled from 'styled-components';

import { IButton } from './types';

export const Button = styled.button<IButton>`
  width: 251px;
  height: 40px;
  font-size: 14px;
  font-family: ${(props) => props.theme.paragraph.fontFamilies.regular};
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  align-items: center;
  border: none;
  border-radius: 2px;
  margin-top: 60px;
  background: ${(props) =>
    props.disabled ? props.theme.colors.grayChateau : props.theme.colors.cello};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  &:hover {
    background: ${(props) => !props.disabled && props.theme.colors.bigStone};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding-top: 30px;
`;

export const LabelTitle = styled.h1`
  font-size: 20px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.fiord};
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const WrapperLaunch = styled.div`
  height: calc(100% - 255px);
  min-height: calc(100vh - 255px);
  background: ${(props) => props.theme.colors.aena};
`;

export const CreateCampaignContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
  align-items: center;
`;

export const CreateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
`;

export const TextBelow = styled.p`
  width: 260px;
  font-size: ${(props) => props.theme.paragraph.fontSizes.large};
  font-weight: 400;
  color: ${(props) => props.theme.colors.fiord};
  text-align: center;
  align-items: center;
  margin-top: 25px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const Or = styled.h2`
  font-size: 18px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 60px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.extraLarge};
  text-align: center;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => props.theme.colors.fiord};
`;
