import { IObject } from 'helpers/types';
import { AnyAction } from 'redux';

import * as constants from '../constants/userShared.action.constants';

interface IShareReducerState {
  variables: IObject[];
  mcUserFields: IObject;
  sharedData: IObject;
}

const defaultState: IShareReducerState = {
  variables: [],
  mcUserFields: {},
  sharedData: {},
};

const addData = (state: IShareReducerState, action: AnyAction) => {
  return {
    ...state,
    variables: action.data.variables,
  };
};

const addMCData = (state: IShareReducerState, action: AnyAction) => {
  return {
    ...state,
    mcUserFields: action.data.mcUserFields,
    sharedData: action.data.shareData,
  };
};

const sharedUserReducer = (
  state: IShareReducerState = defaultState,
  action: AnyAction
): IShareReducerState => {
  switch (action.type) {
    case constants.ON_INITIALIZE_PLAYER_SUCCEDEED:
      return addData(state, action);
    case constants.ON_INITIALIZE_MAILCHIMP_PLAYER_SUCCEDEED:
      return addMCData(state, action);
    default:
      return state;
  }
};

export default sharedUserReducer;
