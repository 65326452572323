import React, { FC, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';

import { CropperWrapper, Wrapper } from './styles';
import { IProps } from './types';

const ImageCropper: FC<IProps> = (props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [aspect, setAspect] = useState(
    props.isProfile ? { width: 1, height: 1 } : { width: 4, height: 3 }
  );

  useEffect(() => {
    if (props.imageVariable) {
      const img = new Image();
      img.onload = function () {
        setAspect({ width: img.width, height: img.height });
      };
      img.src =
        props.imageVariable && props.imageVariable.asset && props.imageVariable.asset.path
          ? props.imageVariable.asset.path
          : '';
    }
    if (props.videoVariable && props.videoVariable.path) {
      const video = document.createElement('video');
      video.addEventListener(
        'loadedmetadata',
        function () {
          setAspect({ width: this.videoWidth, height: this.videoHeight });
        },
        false
      );
      video.src = props.videoVariable.path;
    }
  }, []);

  return (
    <Wrapper asset={!!props.image || !!props.video}>
      <CropperWrapper>
        {!props.loading && (
          <Cropper
            image={props.image}
            video={props.video}
            crop={crop}
            zoom={props.zoom}
            aspect={aspect.width / aspect.height}
            onCropChange={setCrop}
            onZoomChange={props.setZoom}
            onCropComplete={props.onCropComplete}
            zoomWithScroll={false}
          />
        )}
      </CropperWrapper>
    </Wrapper>
  );
};

export default ImageCropper;
