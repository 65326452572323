import { Link } from 'react-router-dom';

import { IValidPass } from 'components/ModalLogin/components/SignUp/types';
import styled from 'styled-components';

import { ColorText, IInputs, InputProps } from './types';

export const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 77px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.colors.porcelain};
`;

export const Label = styled.label`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const ForgotPassword = styled(Link)`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  color: ${(props) => props.theme.colors.apricot};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
`;

export const EditIconProfile = styled.img`
  position: absolute;
  width: 35px;
  height: 35px;
  cursor: pointer;
  margin-top: 85px;
  margin-left: 90px;
  transition: all 0.2s ease-out;
`;

export const ProfilePictureWrapper = styled.div`
  transition: all 0.2s ease-out;
  &:hover {
    ${EditIconProfile} {
      transform: scale(1.05);
    }
  }
`;

export const ProfilePicture = styled.img`
  width: 125px;
  height: 125px;
  background: ${(props) => props.theme.colors.cello};
  border-radius: 50%;
  cursor: pointer;
`;

export const SignUp = styled(Link)`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  text-decoration: underline;
  color: ${(props) => props.theme.colors.cello};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
`;

export const FunnyText = styled.p`
  display: flex;
  font-size: 20px;
  text-align: left;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
`;

export const Bolder = styled.b`
  font-size: 20px;
  text-align: left;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  margin-right: 4px;
`;

export const InputContainer = styled.div`
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

export const Inputs = styled.input<IInputs>`
  width: 300px;
  height: 45px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.fiord};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  border: 1px solid ${(props) => props.theme.colors.porcelain};
  border-radius: 6px;
  padding-left: 10px;
  margin-bottom: ${(props) => (props.editName ? '35px' : '10px')};
  margin-right: 30px;
  &:disabled {
    background: ${(props) => props.theme.colors.white};
    cursor: not-allowed;
    &::selection {
      background: transparent;
    }
  }
`;

export const BottomContainer = styled.div`
  width: 325px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
`;

export const ErrorMessage = styled.p`
  font-size: 11px;
  height: 11px;
  text-align: left;
  letter-spacing: ${(props) => props.theme.spacing.spacing0};
  color: ${(props) => props.theme.colors.red};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  margin-bottom: 10px;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  background: ${(props) => props.theme.colors.white};
`;

export const TitleAccount = styled.h2`
  font-size: 20px;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  color: ${(props) => props.theme.colors.fiord};
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  background-color: ${(props) => props.theme.colors.porcelain};
`;

export const LeftContainer = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
`;

export const RightContainer = styled.div`
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InputsContainer = styled.div<InputProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: ${(props) => (props.hasWrap ? 'wrap' : 'wrap-reverse')};
`;

export const LabelYourAccount = styled.h2`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  color: ${(props) => props.theme.colors.fiord};
  margin-bottom: 20px;
`;

export const TextInformationContainer = styled.div`
  width: 100%;
`;

export const BasicInformationText = styled.b<ColorText>`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  text-decoration: none;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.bold};
  color: ${(props) => (props.raven ? props.theme.colors.raven : props.theme.colors.apricot)};
  &:hover {
    text-decoration: ${(props) => !props.raven && 'underline'};
  }
`;

export const BMSCollectText = styled.p`
  font-size: ${(props) => props.theme.headers.h2.fontSizes.small};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.regular};
  color: ${(props) => props.theme.colors.raven};
`;

export const Button = styled.button`
  width: 150px;
  height: 40px;
  font-size: ${(props) => props.theme.headers.h2.fontSizes.medium};
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.cello};
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  transition: all 0.2s ease-out;
  &:hover {
    background: ${(props) => props.theme.colors.bigStone};
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 60px;
  margin-bottom: 40px;
`;

export const IconBlock = styled.img`
  position: absolute;
  width: 15px;
  height: 15px;
  cursor: pointer;
  top: 22%;
  left: 290px;
`;

export const Links = styled(Link)`
  text-decoration: none;
`;

export const TextPasswordRequirements = styled.p<IValidPass>`
  font-size: 11px;
  height: 11px;
  text-align: left;
  color: ${(props) =>
    props.validPassword ? props.theme.colors.red : props.theme.colors.grayChateau};
  font-family: ${(props) => props.theme.headers.h2.fontFamilies.medium};
  margin-bottom: 10px;
`;
