import { all } from 'redux-saga/effects';

import campaign from './sagas/campaign.sagas';
import metricSagas from './sagas/metric.sagas';
import myCampaigns from './sagas/myCampaigns.sagas';
import share from './sagas/share.sagas';
import sharedUserSagas from './sagas/sharedUser.sagas';
import template from './sagas/template.sagas';
import user from './sagas/user.sagas';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([user, template, campaign, myCampaigns, share, sharedUserSagas, metricSagas]);
}
