import { IEmailData } from 'containers/launchContainer/types';
import { IValue } from 'containers/matchContainer/types';
import { IObject } from 'helpers/types';
import { AnyAction } from 'redux';
import { ICampaign } from 'views/CampaignEdit/types';
import { ITextVariable } from 'views/Video/types';

import * as constants from '../constants/share.action.constants';

export const changeStep = (step: number): AnyAction => ({
  type: constants.ON_CHANGE_STEP_REQUESTED,
  step,
});

export const changeChosen = (chosen: string): AnyAction => ({
  type: constants.ON_CHANGE_CHOSEN_REQUESTED,
  chosen,
});

export const changeImportSelected = (importChosen: string): AnyAction => ({
  type: constants.ON_IMPORT_SELECTED_REQUESTED,
  importChosen,
});

export const initializeCampaignShareSuccedeed = (data: ICampaign): AnyAction => ({
  type: constants.ON_INITIALIZE_CAMPAIGN_SHARE_SUCCEDEED,
  data,
});

export const initializeCampaignShareFailed = (): AnyAction => ({
  type: constants.ON_INITIALIZE_CAMPAIGN_SHARE_FAILED,
});

export const onSubmitShareMatch = (
  campaignId: string,
  values: IValue[][],
  textVariables: ITextVariable[],
  mailChimpMembers: IObject[]
): AnyAction => ({
  type: constants.ON_SUBMIT_SHARE_MATCH_REQUESTED,
  campaignId,
  values,
  textVariables,
  mailChimpMembers,
});

export const onSubmitShareMatchSuccedeed = (id: string): AnyAction => ({
  type: constants.ON_SUBMIT_SHARE_MATCH_SUCCEDEED,
  id,
});

export const onSubmitShareMatchFailed = (): AnyAction => ({
  type: constants.ON_SUBMIT_SHARE_MATCH_FAILED,
});

export const onSubmitOneToOneSendMail = (
  title: string,
  from: string,
  subject: string,
  shareEmails: IEmailData[],
  campaignId: string
): AnyAction => ({
  type: constants.ON_SUBMIT_ONE_TO_ONE_SEND_MAIL_REQUESTED,
  title,
  from,
  subject,
  shareEmails,
  campaignId,
});

export const onSubmitOneToOneSendMailSuccedeed = (): AnyAction => ({
  type: constants.ON_SUBMIT_ONE_TO_ONE_SEND_MAIL_SUCCEDEED,
});

export const onSubmitOneToOneSendMailFailed = (): AnyAction => ({
  type: constants.ON_SUBMIT_ONE_TO_ONE_SEND_MAIL_FAILED,
});

export const onSelectCSV = (campaignId: string, shareType: number): AnyAction => ({
  type: constants.ON_SELECT_CSV_REQUESTED,
  campaignId,
  shareType,
});

export const onSelectCSVSucceed = (data: string, campaign: ICampaign): AnyAction => ({
  type: constants.ON_SELECT_CSV_SUCCEEDED,
  data,
  campaign,
});

export const onSelectCSVFailed = (): AnyAction => ({
  type: constants.ON_SELECT_CSV_FAILED,
});

export const onSelectMailchimp = (campaignId: string, shareType: number): AnyAction => ({
  type: constants.ON_SELECT_MAILCHIMP_REQUESTED,
  campaignId,
  shareType,
});

export const onSelectMailchimpSucceded = (data: string, campaign: ICampaign): AnyAction => ({
  type: constants.ON_SELECT_MAILCHIMP_SUCCEDED,
  data,
  campaign,
});

export const onSelectMailchimpFailed = (): AnyAction => ({
  type: constants.ON_SELECT_MAILCHIMP_FAILED,
});

export const onMailchimpImport = (id: string): AnyAction => ({
  type: constants.ON_MAILCHIMP_IMPORT_REQUESTED,
  id,
});

export const onMailchimpImportSucceded = (data: IObject): AnyAction => ({
  type: constants.ON_MAILCHIMP_IMPORT_SUCCEEDED,
  data,
});

export const onMailchimpImportFailed = (): AnyAction => ({
  type: constants.ON_MAILCHIMP_IMPORT_FAILED,
});

export const initializeShareMatchMergeFields = (listId: string): AnyAction => ({
  type: constants.ON_INITIALIZE_MATCH_VIEW_MERGE_FIELDS_REQUESTED,
  listId,
});

export const initializeShareMatchMergeFieldsSuccedeed = (data: IObject): AnyAction => ({
  type: constants.ON_INITIALIZE_MATCH_VIEW_MERGE_FIELDS_SUCCEDED,
  data,
});

export const initializeShareMatchMergeFieldsFailed = (): AnyAction => ({
  type: constants.ON_INITIALIZE_MATCH_VIEW_MERGE_FIELDS_FAILED,
});

export const initializeShareMatchSegmenteMembers = (
  listId: string,
  segmentId: string
): AnyAction => ({
  type: constants.ON_INITIALIZE_MATCH_VIEW_SEGMENT_MEMBERS_REQUESTED,
  listId,
  segmentId,
});

export const initializeShareMatchSegmenteMembersSuccedeed = (data: IObject): AnyAction => ({
  type: constants.ON_INITIALIZE_MATCH_VIEW_SEGMENT_MEMBERS_SUCCEDED,
  data,
});

export const initializeShareMatchSegmenteMembersFailed = (): AnyAction => ({
  type: constants.ON_INITIALIZE_MATCH_VIEW_SEGMENT_MEMBERS_FAILED,
});

export const selectMailchimpLaunch = (
  campaignId: string,
  body: string,
  from: string,
  subject: string
): AnyAction => ({
  type: constants.ON_SELECT_MAILCHIMP_LAUNCH_REQUESTED,
  campaignId,
  body,
  from,
  subject,
});

export const selectMailchimpLaunchSuccedeed = (): AnyAction => ({
  type: constants.ON_SELECT_MAILCHIMP_LAUNCH_SUCCEDED,
});

export const selectMailchimpLaunchFailed = (): AnyAction => ({
  type: constants.ON_SELECT_MAILCHIMP_LAUNCH_FAILED,
});

export const selectMailchimpSend = (
  campaignId: string,
  body: string,
  from: string,
  subject: string
): AnyAction => ({
  type: constants.ON_SELECT_MAILCHIMP_SEND_MAIL_REQUESTED,
  campaignId,
  body,
  from,
  subject,
});

export const selectMailchimpSendSuccedeed = (): AnyAction => ({
  type: constants.ON_SELECT_MAILCHIMP_SEND_MAIL_SUCCEDED,
});

export const selectMailchimpSendFailed = (): AnyAction => ({
  type: constants.ON_SELECT_MAILCHIMP_SEND_MAIL_FAILED,
});

export const clickMoveStepButton = (url: string): AnyAction => ({
  type: constants.ON_CLICK_MOVE_STEP_BUTTON,
  url,
});

export const onInitializeShareMatch = (id: string): AnyAction => ({
  type: constants.ON_INITIALIZE_SHARE_MATCH_REQUESTED,
  id,
});

export const onInitializeShareMatchSucceded = (data: IObject): AnyAction => ({
  type: constants.ON_INITIALIZE_SHARE_MATCH_SUCCEDED,
  data,
});

export const onInitializeShareMatchFailed = (): AnyAction => ({
  type: constants.ON_INITIALIZE_SHARE_MATCH_FAILED,
});

export const onInitializeShareLaunch = (id: string): AnyAction => ({
  type: constants.ON_INITIALIZE_SHARE_LAUNCH_REQUESTED,
  id,
});

export const onInitializeShareLaunchFailed = (): AnyAction => ({
  type: constants.ON_INITIALIZE_SHARE_LAUNCH_FAILED,
});

export const onBackShareSelect = (campaignId: string, shareType: number): AnyAction => ({
  type: constants.ON_CLICK_BACK_SHARE_SELECT,
  campaignId,
  shareType,
});

export const onBackShareSelectSucceed = (campaignId: string, shareType: number): AnyAction => ({
  type: constants.ON_CLICK_BACK_SHARE_SELECT_SUCCEED,
  campaignId,
  shareType,
});

export const onBackShareSelectFailed = (): AnyAction => ({
  type: constants.ON_CLICK_BACK_SHARE_SELECT_FAILED,
});

export const onBackShareMatch = (campaignId: string): AnyAction => ({
  type: constants.ON_SELECT_BACK_SHARE_MATCH,
  campaignId,
});

export const onSelectBackImport = (id: string): AnyAction => ({
  type: constants.ON_SELECT_BACK_SHARE_IMPORT,
  id,
});

export const onSelectBackImportCSV = (campaignId: string): AnyAction => ({
  type: constants.ON_SELECT_BACK_SHARE_CSV,
  campaignId,
});

export const onSelectBackLaunch = (campaignId: string): AnyAction => ({
  type: constants.ON_SELECT_BACK_SHARE_LAUNCH,
  campaignId,
});

export const onSelectBackLaunchSucceed = (campaignId: string): AnyAction => ({
  type: constants.ON_SELECT_BACK_SHARE_LAUNCH_SUCCEED,
  campaignId,
});

export const onSelectBackLaunchFailed = (): AnyAction => ({
  type: constants.ON_SELECT_BACK_SHARE_LAUNCH_FAILED,
});

export const onRequestLink = (id: string): AnyAction => ({
  type: constants.ON_REQUEST_LINK,
  id,
});

export const onRequestLinkSucceded = (id: string): AnyAction => ({
  type: constants.ON_REQUEST_LINK_SUCCEDED,
  id,
});

export const onRequestLinkFailed = (): AnyAction => ({
  type: constants.ON_REQUEST_LINK_FAILED,
});

export const onRetrieveSegmentMembers = (listId: string, segmentId: string): AnyAction => ({
  type: constants.ON_RETRIEVE_SEGMENT_MEMBERS,
  listId,
  segmentId,
});

export const onRetrieveSegmentMembersSucceded = (data: IObject): AnyAction => ({
  type: constants.ON_RETRIEVE_SEGMENT_MEMBERS_SUCCEDED,
  data,
});

export const onRetrieveSegmentMembersFailed = (): AnyAction => ({
  type: constants.ON_RETRIEVE_SEGMENT_MEMBERS_FAILED,
});

export const onSelectEditTemplate = (id: string): AnyAction => ({
  type: constants.ON_SELECT_EDIT_TEMPLATE,
  id,
});

export const OnInitializeShareSelect = (campaignId: string): AnyAction => ({
  type: constants.ON_INITIALIZE_SELECT_VIEW_REQUESTED,
  campaignId,
});

export const OnInitializeShareSelectSucceeded = (data: ICampaign): AnyAction => ({
  type: constants.ON_INITIALIZE_SELECT_VIEW_SUCCEEDED,
  data,
});

export const OnInitializeShareSelectFailed = (): AnyAction => ({
  type: constants.ON_INITIALIZE_SELECT_VIEW_FAILED,
});
