import React, { FC, useState } from 'react';

import Wrapper from 'components/common/Wrapper/Wrapper';
import { PUBLIC_URL } from 'configs/index';
import StoreLayout from 'containers/StoreLayout/StoreLayout';
import { isEmpty, isPasswordMatch, isValidPassword } from 'helpers/inputHelper/inputHelper';
import i18n from 'i18next';

import './i18n';
import {
  Button,
  ButtonContainer,
  ButtonHome,
  Container,
  ContainerError,
  ErrorText,
  Form,
  IconBlock,
  IconContainer,
  Image,
  InputContainer,
  Inputs,
  LogInText,
  NormalLabel,
  TextPasswordRequirements,
  TokenInvalid,
  WrapperForgot,
} from './styles';
import { IProps } from './types';

const ForgotPassord: FC<IProps> = (props) => {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errors, setErrors] = useState({ passwordMatch: '' });
  const [validPassword, setValidPassword] = useState<boolean>(false);
  const [block, setBlock] = useState({
    password: false,
    confirmPassword: false,
  });

  const handleEye = (key: string) => {
    const blockEye = { ...block };
    blockEye[key] = !blockEye[key];
    setBlock(blockEye);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    handleValidation('password', event.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
    handleValidation('confirmPassword', password, event.target.value);
  };

  const resetPassword = () => {
    if (handleValidation('', password, confirmPassword)) {
      props.resetPassword(password);
    }
  };

  const handleValidation = (inputToValidate = '', password: string, confirmPassword: string) => {
    let formIsValid = true;
    let error = {
      passwordMatch: '',
    };
    if ((inputToValidate === '' || inputToValidate === 'password') && isEmpty(password)) {
      setValidPassword(true);
      formIsValid = false;
    } else {
      if ((inputToValidate === '' || inputToValidate === 'password') && isValidPassword(password)) {
        setValidPassword(true);
        formIsValid = false;
      } else {
        if (
          (inputToValidate === '' || inputToValidate === 'password') &&
          !isEmpty(password) &&
          !isValidPassword(password)
        ) {
          setValidPassword(false);
        }
      }
    }
    if (
      (inputToValidate === '' || inputToValidate === 'confirmPassword') &&
      isEmpty(confirmPassword)
    ) {
      error = {
        ...error,
        passwordMatch: i18n.t('FORM:NEW_CONFIRM_PASSWORD_EMPTY'),
      };
      formIsValid = false;
    } else {
      if (
        (inputToValidate === '' || inputToValidate === 'confirmPassword') &&
        !isEmpty(confirmPassword)
      ) {
        error = { ...error, passwordMatch: '' };
      }
    }
    if (
      !isEmpty(password) &&
      !isValidPassword(password) &&
      !isEmpty(confirmPassword) &&
      isPasswordMatch(password, confirmPassword)
    ) {
      formIsValid = false;
      error = {
        ...error,
        passwordMatch: i18n.t('FORM:PASSWORDS_DONT_MATCH'),
      };
    } else {
      if (
        !isEmpty(password) &&
        !isEmpty(confirmPassword) &&
        !isValidPassword(password) &&
        !isPasswordMatch(password, confirmPassword)
      ) {
        error = { ...error, passwordMatch: '' };
      }
    }
    setErrors(error);
    return formIsValid;
  };

  return (
    <StoreLayout isTemplate="">
      <LogInText>{i18n.t('FORGOT:CHANGEPASS')}</LogInText>
      <WrapperForgot>
        <Wrapper>
          {props.isTokenValid !== '' && (
            <Container>
              {props.isTokenValid === 'validToken' ? (
                <>
                  <Form>
                    <NormalLabel>{i18n.t('FORGOT:NEWPASS')}</NormalLabel>
                    <InputContainer>
                      <Inputs
                        type={block.password ? 'text' : 'password'}
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder={i18n.t('FORGOT:PASS')}
                        maxLength={40}
                      ></Inputs>
                      {block.password ? (
                        <IconBlock
                          onClick={() => handleEye('password')}
                          src={`${PUBLIC_URL}/images/eye-n.svg`}
                        ></IconBlock>
                      ) : (
                        <IconBlock
                          onClick={() => handleEye('password')}
                          src={`${PUBLIC_URL}/images/see_pass.svg`}
                        ></IconBlock>
                      )}
                    </InputContainer>
                    <TextPasswordRequirements validPassword={validPassword}>
                      {i18n.t('SIGNUP:PASSWORD_REQUIREMENTS')}
                    </TextPasswordRequirements>
                    <InputContainer>
                      <Inputs
                        type={block.confirmPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        placeholder={i18n.t('FORGOT:CONFIRMPASS')}
                        maxLength={40}
                      ></Inputs>
                      {block.confirmPassword ? (
                        <IconBlock
                          onClick={() => handleEye('confirmPassword')}
                          src={`${PUBLIC_URL}/images/eye-n.svg`}
                        ></IconBlock>
                      ) : (
                        <IconBlock
                          onClick={() => handleEye('confirmPassword')}
                          src={`${PUBLIC_URL}/images/see_pass.svg`}
                        ></IconBlock>
                      )}
                      <ErrorText>{errors.passwordMatch}</ErrorText>
                    </InputContainer>
                  </Form>
                  <ButtonContainer>
                    <Button onClick={resetPassword}>{i18n.t('FORGOT:CHANGEPASS')}</Button>
                  </ButtonContainer>
                </>
              ) : (
                <ContainerError>
                  <IconContainer>
                    <Image src={`${PUBLIC_URL}/images/bms_logo.svg`} />
                  </IconContainer>
                  <TokenInvalid>{i18n.t('FORGOT:RESET')}</TokenInvalid>
                  <ButtonHome to="/">{i18n.t('FORGOT:HOME')}</ButtonHome>
                </ContainerError>
              )}
            </Container>
          )}
        </Wrapper>
      </WrapperForgot>
    </StoreLayout>
  );
};
export default ForgotPassord;
