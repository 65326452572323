import React, { FC } from 'react';

import { usePlayerResize } from 'helpers/playerResize.helper';
import Video from 'views/Video/Video';

import { Template, TemplateContainer, VideoStyle } from './styles';
import { IProps } from './types';

const Player: FC<IProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [width, handleWindowSizeChange, height]: any[] = usePlayerResize();

  window.addEventListener('resize', handleWindowSizeChange);

  return props.scenes.length ? (
    <TemplateContainer>
      <Template width={width} height={height}>
        <VideoStyle>
          <Video
            isPlaying={false}
            layers={props.layers}
            segments={props.segments}
            textVariables={props.textVariables}
            imageVariables={props.imageVariables}
            colorVariables={props.colorVariables}
            handleTextChange={props.handleTextChange}
            backgroundColor={props.backgroundColor}
            isEdit={true}
            frame={props.scenes[0].frameEnd}
            replay={false}
            backgroundVideo={props.backgroundVideo}
            autoplayBackgroundVideo={false}
            removeVideoData={props.onRemoveVideoData}
            currentFrameDistance={props.currentFrameDistance}
          />
        </VideoStyle>
      </Template>
    </TemplateContainer>
  ) : null;
};
export default Player;
