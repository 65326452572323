import styled from 'styled-components';

import { ISize } from './types';

export const TemplateContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.porcelain};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

export const VideoContainer = styled.div<ISize>`
  width: 80%;
  position: relative;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ControlsWrapper = styled.div<ISize>`
  width: ${(props) => props.width}px;
  height: 40px;
`;
